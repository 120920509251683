/** @jsxImportSource @emotion/react */

import React, { useEffect, useState } from "react";

import ApiTask from "../../../API/test/task/task";

import * as styles from "./fina.style";

const getDateObj = (timestamp) => {
  const dateValue = new Date(timestamp);

  const year = dateValue.getFullYear();
  const month = dateValue.getMonth().toString().padStart(2, "0");
  const date = dateValue.getDate().toString().padStart(2, "0");

  return {
    year,
    month,
    date,
  };
};

const defaultStartAt = getDateObj(Date.now() - 60 * 60 * 24 * 30);

function TestTaskFina() {
  const [showType, setShowType] = useState(0);

  const [selectTaskIdx, setSelectTaskIdx] = useState(undefined);

  const [taskPage, setTaskPage] = useState(1);
  const [lastTaskElement, setLastTaskElement] = useState(null);
  const [taskLast, setTaskLast] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const taskIO = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        nextPageTaskList();
      }
    });
  });

  const [companyInfoPage, setCompanyInfoPage] = useState(1);
  const [lastCompanyInfoElement, setLastCompanyInfoElement] = useState(null);
  const [companyInfoLast, setCompanyInfoLast] = useState(false);
  const [companyInfoList, setCompanyInfoList] = useState([]);

  const companyInfoIO = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        nextPageCompanyInfoList();
      }
    });
  });

  const [finaInfoList, setFinaInfoList] = useState([]);

  const [rivalPage, setRivalPage] = useState(1);
  const [lastRivalElement, setLastRivalElement] = useState(null);
  const [rivalLast, setRivalLast] = useState(false);
  const [rivalList, setRivalList] = useState([]);

  const [riverStartAt, setRiverStartAt] = useState(
    `${defaultStartAt.year}-${defaultStartAt.month}-${defaultStartAt.date}`
  );
  const [riverEndAt, setRiverEndAt] = useState("");

  const [selectedCompanyInfo, setSelectedCompanyInfo] = useState(undefined);
  const [selectedCompanyInfoForFina, setSelectedCompanyInfoForFina] =
    useState(undefined);

  const rivalIO = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        nextPageRivalList();
      }
    });
  });

  const [companyBidInfo, setCompanyBidInfo] = useState(undefined);

  const getTaskList = async () => {
    const response = await ApiTask.getTaskListAPI({ page: taskPage });

    if (response?.status !== 200) {
      alert(
        "서버와 연결에 오류가 발생했습니다." + "\n잠시 후 다시 시도해주세요."
      );
    }

    if (response.data.length === 0) {
      setTaskLast(true);
      return;
    }

    if (taskPage === 1) {
      setTaskList(response.data);
    } else {
      setTaskList([...taskList, ...response.data]);
    }
  };

  const nextPageTaskList = () => {
    if (taskLast) return;

    if (taskIO && lastTaskElement) {
      taskIO.unobserve(lastTaskElement);
    }

    setTaskPage(taskPage + 1);
  };

  const getTaskCompanyInfo = async () => {
    if (selectTaskIdx === undefined) return;

    const response = await ApiTask.getTaskBidderAPI(selectTaskIdx, {
      page: companyInfoPage,
    });

    if (response?.status !== 200) {
      alert(
        "서버와 연결에 오류가 발생했습니다." + "\n잠시 후 다시 시도해주세요."
      );
    }

    if (response.data.items.length === 0) {
      setCompanyInfoLast(true);
      return;
    }

    if (companyInfoPage === 1) {
      setCompanyInfoList(response.data.items);
    } else {
      setCompanyInfoList([...companyInfoList, ...response.data.items]);
    }
  };

  const nextPageCompanyInfoList = () => {
    if (companyInfoLast) return;

    if (companyInfoIO && lastCompanyInfoElement) {
      companyInfoIO.unobserve(lastCompanyInfoElement);
    }

    setCompanyInfoPage(companyInfoPage + 1);
  };

  const getCompanyFinaList = async () => {
    if (
      selectedCompanyInfoForFina?.corporate_number === undefined ||
      selectedCompanyInfoForFina.corporate_number === null ||
      selectedCompanyInfoForFina.bizYear === null
    )
      return;

    const response = await ApiTask.getTaskBidderFinaListAPI(
      selectedCompanyInfoForFina.corporate_number
    );

    if (response?.status !== 200) {
      alert(
        "서버와 연결에 오류가 발생했습니다." + "\n잠시 후 다시 시도해주세요."
      );
    }

    setFinaInfoList(response.data);
  };

  const getCompanyRivalList = async () => {
    if (
      selectedCompanyInfo?.license_number === undefined ||
      selectedCompanyInfo.license_number === null
    )
      return;

    const params = {
      licenseNumber: selectedCompanyInfo.license_number,
      page: rivalPage,
    };

    if (riverStartAt !== "") params.startDate = riverStartAt;
    if (riverEndAt !== "") params.endDate = riverEndAt;

    const response = await ApiTask.getTaskBidderRivalListApi(params);

    if (response?.status !== 200) {
      alert(
        "서버와 연결에 오류가 발생했습니다." + "\n잠시 후 다시 시도해주세요."
      );
    }

    if (response.data.items.length === 0) {
      setRivalLast(true);

      if (rivalPage === 1) {
        setRivalList([{ company_name: "경쟁사 정보가 없습니다." }]);
      }
      return;
    }

    if (rivalPage === 1) {
      setRivalList(response.data.items);
    } else {
      setRivalList([...rivalList, ...response.data.items]);
    }
  };

  const getCompanyBidInfo = async () => {
    if (selectedCompanyInfoForFina?.license_number === undefined) return;

    const response = await ApiTask.getTaskBidderInfo({
      licenseNumber: selectedCompanyInfoForFina.license_number,
    });

    if (response?.status !== 200) {
      alert(
        "서버와 연결에 오류가 발생했습니다." + "\n잠시 후 다시 시도해주세요."
      );
    }

    setCompanyBidInfo(response.data);
  };

  const nextPageRivalList = () => {
    if (rivalLast) return;

    if (rivalIO && lastRivalElement) {
      rivalIO.unobserve(lastRivalElement);
    }

    setRivalPage(rivalPage + 1);
  };

  useEffect(() => {
    if (showType === 0) {
      getTaskList();
    }
  }, [showType, taskPage]);

  useEffect(() => {
    if (taskIO && lastTaskElement) {
      taskIO.observe(lastTaskElement);
    }
  }, [lastTaskElement]);

  useEffect(() => {
    if (companyInfoIO && lastCompanyInfoElement) {
      companyInfoIO.observe(lastCompanyInfoElement);
    }
  }, [lastCompanyInfoElement]);

  useEffect(() => {
    getTaskCompanyInfo();
  }, [selectTaskIdx, companyInfoPage]);

  useEffect(() => {
    getCompanyFinaList();
    getCompanyBidInfo();
  }, [selectedCompanyInfoForFina]);

  useEffect(() => {
    if (rivalIO && lastRivalElement) {
      rivalIO.observe(lastRivalElement);
    }
  }, [lastRivalElement]);

  useEffect(() => {
    getCompanyRivalList();
  }, [rivalPage, selectedCompanyInfo, riverStartAt, riverEndAt]);

  return (
    <div css={styles.container}>
      <div css={styles.typeWrap}>
        <button onClick={() => setShowType(0)}>공고로 조회</button>
        <button onClick={() => setShowType(1)}>회사로 조회</button>
      </div>
      {showType === 0 ? (
        <div css={styles.taskBody}>
          <div css={styles.taskInfoWrap}>
            <div css={styles.taskTitle}>
              공고 리스트
              <div>(색상 O: 입찰자 및 낙찰자 정보 모두존재)</div>
              <div>(색상 X: 낙찰자 정보만 존재)</div>
            </div>
            <div css={styles.taskListWrap}>
              {taskList.map((v, i) => {
                return (
                  <div
                    ref={taskList.length - 1 === i ? setLastTaskElement : null}
                    css={styles.taskItemWrap(v.has_bidder)}
                    key={i}
                    onClick={() => {
                      setSelectTaskIdx(v.idx);
                      setCompanyInfoPage(1);
                      setCompanyInfoLast(false);
                    }}
                  >
                    {v.title}
                  </div>
                );
              })}
            </div>
          </div>

          <div css={styles.taskInfoWrap}>
            <div css={styles.taskTitle}>
              입찰 회사 리스트
              <div>(색상 O: 낙찰자)</div>
              <div>(색상 X: 입찰자)</div>
            </div>
            <div css={styles.taskListWrap}>
              {companyInfoList.map((v, i) => {
                return (
                  <div
                    ref={
                      companyInfoList.length - 1 === i
                        ? setLastCompanyInfoElement
                        : null
                    }
                    css={styles.companyInfoWrap(v.is_winner)}
                    key={i}
                    onClick={() => {
                      setRivalPage(1);
                      setRivalLast(false);
                      setSelectedCompanyInfo(v);
                      setSelectedCompanyInfoForFina(v);
                    }}
                  >
                    <div>{v.company_name}</div>

                    <div>
                      {v.corporate_number === null || v.bizYear === null ? (
                        <span css={styles.finaInfo(false)}>재무정보 X</span>
                      ) : (
                        <span css={styles.finaInfo(true)}>재무정보 O</span>
                      )}
                    </div>
                    <div>
                      <div>사업자번호</div>
                      <div>{v.license_number}</div>
                    </div>
                    <div>
                      <div>법인번호</div>
                      <div>{v.corporate_number || "없음"}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div css={styles.rivalListWrap}>
            <div css={styles.taskTitle}>
              경쟁사 리스트 "{selectedCompanyInfo?.company_name || ""}"
              <div>
                <input
                  type={"date"}
                  value={riverStartAt}
                  onChange={(e) => {
                    setRiverStartAt(e.target.value);
                  }}
                />
              </div>
              <div>
                <input
                  type={"date"}
                  value={riverEndAt}
                  onChange={(e) => {
                    setRiverEndAt(e.target.value);
                  }}
                />
              </div>
            </div>
            <div css={styles.taskListWrap}>
              {rivalList.map((v, i) => {
                return (
                  <div
                    ref={
                      rivalList.length - 1 === i ? setLastRivalElement : null
                    }
                    css={styles.companyInfoWrap(0)}
                    key={i}
                    onClick={() => {
                      setSelectedCompanyInfoForFina(v);
                    }}
                  >
                    <div>{v.name || v.company_name}</div>
                    <div>
                      <div>공통지원공고수</div>
                      <div>{v.cnt}개</div>
                    </div>
                    <div>
                      <div>사업자번호</div>
                      <div>{v.license_number}</div>
                    </div>
                    <div>
                      <div>등급</div>
                      <div>{v.total}</div>
                    </div>
                    <div>
                      <div>재무정보</div>
                      <div>
                        {!v.corporate_number || v.bizYear === null ? (
                          <span css={styles.finaInfo(false)}>X</span>
                        ) : (
                          <span css={styles.finaInfo(true)}>O</span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div css={styles.rivalInfoWrap}>
            <div css={styles.taskTitle}>
              경쟁사 정보 "{selectedCompanyInfoForFina?.company_name || ""}"
            </div>
            <div css={styles.taskListWrap}>
              <div>지원 정부과제: {companyBidInfo?.cnt}개</div>
              <div>낙찰 정부과제: {companyBidInfo?.win_cnt}개</div>
              <div>
                낙찰율:{" "}
                {(
                  (companyBidInfo?.win_cnt / companyBidInfo?.cnt) *
                  100
                ).toFixed(2)}
                %
              </div>
            </div>
          </div>

          <div css={styles.finaInfoWrap}>
            <div css={styles.taskTitle}>
              재무 정보 리스트 "{selectedCompanyInfoForFina?.company_name}"
            </div>
            <div css={styles.taskListWrap}>
              {selectedCompanyInfoForFina?.corporate_number === null ||
              selectedCompanyInfoForFina?.bizYear === null ? (
                <div css={styles.finaItemWrap}>
                  <div>
                    <div>해당 회사의 법인번호 정보가 없거나</div>
                    <div>법인정보로 조회되는 재무 정보가 없습니다.</div>
                  </div>
                </div>
              ) : (
                finaInfoList.map((v, i) => {
                  return (
                    <div css={styles.finaItemWrap} key={i}>
                      <div>
                        <div>연도</div>
                        <div>{v.bizYear}</div>
                      </div>
                      <div>
                        <div>매출</div>
                        <div>{v.enpSaleAmt.toLocaleString()}</div>
                      </div>
                      <div>
                        <div>영업이익</div>
                        <div>{v.enpBzopPft.toLocaleString()}</div>
                      </div>
                      <div>
                        <div>포괄손익계산액</div>
                        <div>{v.iclsPalClcAmt.toLocaleString()}</div>
                      </div>
                      <div>
                        <div>당기순이익</div>
                        <div>{v.enpCrtmNpf.toLocaleString()}</div>
                      </div>
                      <div>
                        <div>총자산금액</div>
                        <div>{v.enpTastAmt.toLocaleString()}</div>
                      </div>
                      <div>
                        <div>총부채금액</div>
                        <div>{v.enpTdbtAmt.toLocaleString()}</div>
                      </div>
                      <div>
                        <div>총자본금액</div>
                        <div>{v.enpTcptAmt.toLocaleString()}</div>
                      </div>
                      <div>
                        <div>자본금액</div>
                        <div>{v.enpCptlAmt.toLocaleString()}</div>
                      </div>
                      <div>
                        <div>재무제표부채비율</div>
                        <div>{v.fnclDebtRto}</div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default TestTaskFina;
