import React, { useState } from "react";
import "../../css/CandidatePop.scss";
import FilterButton from "./FilterButton";
import FilterContent from "./FilterContent";
import { isUndefined } from "lodash";

const CandidatePopLeft = ({ data }) => {
  const filterArr = ["특허요약", "국내시장", "국외시장", "대표기업"];
  const [filter, setFilter] = useState("특허요약");
  const commentMap = {
    특허요약: data?.summary
      ?.replace(/\.$/, "")
      ?.split(". ")
      ?.map((element) => element + "."),
    국내시장: data?.market_info
      ?.replace(/\.$/, "")
      ?.split(". ")
      ?.map((element) => element + "."),
    국외시장: data?.for_market_info
      ?.replace(/\.$/, "")
      ?.split(". ")
      ?.map((element) => element + "."),
    대표기업: data?.allied_company?.split("|"),
  };
  return (
    <div className="left">
      <FilterButton
        selectedFilter={filter}
        setSelectedFilter={setFilter}
        filterArr={filterArr}
      />
      <FilterContent comment={!isUndefined(data) ? commentMap[filter] : []} />
    </div>
  );
};

export default CandidatePopLeft;
