import React from "react";
import { Link } from "react-router-dom";

function UserTab({ member, onClickLogout, unEvent }) {
  const isViewTypeIpnow = window.$Global.isIpnow();
  const isCompany = window.$Global.checkAuth("특허회사", member.auth);
  const isInvestor = window.$Global.checkAuth("투자자", member.auth);

  return (
    <div className="user_tab">
      <ul className="header_user_tab_ul">
        {!isInvestor &&
          !window.$Global.checkAuth("특허회사_발명자만", member.auth) && (
            <li>
              <Link to="/unEvent">
                <i
                  className={
                    unEvent.unConfirmEventCnt > 0
                      ? "icon_notification_on"
                      : "icon_notification"
                  }
                />
                <span
                  style={{
                    color:
                      unEvent.unConfirmEventCnt > 0 ? "#4593f5" : "#343434",
                  }}
                >
                  {unEvent.unConfirmEventCnt}건
                </span>
              </Link>
            </li>
          )}
        <li>
          <Link to="/mypage/info">마이 페이지</Link>
        </li>
        {((!window.$Global.checkAuth("특허회사_발명자만", member.auth) &&
          !window.$Global.checkAuth("대리인_담당자만", member.auth)) ||
          isInvestor) && (
          <li>
            <Link to={isInvestor ? "/manage/product" : "/manage/inventor"}>
              <i
                className="icon_admin"
                style={{ width: "21px", height: "21px", marginRight: 5 }}
              />
              관리자
            </Link>
          </li>
        )}
        {isViewTypeIpnow && isCompany && (
          <li>
            <Link to="/approval/list">결재</Link>
          </li>
        )}
        <li onClick={onClickLogout}>로그아웃</li>
      </ul>
    </div>
  );
}

export default React.memo(UserTab);
