import React, { useState, useContext } from "react";
import "./css/NameSearchPopup.scss";
import { Link, useParams } from "react-router-dom";
import TradeMarkAPI from "../../API/trademark";
import TradeMarkContext from "../../store/TradeMark";
import product from "../../API/product";
const NameSearchPopup = ({
  nationListArr,
  setNationListArr,
  setIsNationPopupClick,
  setSearchPopupClick,
  //tradeMark,setTradeMark,
  setData,
  data,
}) => {
  //let trademarkInfo = useContext(TradeMarkContext);
  //const [searchQueryProduct, setSearchQueryProduct]=useState();
  const [searchQuery, setSearchQuery] = useState({
    product_list: [{ history: "" }],
    BrandName: "",
    applicant: "",
    rightHolderName: "",
  });
  const [saveResponse, setSaveResponse] = useState(null);
  const [searchResultsProduct, setSearchResultsProduct] = useState([]);
  const [searchResultsApplicant, setSearchResultsApplicant] = useState([]);
  const [searchResultsRightholder, setSearchResultsRightholder] = useState([]);
  const [searchResultsBrandname, setSearchResultsBrandname] = useState([]);
  const { trademark_idx } = useParams();
  const [type, setType] = useState("1");
  const [selectedSearchTerm, setSelectedSearchTerm] = useState("");

  const handleSearchInputChangeProduct = async (e, type) => {
    const searchTerm = e.target.value;
    const encodedSearchTerm = searchTerm;
    try {
      const response = await TradeMarkAPI.searchTest(
        trademark_idx,
        type,
        encodedSearchTerm
      );
      if (type === "1") {
        setSearchResultsProduct(response.data);
      } else if (type === "2") {
        setSearchResultsApplicant(response.data);
      } else if (type === "3") {
        setSearchResultsRightholder(response.data);
      } else if (type === "4") {
        setSearchResultsBrandname(response.data);
      } else if (response.data === 0) {
        window.confirm("검색된 결과가 없습니다");
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  const handleResultButtonClick = (product_list) => {
    const searchTerm = product_list.history;
    setData({
      ...data,
      product_list: [{ history: searchTerm }],
    });
  };

  const handleResultButtonClick1 = (rightHolderName) => {
    console.log("Button clicked:", rightHolderName);
    setData({
      ...data,
      right_holder_name: rightHolderName.history,
    });
  };
  const handleResultButtonClick2 = (brandName) => {
    setData({
      ...data,
      name: brandName.history,
    });
  };
  const handleResultButtonClick3 = (applicantName) => {
    console.log("Button clicked:", applicantName);
    setData({
      ...data,
      applicant_name: applicantName.history,
    });
  };
  const onClickregist = async () => {
    const encodedSearchTerm = encodeURIComponent(selectedSearchTerm);
    try {
      const response = await TradeMarkAPI.searchTest2(
        trademark_idx,
        type,
        encodedSearchTerm
      );
      setSaveResponse(response);
      if (type === "1") {
        // type 1에 대한 데이터 저장 로직
        if (searchResultsProduct.length === 0) {
          setSearchQuery((tradeMarks) => ({
            ...tradeMarks,
            Product: selectedSearchTerm, // 검색어 그대로 저장
          }));
        }
      } else if (type === "2") {
        if (searchResultsApplicant.length === 0) {
          setSearchQuery((tradeMarks) => ({
            ...tradeMarks,
            applicant: selectedSearchTerm,
          }));
        }
      } else if (type === "3") {
        if (searchResultsRightholder.length === 0) {
          setSearchQuery((tradeMarks) => ({
            ...tradeMarks,
            rightHolderName: selectedSearchTerm,
          }));
        }
      } else if (type === "4") {
        if (searchResultsBrandname.length === 0) {
          setSearchQuery((tradeMarks) => ({
            ...tradeMarks,
            brandName: selectedSearchTerm,
          }));
        }
      }
    } catch (error) {
      console.error("데이터 저장 중 오류 발생:", error);
    }
  };
  return (
    <div id="NameSearchPopup">
      <header>
        <h2>검색</h2>
        <button onClick={onClickregist}>제품 등록</button>
        <i className="icon_exit" onClick={() => setSearchPopupClick(false)} />
      </header>
      <div className="nation_list_wrapper">
        <ul>
          <div className="search_wrapper">
            <select
              onChange={(e) => {
                const selectedType = e.target.value;
                setType(selectedType);
                setSelectedSearchTerm("");
              }}
            >
              <option value="1">관련제품명</option>
              <option value="2">출원인</option>
              <option value="3">최종관리자</option>
              <option value="4">상표명칭</option>
              {/* Add more options as needed */}
            </select>

            <input
              type="text"
              placeholder="Search"
              value={selectedSearchTerm}
              onChange={(e) => setSelectedSearchTerm(e.target.value)}
              onBlur={(e) => handleSearchInputChangeProduct(e, type)}
            />
            <i className="icon_search_gray" />
          </div>
        </ul>

        {/* Render search results based on the selected type */}
        {type === "1" && (
          <div>
            {searchResultsProduct.length === 0 ? (
              <div>검색 결과가 없습니다</div>
            ) : (
              searchResultsProduct.map((product) => (
                <button
                  onClick={() => {
                    handleResultButtonClick(product);
                    setSearchPopupClick(false);
                  }}
                >
                  {product.history}
                </button>
              ))
            )}
          </div>
        )}
        {type === "2" && (
          <div>
            {searchResultsApplicant.length === 0 ? (
              <div>검색 결과가 없습니다</div>
            ) : (
              searchResultsApplicant.map((applicantName) => (
                <button
                  onClick={() => {
                    handleResultButtonClick3(applicantName);
                    setSearchPopupClick(false);
                  }}
                >
                  {applicantName.history}
                </button>
              ))
            )}
          </div>
        )}
        {type === "3" && (
          <div>
            {searchResultsRightholder.length === 0 ? (
              <div>검색 결과가 없습니다</div>
            ) : (
              searchResultsRightholder.map((rightHolderName) => (
                <button
                  onClick={() => {
                    handleResultButtonClick1(rightHolderName);
                    setSearchPopupClick(false);
                  }}
                >
                  {rightHolderName.history}
                </button>
              ))
            )}
          </div>
        )}
        {type === "4" && (
          <div>
            {searchResultsBrandname.length === 0 ? (
              <div>검색 결과가 없습니다</div>
            ) : (
              searchResultsBrandname.map((brandName) => (
                <button
                  onClick={() => {
                    handleResultButtonClick2(brandName);
                    setSearchPopupClick(false);
                  }}
                >
                  {brandName.history}
                </button>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NameSearchPopup;
