import API from "../../util/api";

export default {
  getTransferPatentList(params) {
    return API.get(`/common/statistics/getTransferPatentList`, {
      params: params,
    });
  },
  getFindBuyers(params) {
    return API.get(`/common/statistics/getFindBuyers`, {
      params: params,
    });
  },
};
