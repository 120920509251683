const TitleAndDeleteRow = ({ title, index, editing, onDelete }) => {
  return (
    <div className="inventor-wrapper">
      <div className="inventor">
        <h2>
          {title === "발명의 공개" ? "사유" : title} {index + 1}
        </h2>
        {onDelete && editing && (
          <button
            className="icon_badge_del"
            onClick={() => onDelete(title, index)}
          />
        )}
      </div>
      {title === "발명의 공개" && (
        <div className="inventor">
          <h2>공개</h2>
        </div>
      )}
    </div>
  );
};

export default TitleAndDeleteRow;
