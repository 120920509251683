import InputRow from "../row/InputRow";
import SelectRow from "../../patent/detail/common/SelectRow";
import ToggleButtonRow from "../row/ToggleButtonRow";
import DatePickerRow from "../row/DatePickerRow";
import React, { useEffect, useContext, useState } from "react";
import TradeMarkFileTypeContext from "../../../store/TradeMarkFileType";

function FormInfoOverseas({
  tradeMark,
  setTradeMark,
  isNewTrademark,
  managerSelectTag,
  stateDepth,
  setStateDepth,
  stateList,
  secondStateOption,
  agentListSelectTag,
  onClickDownPopShow,
  onClickUpPopShow,
  setIsNationPopupClick,
  fileImage,
  fpFileImage,
  onClickSave,
  handleEditing,
  setSearchPopupClick,
  productSelect,
}) {
  const fileType = useContext(TradeMarkFileTypeContext);

  return (
    <div
      id="infoField"
      className={`field ${
        tradeMark.infoEditing && !isNewTrademark ? "edit-mode-field" : ""
      }`}
    >
      <div className="field_header">
        <h5 className="title">서지사항</h5>

        {!isNewTrademark && tradeMark.infoEditing ? (
          <>
            <div className="btn-group">
              <button className="btn_save" onClick={onClickSave}>
                저장
              </button>
              <button
                className="btn_cancel"
                onClick={() => {
                  handleEditing("infoCancelEditing");
                }}
              >
                취소
              </button>
            </div>
          </>
        ) : (
          !isNewTrademark &&
          !tradeMark.infoEditing && (
            <button
              className="btn_edit"
              onClick={() => handleEditing("infoEditing")}
            >
              수정
            </button>
          )
        )}
      </div>
      <div className="rows">
        <InputRow
          title="관리번호"
          conditions={tradeMark.infoEditing}
          content={tradeMark.manage_number}
          onChange={(e) =>
            setTradeMark({
              ...tradeMark,
              manage_number: e.target.value,
            })
          }
        />
        <SelectRow
          title="담당자"
          content={tradeMark.manager_name}
          onChange={(e) =>
            setTradeMark({
              ...tradeMark,
              manager_idx: e.target.value,
              manager_name: e.target.options[e.target.selectedIndex].text,
            })
          }
          conditions={tradeMark.infoEditing}
          contentIdx={tradeMark.manager_idx}
          selectTag={managerSelectTag}
        />
        <div className="row">
          <h2 className="title">현 상태</h2>
          {tradeMark.infoEditing ? (
            <>
              <select
                value={stateDepth.first}
                onChange={(e) => {
                  stateDepth.first = e.target.value;
                  setStateDepth({ ...stateDepth });
                }}
              >
                {Object.keys(stateList).map((item) => {
                  return (
                    <option value={item}>
                      {item === "active" ? "활성화" : "비활성화"}
                    </option>
                  );
                })}
              </select>
              {stateDepth.first && (
                <select
                  value={tradeMark.state + "." + tradeMark.termination_type}
                  onChange={(e) => {
                    setTradeMark({
                      ...tradeMark,
                      state: e.target.value.split(".")[0],
                      termination_type: e.target.value.split(".")[1],
                    });
                  }}
                >
                  <option
                    value={tradeMark.state + "." + tradeMark.termination_type}
                  >
                    {window.$Global.convertStateName(
                      Number(tradeMark.state),
                      Number(tradeMark.termination_type)
                    )}
                  </option>
                  {secondStateOption.map((item) => (
                    <option value={item.state + "." + item.termination_type}>
                      {item.label}
                    </option>
                  ))}
                </select>
              )}
            </>
          ) : (
            <p
              style={{
                color:
                  tradeMark.state === 98
                    ? "red"
                    : tradeMark.state === 7 && "blue",
              }}
            >
              {window.$Global.convertStateName(
                Number(tradeMark.state),
                Number(tradeMark.termination_type)
              )}
            </p>
          )}
        </div>
      </div>
      <div className="rows">
        <InputRow
          title="대리인 Ref."
          conditions={tradeMark.infoEditing}
          content={tradeMark.agent_ref}
          onChange={(e) =>
            setTradeMark({
              ...tradeMark,
              agent_ref: e.target.value,
            })
          }
        />
        <SelectRow
          title="국내 대리인"
          content={tradeMark.agent_company_name}
          onChange={(e) =>
            setTradeMark({
              ...tradeMark,
              agent_company_idx: e.target.value,
            })
          }
          conditions={tradeMark.infoEditing}
          contentIdx={tradeMark.agent_company_idx}
          selectTag={agentListSelectTag}
        />
        <div className="row no_data">
          <h2 className="title">상표 견본이미지</h2>
          <div className="btns">
            {!isNewTrademark && (
              <button
                className={
                  tradeMark.image_key
                    ? "icon_download_attached"
                    : "icon_download"
                }
                onClick={() => onClickDownPopShow(fileType.image)}
              />
            )}
            <button
              className="icon_upload"
              onClick={() => onClickUpPopShow(fileType.image)}
            />
          </div>
        </div>
      </div>
      <div className="rows">
        <InputRow
          title="해외 대리인"
          conditions={tradeMark.infoEditing}
          content={tradeMark.overseas_agent_company_name}
          onChange={(e) =>
            setTradeMark({
              ...tradeMark,
              overseas_agent_company_name: e.target.value,
            })
          }
        />

        <div className="row">
          <h2>지역</h2>
          <span>
            {window.$Global.convertNationName(tradeMark.nation) || "미선택"}
          </span>

          {tradeMark.infoEditing && (
            <i
              className="icon_plus"
              onClick={() => setIsNationPopupClick(true)}
            />
          )}
        </div>
      </div>
      <div className="rows">
        <div className="row">
          <InputRow
            title="출원인"
            conditions={tradeMark.editing || tradeMark.infoEditing}
            content={tradeMark.applicant_name}
            onChange={(e) =>
              setTradeMark({
                ...tradeMark,
                applicant_name: e.target.value,
              })
            }
          />
        </div>
        <div className="row">
          <InputRow
            title="최종권리자"
            conditions={tradeMark.infoEditing}
            content={tradeMark.right_holder_name}
            onChange={(e) => {
              const searchTerm = e.target.value;
              setTradeMark({
                ...tradeMark,
                right_holder_name: searchTerm,
              });
            }}
          />
        </div>
        <div className="thumbnail">
          {Boolean(tradeMark.image_key) ? (
            tradeMark.image_type == "svg" ? (
              <span>미리보기를 지원하지 않는 파일형식</span>
            ) : (
              <img
                src={window.$Global.getCDN(tradeMark.image_key)}
                alt="이미지"
                onError={(error) => console.log(error)}
                onClick={() =>
                  window.$Global.handleCopyImage(
                    window.$Global.getCDN(tradeMark.image_key)
                  )
                }
              />
            )
          ) : fileImage ? (
            <img src={fileImage} alt="sample" />
          ) : (
            <span>미리보기</span>
          )}
        </div>
      </div>
      <div className="rows">
        <div className="row">
          <InputRow
            title="상표명칭"
            conditions={tradeMark.infoEditing}
            content={tradeMark.name}
            onChange={(e) => {
              const searchTerm = e.target.value;
              setTradeMark({
                ...tradeMark,
                name: searchTerm,
              });
            }}
          />
        </div>
        <div className="row">
          <SelectRow
            title="관련제품명"
            content={
              tradeMark.product_list[0] ? tradeMark.product_list[0].name : null
            }
            onChange={(e) =>
              setTradeMark({
                ...tradeMark,
                product_list: [{ idx: e.target.value, state: 0 }],
              })
            }
            conditions={tradeMark.editing || tradeMark.infoEditing}
            contentIdx={
              tradeMark.product_list[0] ? tradeMark.product_list[0].idx : 0
            }
            selectTag={productSelect}
          />
        </div>
      </div>
    </div>
  );
}

export default FormInfoOverseas;
