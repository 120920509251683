import moment from "moment";
import "../css/CandidatePop.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CandidatePopRight from "./CandidatePop/CandidatePopRight";
import CandidatePopLeft from "./CandidatePop/CandidatePopLeft";
import { useEffect, useState } from "react";
import transfer from "../../../API/transfer";

const CandidatePop = ({ closePopup, report_idx }) => {
  const history = useHistory();
  const [buyersData, setBuyersData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [page, setPage] = useState(1);

  const fetchBuyersData = async () => {
    const params = {
      report_idx: report_idx,
      page: page,
    };
    const data = await transfer.getFindBuyers(params);
    setBuyersData(data.data || []);
    setTotalCount(data.data.total_count);
  };
  useEffect(() => {
    fetchBuyersData();
  }, [page]);

  return (
    <div
      onClick={() => {
        closePopup();
      }}
      id="CandidatePop"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="patent_content"
      >
        <div className="patent_name">
          {buyersData?.reportInfo?.invention_name}
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <CandidatePopLeft data={buyersData?.reportInfo} />
          <CandidatePopRight
            page={page}
            setPage={setPage}
            data={buyersData?.list}
            totalCount={totalCount}
            history={history}
          />
        </div>
        <button
          className="close"
          onClick={() => {
            closePopup();
          }}
        >
          <i className="icon_exit_gray"></i>
        </button>
      </div>
    </div>
  );
};

export default CandidatePop;
