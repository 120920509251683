import API from "../../util/api";

export default {
  getStatistic(payload) {
    return API.get("/manager/company/statistic", { params: payload });
  },
  // 상단 왼쪽 특허 통계
  getPatentMainStatistic(payload) {
    return API.get("/manager/company/statisticPatentMain", { params: payload });
  },

  // 상단 오른쪽 상표 통계
  getTrademarkMainStatistic(payload) {
    return API.get("/manager/company/statisticTrademarkMain", { params: payload });
  },

  // 상단 오른쪽 디자인 통계
  getDesignMainStatistic(payload) {
    return API.get("/manager/company/statisticDesignMain", { params: payload });
  },

  // 하단 왼쪽 연차료 통계
  getAnnualPayMainStatistic(payload) {
    return API.get("/manager/company/statisticAnnualpayMain", { params: payload });
  },
  // 하단 오른쪽 그래프 통계
  getGraphMainStatistic(payload) {
    return API.get("/manager/company/statisticGraphMain", { params: payload });
  },
  getStatisticAgent(company_idx) {
    return API.get(`/agent/company/${company_idx}/statistic`);
  },
  getStatisticPaymentHistory() {
    return API.get(`/test/statistic/paymentHistory`);
  },
  getStatisticTemplateTradeHistory() {
    return API.get(`/test/statistic/templateTradeHistory`);
  },
  insertPaymentHistory(payload) {
    return API.post("/test/statistic/paymentHistory", payload);
  },
  deletePaymentHistory(payload) {
    return API.delete("/test/statistic/paymentHistory", { data: payload });
  },
  getPatentByYear(payload) {
    return API.get("/common/statistics/getPatentByYear", { params: payload });
  },
  getRightTransfer(payload) {
    return API.get("/common/statistics/getRightTransfer", { params: payload });
  },
  // // 연도별 기술이전(양도) 수가 많은 대학&특허
  // getTransferTop(payload) {
  //   return API.get("/common/statistics/getTransferTop", { params: payload });
  // },

  // // 연도별 기술이전(양도) 수가 많은 대학&특허 상세 데이터
  // getComTransferInfo(payload) {
  //   return API.get("/common/statistics/getComTransferInfo", { params: payload });
  // },
  // // 기업 기술이전 분야 네트워크- IPC
  // getTransferIpc(payload) {
  //   return API.get("/common/statistics/getTransferIPC", { params: payload });
  // },
  // getTransferor(payload) {
  //   return API.get("/common/statistics/getTransferor", { params: payload });
  // },
  getTechEval(payload) {
    return API.get("/common/statistics/getTechEval", { params: payload });
  },
  getAlive(payload) {
    return API.get("/common/statistics/getPatentAlive", { params: payload });
  },
  getPatentNation(payload) {
    return API.get("/common/statistics/getPatentNation", { params: payload });
  },
  getAnnualPayData(payload) {
    return API.get("/common/statistics/getAnnualPayData", { params: payload });
  },
};
