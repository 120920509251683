import React from "react";
import styles from "./css/InputField.module.scss";

const InputField = ({ label, required, ...props }) => {
  return (
    <div className={styles.fieldWrapper}>
      <label>
        {label} {required && <span className={styles.required}>*</span>}
      </label>
      <input type="text" {...props} />
    </div>
  );
};

export default InputField;
