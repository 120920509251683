import React, { useState } from "react";
import Tooltip from "../../common/Tooltip";

const ShareInputRow = ({
  title,
  titleClass,
  content,
  defaultValueUse,
  defaultValue,
  editing,
  inputClass,
  inputType,
  onChange,
  placeholder,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className={`${titleClass}`}>
      <h2>
        지분율
        <i
          className="icon_warning_empty"
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        />
      </h2>

      {/* 툴팁 컴포넌트 */}
      <Tooltip
        text="지분율 합은 100이어야 합니다."
        show={showTooltip}
        position="top"
      />
      {editing ? (
        <input
          className={inputClass || ""}
          type={inputType || "text"}
          value={defaultValue || ""}
          onChange={onChange}
          placeholder={placeholder || ""}
        />
      ) : (
        <p>{content || "N/A"}</p>
      )}
    </div>
  );
};

export default ShareInputRow;
