import React, { useEffect, useRef, useState } from "react";
import AdminAPI from "../../API/admin";
import "../common/css/ContentData.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { dummyBanKeyword } from "../../const/data";

const bidTypeSpan = [
  <span style={{ color: "blue" }}>조달청-공사</span>,
  <span style={{ color: "blue" }}>조달청-물품</span>,
  <span style={{ color: "blue" }}>조달청-용역</span>,
  <span style={{ color: "blue" }}>조달청-필터링</span>,
  <span style={{ color: "red" }}>비즈인포</span>,
  <span style={{ color: "green" }}>NTIS</span>,
  <span style={{ color: "blueviolet" }}>엑셀업로드</span>,
  <span style={{ color: "blueviolet" }}>창업진흥원</span>,
  <span style={{ color: "blueviolet" }}>SMTECH</span>,
  <span style={{ color: "blueviolet" }}>ITech</span>,
];

const sessionDataKeys =
  "page, keyword, bid_type, need_to_work, ready, last, input_date, in_charge_name"
    .replace(/\s/g, "")
    .split(",");

const localDataKeys = "ban_keyword, ban_keyword_disable"
  .replace(/\s/g, "")
  .split(",");

const nowDate = new Date();
const defaultDate = `${nowDate.getFullYear()}-${(
  parseInt(nowDate.getMonth()) + 1
)
  .toString()
  .padStart(2, "0")}-${parseInt(nowDate.getDate())
  .toString()
  .padStart(2, "0")}`;

function AdminTaskContentList({ location }) {
  const sessionData = {};
  const localData = {};

  for (const k of sessionDataKeys) {
    sessionData[k] = sessionStorage.getItem(k) || undefined;
  }

  for (const k of localDataKeys) {
    localData[k] = localStorage.getItem(k) || undefined;
  }

  localData["ban_keyword"] = localData?.ban_keyword ?? dummyBanKeyword;

  const banKeywordRef = useRef(null);
  const history = useHistory();
  const count = 100;
  const [curPage, setCurPage] = useState(sessionData?.page || 1);
  const [content, setContent] = useState([]);
  const [page, setPage] = useState(sessionData?.page || 1);
  const [last, setLast] = useState(sessionData?.last || 1);
  const [dataList, setDataList] = useState([]);
  const [banKeyword, setBanKeyword] = useState(localData?.ban_keyword || "");
  const [searchBanKeyword, setSearchBanKeyword] = useState(
    localData?.ban_keyword || ""
  );
  const [inChargeName, setInChargeName] = useState(
    sessionData?.in_charge_name || undefined
  );

  const [banKeywordDisable, setBanKeywordDisable] = useState(
    localData?.ban_keyword_disable === "true"
  );

  const [keyword, setKeyword] = useState(sessionData?.keyword || "");
  const [searchKeyword, setSearchKeyword] = useState(
    sessionData?.keyword || ""
  );

  const [inputDate, setInputDate] = useState(sessionData?.input_date);

  const [totalCount, setTotalCount] = useState(0);

  const [albaList, setAlbaList] = useState([]);
  const [bidType, setBidType] = useState(sessionData?.bid_type || -1);
  const [needToWork, setNeedToWork] = useState(sessionData?.need_to_work || -1);
  const [ready, setReady] = useState(sessionData?.ready || -1);

  const [selectInCharge, setSelectInCharge] = useState("");

  const tableRef = useRef(null);

  const getAPI = async () => {
    if (sessionStorage.getItem("company_name") === "더미회사") {
      AdminAPI.getAPI().defaults.headers["x-token"] =
        sessionStorage.getItem("login_token");
    } else {
      AdminAPI.getAPI().defaults.headers["x-token"] =
        "a23e21f873b8b7df899b2d77cde374cf:76093c6bb908a14764d7b3bd34e4c4684e52384c756268d92f154040334e9c32cbd10ffb96a99478663d75aa596ed7ab82c9dca57db7f1880d98cbf2fd412b4c622d138ee7d1834af5efea058658e61b8575c3e525b63d306e11d9affe6fd68cf185fd51f237a9c4f4ca1febe5fe50a2f6fc81433374071d8592c17f6ebde906134b5d5688477359e417133f9db6c391032c9e8bada8324a8943d89ab3796ee68e0822e6bc216fbeffe9c6e70887cb8f96a59b2e0cab3546068b5c6271e2cd188e4a8023c7c4c34b1021b40d18973d7358ec871f1d6d270a8ca4904b7d36f5a2a302ada20c06279c8437deb4481bd6a67c85118aaa6f91aa09e33d659be20dfd908c538d05197f9cd1956440c034f5a29d146a26ed45d91de4dc4ad523ef3f76";
    }
    let result = await AdminAPI.getTaskList(
      {
        count,
        page: curPage,
        keyword,
        sort: 6,
      },
      {
        ready,
        ban_keyword: !banKeywordDisable ? banKeyword : undefined,
        date: inputDate,
        bid_type: bidType,
        need_to_work: needToWork,
        admin: 1,
        in_charge_name: inChargeName,
      }
    ).then((res) => res.data);

    sessionStorage.setItem("last", result.last);
    sessionStorage.setItem("page", result.page);

    setTotalCount(result.total_count);
    setLast(result.last);
    setPage(result.page);
    setDataList(result.items);

    if (sessionStorage.getItem("scrollUp") === "true") {
      tableRef.current.scrollTop = 0;
      sessionStorage.setItem("scrollUp", "false");
    }
  };

  const search = () => {
    sessionStorage.setItem("keyword", keyword);
    localStorage.setItem("ban_keyword", banKeyword);
    setCurPage(1);
    setSearchKeyword(keyword);
    setSearchBanKeyword(banKeyword);
  };

  if (sessionStorage.getItem("refreshFindBiz") === "true") {
    sessionStorage.setItem("refreshFindBiz", "false");
    getAPI();
  }

  useEffect(() => {
    getAPI();
  }, [
    bidType,
    needToWork,
    searchKeyword,
    searchBanKeyword,
    ready,
    curPage,
    inputDate,
    banKeywordDisable,
    inChargeName,
  ]);

  useEffect(() => {
    if (!window.$Global.isEmptyObject(dataList)) {
      drawTable();
    }
  }, [dataList]);

  useEffect(async () => {
    const albaList = (await AdminAPI.getAlbaList()).data;

    setAlbaList(albaList);

    setSelectInCharge(
      <select
        style={{ width: "60px", marginLeft: "18px" }}
        defaultValue={sessionData?.in_charge_name}
        onChange={(e) => {
          sessionStorage.setItem("in_charge_name", e.target.value);
          setInChargeName(e.target.value);
        }}
      >
        {albaList.map((v, i) => {
          return (
            <option key={"in_charge_name" + i} value={v.name}>
              {v.name}
            </option>
          );
        })}
      </select>
    );
  }, []);

  const patchAdditional = async (e, idx, type) => {
    const payload = {};

    if (type === 0) {
      payload.needToWork = e.target.checked === true ? 1 : 0;
    } else if (type === 1) {
      payload.inChargeName = e.target.value;
    }

    await AdminAPI.updateTaskAdditional(idx, payload);
  };

  const drawTable = () => {
    setContent(
      dataList.map((item, index) => {
        return (
          <tr
            key={item.idx}
            onClick={() =>
              history.push(
                `/admin/posting/task?isPostType=view&idx=${item.idx}`
              )
            }
          >
            <td>{bidTypeSpan[item.bid_type] || <span>수동업로드</span>}</td>
            <td>
              <div className="content">
                <h2>{item.title}</h2>
              </div>
            </td>
            <td>
              {isNaN(item.deadline_at)
                ? item.deadline_at
                : window.$Global.convertDateTime(item.deadline_at)}
            </td>
            <td>
              {item.file_cnt > 0 ? "유" : <span className="color_red">무</span>}
            </td>
            <td
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <select
                style={{ width: "60px", marginLeft: "18px" }}
                defaultValue={item.in_charge_name}
                onChange={(e) => patchAdditional(e, item.idx, 1)}
              >
                {albaList.map((v, i) => (
                  <option key={i} value={v.name}>
                    {v.name}
                  </option>
                ))}
              </select>
            </td>
            <td
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="checkbox"
                defaultChecked={item.need_to_work === 1}
                onClick={(e) => patchAdditional(e, item.idx, 0)}
              />
            </td>
            <td>
              {item.uri_match ? (
                <span className="color_red">무</span>
              ) : (
                <span className="color_blue">유</span>
              )}
            </td>
            <td>
              {item.ready === 0 ? (
                <span className="color_red">무</span>
              ) : (
                <span className="color_blue">유</span>
              )}
            </td>
          </tr>
        );
      })
    );
  };

  const firstPage = () => {
    sessionStorage.setItem("scrollUp", "true");
    sessionStorage.setItem("page", 1);
    setCurPage(1);
  };

  const beforePage = () => {
    if (parseInt(curPage) - 1 <= 0) {
      alert("첫 페이지에 도달했습니다.");
      return;
    }
    sessionStorage.setItem("scrollUp", "true");
    sessionStorage.setItem("page", parseInt(curPage) - 1);
    setCurPage(parseInt(curPage) - 1);
  };

  const nextPage = () => {
    if (parseInt(curPage) + 1 > last) {
      alert("마지막 페이지에 도달했습니다.");
      return;
    }
    sessionStorage.setItem("scrollUp", "true");
    sessionStorage.setItem("page", parseInt(curPage) + 1);
    setCurPage(parseInt(curPage) + 1);
  };

  const resetBanKeyword = () => {
    setBanKeyword(dummyBanKeyword);
    banKeywordRef.current.value = dummyBanKeyword;
    setSearchBanKeyword(dummyBanKeyword);
    localStorage.setItem("ban_keyword", dummyBanKeyword);
  };

  return (
    <div id="AdminTaskContentList">
      <div style={{ margin: "auto", width: 1080, textAlign: "right" }}>
        <button
          className="btn_write"
          style={{
            position: "relative",
            top: 40,
            width: 100,
            height: 30,
            fontSize: 16,
            background: "#4593f5",
            color: "#ffffff",
          }}
          onClick={() => history.push("/admin/posting/task")}
        >
          글 작성하기
        </button>
      </div>
      <div id="ContentData">
        <div className="pc">
          {sessionStorage.getItem("company_name") !== "더미회사" && (
            <>
              <h2>제외 키워드 (,으로 구분할것)</h2>
              <div className="ban_keyword">
                <input
                  ref={banKeywordRef}
                  type="text"
                  placeholder="제외하고 싶은 단어 (,로 구분하여 입력할 것)"
                  style={{ marginBottom: 15, width: "100%", height: 32 }}
                  onChange={(e) => setBanKeyword(e.target.value)}
                  onKeyUp={(e) => e.key === "Enter" && search()}
                  defaultValue={banKeyword}
                  disabled={banKeywordDisable}
                />
                <input
                  style={{ marginLeft: 10, width: 30, height: 30 }}
                  type="checkbox"
                  defaultChecked={!banKeywordDisable}
                  value={!banKeywordDisable}
                  onChange={(e) => {
                    const checked = !e.target.checked;

                    setBanKeywordDisable(checked);
                    localStorage.setItem("ban_keyword_disable", checked);
                  }}
                />
                <button
                  style={{ marginLeft: 55, width: 100, height: 32 }}
                  onClick={resetBanKeyword}
                >
                  키워드 초기화
                </button>
              </div>
            </>
          )}
          <h2>사업 등록글</h2>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              placeholder="공고, 세부 공고명으로 검색"
              style={{ marginBottom: 15, width: "100%", height: 32 }}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && search()}
              defaultValue={searchKeyword}
            />

            <button
              style={{ marginLeft: 55, width: 100, height: 32 }}
              onClick={search}
            >
              검색
            </button>
          </div>
          <div>
            <select
              style={{ width: "100px" }}
              onChange={(e) => {
                sessionStorage.setItem("bid_type", e.target.value);
                setBidType(e.target.value);
              }}
              defaultValue={sessionData?.bid_type || -1}
            >
              <option value={-1}>분류</option>
              <option value={0}>조달청-공사</option>
              <option value={1}>조달청-물품</option>
              <option value={2}>조달청-용역</option>
              <option value={3}>비즈인포</option>
              <option value={4}>엑셀업로드</option>
              <option value={5}>수동업로드</option>
              <option value={6}>창업진흥원</option>
              <option value={7}>SMTECH</option>
              <option value={8}>ITech</option>
            </select>
            <select
              style={{ width: "100px" }}
              onChange={(e) => {
                sessionStorage.setItem("need_to_work", e.target.value);
                setNeedToWork(e.target.value);
              }}
              defaultValue={sessionData?.need_to_work || -1}
            >
              <option value={-1}>작업요청</option>
              <option value={1}>O</option>
              <option value={0}>X</option>
            </select>
            <select
              style={{ width: "100px" }}
              onChange={(e) => {
                sessionStorage.setItem("ready", e.target.value);
                setReady(e.target.value);
              }}
              defaultValue={sessionData?.ready || -1}
            >
              <option value={-1}>수정여부</option>
              <option value={1}>유</option>
              <option value={0}>무</option>
            </select>
            <input
              style={{ width: "130px" }}
              type="date"
              onChange={(e) => {
                sessionStorage.setItem("input_date", e.target.value);
                setInputDate(e.target.value);
              }}
              defaultValue={sessionData?.input_date}
            ></input>
            {selectInCharge}
          </div>
          <div className="table-box-wrap">
            <div id="scrollableDiv" className="wrap_list" ref={tableRef}>
              {/*<InfiniteScroll*/}
              {/*    dataLength={totalCount}*/}
              {/*    next={nextList}*/}
              {/*    hasMore={hasMore}*/}
              {/*    scrollableTarget="scrollableDiv">*/}
              <table>
                <thead>
                  <tr>
                    <th>유형</th>
                    <th>공고명</th>
                    <th>마감일</th>
                    <th>파일 유무</th>
                    <th>담당자</th>
                    <th>작업요청</th>
                    <th>링크수정</th>
                    <th>수정여부</th>
                  </tr>
                </thead>
                <tbody>{content}</tbody>
              </table>
              {/*</InfiniteScroll>*/}
            </div>
          </div>
          <div
            className="page_wrap"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "60px",
            }}
          >
            <button className="btn_page" onClick={firstPage}>
              처음 페이지
            </button>
            <button className="btn_page" onClick={beforePage}>
              이전 페이지
            </button>
            <span>
              {curPage}/{last}
            </span>
            <button className="btn_page" onClick={nextPage}>
              다음 페이지
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminTaskContentList;
