import "./css/SideBar.scss";
import checkOffIcon from "../../assets/images/businessPlan/icon-checkbox-off.svg";
import checkOnIcon from "../../assets/images/businessPlan/icon-checkbox-on.svg";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const SideBar = () => {
  const location = useLocation();

  const [showList, setShowList] = useState({
    firstText: false,
    secondText: false,
  });

  return (
    <div id="SideBar">
      <div className="sidebar_items">
        <div className="sidebar_item">
          <img src={checkOnIcon} alt="checkOffIcon" />
          <div className="sidebar_item_text_test">
            <p>연구개발계획서</p>
          </div>
        </div>
        <div className="sidebar_item">
          <img src={checkOffIcon} alt="checkOffIcon" />
          <div className="sidebar_item_text">
            <p>요약문</p>
          </div>
        </div>
        <div className="sidebar_item">
          <img src={checkOffIcon} alt="checkOffIcon" />
          <div
            className="sidebar_item_text"
            onClick={() =>
              setShowList({ ...showList, firstText: !showList.firstText })
            }
            style={{ cursor: "pointer" }}
          >
            <p>본문1</p>
          </div>
        </div>
        {(showList.firstText || location.pathname.includes("third")) && (
          <>
            <div className="sidebar_item">
              <img src={checkOffIcon} alt="checkOffIcon" />
              <div className="sidebar_item_text">
                <p>
                  Step.1
                  <br />
                  연구개발과제의 필요성
                </p>
                <p className="small_text">개발기술 개요 및 필요성</p>
              </div>
            </div>
            <div className="sidebar_item">
              <img src={checkOffIcon} alt="checkOffIcon" />
              <div className="sidebar_item_text">
                <p>
                  Step.2
                  <br />
                  연구개발과제의 목표 및 내용
                </p>
                <p className="small_text">연구개발과제의 최종 목표</p>
                <p className="small_text">연구개발과제의 단계별 목표</p>
                <p className="small_text">연구개발과제의 내용</p>
                <p className="small_text">
                  연구개발과제의 수행일정 및 주요 결과물
                </p>
              </div>
            </div>
            <div className="sidebar_item">
              <img src={checkOffIcon} alt="checkOffIcon" />
              <div className="sidebar_item_text">
                <p>
                  Step.3
                  <br />
                  연구개발과제의
                  <br />
                  추진전략·방법 및<br />
                  추진체계
                </p>
                <p className="small_text">
                  연구개발과제의
                  <br />
                  추진전략·방법
                </p>
                <p className="small_text">연구개발과제의 추진체계</p>
              </div>
            </div>
            <div className="sidebar_item">
              <img src={checkOffIcon} alt="checkOffIcon" />
              <div className="sidebar_item_text">
                <p>
                  Step.4
                  <br />
                  연구개발성과의
                  <br />
                  활용방안 및 기대 효과
                </p>
                <p className="small_text">연구개발 성과의 활용방안</p>
                <p className="small_text">연구개발성과의 기대효과</p>
              </div>
            </div>
            <div className="sidebar_item">
              <img src={checkOffIcon} alt="checkOffIcon" />
              <div className="sidebar_item_text">
                <p>
                  Step.5
                  <br />
                  연구개발성과의
                  <br />
                  사업화 전략 및 계획
                </p>
                <p className="small_text">국내외 시장 동향</p>
                <p className="small_text">
                  지식재산권, 표준화 및 <br />
                  인증기준 현황
                </p>
                <p className="small_text">표준화 전략</p>
                <p className="small_text">사업화 계획</p>
              </div>
            </div>
            <div className="sidebar_item">
              <img src={checkOffIcon} alt="checkOffIcon" />
              <div className="sidebar_item_text">
                <p>
                  Step.6
                  <br />
                  연구개발 안전 및<br />
                  보안조치 이행계획
                </p>
                <p className="small_text">안전조치 이행계획</p>
                <p className="small_text">보안조치 이행계획</p>
                <p className="small_text">그 밖의 조치사항 이행계획</p>
              </div>
            </div>
          </>
        )}
        <div className="sidebar_item">
          <img src={checkOffIcon} alt="checkOffIcon" />
          <div
            className="sidebar_item_text"
            onClick={() =>
              setShowList({ ...showList, secondText: !showList.secondText })
            }
            style={{ cursor: "pointer" }}
          >
            <p>본문2</p>
          </div>
        </div>
        {(showList.secondText ||
          location.pathname.includes("fifth") ||
          location.pathname.includes("fourth")) && (
          <>
            <div className="sidebar_item">
              <img src={checkOffIcon} alt="checkOffIcon" />
              <div className="sidebar_item_text">
                <p>
                  Step.1
                  <br />
                  연구개발기관 현황
                </p>
                <p className="small_text">연구개발기관 현황</p>
                <p className="small_text">연구책임자 등 현황</p>
                <p className="small_text">연구시설ㆍ장비 보유현황</p>
                <p className="small_text">연구개발기관 일반 현황</p>
              </div>
            </div>
            <div className="sidebar_item">
              <img src={checkOffIcon} alt="checkOffIcon" />
              <div className="sidebar_item_text">
                <p>
                  Step.2
                  <br />
                  연구개발과제의 목표 및 내용
                </p>
                <p className="small_text">연구개발비 지원ㆍ부담계획</p>
                <p className="small_text">연구개발비 사용계획</p>
                <p className="small_text">연구시설ㆍ장비 구축ㆍ 운영계획</p>
              </div>
            </div>
            <div className="sidebar_item">
              <img src={checkOffIcon} alt="checkOffIcon" />
              <div className="sidebar_item_text">
                <p>
                  Step.3
                  <br />
                  연구개발과제의
                  <br />
                  추진전략·방법 및<br />
                  추진체계
                </p>
                <p className="small_text">
                  연구개발과제의
                  <br />
                  추진전략·방법
                </p>
                <p className="small_text">연구개발과제의 추진체계</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SideBar;
