import React, { useState } from "react";
import "../../css/CandidatePop.scss";
import Pagination from "../../../common/Pagination";
import CompetitorDetailPop from "../CompetitorDetailPop";

const CandidatePopRight = ({ page, setPage, data, totalCount, history }) => {
  const [competitorPopup, setCompetitorPopup] = useState(false);
  const [curCompany, setCurCompany] = useState({
    applicant: null,
    bizno: null,
  });
  return (
    <>
      <div className="right">
        <div className="right_header">
          <strong>기술이전 후보기업</strong>
          {/* <button
            onClick={() => {
              history.push("/mypage/info?depth1=1&depth2=0");
            }}
            className="mypage_button"
          >
            등록한 후보기업
          </button> */}
        </div>
        <div className="tbl_box">
          <table>
            <colgroup>
              <col width={30} />
              <col width={120} />
              <col width={70} />
              <col width={70} />
              <col width={100} />
              {/* <col width={80} /> */}
            </colgroup>
            <thead>
              <tr>
                <th>순위</th>
                <th>기업명</th>
                <th>대표자</th>
                <th>소재지</th>
                <th>사업자번호</th>
                {/* <th>기술도입경험</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, idx) => (
                <tr
                  onClick={() => {
                    setCurCompany({
                      applicant: item.holder_company_code,
                      bizno: item.license_number,
                    });
                    setCompetitorPopup(true);
                  }}
                  key={idx}
                >
                  <td>{idx + 1 + 10 * (page - 1)}</td>
                  <td>{item.holder_company_name}</td>
                  <td>{item.company_ceo}</td>
                  <td>{item.region}</td>
                  <td>{item.license_number}</td>
                  {/* <td>{'O'}</td> */}
                </tr>
              ))}
              {totalCount === 0 && (
                <tr>
                  <td colSpan={5}>데이터가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {totalCount > 0 && (
          <Pagination
            curPage={page}
            lastNum={Math.ceil(totalCount / 10)}
            onClick={setPage}
            disableMoveToTop={true}
          />
        )}
      </div>
      {competitorPopup && (
        <CompetitorDetailPop
          applicant={curCompany.applicant}
          bizno={curCompany.bizno}
          closePopup={() => {
            setCompetitorPopup(false);
          }}
        />
      )}
    </>
  );
};

export default CandidatePopRight;
