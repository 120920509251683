import { cacheAdapterEnhancer } from "axios-extensions";
import config from "../const/config";
const axios = require("axios");

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? config.v4Url + "/api/v4"
      : config.v4Url + "/api/v4",
  timeout: 6 * 1000 * 10 * 10 * 2, // 20분
  headers: {
    "x-token": sessionStorage.getItem(config.keyToken),
    "g-token": sessionStorage.getItem(config.keyToken),
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
  }),
});

export default instance;
