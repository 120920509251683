/**
 zeplin: 해당 발명자 정보 페이지
 - 발명자의 특허 리스트를 보여주는 페이지
 **/

import Pagination from "../../common/Pagination";
import "./css/InventorPatentList.scss";
import React, { useEffect, useState } from "react";
import AdminAPI from "../../../API/admin";
import { useParams } from "react-router-dom";
import qs from "query-string";

const InventorPatentList = ({ history, location }) => {
  const { allCompanyNameSearch, companyNameSearch, companyIdx } = qs.parse(
    location.search
  );
  const [isEditing, setIsEditing] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  // 발명자 정보
  const [inventorInfo, setInventorInfo] = useState(location.state);
  // 발명자의 특허 idx
  const [patentIdxList, setPatentIdxList] = useState(location.state);
  // 발명자가 가지고 있는 특허 정보
  const [inventorPatentList, setInventorPatentList] = useState([]);

  useEffect(() => {
    getInventorInfo();
  }, []);

  useEffect(() => {
    getInventorPatentList();
  }, [curPage]);

  const getInventorInfo = () => {
    AdminAPI.getInventorInfo(inventorInfo.idx).then((res) => {
      setInventorInfo(res.data[0]);
    });
  };

  const getInventorPatentList = () => {
    let params = {
      count: 10,
      page: curPage,
      idx: patentIdxList.idx,
    };
    AdminAPI.getInventorPatentList(params).then((res) => {
      setInventorPatentList(res.data.items);
      setLast(res.data.last);
    });
  };

  const handleUpdate = () => {
    AdminAPI.updateInventorInfo(inventorInfo.idx, inventorInfo).then(() => {
      getInventorInfo();
    });
  };

  const handleLocation = () => {
    if (allCompanyNameSearch) {
      history.push({
        pathname: "/admin/annual/inventor/list",
        search: `?allCompanyNameSearch=${allCompanyNameSearch}`,
      });
    } else if (companyNameSearch) {
      history.push({
        pathname: "/admin/inventor/list",
        search: `?companyNameSearch=${companyNameSearch}&companyIdx=${companyIdx}`,
        // state:
      });
    } else {
      history.goBack();
    }
  };
  return (
    <div id="InventorPatentList">
      <div className="header">
        <h2>"{inventorInfo.inventor_name}"</h2>
        <div>
          {isEditing ? (
            <button
              onClick={() => {
                handleUpdate();
                setIsEditing(!isEditing);
              }}
            >
              저장
            </button>
          ) : (
            <button onClick={() => setIsEditing(!isEditing)}>수정</button>
          )}
          <button onClick={() => handleLocation()}>목록으로 돌아가기</button>
        </div>
      </div>
      <div className="inventor_info_grid">
        <div>
          <p>발명자 이름</p>
          {isEditing ? (
            <input
              type="text"
              value={inventorInfo.inventor_name}
              onChange={(e) => {
                inventorInfo.inventor_name = e.target.value;
                setInventorInfo({ ...inventorInfo });
              }}
            />
          ) : (
            <p>{inventorInfo.inventor_name}</p>
          )}
        </div>
        <div>
          <p>전화번호</p>
          {isEditing ? (
            <input
              type="text"
              value={inventorInfo.user_tel_no}
              onChange={(e) => {
                inventorInfo.user_tel_no = e.target.value;
                setInventorInfo({ ...inventorInfo });
              }}
            />
          ) : (
            <p>{inventorInfo.user_tel_no}</p>
          )}
        </div>
        <div>
          <p>이메일</p>
          {isEditing ? (
            <input
              type="text"
              value={inventorInfo.user_email}
              onChange={(e) => {
                inventorInfo.user_email = e.target.value;
                setInventorInfo({ ...inventorInfo });
              }}
            />
          ) : (
            <p>{inventorInfo.user_email}</p>
          )}
        </div>
        <div>
          <p>주소</p>
          {isEditing ? (
            <input
              type="text"
              value={inventorInfo.user_addr}
              onChange={(e) => {
                inventorInfo.user_addr = e.target.value;
                setInventorInfo({ ...inventorInfo });
              }}
            />
          ) : (
            <p>{inventorInfo.user_addr}</p>
          )}
        </div>
      </div>
      <div className="inventor_list">
        <table>
          <colgroup>
            <col width="50px" />
            <col width="200px" />
            <col width="200px" />
            <col width="" />
            <col width="200px" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>소속기관명</th>
              <th>특허번호</th>
              <th>발명의 명칭</th>
              <th>출원일</th>
            </tr>
          </thead>
          <tbody>
            {inventorPatentList.map((item, index) => {
              return (
                <tr
                  onClick={() =>
                    history.push({
                      pathname: "/admin/annual/giveup/inventor/list",
                      state: {
                        apply_number: item.apply_number,
                        dummy_patent_idx: item.dummy_patent_idx,
                      },
                    })
                  }
                >
                  <td>{(curPage - 1) * 10 + (index + 1)}</td>
                  <td>{item.company_name}</td>
                  <td>{item.apply_number}</td>
                  <td>{item.invention_title}</td>
                  <td>{window.$Global.convertDate(item.apply_at * 1000)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
    </div>
  );
};

export default InventorPatentList;
