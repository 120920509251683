import React, { useState } from "react";
import DemandCompany from "./SearchDemand/DemandCompany";
import RecommendPatent from "./RecommendPatent/RecommendPatent";
import "./css/DDashboard.scss";
import CandidatePop from "./SearchDemand/CandidatePop";
import CompetitorDetailPop from "./SearchDemand/CompetitorDetailPop";
import CurrentSituation from "./CurrentSituation/CurrentSituation";

const TechTransferMain = () => {
  return (
    <div id="DDashboard">
      <div className="wrap">
        <DemandCompany />
        <CurrentSituation />
        {/* <RecommendPatent setRecommendPopup={setRecommendPopup} /> */}
      </div>
    </div>
  );
};

export default TechTransferMain;
