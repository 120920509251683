import React, { useContext } from "react";
import PatentContext from "../../../store/Patent";
import ButtonRow from "./common/ButtonRow";
import DateAndFileRow from "./common/DateAndFileRow";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import NormalRow from "./common/NormalRow";
import InputRow from "./common/InputRow";
import typeName from "./constrant/typeName";
import DatePickerRow from "./common/DatePickerRow";
import InputAndFileRow from "./common/InputAndFileRow";
import SelectRow from "./common/SelectRow";
import InventorRow from "./common/InventorRow";
import ProcessManage from "./common/ProcessManage";
import ProcessStep from "./common/ProcessStep";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import DatePickerCustom from "../../datepicker/DatePickerCustom";

function FormCompanyKr({
  inventorSelectTag,
  ListOaTag,
  inventorListTag,
  managerSelectTag,
  checkAuth,
  onClickDownPopShow,
  onClickUpPopShow,
  onChangeInput,
  onClickClaim,
  onClickInventorAdd,
  onClickInventorDelete,
  onChangeDatePickerInnerDate,
  onChangeDatePicker,
  addHistoryList,
  handleHistoryInput,
  handleHistoryDelete,
  setHistoryPopupShow,
  setHistoryPopupIdx,
  setPatentInfo,
  setSecondIdx,
}) {
  const patentInfo = useContext(PatentContext);

  return (
    <>
      <div className="field">
        <ProcessStep text="서지 사항" state={patentInfo.state} />
        <div className="rows">
          <InputRow
            title="관리번호"
            titleClass="color_blue"
            condition={patentInfo.editing}
            content={patentInfo.manage_number}
            onChange={onChangeInput}
            setKey="manage_number"
          />
          <NormalRow title="유형" content={patentInfo.type} />
          <SelectRow
            title="담당자"
            content={patentInfo.manager_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                manager_idx: e.target.value,
                manager_name: e.target.options[e.target.selectedIndex].text,
              })
            }
            condition={patentInfo.editing}
            contentIdx={patentInfo.manager_idx}
            selectTag={managerSelectTag}
          />
          {/*<div className="row">*/}
          {/*<h2 className="title">Family 출원<Link className="icon_plus" to={`/patent/family/${patentInfo.idx}`}/></h2>*/}
          {/*<p>{patentInfo.family_cnt}건</p>*/}
          {/*</div>*/}
        </div>
        <div className="rows">
          {patentInfo.editing ? (
            <div className="row between">
              <h2 className="title color_blue">발명자 추가</h2>
              <button
                className="icon_blue_add"
                onClick={() => onClickInventorAdd()}
              />
            </div>
          ) : (
            <div className="row no_data" />
          )}
          <div className="row">
            <h2 className="title">발명자 1</h2>
            {patentInfo.editing ? (
              <>
                <input
                  type="text"
                  value={patentInfo.inventor_list[0].inventor_name}
                  onChange={(e) => {
                    patentInfo.inventor_list[0].inventor_name = e.target.value;
                    setPatentInfo({ ...patentInfo });
                  }}
                  style={{ width: 135 }}
                />
                <i
                  className="icon_badge_del"
                  onClick={() => onClickInventorDelete(0)}
                />
              </>
            ) : (
              <p>{patentInfo.inventor_list[0].inventor_name || "N/A"}</p>
            )}
          </div>
        </div>
        {inventorListTag}
        <div className="rows">
          <DateAndFileRow
            title="디자인 창작물 접수"
            date={patentInfo.invention_created_at}
            haveFile={Boolean(patentInfo.invention_file_cnt)}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.invent, { type: 0 })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.invent, { type: 0 })
            }
            uploadBtnShow={checkAuth("특허회사_관리자")}
          />
        </div>
      </div>
      <div className="field">
        <ProcessStep text="출원" state={patentInfo.state} />
        <div className="rows">
          <InputAndFileRow
            title="출원번호"
            titleClassName="color_blue"
            content={patentInfo.apply_number}
            onChange={(e) => onChangeInput("apply_number", e.target.value)}
            editing={patentInfo.editing}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.apply, { type: 5 })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.apply, { type: 5 })
            }
            haveFile={Boolean(patentInfo.apply_file_cnt)}
          />
          <DatePickerRow
            title="출원일"
            condition={patentInfo.editing}
            data={patentInfo.apply_at}
            dataKey="apply_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <NormalRow
            title="PCT 출원 기한"
            titleClassName="color_blue"
            content={patentInfo.pct_apply_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="디자인의 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="영문 명칭"
            condition={patentInfo.editing}
            content={patentInfo.invention_name_en}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name_en"
          />
          <div className="row no_data" />
        </div>
        <div className="rows">
          <NormalRow
            title="심사청구"
            component={
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.screen_req}
                toggleSelected={onClickClaim}
              />
            }
          />
          <NormalRow
            title="심사청구 기한"
            titleClassName="color_blue"
            content={patentInfo.screen_deadline}
            contentClassName="color_red"
            txtType="date"
          />
          <NormalRow
            title="심사청구일"
            content={patentInfo.screen_req_at}
            txtType="date"
          />
        </div>
        <div className="process_manage">
          <ProcessManage
            title="출원 상세 절차 관리"
            selected={patentInfo.apply_detail}
          />
          {Boolean(patentInfo.apply_detail) && (
            <>
              <div className="rows">
                <ButtonRow
                  title="초안작성 지시"
                  condition={patentInfo.editing}
                  data={patentInfo.draft_order_at}
                  onClick={() =>
                    patentInfo.setPatent({
                      ...patentInfo,
                      draft_order_at: new Date().getTime(),
                    })
                  }
                />
                <InnerDatePickerAndFileRow
                  title="초안 접수"
                  data={patentInfo.draftReceivingDate}
                  condition={patentInfo.editing}
                  setKey="draftReceivingDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_file_cnt)}
                  dateBtnHide={true}
                  uploadBtnShow={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft, { type: 0 })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft, { type: 0 })
                  }
                />
              </div>
              <div className="rows">
                <InnerDatePickerAndFileRow
                  title={`초안\n발명자 검토`}
                  data={patentInfo.draftInventorsDate}
                  condition={patentInfo.editing}
                  setKey="draftInventorsDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_researcher_file_cnt)}
                  uploadBtnShow={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_reply, { type: 1 })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_reply, { type: 1 })
                  }
                />
                <InnerDatePickerAndFileRow
                  title={"초안\n담당자 검토"}
                  data={patentInfo.draftManagerDate}
                  condition={patentInfo.editing}
                  setKey="draftManagerDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_manager_file_cnt)}
                  uploadBtnShow={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_reply, { type: 2 })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_reply, { type: 2 })
                  }
                />
                <div className="row no_data" />
              </div>
              <div className="rows">
                <DateAndFileRow
                  title="초안 수정본 전달"
                  date={patentInfo.draftModifiedUploadDate.date}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft, { type: 3 })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft, { type: 3 })
                  }
                  uploadBtnShow={checkAuth("특허회사_관리자")}
                  haveFile={Boolean(patentInfo.draft_modified_file_cnt)}
                />
                <InnerDatePickerAndFileRow
                  title={"초안\n최종본 접수"}
                  data={patentInfo.draftFinalDate}
                  condition={patentInfo.editing}
                  setKey="draftFinalDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  uploadBtnShow={true}
                  haveFile={Boolean(patentInfo.draft_final_file_cnt)}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft, { type: 4 })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft, { type: 4 })
                  }
                />
                <ButtonRow
                  title="출원 지시"
                  titleClassName="color_blue"
                  condition={patentInfo.editing}
                  data={patentInfo.apply_order_at}
                  onClick={() =>
                    patentInfo.setPatent({
                      ...patentInfo,
                      apply_order_at: new Date().getTime(),
                    })
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
      {ListOaTag}
      <div className="field">
        <ProcessStep text="등록" state={patentInfo.state} />
        <div className="rows">
          <DatePickerRow
            title="등록 결정일"
            titleClassName="color_blue"
            condition={patentInfo.editing}
            data={patentInfo.register_decision_at}
            dataKey="register_decision_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <InputAndFileRow
            title="등록번호"
            content={patentInfo.register_number}
            editing={patentInfo.editing}
            haveFile={Boolean(patentInfo.register_file_cnt)}
            onChange={(e) => onChangeInput("register_number", e.target.value)}
            onClickDownPopShow={() =>
              onClickDownPopShow(typeName.register, { type: 6 })
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(typeName.register, { type: 6 })
            }
          />
          <DatePickerRow
            title="등록일"
            condition={patentInfo.editing}
            data={patentInfo.register_at}
            dataKey="register_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <NormalRow
            title="존속기간 만료일"
            titleClassName="color_blue"
            content={patentInfo.expiration_at}
            contentClassName="color_blue"
            txtType="date"
          />
          <NormalRow
            title="등록기한"
            titleClassName="color_blue"
            content={patentInfo.register_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <div className="row no_data" />
          <NormalRow
            title="관납료 납부기한"
            titleClassName="color_blue"
            content={patentInfo.payment_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
      </div>
      <div className="field">
        <ProcessStep text="연차료 정보" state={patentInfo.state} />
        <div className="rows">
          <NormalRow
            title="납부년차"
            titleClassName="color_blue"
            content={
              patentInfo.payment_year && patentInfo.payment_year + "년차"
            }
            contentClassName="color_blue"
          />
          <NormalRow
            title="연차료 기한"
            titleClassName="color_blue"
            content={patentInfo.annual_payment_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
      </div>
      <div className="field area_box" style={{ margin: "30px 0" }}>
        <div className="header" style={{ padding: "0 10px" }}>
          <h5 className="title">History</h5>
          {patentInfo.editing && (
            <button className="add_btn" onClick={addHistoryList}>
              추가하기
            </button>
          )}
        </div>
        <table className="history_table">
          <colgroup>
            <col width={130} />
            <col width={170} />
            <col width={170} />
            <col width={130} />
            <col width={130} />
            <col width={50} />
            <col width={50} />
            {patentInfo.editing && (
              <>
                <col width={50} />
              </>
            )}
          </colgroup>
          <thead>
            <tr>
              <th>업무일자</th>
              <th>업무제목</th>
              <th>코멘트</th>
              <th>지시요청기한</th>
              <th>법정기한</th>
              <th>파일</th>
              <th>상세보기</th>
              {patentInfo.editing && (
                <>
                  <th>삭제</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {patentInfo.history_list.map((item, idx) => {
              return item.isEdit ? (
                <tr>
                  <td>
                    <div className="history_date">
                      <DatePickerCustom
                        data={item.work_at}
                        onChangeDatePicker={(e) => {
                          item.work_at = new Date(e).getTime();
                          setPatentInfo({ ...patentInfo });
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="title"
                      value={item.title}
                      onChange={(e) => handleHistoryInput(e, idx)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="content"
                      value={item.content}
                      onChange={(e) => handleHistoryInput(e, idx)}
                    />
                  </td>
                  <td>
                    <div className="history_date">
                      <DatePickerCustom
                        data={item.instruction_deadline}
                        onChangeDatePicker={(e) => {
                          item.instruction_deadline = new Date(e).getTime();
                          setPatentInfo({ ...patentInfo });
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="history_date">
                      <DatePickerCustom
                        data={item.legal_deadline}
                        onChangeDatePicker={(e) => {
                          item.legal_deadline = new Date(e).getTime();
                          setPatentInfo({ ...patentInfo });
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    {Boolean(item.idx) && (
                      <i
                        className={
                          item.history_file_cnt
                            ? "icon_download_attached"
                            : "icon_download"
                        }
                        onClick={() => {
                          onClickDownPopShow(
                            typeName.history,
                            null,
                            null,
                            item.idx
                          );
                          setSecondIdx(item.idx);
                        }}
                      />
                    )}
                    <i
                      className="icon_upload"
                      onClick={() => {
                        onClickUpPopShow("history", null, null, item.idx);
                      }}
                    />
                  </td>
                  <td>
                    <i
                      className="icon_arrow_right_s"
                      onClick={() => {
                        setHistoryPopupIdx(idx);
                        setHistoryPopupShow(true);
                      }}
                    />
                  </td>
                  {patentInfo.editing && (
                    <>
                      <td>
                        <i
                          className="icon_badge_del"
                          onClick={() => handleHistoryDelete(idx)}
                        />
                      </td>
                    </>
                  )}
                </tr>
              ) : (
                <tr>
                  <td>
                    {item.work_at > 0 &&
                      window.$Global.convertDate(item.work_at)}
                  </td>
                  <td>{item.title}</td>
                  <td>{item.content}</td>
                  <td>
                    {item.instruction_deadline > 0 &&
                      window.$Global.convertDate(item.instruction_deadline)}
                  </td>
                  <td>
                    {item.legal_deadline > 0 &&
                      window.$Global.convertDate(item.legal_deadline)}
                  </td>
                  <td>
                    {Boolean(item.idx) && (
                      <i
                        className={
                          item.history_file_cnt
                            ? "icon_download_attached"
                            : "icon_download"
                        }
                        onClick={() => {
                          onClickDownPopShow(
                            typeName.history,
                            null,
                            null,
                            item.idx
                          );
                          setSecondIdx(item.idx);
                        }}
                      />
                    )}
                    <i
                      className="icon_upload"
                      onClick={() => {
                        onClickUpPopShow("history", null, null, item.idx);
                      }}
                    />
                  </td>
                  <td>
                    <i
                      className="icon_arrow_right_s"
                      onClick={() => {
                        setHistoryPopupIdx(idx);
                        setHistoryPopupShow(true);
                      }}
                    />
                  </td>
                  {patentInfo.editing && (
                    <>
                      <td>
                        <i
                          className="icon_badge_del"
                          onClick={() => handleHistoryDelete(idx)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default FormCompanyKr;
