import SideBar from "../../SideBar";
import RnDText from "../RnDText";
import FirstInfoText from "../../FirstInfoText";
import "./css/RndPlanThird.scss";
import BringingUpIcon from "../../BringingUpIcon";
import ReferenceIcon from "../../ReferenceIcon";
import { useHistory } from "react-router-dom";
import prevButton from "../../../../assets/images/businessPlan/button-prev.svg";
import nextButton from "../../../../assets/images/businessPlan/button-next.svg";
import { useEffect, useState } from "react";
import CommonAPI from "../../../../API/common";
import _ from "lodash";
import SunEditor from "suneditor-react";

const RnDPlanThird = () => {
  useEffect(() => {
    CommonAPI.getBusinessData(window.sessionStorage.getItem("itemIdx")).then(
      (res) => {
        getDefaultData(res.data);
        setResponseData(res.data);
      }
    );
  }, []);
  const history = useHistory();
  const [responseData, setResponseData] = useState({});
  const [thirdPageData, setThirdPageData] = useState({
    stepOne: "1",
    stepTwo: ["1", "1", "1", "1"],
    stepThree: ["1", "1"],
    stepFour: ["1", "1"],
    stepFive: ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1"],
    stepSix: ["1", "1", "1"],
  });

  // const [thirdPageData, setThirdPageData] = useState({
  //   stepOne: "",
  //   stepTwo: ["", "", "", ""],
  //   stepThree: ["", ""],
  //   stepFour: ["", ""],
  //   stepFive: ["", "", "", "", "", "", "", "", "", ""],
  //   stepSix: ["", "", ""],
  // });

  const getDefaultData = (data) => {
    data.content.forEach((item, index) => {
      if (item.subject === "연구개발과제의 필요성") {
        thirdPageData["stepOne"] = item.content;
        setThirdPageData({ ...thirdPageData });
      } else if (item.subject === "연구개발과제의 목표 및 내용") {
        thirdPageData["stepTwo"] = [
          item.content[1][0].content,
          item.content[3][0].content,
          item.content[5][0].content,
          item.content[7][0].content,
        ];
        setThirdPageData({ ...thirdPageData });
      } else if (item.subject === "연구개발과제의 추진전략 방법 및 추진체계") {
        thirdPageData["stepThree"] = [
          item.content[1][0].content,
          item.content[3][0].content,
        ];
        setThirdPageData({ ...thirdPageData });
      } else if (item.subject === "연구개발성과의 활용방안 및 기대효과") {
        thirdPageData["stepFour"] = [
          item.content[1][0].content,
          item.content[3][0].content,
        ];
        setThirdPageData({ ...thirdPageData });
      } else if (item.subject === "연구개발성과의 사업화 전략 및 계획") {
        thirdPageData["stepFive"] = [
          item.content[2][0].content,
          item.content[4][0].content,
          item.content[6][0].content,
          item.content[8][0].content,
          item.content[10][0].content,
          item.content[13][0].content,
          item.content[15][0].content,
          item.content[17][0].content,
          item.content[19][0].content,
          item.content[21][0].content,
        ];
        setThirdPageData({ ...thirdPageData });
      } else if (item.subject === "연구개발 안전 및 보안조치 이행계획") {
        thirdPageData["stepSix"] = [
          item.content[1][0].content,
          item.content[3][0].content,
          item.content[5][0].content,
        ];
        setThirdPageData({
          ...thirdPageData,
        });
      }
    });
  };

  const handleChange = (e, idx) => {
    const { name, value } = e.target;
    if (name === "stepOne") {
      setThirdPageData({
        ...thirdPageData,
        [name]: value,
      });
    } else {
      thirdPageData[name][idx] = value;
      setThirdPageData({ ...thirdPageData });
    }
  };

  const sendData = () => {
    const content = [
      {
        subject: "연구개발과제의 필요성",
        type: "text",
        content: thirdPageData.stepOne,
      },
      {
        subject: "연구개발과제의 목표 및 내용",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "1) 연구개발과제의 최종 목표",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepTwo[0],
            },
          ],
          [
            {
              type: "text",
              content: "2) 연구개발과제의 단계별 목표",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepTwo[1],
            },
          ],
          [
            {
              type: "text",
              content: "3) 연구개발과제의 내용",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepTwo[2],
            },
          ],
          [
            {
              type: "text",
              content: "4) 연구개발과제 수행일정 및 주요 결과물",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepTwo[3],
            },
          ],
        ],
      },
      {
        subject: "연구개발과제의 추진전략 방법 및 추진체계",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "1) 연구개발과제의 추진전략ㆍ방법",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepThree[0],
            },
          ],
          [
            {
              type: "text",
              content: "2) 연구개발과제의 추진체계",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepThree[1],
            },
          ],
        ],
      },
      {
        subject: "연구개발성과의 활용방안 및 기대효과",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "1) 연구개발성과의 활용방안",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFour[0],
            },
          ],
          [
            {
              type: "text",
              content: "2) 연구개발성과의 기대효과",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFour[1],
            },
          ],
        ],
      },
      {
        subject: "연구개발성과의 사업화 전략 및 계획",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "1) 국내외 시장 동향",
            },
          ],
          [
            {
              type: "text",
              content: "(1) 국내외 시장규모 및 수출입 현황",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[0],
            },
          ],
          [
            {
              type: "text",
              content: "(2) 국내외 주요 수요처 현황",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[1],
            },
          ],
          [
            {
              type: "text",
              content: "(3) 국내외 경쟁기관 및 기술 현황",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[2],
            },
          ],
          [
            {
              type: "text",
              content: "2) 지식재산권, 표준화 및 인증기준 현황",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[3],
            },
          ],
          [
            {
              type: "text",
              content: "3) 표준화 전략",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[4],
            },
          ],
          [
            {
              type: "text",
              content: "4) 사업화 계획",
            },
          ],
          [
            {
              type: "text",
              content: "(1) 사업화 전략",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[5],
            },
          ],
          [
            {
              type: "text",
              content: "(2) 투자 계획",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[6],
            },
          ],
          [
            {
              type: "text",
              content: "(3) 생산 계획",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[7],
            },
          ],
          [
            {
              type: "text",
              content: "(4) 해외시장 진출 계획",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[8],
            },
          ],
          [
            {
              type: "text",
              content: "(5) 사업화에 따른 기대효과",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepFive[9],
            },
          ],
        ],
      },
      {
        subject: "연구개발 안전 및 보안조치 이행계획",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "1) 안전조치 이행계획",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepSix[0],
            },
          ],
          [
            {
              type: "text",
              content: "2) 보안조치 이행계획",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepSix[1],
            },
          ],
          [
            {
              type: "text",
              content: "3) 그 밖의 조치사항 이행계획",
            },
          ],
          [
            {
              type: "text",
              content: thirdPageData.stepSix[2],
            },
          ],
        ],
      },
    ];
    sendDataToAPI(content);
  };

  const sendDataToAPI = (contentData) => {
    responseData.content = _.uniqBy(responseData.content, "subject");
    responseData.content.forEach((parentItem, index) => {
      contentData.forEach((childItem) => {
        if (parentItem.subject === childItem.subject) {
          responseData.content[index].content = childItem.content;
          setResponseData({ ...responseData });
        }
      });
    });

    if (window.sessionStorage.getItem("itemIdx") !== null) {
      const payload = {
        title: "R&D 사업계획서",
        content: [...responseData.content],
      };
      CommonAPI.updateBusinessData(
        window.sessionStorage.getItem("itemIdx"),
        payload
      ).then(() => history.push("/plan/rnd/list/fourth"));
    } else {
      const payload = {
        title: "R&D 사업계획서",
        category1_idx: 1,
        category2_idx: 11,
        content: [contentData],
      };
      CommonAPI.addBusinessData(payload).then(() =>
        history.push("/plan/rnd/list/first")
      );
    }
  };

  return (
    <div className="wrapper">
      <SideBar />
      <div className="rnd_wrapper">
        <RnDText index={3} />
        <div className="rnd_plan">
          <div className="plan_text">
            <div>
              <h2>Step.1 연구개발과제의 필요성</h2>
              <FirstInfoText />
            </div>
            <div>
              <span>
                *사업별 특성에 따라 작성 항목 및 작성안내 문구 변경 가능
              </span>
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepOne}
              onChange={(e) => {
                setThirdPageData({ ...thirdPageData, stepOne: e });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepOne"*/}
            {/*  value={thirdPageData.stepOne}*/}
            {/*  onChange={(e) => handleChange(e)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
        </div>
        <div className="rnd_plan">
          <h2>Step.2 연구개발과제의 목표 및 내용</h2>
          <div className="plan_text">
            <div>
              <p className="sub_title">1) 연구개발과제의 최종 목표</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepTwo[0]}
              onChange={(e) => {
                thirdPageData.stepTwo[0] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepTwo"*/}
            {/*  value={thirdPageData.stepTwo[0]}*/}
            {/*  onChange={(e) => handleChange(e, 0)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <div>
              <p className="sub_title">2) 연구개발과제의 단계별 목표</p>
              <FirstInfoText />
            </div>
            <div>
              <p className="check_text"> ※ 해당 시 작성합니다</p>
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepTwo[1]}
              onChange={(e) => {
                thirdPageData.stepTwo[1] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepTwo"*/}
            {/*  value={thirdPageData.stepTwo[1]}*/}
            {/*  onChange={(e) => handleChange(e, 1)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <div>
              <p className="sub_title">3) 연구개발과제의 내용</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepTwo[2]}
              onChange={(e) => {
                thirdPageData.stepTwo[2] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepTwo"*/}
            {/*  value={thirdPageData.stepTwo[2]}*/}
            {/*  onChange={(e) => handleChange(e, 2)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <div>
              <p className="sub_title">
                4) 연구개발과제 수행일정 및 주요 결과물
              </p>
              <FirstInfoText />
            </div>
            <div>
              <p className="check_text"> ※ 해당 시 작성합니다</p>
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepTwo[3]}
              onChange={(e) => {
                thirdPageData.stepTwo[3] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepTwo"*/}
            {/*  value={thirdPageData.stepTwo[3]}*/}
            {/*  onChange={(e) => handleChange(e, 3)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
        </div>
        <div className="rnd_plan">
          <h2>Step.3 연구개발과제의 추진전략·방법 및 추진체계</h2>
          <div className="plan_text">
            <div>
              <p className="sub_title">1) 연구개발과제의 추진전략ㆍ방법</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepThree[0]}
              onChange={(e) => {
                thirdPageData.stepThree[0] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepThree"*/}
            {/*  value={thirdPageData.stepThree[0]}*/}
            {/*  onChange={(e) => handleChange(e, 0)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <div>
              <p className="sub_title">2) 연구개발과제의 추진체계</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepThree[1]}
              onChange={(e) => {
                thirdPageData.stepThree[1] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepThree"*/}
            {/*  value={thirdPageData.stepThree[1]}*/}
            {/*  onChange={(e) => handleChange(e, 1)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
        </div>
        <div className="rnd_plan">
          <h2>Step.4 연구개발성과의 활용방안 및 기대 효과</h2>
          <div className="plan_text">
            <div>
              <p className="sub_title">1) 연구개발성과의 활용방안</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFour[0]}
              onChange={(e) => {
                thirdPageData.stepFour[0] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFour"*/}
            {/*  value={thirdPageData.stepFour[0]}*/}
            {/*  onChange={(e) => handleChange(e, 0)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <div>
              <p className="sub_title">2) 연구개발성과의 기대효과</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFour[1]}
              onChange={(e) => {
                thirdPageData.stepFour[1] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFour"*/}
            {/*  value={thirdPageData.stepFour[1]}*/}
            {/*  onChange={(e) => handleChange(e, 1)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
        </div>
        <div className="rnd_plan">
          <div className="plan_text">
            <div>
              <h2>Step.5 연구개발성과의 사업화 전략 및 계획 </h2>
            </div>
            <div>
              <span>
                해당 시 작성하며, 작성 시에는 연구개발과제 특성에 따라
                선택적으로 항목 적용이 가능합니다
              </span>
            </div>
          </div>
          <div className="sub_title_wrapper">
            <p className="sub_title">1) 국내외 시장 동향</p>
            <FirstInfoText />
          </div>
          <div className="rnd_textarea_wrapper margin_10">
            <div className="rnd_icon">
              <p className="small_title">
                (1)
                <br />
                국내외 시장규모
                <br />및 수출입 현황
              </p>
              <FirstInfoText />
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[0]}
              onChange={(e) => {
                thirdPageData.stepFive[0] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[0]}*/}
            {/*  onChange={(e) => handleChange(e, 0)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="rnd_textarea_wrapper margin_10">
            <div className="rnd_icon">
              <p className="small_title">
                (2)
                <br />
                국내외
                <br />
                주요 수요처 현황
              </p>
              <FirstInfoText />
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[1]}
              onChange={(e) => {
                thirdPageData.stepFive[1] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[1]}*/}
            {/*  onChange={(e) => handleChange(e, 1)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="rnd_textarea_wrapper margin_10">
            <div className="rnd_icon">
              <p className="small_title">
                (3)
                <br />
                국내외 경쟁기관
                <br />및 기술 현황
              </p>
              <FirstInfoText />
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[2]}
              onChange={(e) => {
                thirdPageData.stepFive[2] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[2]}*/}
            {/*  onChange={(e) => handleChange(e, 2)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <div>
              <p className="sub_title">
                2) 지식재산권, 표준화 및 인증기준 현황
              </p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[3]}
              onChange={(e) => {
                thirdPageData.stepFive[3] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[3]}*/}
            {/*  onChange={(e) => handleChange(e, 3)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <div>
              <p className="sub_title">3) 표준화 전략</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[4]}
              onChange={(e) => {
                thirdPageData.stepFive[4] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[4]}*/}
            {/*  onChange={(e) => handleChange(e, 4)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <p className="sub_title">4) 사업화 계획</p>
          </div>
          <div className="rnd_textarea_wrapper margin_10">
            <div className="rnd_icon">
              <p className="small_title">
                (1)
                <br />
                사업화 전략
              </p>
              <FirstInfoText />
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[5]}
              onChange={(e) => {
                thirdPageData.stepFive[5] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[5]}*/}
            {/*  onChange={(e) => handleChange(e, 5)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="rnd_textarea_wrapper margin_10">
            <div className="rnd_icon">
              <p className="small_title">
                (2)
                <br />
                투자 계획
              </p>
              <FirstInfoText />
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[6]}
              onChange={(e) => {
                thirdPageData.stepFive[6] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[6]}*/}
            {/*  onChange={(e) => handleChange(e, 6)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="rnd_textarea_wrapper margin_10">
            <div className="rnd_icon">
              <p className="small_title">
                (3)
                <br />
                생산 계획
              </p>
              <FirstInfoText />
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[7]}
              onChange={(e) => {
                thirdPageData.stepFive[7] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[7]}*/}
            {/*  onChange={(e) => handleChange(e, 7)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="rnd_textarea_wrapper margin_10">
            <div className="rnd_icon">
              <p className="small_title">
                (4)
                <br />
                해외 시장
                <br />
                진출 계획
              </p>
              <FirstInfoText />
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[8]}
              onChange={(e) => {
                thirdPageData.stepFive[8] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[8]}*/}
            {/*  onChange={(e) => handleChange(e, 8)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="rnd_textarea_wrapper margin_10">
            <div className="rnd_icon">
              <p className="small_title">
                (5)
                <br />
                사업화에 따른
                <br />
                기대효과
              </p>
              <FirstInfoText />
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepFive[9]}
              onChange={(e) => {
                thirdPageData.stepFive[9] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepFive"*/}
            {/*  value={thirdPageData.stepFive[9]}*/}
            {/*  onChange={(e) => handleChange(e, 9)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
        </div>
        <div className="rnd_plan">
          <div className="plan_text">
            <div>
              <h2>Step.6 연구개발 안전 및 보안조치 이행계획</h2>
            </div>
            <div>
              <span>*연구개발과제 협약 시 제출하는 계획입니다</span>
            </div>
          </div>
          <div className="sub_title_wrapper">
            <p className="sub_title">1) 안전조치 이행계획</p>
            <FirstInfoText />
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepSix[0]}
              onChange={(e) => {
                thirdPageData.stepSix[0] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepSix"*/}
            {/*  value={thirdPageData.stepSix[0]}*/}
            {/*  onChange={(e) => handleChange(e, 0)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <div>
              <p className="sub_title">2) 보안조치 이행계획</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepSix[1]}
              onChange={(e) => {
                thirdPageData.stepSix[1] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepSix"*/}
            {/*  value={thirdPageData.stepSix[1]}*/}
            {/*  onChange={(e) => handleChange(e, 1)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
          <div className="plan_text">
            <div>
              <p className="sub_title">3) 그 밖의 조치사항 이행계획</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="rnd_textarea_wrapper">
            <div className="rnd_icon">
              <BringingUpIcon />
              <ReferenceIcon />
            </div>
            <SunEditor
              lang="ko"
              setContents={thirdPageData.stepSix[2]}
              onChange={(e) => {
                thirdPageData.stepSix[2] = e;
                setThirdPageData({ ...thirdPageData });
              }}
            />
            {/*<textarea*/}
            {/*  name="stepSix"*/}
            {/*  value={thirdPageData.stepSix[2]}*/}
            {/*  onChange={(e) => handleChange(e, 2)}*/}
            {/*/>*/}
            {/*<p className="content_length">0/최소 1000자</p>*/}
          </div>
        </div>
        <div className="prev_next_button">
          <button
            className="prev_button"
            onClick={() => history.push("/plan/rnd/list/second")}
          >
            <img src={prevButton} alt="prevButton" />
          </button>
          <button className="next_button" onClick={() => sendData()}>
            <img src={nextButton} alt="nextButton" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RnDPlanThird;
