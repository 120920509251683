import API from "../../util/api";

export default {
  getInventionFileList(invention_idx, description) {
    return API.get(`/manager/invention/${invention_idx}/file`, {
      params: { description: description },
    });
  },
  getInventionOpenFileList(invention_idx, open_idx) {
    return API.get(`/manager/invention/${invention_idx}/open/${open_idx}/file`);
  },
  getJointCompany(keyword) {
    return API.get(`/manager/company/joint`, { params: { keyword: keyword } });
  },
  getInventionInfo(invention_idx) {
    return API.get(`/manager/invention/${invention_idx}`);
  },
  // 발명신고서 상세페이지 서식 다운로드
  downloadInventionDescription(invention_idx) {
    return API.get(`/manager/invention/${invention_idx}/download`, {
      responseType: "blob",
    })
      .then((response) => {
        const blob = response.data;

        // ⚡ 파일 다운로드
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "발명신고서.docx"; // 확장자 `.docx` 설정
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Download failed:", error);
        alert("파일 다운로드에 실패했습니다.");
      });
  },
  downloadInventionFile(invention_idx, file_idx) {
    window.$Global.download(
      `/manager/invention/${invention_idx}/file/${file_idx}/download`
    );
  },
  downloadInventionOpenFile(invention_idx, open_idx, file_idx) {
    window.$Global.download(
      `/manager/invention/${invention_idx}/open/${open_idx}/file/${file_idx}/download`
    );
  },
  deleteInventionFile(invention_idx, file_idx) {
    return API.delete(`/manager/invention/${invention_idx}/file/${file_idx}`);
  },
  deleteInventionOpenFile(invention_idx, open_idx, file_idx) {
    return API.delete(
      `/manager/invention/${invention_idx}/open/${open_idx}/file/${file_idx}`
    );
  },
  uploadInventionFile(invention_idx, formData) {
    return API.post(`/manager/invention/${invention_idx}/file`, formData);
  },
  uploadInventionOpenFile(invention_idx, open_idx, formData) {
    return API.post(
      `/manager/invention/${invention_idx}/open/${open_idx}/file`,
      formData
    );
  },

  // 발명자 계정 연결
  connectInventorAccount(formData) {
    return API.post(`/manager/member`, formData);
  },

  // 발명자 계정 수정(관리자)
  updateInventorAccount(formData, inventor_idx) {
    return API.patch(`/manager/inventor/member/${inventor_idx}`, formData);
  },

  // 발명자 계정 삭제
  deleteInventorAccount(inventor_idx) {
    return API.delete(`/manager/inventor/member/${inventor_idx}`);
  },

  // 발명자 약관 동의 여부 제출
  submitInventorConsent(formData) {
    return API.post(`/manager/inventor/consent`, formData);
  },
};
