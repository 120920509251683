import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContentTableList from "../../common/ContentTableList";
import LawFirmAPI from "../../../API/lawfirm";
import _ from "lodash";

function AdminLawfirm({ category }) {
  const [resData, setResData] = useState({ items: [] });
  const [tbody, setTbody] = useState([]);

  const [curPage, setCurPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const count = 10;
  const path = "/admin/manage/lawfirm";

  let payload = {
    count: count,
    page: 1,
  };

  useEffect(() => {
    getList(payload);
    setCurPage(1);
  }, []);

  useEffect(() => {
    drawTable();
  }, [resData]);

  const getList = (payload, content_type) => {
    LawFirmAPI.getList(payload).then((res) => {
      let result = res.data;

      if (content_type == "next") {
        result.items = resData.items.concat(result.items);
      }
      setResData(result);
    });
  };

  const drawTable = () => {
    setTbody(
      resData.items.map((item, index) => {
        return (
          <tr key={index}>
            <td>{resData.total_count - index}</td>
            <td>
              <Link
                to={`${path}/posting?idx=${item.idx}&type=view&category=${category}`}
              >
                <div className="content">
                  <img src={window.$Global.getCDN(item.img_key)} alt="이미지" />
                  <div>
                    <h2>{item.title}</h2>
                  </div>
                </div>
              </Link>
            </td>
            <td>{window.$Global.convertDate(item.created_at)}</td>
            <td>
              <button
                className="icon_badge_del"
                onClick={() => onClickPostDelete(index, item.idx)}
              />
            </td>
          </tr>
        );
      })
    );
    if (resData.page == resData.last) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  const nextList = () => {
    let payload = {
      page: curPage + 1,
      count: count,
    };
    setCurPage(curPage + 1);
    getList(payload, "next");
  };

  const onClickPostDelete = (depth, idx) => {
    LawFirmAPI.deletePost(idx).then(() => {
      let copy = _.cloneDeep(resData);
      copy.items.splice(depth, 1);
      setResData(copy);
      alert("삭제되었습니다");
    });
  };

  return (
    <>
      <div className="btns">
        <Link to={`${path}/posting?category=${category}`} className="btn_post">
          글 작성하기
        </Link>
      </div>
      <ContentTableList
        addTheadList={["삭제"]}
        title={"해외 로펌"}
        data={tbody}
        content={tbody}
        drawList={nextList}
        hasMore={hasMore}
      />
    </>
  );
}

export default AdminLawfirm;
