import "../../../trademark/css/HistoryPopup.scss";

import { useContext, useState } from "react";
import PatentContext from "../../../../store/Patent";
import DatePickerCustom from "../../../datepicker/DatePickerCustom";

const HistoryPopup = ({ setHistoryPopupShow, historyPopupIdx }) => {
  const design = useContext(PatentContext);
  const [historyItem, setHistoryItem] = useState(
    design.history_list[historyPopupIdx]
  );

  const onClickSave = () => {
    design.setPatent({ ...design });
    setHistoryPopupShow(false);
  };
  return (
    <div id="HistoryPopup">
      <header>
        <p>History 상세보기</p>
        <i
          className="icon_exit_gray"
          onClick={() => setHistoryPopupShow(false)}
        />
      </header>
      <section>
        <ul>
          <li>
            <p>업무일자</p>
            <div>
              {design.editing ? (
                <DatePickerCustom
                  data={historyItem.work_at}
                  onChangeDatePicker={(e) => {
                    historyItem.work_at = new Date(e).getTime();
                    design.setPatent({ ...design });
                  }}
                />
              ) : (
                <p>{window.$Global.convertDate(historyItem.work_at)}</p>
              )}
            </div>
          </li>
          <li>
            <p>지시요청기한</p>
            <div>
              {design.editing ? (
                <DatePickerCustom
                  data={historyItem.instruction_deadline}
                  onChangeDatePicker={(e) => {
                    historyItem.instruction_deadline = new Date(e).getTime();
                    design.setPatent({ ...design });
                  }}
                />
              ) : (
                <p>
                  {window.$Global.convertDate(historyItem.instruction_deadline)}
                </p>
              )}
            </div>
          </li>
          <li>
            <p>법정기한</p>
            <div>
              {design.editing ? (
                <DatePickerCustom
                  data={historyItem.legal_deadline}
                  onChangeDatePicker={(e) => {
                    historyItem.legal_deadline = new Date(e).getTime();
                    design.setPatent({ ...design });
                  }}
                />
              ) : (
                <p>{window.$Global.convertDate(historyItem.legal_deadline)}</p>
              )}
            </div>
          </li>
          <li>
            <p>업무제목</p>
            <input
              type="text"
              value={historyItem.title}
              onChange={(e) => {
                historyItem.title = e.target.value;
                design.setPatent({ ...design });
              }}
              readOnly={!design.editing}
            />
          </li>
          <li>
            <p>코멘트</p>
            <textarea
              value={historyItem.content}
              onChange={(e) => {
                historyItem.content = e.target.value;
                design.setPatent({ ...design });
              }}
              readOnly={!design.editing}
            />
          </li>
        </ul>
      </section>
      <footer>
        <button onClick={onClickSave}>저장하기</button>
      </footer>
    </div>
  );
};

export default HistoryPopup;
