import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import "./css/DeadLineList.scss";
import DeadlineAPI from "../../API/deadline/index";
import InfiniteScroll from "react-infinite-scroll-component";
import CheckBox from "../common/CheckBox";
import _ from "lodash";
import MemberContext from "../../store/Member";
import * as htmlToImage from "html-to-image";
import { useReactToPrint } from "react-to-print";
import trademark from "../../API/trademark";

function DeadLineList() {
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const history = useHistory();
  const count = 100;
  const { auth, manage_company_idx } = useContext(MemberContext);

  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);

  const [tbody, setTbody] = useState([]);
  const [curPage, setCurPage] = useState({
    patent: 1,
    trademark: 1,
  });
  const [hasMore, setHasMore] = useState(false);
  const [resposeData, setResponseData] = useState({});
  const [checkList, setCheckList] = useState([]);
  const [isPatent, setIsPatent] = useState(
    window.sessionStorage.getItem("deadlineTab")
  );
  window.sessionStorage.setItem("deadlineTab", isPatent);

  const arr = [
    "전체",
    "우선권 기한",
    "해외출원 기한",
    "PCT출원 기한",
    "심사청구 기한",
    "OA 기한",
    "등록료 납부기한",
    "분할출원 기한",
    "연차료 기한",
  ];

  const tradeMarkArr = [
    "전체",
    "이의신청 기한순",
    "등록료 납부 기한순",
    "등록비 분할출원 기한순",
    "거절결정 불복기한순",
    "oa제출대응 기한순",
    "재심사 제출 대응 기한순",
    "거절결정불복심판 제출 대응 기한 순",
    "심결취소소송 제출 대응 기한 순",
    "파기환송심 제출 대응 기한 순",
    "히스토리 지시요청기한",
    "히스토리 법정 기한",
    "갱신 분할납부기한",
  ];

  useEffect(() => {
    let initCheckList = isPatent
      ? arr.map((item, index) => (index == 0 ? true : false))
      : tradeMarkArr.map((item, index) => (index === 0 ? true : false));
    setCheckList(initCheckList);
  }, [isPatent]);

  useEffect(() => {
    if (checkList.length) {
      let selectedIdx = checkList.findIndex((item) => item == true);
      isPatent
        ? getAPI({ count: count, page: 1, sort_type: selectedIdx })
        : getTradeMarkList({ count: count, page: 1, sort_type: selectedIdx });
    }
  }, [checkList, isPatent]);

  const getTradeMarkList = async (payload, type) => {
    let data = {};
    data = await DeadlineAPI.getTradeMarkDeadline(payload).then(
      (res) => res.data
    );
    if (type == "next") {
      data.items = resposeData.items.concat(data.items);
    }

    if (data.page == data.last) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    setCurPage({ ...curPage, trademark: curPage.trademark + 1 });
    setResponseData(data);

    let tag = [];

    if (data.items.length) {
      tag = data.items.map((item, index) => {
        return (
          <tr
            key={index}
            onClick={() => history.push(`/trademark/detail/${item.idx}`)}
          >
            <td>{item.manage_number}</td>
            <td>{item.apply_number}</td>
            <td>{item.name}</td>
            <td>{window.$Global.convertDate(item.objection_deadline)}</td>
            <td>{window.$Global.convertDate(item.reject_deadline)}</td>
            <td>
              {window.$Global.convertDate(item.registration_payment_deadline)}
            </td>
            <td>{window.$Global.convertDate(item.renewal_deadline)}</td>
            <td>{window.$Global.convertDate(item.foreign_apply_deadline)}</td>
            <td>
              {window.$Global.convertDate(
                item.registration_divide_payment_deadline
              )}
            </td>
            <td>{window.$Global.convertDate(item.oa_deadline)}</td>
            <td>
              {window.$Global.convertDate(
                item.re_examination_submission_deadline
              )}
            </td>
            <td>
              {window.$Global.convertDate(
                item.refusal_decision_objection_submission_deadline
              )}
            </td>
            <td>
              {window.$Global.convertDate(
                item.trial_decision_revocation_suit_submission_deadline
              )}
            </td>
            <td>
              {window.$Global.convertDate(
                item.case_reversed_and_remanded_submission_deadline
              )}
            </td>
            <td>
              {window.$Global.convertDate(item.history_instruction_deadline)}
            </td>
            <td>{window.$Global.convertDate(item.history_legal_deadline)}</td>
            <td>
              {window.$Global.convertDate(item.renew_split_payment_deadline)}
            </td>
          </tr>
        );
      });
    } else {
      tag = window.$Global.notTd(11, "등록된 정보가 없습니다");
    }

    setTbody(tag);
  };

  const handlePatentClick = () => {
    setIsPatent(true);
    setCheckList([]);
  };

  const onChangeChecked = (e, index) => {
    let copy = _.cloneDeep(checkList);

    copy = copy.map((item, idx) => {
      if (idx != index) {
        return false;
      }
    });
    copy[index] = e.target.checked;

    if (copy.findIndex((item) => item == true) == -1) {
      copy[0] = true;
    }
    setCheckList(copy);
    isPatent
      ? setCurPage({ ...curPage, patent: 1 })
      : setCurPage({ ...curPage, trademark: 1 });
  };

  const getAPI = async (payload, type) => {
    let data = {};

    if (isCompany) {
      data = await DeadlineAPI.getPatentDeadline(payload).then(
        (res) => res.data
      );
    } else if (isAgent) {
      data = await DeadlineAPI.getPatentDeadlineAsAgent(
        manage_company_idx,
        payload
      ).then((res) => res.data);
    }

    if (type == "next") {
      data.items = resposeData.items.concat(data.items);
    }

    if (data.page == data.last) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }

    setCurPage({ ...curPage, patent: curPage.patent + 1 });
    setResponseData(data);

    let tag = [];

    if (data.items.length) {
      tag = data.items.map((item, index) => {
        return (
          <tr
            key={index}
            onClick={() => history.push(`/patent/detail/${item.idx}`)}
          >
            <td>{item.manage_number}</td>
            <td>{item.apply_number}</td>
            <td>{item.invention_name}</td>
            <td>
              {window.$Global.convertDate(
                item.priority_claim_deadline_domestic
              )}
            </td>
            <td>
              {window.$Global.convertDate(item.priority_claim_deadline_foreign)}
            </td>
            <td>{window.$Global.convertDate(item.pct_apply_deadline)}</td>
            <td>{window.$Global.convertDate(item.screen_deadline)}</td>
            <td>{window.$Global.convertDate(item.oa_deadline)}</td>
            <td>{window.$Global.convertDate(item.payment_deadline)}</td>
            <td>{window.$Global.convertDate(item.division_deadline)}</td>
            <td>{window.$Global.convertDate(item.annual_payment_deadline)}</td>
          </tr>
        );
      });
    } else {
      tag = window.$Global.notTd(11, "등록된 정보가 없습니다");
    }

    setTbody(tag);
  };

  const nextList = () => {
    let selectedIdx = checkList.findIndex((item) => item == true);
    let payload = {
      count: count,
      page: isPatent ? curPage.patent : curPage.trademark,
      sort_type: selectedIdx,
    };

    isPatent ? getAPI(payload, "next") : getTradeMarkList(payload, "next");
  };

  const download = () => {
    let sort_type = checkList.findIndex((item) => item);
    isPatent
      ? window.$Global.fileDownloadSync(`/manager/patent/deadline/download`, {
          sort_type,
        })
      : window.$Global.fileDownloadSync(
          `/manager/trademark/deadline/download`,
          { sort_type }
        );
  };

  const capture = () => {
    setTimeout(() => {
      try {
        htmlToImage
          .toPng(document.getElementById("Table"))
          .then(function (dataUrl) {
            let a = document.createElement("a"); //Create <a>
            a.href = dataUrl; //Image Base64 Goes here
            a.download = "Image.png"; //File name Here
            a.click(); //Downloaded file
          });
      } catch (err) {
        alert("서버에서 불러올 데이터가 없어 다운할 수 없습니다");
      }
    }, 100);
  };

  return (
    <div id="deadlineList">
      <div className="area_box">
        <img
          src={
            require("../../assets/images/deadline/icon_deadline.svg").default
          }
          alt="소개 이미지"
        />
        <div className="inner">
          <h2>IP 기한 관리란?</h2>
          <p>
            IPNOW의 IP 기한 관리는 다양한 기한을 알려드려 꼼꼼하게 일을 할 수
            있도록 도와주는 기능입니다.
            <br />각 기한에 대한 알림 날짜 및 횟수를 설정할 수 있는 기한 알림
            설정 서비스도 제공됩니다.
          </p>
        </div>
      </div>
      <div className="header">
        <h2 className="title">IP 기한관리</h2>
        <div className="btns">
          <button className="btn_download" onClick={handlePrint}>
            프린트하기
          </button>
          <button className="btn_download" onClick={download}>
            Excel 다운
          </button>
          <Link
            className="btn_setting"
            to={{ pathname: "/deadline/setting", state: { value: isPatent } }}
          >
            기한 알림 설정
          </Link>
        </div>
      </div>
      <div className="sorting">
        <p>
          <i className="icon_alert_circle" />
          <span>선택한 항목의 임박한 기한 순서대로 나열됩니다.</span>
        </p>
        <div className="sort_check">
          {checkList.map((item, index) => (
            <CheckBox
              key={index}
              text={isPatent ? arr[index] : tradeMarkArr[index]}
              checked={item}
              onChangeChecked={(e) => onChangeChecked(e, index)}
            />
          ))}
        </div>
      </div>
      <div className="change_view">
        <div
          onClick={handlePatentClick}
          style={{
            borderBottom: isPatent && "3px solid rgb(69,147,245)",
          }}
        >
          특허
        </div>
        <div
          onClick={() => setIsPatent(false)}
          style={{
            borderBottom: !isPatent && "3px solid rgb(69,147,245)",
          }}
        >
          상표
        </div>
      </div>
      <div className="table-box-wrap">
        <div id="scrollableDiv" className="wrap_list">
          <InfiniteScroll
            dataLength={tbody.length}
            next={nextList}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            <table ref={componentRef}>
              <thead>
                {isPatent ? (
                  <tr>
                    <th>관리번호</th>
                    <th>출원번호</th>
                    <th>발명의 명칭</th>
                    <th>우선권 기한</th>
                    <th>해외출원 기한</th>
                    <th>PCT출원 기한</th>
                    <th>심사청구 기한</th>
                    <th>OA 기한</th>
                    <th>등록료 납부기한</th>
                    <th>분할출원 기한</th>
                    <th>연차료 기한</th>
                  </tr>
                ) : (
                  <tr>
                    <th>관리번호</th>
                    <th>출원번호</th>
                    <th>상표명칭</th>
                    <th>이의신청 마감일</th>
                    <th>거절결정 불복 기한순</th>
                    <th>등록료 납부 기한</th>
                    <th>갱신 마감 일</th>
                    <th>해외 출원 마감 기한</th>
                    <th>등록비 분할출원 기한</th>
                    <th>oa제출대응 기한</th>
                    <th>재심사 제출 대응 기한</th>
                    <th>거절결정불복심판 제출 대응 기한</th>
                    <th>심결취소소송 제출 대응 기한</th>
                    <th>파기환송심 제출 대응 기한</th>
                    <th>히스토리 지시요청 기한</th>
                    <th>히스토리 법정 기한</th>
                    <th>갱신 분할납부 기한</th>
                  </tr>
                )}
              </thead>
              <tbody>{tbody}</tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

export default DeadLineList;
