import React, { useEffect, useState } from "react";
import qs from "query-string";
import { Link } from "react-router-dom";
import "./css/SignupTerms.scss";
import InventionAPI from "../../API/invention";
import CheckBox from "../common/CheckBox";
import Terms from "../../const/Terms";
import { IconButton } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";

// isTerms: 약관 동의 제출 여부
// onClose: 모달창 닫기
function SignupTerms({
  location,
  isTerms = true,
  onClose,
  setInventorConsent,
}) {
  const [agreeUse, setAgreeUse] = useState(false);
  const [agreePrivate, setAgreePrivate] = useState(false);
  const [agreeAll, setAgreeAll] = useState(false);

  const [moreType, setMoreType] = useState(0);

  // ✅ "모두 동의함" 체크박스 클릭 시 모든 항목을 같은 상태로 변경
  const onChangeSortChecked = () => {
    const newState = !agreeAll;
    setAgreeUse(newState);
    setAgreePrivate(newState);
    setAgreeAll(newState);
  };

  // ✅ 개별 체크박스 클릭 시 "모두 동의함" 상태 자동 업데이트
  useEffect(() => {
    if (agreeUse && agreePrivate) {
      setAgreeAll(true); // 두 개가 모두 체크되면 "모두 동의함"도 체크
    } else {
      setAgreeAll(false); // 하나라도 체크 해제되면 "모두 동의함" 해제
    }
  }, [agreeUse, agreePrivate]);

  const onClickAgree = () => {
    if (moreType == 1) {
      setAgreeUse(true);
    } else if (moreType == 2) {
      setAgreePrivate(true);
    }
    setMoreType(0);
  };

  // ✅ 로그아웃 핸들러 (약관 동의 안내 추가)
  const handleLogout = () => {
    const confirmLogout = window.confirm(
      "서비스 이용을 위해 약관 동의가 필요합니다.\n" +
        "동의하지 않으면 자동 로그아웃됩니다. 계속하시겠습니까?"
    );

    if (confirmLogout) {
      window.$Global.logout();
    }
  };

  // ✅ 약관 동의 제출 핸들러
  const handleSubmit = () => {
    InventionAPI.submitInventorConsent({ consent: true }).then(() => {
      sessionStorage.setItem("inventor_consent", "1"); // ✅ 세션 스토리지 값 변경
      setInventorConsent(1);
    });
    onClose && onClose();
  };

  return (
    <div id="SignupTerms" style={!isTerms ? { padding: 0 } : {}}>
      <div className="header">
        <h2 className="page_title">약관동의</h2>
        {!isTerms && (
          <IconButton onClick={handleLogout} sx={{ color: "gray" }}>
            <AiOutlineClose size={24} />
          </IconButton>
        )}
      </div>

      <div className="pc">
        <div className="wrap_term">
          <p className="desc">IPNOW 이용약관동의</p>
          <div
            className="term"
            dangerouslySetInnerHTML={{ __html: Terms.useTxt }}
          />
          <CheckBox
            text="동의함"
            checked={agreeUse}
            onChangeChecked={() => setAgreeUse(!agreeUse)}
          />
        </div>
        <div className="wrap_term">
          <p className="desc">개인정보 수집 및 이용에 대한 안내</p>
          <div
            className="term"
            dangerouslySetInnerHTML={{ __html: Terms.privateTxt }}
          />
          <CheckBox
            text="동의함"
            checked={agreePrivate}
            onChangeChecked={() => setAgreePrivate(!agreePrivate)}
          />
          <CheckBox
            text="모두 동의함"
            checked={agreeAll}
            onChangeChecked={onChangeSortChecked}
          />
        </div>
      </div>
      <div className="mo">
        <div className="tab">
          <div className="tab_title">
            {moreType == 0 && (
              <>
                <CheckBox
                  checked={agreeAll}
                  onChangeChecked={onChangeSortChecked}
                />
                <div className="title">
                  <p>전체동의</p>
                </div>
              </>
            )}
            {moreType == 1 && (
              <div className="title">
                <p>(필수)서비스 이용약관</p>
              </div>
            )}
            {moreType == 2 && (
              <div className="title">
                <p>(필수)개인정보 수집 및 이용에 대한 안내</p>
              </div>
            )}
          </div>
          <ul className={`tab_content ${moreType > 0 ? "agree" : ""}`}>
            {moreType == 0 && (
              <>
                <li>
                  <CheckBox
                    checked={agreeUse}
                    onChangeChecked={() => setAgreeUse(!agreeUse)}
                  />
                  <p className="txt">(필수)서비스 이용약관</p>
                  <button className="more" onClick={() => setMoreType(1)}>
                    자세히
                  </button>
                </li>
                <li>
                  <CheckBox
                    checked={agreePrivate}
                    onChangeChecked={() => setAgreePrivate(!agreePrivate)}
                  />
                  <p className="txt">(필수)개인정보 수집 및 이용에 대한 안내</p>
                  <button className="more" onClick={() => setMoreType(2)}>
                    자세히
                  </button>
                </li>
              </>
            )}
            {moreType == 1 && (
              <li>
                <p
                  className="txt agree"
                  dangerouslySetInnerHTML={{ __html: Terms.useTxt }}
                />
              </li>
            )}
            {moreType == 2 && (
              <li>
                <p
                  className="txt agree"
                  dangerouslySetInnerHTML={{ __html: Terms.privateTxt }}
                />
              </li>
            )}
          </ul>
        </div>

        {moreType > 0 && (
          <div className="btns">
            <button className="btn btn_no" onClick={() => setMoreType(0)}>
              뒤로
            </button>
            <button className="btn" onClick={onClickAgree}>
              동의
            </button>
          </div>
        )}
      </div>
      {moreType == 0 && (
        <div className="btns">
          {isTerms ? (
            <>
              <Link className={`btn btn_prev`} to={`/signup/type`}>
                이전
              </Link>
              <Link
                className={`btn btn_next_step ${
                  agreeUse && agreePrivate ? "" : "btn_no"
                }`}
                to={`/signup/input?type=${qs.parse(location?.search).type}`}
              >
                다음
              </Link>
            </>
          ) : (
            <button
              className={`btn btn_next_step ${
                agreeUse && agreePrivate ? "" : "btn_no"
              }`}
              onClick={handleSubmit}
            >
              제출
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default SignupTerms;
