import "../css/CompetitorFinanceStatus.scss";

const CompetitorFinanceStatus = ({ data, togglePop }) => {
  // 데이터 변환 함수
  const transformData = (data) => {
    // 데이터가 비어 있으면 빈 객체 반환
    if (!data || Object.keys(data).length === 0) {
      return { items: [], years: [], transformedData: {} };
    }

    // 항목과 연도 추출
    const items = Array.from(
      new Set(
        Object.values(data)
          .flat()
          .map((item) => Object.keys(item))
          .flat()
      )
    );
    const years = Object.keys(data);

    // 변환된 데이터 구조 만들기
    const transformedData = items.reduce((acc, item) => {
      acc[item] = years.map((year) => {
        const found = data[year].find((entry) => entry[item] !== undefined);
        return found ? found[item] : null;
      });
      return acc;
    }, {});

    return { items, years, transformedData };
  };

  // 데이터를 변환
  const { items, years, transformedData } = transformData(data);

  return (
    <div id="CompetitorFinanceStatus">
      <div class="sub_title">
        <p>
          연도별 재무상태표 <span>(단위:백만원)</span>
        </p>
      </div>

      {Object.keys(data).length ? (
        <div className="tbl_box custom_scroll">
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>재무상태표</th>
                {Object.keys(data).map((year) => (
                  <th key={year}>{year}</th>
                ))}
              </tr>
              <tr>
                {Object.keys(data).map((year) => (
                  <th key={year}>금액</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.length > 0 ? (
                items.map((item) => (
                  <tr key={item}>
                    <td className="name">{item}</td>
                    {years.map((year, index) => (
                      <td key={`${item}-${year}`}>
                        {window.$Global.commaify(
                          Math.floor(transformedData[item][index] * 0.001)
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={years.length + 1}>데이터가 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="no_data">데이터가 없습니다.</p>
      )}

      <button onClick={() => togglePop(false)}>
        <i class="icon_exit_gray close"></i>
      </button>
    </div>
  );
};

export default CompetitorFinanceStatus;
