import "./css/InventorList.scss";
import { useHistory } from "react-router-dom";
import Pagination from "../../common/Pagination";
import React, { useEffect, useState } from "react";
import AdminAPI from "../../../API/admin";
import qs from "query-string";

const InventorList = ({ location }) => {
  const { allCompanyNameSearch, companyNameSearch, companyIdx } = qs.parse(
    location.search
  );
  const history = useHistory();
  const { company_idx } = location.state || companyIdx;
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [inventorList, setInventorList] = useState([]);
  const [searchInput, setSearchInput] = useState(companyNameSearch);
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    getInventorList();
  }, [curPage]);

  useEffect(() => {
    getCompanyInfo(company_idx);
  }, []);

  const getInventorList = () => {
    let params = {
      count: 10,
      page: curPage,
      keyword: searchInput, // 검색창 입력시 호출하는 변수
      company_idx: company_idx,
    };

    AdminAPI.getAdminCompanyInventorAllList(
      company_idx || companyIdx,
      params
    ).then((res) => {
      setLast(res.data.last);
      setInventorList(res.data.items);
    });
  };

  const getCompanyInfo = (company_idx) => {
    AdminAPI.getAdminCompanyInfo(company_idx || companyIdx).then((res) => {
      setCompanyInfo(res.data);
    });
  };

  const updateCheckBox = (item) => {
    AdminAPI.updateInventorInfo(item.idx, item).then(() => {
      getInventorList();
    });
  };

  return (
    <div id="InventorList">
      <div className="header">
        <h2>"{companyInfo.name}"의 전체 발명자 리스트</h2>
        <button onClick={() => history.push("/admin/annual")}>
          목록으로 돌아가기
        </button>
      </div>
      <div className="inventor_search_wrap">
        <input
          type="text"
          placeholder="찾고싶은 발명자의 이름을 입력하세요."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyUp={(e) => e.key === "Enter" && getInventorList()}
        />
        <i className="icon_search_gray" onClick={() => getInventorList()} />
      </div>
      <div className="inventor_list">
        <table>
          <colgroup>
            <col width="50px" />
            <col width="80px" />
            <col width="200px" />
            <col width="250px" />
            <col width="500px" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>주소</th>
              <th>특허건수</th>
              <th>상세보기</th>
              <th>확인</th>
            </tr>
          </thead>
          <tbody>
            {inventorList?.map((item, index) => {
              return (
                <tr
                  onClick={() =>
                    history.push({
                      pathname: `/admin/inventor/patent/list`,
                      search: `?companyNameSearch=${
                        searchInput ? searchInput : ""
                      }&companyIdx=${company_idx}`,
                      state: item,
                    })
                  }
                >
                  <td>{(curPage - 1) * 10 + (index + 1)}</td>
                  <td>{item.inventor_name}</td>
                  <td>{item.user_tel_no}</td>
                  <td>{item.user_email}</td>
                  <td>{item.user_addr}</td>
                  <td>{item.patent_cnt} 건</td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <button className="btn_add">등록</button>
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={item.is_completed}
                      value={item.is_completed}
                      onChange={() => {
                        item.is_completed = Number(!item.is_completed);
                        updateCheckBox(item);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
    </div>
  );
};

export default InventorList;
