import React, { useState } from "react";
import "./DummyLogin.scss";
import MemberAPI from "../../API/member";
import { useHistory } from "react-router-dom";
import config from "../../const/config";
import AdminAPI from "../../API/admin";

const DummyLogin = () => {
  const [loginInfo, setLoginInfo] = useState({
    id: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const onChangeData = (e) => {
    if (e.target.name === "id") {
      setLoginInfo({ ...loginInfo, id: e.target.value });
    } else if (e.target.name === "password") {
      setLoginInfo({ ...loginInfo, password: e.target.value });
    }
  };

  const login = () => {
    MemberAPI.login(loginInfo).then((res) => {
      const data = res.data;
      checkLogin(data, () => setError(true));
    });
  };

  const checkLogin = async (data, errorCallback) => {
    if (data.token) {
      sessionStorage.setItem(config.keyToken, data.token);

      let historyURL = sessionStorage.getItem("historyURL");
      if (historyURL) {
        window.location.href = historyURL;
        sessionStorage.removeItem("historyURL");
        return;
      }
      window.location.href = "/admin/posting/task/list";
    } else {
      errorCallback();
    }
  };

  return (
    <div id="DummyLogin" className="login">
      <div className="head">
        <h2>
          로그인<span className="pc">이 필요한 서비스입니다.</span>
        </h2>
      </div>
      <div className="wrap_form">
        <div className="login_form">
          <div className="form">
            <div className="input_wrap">
              <div className="input_area id_area">
                <label className="ir_txt" htmlFor="id">
                  아이디
                </label>
                <i className="icon_login_user_id" />
                <input
                  type="text"
                  name="id"
                  className="input_id"
                  placeholder="아이디"
                  value={loginInfo.id}
                  onChange={onChangeData}
                />
              </div>
              <div className="input_area pw_area">
                <label className="ir_txt" htmlFor="pw">
                  비밀번호
                </label>
                <i className="icon_login_lock_pw" />
                <input
                  type="password"
                  name="password"
                  className="input_password"
                  placeholder="비밀번호"
                  value={loginInfo.password}
                  onChange={onChangeData}
                />
              </div>
            </div>
            {error && (
              <p className="error">
                가입하지 않은 아이디이거나 잘못된 비밀번호입니다.
              </p>
            )}
            <button className="btn_login" onClick={() => login()}>
              로그인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DummyLogin;
