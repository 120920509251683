import React from "react";

const FilterContent = ({ comment }) => {
  console.log(comment);
  return (
    <div className="left_content">
      {comment && comment.length > 0 ? (
        comment.map((element, index) => (
          <div style={{ display: "flex", gap: "25px" }}>
            <div>🔹 </div>
            <div key={index}>
              {element}
              <br />
              <br />
            </div>
          </div>
        ))
      ) : (
        <p style={{ textAlign: "center" }}>데이터가 없습니다.</p>
      )}
    </div>
  );
};

export default FilterContent;
