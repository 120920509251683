import React, { useEffect, useState, useContext } from "react";
import Menu from "./common/Menu";
import "./css/CompanyCode.scss";
import memberContext from "../../store/Member";
import AccountAPI from "../../API/account";

function CompanyCode() {
  const { auth } = useContext(memberContext);
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const [code, setCode] = useState(null);
  const codeInput = React.useRef();

  useEffect(() => {
    if (isCompany) {
      AccountAPI.getAccountInfo().then((res) => setCode(res.data.code));
    } else {
      AccountAPI.getAccountCompanyInfoAsAgent().then((res) =>
        setCode(res.data.code)
      );
    }
  }, []);

  return (
    <div id="CompanyCode">
      <Menu number={4} />
      <div className="form">
        <div className="area">
          <div className="header">
            <h2>회사 코드</h2>
          </div>
          <div className="box">
            <p>
              계정 연결 코드는{" "}
              <input
                className="color_blue"
                ref={codeInput}
                value={code}
                readOnly
              />{" "}
              입니다.
            </p>
          </div>
          <p className="company_code_info">
            <i className="icon_badge_info_black" />
            {isCompany ? (
              <span>
                해당 코드를 대리인이 관리자페이지에 등록하면 귀사와 연결됩니다.
              </span>
            ) : (
              <span>
                해당 코드를 기업이 관리자페이지에 등록하면 귀사와 연결됩니다.
              </span>
            )}
          </p>
          <button onClick={() => window.$Global.copy(codeInput)}>
            복사하기
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyCode;
