import SideBar from "../../SideBar";
import RnDText from "../RnDText";
import FirstInfoText from "../../FirstInfoText";
import "./css/RndPlanFourth.scss";
import BringingUpIcon from "../../BringingUpIcon";
import AddCol from "../../AddCol";
import buttonIcon from "../../../../assets/images/businessPlan/button-add-col.svg";
import DatePicker from "react-datepicker";
import prevButton from "../../../../assets/images/businessPlan/button-prev.svg";
import nextButton from "../../../../assets/images/businessPlan/button-next.svg";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { kr_index } from "../../../../util/util";
import CommonAPI from "../../../../API/common";
import _ from "lodash";

const RnDPlanFourth = () => {
  useEffect(() => {
    CommonAPI.getBusinessData(window.sessionStorage.getItem("itemIdx")).then(
      (res) => {
        getDefaultData(res.data);

        setResponseData(res.data);
      }
    );
  }, []);
  const year = new Date().getFullYear();
  const history = useHistory();
  const [responseData, setResponseData] = useState({});

  const [fourthPageData, setFourthPageData] = useState({
    supervisor_info: ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1"],
    joint_info: ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1"],
    commission_info: ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1"],
    name_thesis: ["1", "1", "1"],
    agency_name: ["1", "1", "1"],
    business_number: ["1", "1", "1"],
    corporate_number: ["1", "1", "1"],
    representative: ["1", "1", "1"],
    agency_type: ["1", "1", "1"],
    stockholder: ["1", "1", "1"],
    founded: ["1", "1", "1"],
    main_item: ["1", "1", "1"],
    employees: ["1", "1", "1"],
    sales: ["1", "1", "1"],
    rnd_per_sales: ["1", "1", "1"],
    debt_ratio: [
      ["1", "1", "1"],
      ["1", "1", "1"],
      ["1", "1", "1"],
    ],
    current_rate: [
      ["1", "1", "1"],
      ["1", "1", "1"],
      ["1", "1", "1"],
    ],
    total_capital: [
      ["1", "1", "1"],
      ["1", "1", "1"],
      ["1", "1", "1"],
    ],
    capital: [
      ["1", "1", "1"],
      ["1", "1", "1"],
      ["1", "1", "1"],
    ],
    operating_profit: [
      ["1", "1", "1"],
      ["1", "1", "1"],
      ["1", "1", "1"],
    ],
    compensation_ratio: [
      ["1", "1", "1"],
      ["1", "1", "1"],
      ["1", "1", "1"],
    ],
    support_name: ["1", "1", "1"],
    support_department: ["1", "1", "1"],
    support_position: ["1", "1", "1"],
    support_telephone: ["1", "1", "1"],
    support_phone: ["1", "1", "1"],
    support_email: ["1", "1", "1"],
    support_fax: ["1", "1", "1"],
  });

  const [supervisorEducation, setSupervisorEducation] = useState([
    {
      startDate: 1000,
      endDate: 1000,
      school_name: "1",
      major: "1",
      academic_degree: "1",
      professor: "1",
    },
  ]);

  const [supervisorCareer, setSupervisorCareer] = useState([
    {
      startDate: 1000,
      endDate: 1000,
      organization_name: "1",
      position: "1",
      note: "1",
    },
  ]);

  const [supervisorPerformance, setSupervisorPerformance] = useState([
    {
      agency: "1",
      detail_project: "1",
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      role: "1",
      etc: "1",
    },
    {
      agency: "1",
      detail_project: "1",
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      role: "1",
      etc: "1",
    },
    {
      agency: "1",
      detail_project: "1",
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      role: "1",
      etc: "1",
    },
  ]);

  const [supervisorThesis, setSupervisorThesis] = useState([
    {
      sortation: "1",
      thesis_name: "1",
      paper: "1",
      year: "1",
      role: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      thesis_name: "1",
      paper: "1",
      year: "1",
      role: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      thesis_name: "1",
      paper: "1",
      year: "1",
      role: "1",
      apply_number: "1",
      etc: "1",
    },
  ]);

  const [supervisorApply, setSupervisorApply] = useState([
    {
      sortation: "1",
      name: "1",
      country_name: "1",
      apply_date: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      name: "1",
      country_name: "1",
      apply_date: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      name: "1",
      country_name: "1",
      apply_date: "1",
      apply_number: "1",
      etc: "1",
    },
  ]);

  const [supervisorAwards, setSupervisorAwards] = useState([
    {
      sortation: "1",
      award_name: "1",
      summary: "1",
      award_year: "1",
    },
    {
      sortation: "1",
      award_name: "1",
      summary: "1",
      award_year: "1",
    },
    {
      sortation: "1",
      award_name: "1",
      summary: "1",
      award_year: "1",
    },
  ]);

  const [jointEducation, setJointEducation] = useState([
    {
      startDate: 1000,
      endDate: 1000,
      school_name: "1",
      major: "1",
      academic_degree: "1",
      professor: "1",
    },
  ]);

  const [jointCareer, setJointCareer] = useState([
    {
      startDate: 1000,
      endDate: 1000,
      organization_name: "1",
      position: "1",
      note: "1",
    },
  ]);

  const [jointPerformance, setJointPerformance] = useState([
    {
      agency: "1",
      detail_project: "1",
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      role: "1",
      etc: "1",
    },
    {
      agency: "1",
      detail_project: "1",
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      role: "1",
      etc: "1",
    },
    {
      agency: "1",
      detail_project: "1",
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      role: "1",
      etc: "1",
    },
  ]);

  const [jointThesis, setJointThesis] = useState([
    {
      sortation: "1",
      thesis_name: "1",
      paper: "1",
      year: "1",
      role: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      thesis_name: "1",
      paper: "1",
      year: "1",
      role: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      thesis_name: "1",
      paper: "1",
      year: "1",
      role: "1",
      apply_number: "1",
      etc: "1",
    },
  ]);

  const [jointApply, setJointApply] = useState([
    {
      sortation: "1",
      name: "1",
      country_name: "1",
      apply_date: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      name: "1",
      country_name: "1",
      apply_date: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      name: "1",
      country_name: "1",
      apply_date: "1",
      apply_number: "1",
      etc: "1",
    },
  ]);

  const [jointAwards, setJointAwards] = useState([
    {
      sortation: "1",
      award_name: "1",
      summary: "1",
      award_year: "1",
    },
    {
      sortation: "1",
      award_name: "1",
      summary: "1",
      award_year: "1",
    },
    {
      sortation: "1",
      award_name: "1",
      summary: "1",
      award_year: "1",
    },
  ]);

  const [commissionEducation, setCommissionEducation] = useState([
    {
      startDate: 1000,
      endDate: 1000,
      school_name: "1",
      major: "1",
      academic_degree: "1",
      professor: "1",
    },
  ]);

  const [commissionCareer, setCommissionCareer] = useState([
    {
      startDate: 1000,
      endDate: 1000,
      organization_name: "1",
      position: "1",
      note: "1",
    },
  ]);

  const [commissionPerformance, setCommissionPerformance] = useState([
    {
      agency: "1",
      detail_project: "1",
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      role: "1",
      etc: "1",
    },
    {
      agency: "1",
      detail_project: "1",
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      role: "1",
      etc: "1",
    },
    {
      agency: "1",
      detail_project: "1",
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      role: "1",
      etc: "1",
    },
  ]);

  const [commissionThesis, setCommissionThesis] = useState([
    {
      sortation: "1",
      thesis_name: "1",
      paper: "1",
      year: "1",
      role: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      thesis_name: "1",
      paper: "1",
      year: "1",
      role: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      thesis_name: "1",
      paper: "1",
      year: "1",
      role: "1",
      apply_number: "1",
      etc: "1",
    },
  ]);

  const [commissionApply, setCommissionApply] = useState([
    {
      sortation: "1",
      name: "1",
      country_name: "1",
      apply_date: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      name: "1",
      country_name: "1",
      apply_date: "1",
      apply_number: "1",
      etc: "1",
    },
    {
      sortation: "1",
      name: "1",
      country_name: "1",
      apply_date: "1",
      apply_number: "1",
      etc: "1",
    },
  ]);

  const [commissionAwards, setCommissionAwards] = useState([
    {
      sortation: "1",
      award_name: "1",
      summary: "1",
      award_year: "1",
    },
    {
      sortation: "1",
      award_name: "1",
      summary: "1",
      award_year: "1",
    },
    {
      sortation: "1",
      award_name: "1",
      summary: "1",
      award_year: "1",
    },
  ]);

  const [researcher, setResearcher] = useState([
    {
      name: "1",
      country: "1",
      agency: "1",
      position: "1",
      research_number: "1",
      degree: "1",
      major: "1",
      year: "1",
      role: "1",
      recruit: "1",
      time: "1",
      first_start_year: "1",
      first_end_year: "1",
      last_start_year: "1",
      last_end_year: "1",
      period: "1",
    },
    {
      name: "1",
      country: "1",
      agency: "1",
      position: "1",
      research_number: "1",
      degree: "1",
      major: "1",
      year: "1",
      role: "1",
      recruit: "1",
      time: "1",
      first_start_year: "1",
      first_end_year: "1",
      last_start_year: "1",
      last_end_year: "1",
      period: "1",
    },
    {
      name: "1",
      country: "1",
      agency: "1",
      position: "1",
      research_number: "1",
      degree: "1",
      major: "1",
      year: "1",
      role: "1",
      recruit: "1",
      time: "1",
      first_start_year: "1",
      first_end_year: "1",
      last_start_year: "1",
      last_end_year: "1",
      period: "1",
    },
    {
      name: "1",
      country: "1",
      agency: "1",
      position: "1",
      research_number: "1",
      degree: "1",
      major: "1",
      year: "1",
      role: "1",
      recruit: "1",
      time: "1",
      first_start_year: "1",
      first_end_year: "1",
      last_start_year: "1",
      last_end_year: "1",
      period: "1",
    },
    {
      name: "1",
      country: "1",
      agency: "1",
      position: "1",
      research_number: "1",
      degree: "1",
      major: "1",
      year: "1",
      role: "1",
      recruit: "1",
      time: "1",
      first_start_year: "1",
      first_end_year: "1",
      last_start_year: "1",
      last_end_year: "1",
      period: "1",
    },
    {
      name: "1",
      country: "1",
      agency: "1",
      position: "1",
      research_number: "1",
      degree: "1",
      major: "1",
      year: "1",
      role: "1",
      recruit: "1",
      time: "1",
      first_start_year: "1",
      first_end_year: "1",
      last_start_year: "1",
      last_end_year: "1",
      period: "1",
    },
  ]);
  const [researchSupport, setResearchSupport] = useState([
    {
      name: "1",
      country: "1",
      agency: "1",
      position: "1",
      degree: "1",
      major: "1",
      year: "1",
      role: "1",
      recruit: "1",
      time: "1",
      first_start_year: "1",
      first_end_year: "1",
      last_start_year: "1",
      last_end_year: "1",
      period: "1",
    },
    {
      name: "1",
      country: "1",
      agency: "1",
      position: "1",
      degree: "1",
      major: "1",
      year: "1",
      role: "1",
      recruit: "1",
      time: "1",
      first_start_year: "1",
      first_end_year: "1",
      last_start_year: "1",
      last_end_year: "1",
      period: "1",
    },
  ]);

  const [relatedAgency, setRelatedAgency] = useState([
    {
      agency_name: "1",
      role: "1",
      manager_name_kr: "1",
      manager_name_en: "1",
      manager_nation: "1",
      manager_agency_name: "1",
      manager_telephone: "1",
      manager_department: "1",
      manager_phone: "1",
      manager_position: "1",
      manager_email: "1",
      business_name_kr: "1",
      business_name_en: "1",
      business_agency_name: "1",
      business_telephone: "1",
      business_department: "1",
      business_phone: "1",
      business_position: "1",
      business_email: "1",
      business_address: "1",
    },
    {
      agency_name: "1",
      role: "1",
      manager_name_kr: "1",
      manager_name_en: "1",
      manager_nation: "1",
      manager_agency_name: "1",
      manager_telephone: "1",
      manager_department: "1",
      manager_phone: "1",
      manager_position: "1",
      manager_email: "1",
      business_name_kr: "1",
      business_name_en: "1",
      business_agency_name: "1",
      business_telephone: "1",
      business_department: "1",
      business_phone: "1",
      business_position: "1",
      business_email: "1",
      business_address: "1",
    },
  ]);

  const [currentStatus, setCurrentStatus] = useState([
    {
      agency_name: "1",
      property_name: "1",
      country_name: "1",
      apply_number: "1",
    },
    {
      agency_name: "1",
      property_name: "1",
      country_name: "1",
      apply_number: "1",
    },
    {
      agency_name: "1",
      property_name: "1",
      country_name: "1",
      apply_number: "1",
    },
  ]);

  const [executionPerformance, setExecutionPerformance] = useState([
    {
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      content: "1",
      agency: "1",
      etc: "1",
    },
    {
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      content: "1",
      agency: "1",
      etc: "1",
    },
    {
      name: "1",
      organization_agency: "1",
      affiliated_agency: "1",
      total_duration: "1",
      participation_period: "1",
      content: "1",
      agency: "1",
      etc: "1",
    },
  ]);

  const [techTransfer, setTechTransfer] = useState([
    {
      institution_name: "1",
      transfer_type: "1",
      contract_name: "1",
      agency_name: "1",
      tech_date: "1",
      tech_cost: "1",
      total_cost: "1",
    },
    {
      institution_name: "1",
      transfer_type: "1",
      contract_name: "1",
      agency_name: "1",
      tech_date: "1",
      tech_cost: "1",
      total_cost: "1",
    },
    {
      institution_name: "1",
      transfer_type: "1",
      contract_name: "1",
      agency_name: "1",
      tech_date: "1",
      tech_cost: "1",
      total_cost: "1",
    },
  ]);

  const [commercialPerformance, setCommercialPerformance] = useState([
    {
      institution_name: "1",
      way: "1",
      form: "1",
      country: "1",
      name: "1",
      content: "1",
      company_name: "1",
      domestic_cost: "1",
      overseas_cost: "1",
      cost_year: "1",
      tech_lifespan: "1",
    },
    {
      institution_name: "1",
      way: "1",
      form: "1",
      country: "1",
      name: "1",
      content: "1",
      company_name: "1",
      domestic_cost: "1",
      overseas_cost: "1",
      cost_year: "1",
      tech_lifespan: "1",
    },
  ]);

  const [holdingStatus, setHoldingStatus] = useState([
    {
      holding_agency: "1",
      equipment_name: "1",
      standard: "1",
      quantity: "1",
      purpose: "1",
      use: "1",
      reflection_status: "1",
    },
    {
      holding_agency: "1",
      equipment_name: "1",
      standard: "1",
      quantity: "1",
      purpose: "1",
      use: "1",
      reflection_status: "1",
    },
    {
      holding_agency: "1",
      equipment_name: "1",
      standard: "1",
      quantity: "1",
      purpose: "1",
      use: "1",
      reflection_status: "1",
    },
  ]); /*
  const [fourthPageData, setFourthPageData] = useState({
    supervisor_info: ["", "", "", "", "", "", "", "", "", "", ""],
    joint_info: ["", "", "", "", "", "", "", "", "", "", ""],
    commission_info: ["", "", "", "", "", "", "", "", "", "", ""],
    name_thesis: ["", "", ""],
    agency_name: ["", "", ""],
    business_number: ["", "", ""],
    corporate_number: ["", "", ""],
    representative: ["", "", ""],
    agency_type: ["", "", ""],
    stockholder: ["", "", ""],
    founded: ["", "", ""],
    main_item: ["", "", ""],
    employees: ["", "", ""],
    sales: ["", "", ""],
    rnd_per_sales: ["", "", ""],
    debt_ratio: [
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
    ],
    current_rate: [
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
    ],
    total_capital: [
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
    ],
    capital: [
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
    ],
    operating_profit: [
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
    ],
    compensation_ratio: [
      ["", "", ""],
      ["", "", ""],
      ["", "", ""],
    ],
    support_name: ["", "", ""],
    support_department: ["", "", ""],
    support_position: ["", "", ""],
    support_telephone: ["", "", ""],
    support_phone: ["", "", ""],
    support_email: ["", "", ""],
    support_fax: ["", "", ""],
  });

  const [supervisorEducation, setSupervisorEducation] = useState([
    {
      startDate: null,
      endDate: null,
      school_name: "",
      major: "",
      academic_degree: "",
      professor: "",
    },
  ]);

  const [supervisorCareer, setSupervisorCareer] = useState([
    {
      startDate: null,
      endDate: null,
      organization_name: "",
      position: "",
      note: "",
    },
  ]);

  const [supervisorPerformance, setSupervisorPerformance] = useState([
    {
      agency: "",
      detail_project: "",
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      role: "",
      etc: "",
    },
    {
      agency: "",
      detail_project: "",
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      role: "",
      etc: "",
    },
    {
      agency: "",
      detail_project: "",
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      role: "",
      etc: "",
    },
  ]);

  const [supervisorThesis, setSupervisorThesis] = useState([
    {
      sortation: "",
      thesis_name: "",
      paper: "",
      year: "",
      role: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      thesis_name: "",
      paper: "",
      year: "",
      role: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      thesis_name: "",
      paper: "",
      year: "",
      role: "",
      apply_number: "",
      etc: "",
    },
  ]);

  const [supervisorApply, setSupervisorApply] = useState([
    {
      sortation: "",
      name: "",
      country_name: "",
      apply_date: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      name: "",
      country_name: "",
      apply_date: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      name: "",
      country_name: "",
      apply_date: "",
      apply_number: "",
      etc: "",
    },
  ]);

  const [supervisorAwards, setSupervisorAwards] = useState([
    {
      sortation: "",
      award_name: "",
      summary: "",
      award_year: "",
    },
    {
      sortation: "",
      award_name: "",
      summary: "",
      award_year: "",
    },
    {
      sortation: "",
      award_name: "",
      summary: "",
      award_year: "",
    },
  ]);

  const [jointEducation, setJointEducation] = useState([
    {
      startDate: null,
      endDate: null,
      school_name: "",
      major: "",
      academic_degree: "",
      professor: "",
    },
    {
      startDate: null,
      endDate: null,
      school_name: "",
      major: "",
      academic_degree: "",
      professor: "",
    },
    {
      startDate: null,
      endDate: null,
      school_name: "",
      major: "",
      academic_degree: "",
      professor: "",
    },
  ]);

  const [jointCareer, setJointCareer] = useState([
    {
      startDate: null,
      endDate: null,
      organization_name: "",
      position: "",
      note: "",
    },
    {
      startDate: null,
      endDate: null,
      organization_name: "",
      position: "",
      note: "",
    },
    {
      startDate: null,
      endDate: null,
      organization_name: "",
      position: "",
      note: "",
    },
    {
      startDate: null,
      endDate: null,
      organization_name: "",
      position: "",
      note: "",
    },
  ]);

  const [jointPerformance, setJointPerformance] = useState([
    {
      agency: "",
      detail_project: "",
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      role: "",
      etc: "",
    },
    {
      agency: "",
      detail_project: "",
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      role: "",
      etc: "",
    },
    {
      agency: "",
      detail_project: "",
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      role: "",
      etc: "",
    },
  ]);

  const [jointThesis, setJointThesis] = useState([
    {
      sortation: "",
      thesis_name: "",
      paper: "",
      year: "",
      role: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      thesis_name: "",
      paper: "",
      year: "",
      role: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      thesis_name: "",
      paper: "",
      year: "",
      role: "",
      apply_number: "",
      etc: "",
    },
  ]);

  const [jointApply, setJointApply] = useState([
    {
      sortation: "",
      name: "",
      country_name: "",
      apply_date: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      name: "",
      country_name: "",
      apply_date: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      name: "",
      country_name: "",
      apply_date: "",
      apply_number: "",
      etc: "",
    },
  ]);

  const [jointAwards, setJointAwards] = useState([
    {
      sortation: "",
      award_name: "",
      summary: "",
      award_year: "",
    },
    {
      sortation: "",
      award_name: "",
      summary: "",
      award_year: "",
    },
    {
      sortation: "",
      award_name: "",
      summary: "",
      award_year: "",
    },
  ]);

  const [commissionEducation, setCommissionEducation] = useState([
    {
      startDate: null,
      endDate: null,
      school_name: "",
      major: "",
      academic_degree: "",
      professor: "",
    },
    {
      startDate: null,
      endDate: null,
      school_name: "",
      major: "",
      academic_degree: "",
      professor: "",
    },
    {
      startDate: null,
      endDate: null,
      school_name: "",
      major: "",
      academic_degree: "",
      professor: "",
    },
  ]);

  const [commissionCareer, setCommissionCareer] = useState([
    {
      startDate: null,
      endDate: null,
      organization_name: "",
      position: "",
      note: "",
    },
    {
      startDate: null,
      endDate: null,
      organization_name: "",
      position: "",
      note: "",
    },
    {
      startDate: null,
      endDate: null,
      organization_name: "",
      position: "",
      note: "",
    },
    {
      startDate: null,
      endDate: null,
      organization_name: "",
      position: "",
      note: "",
    },
  ]);

  const [commissionPerformance, setCommissionPerformance] = useState([
    {
      agency: "",
      detail_project: "",
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      role: "",
      etc: "",
    },
    {
      agency: "",
      detail_project: "",
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      role: "",
      etc: "",
    },
    {
      agency: "",
      detail_project: "",
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      role: "",
      etc: "",
    },
  ]);

  const [commissionThesis, setCommissionThesis] = useState([
    {
      sortation: "",
      thesis_name: "",
      paper: "",
      year: "",
      role: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      thesis_name: "",
      paper: "",
      year: "",
      role: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      thesis_name: "",
      paper: "",
      year: "",
      role: "",
      apply_number: "",
      etc: "",
    },
  ]);

  const [commissionApply, setCommissionApply] = useState([
    {
      sortation: "",
      name: "",
      country_name: "",
      apply_date: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      name: "",
      country_name: "",
      apply_date: "",
      apply_number: "",
      etc: "",
    },
    {
      sortation: "",
      name: "",
      country_name: "",
      apply_date: "",
      apply_number: "",
      etc: "",
    },
  ]);

  const [commissionAwards, setCommissionAwards] = useState([
    {
      sortation: "",
      award_name: "",
      summary: "",
      award_year: "",
    },
    {
      sortation: "",
      award_name: "",
      summary: "",
      award_year: "",
    },
    {
      sortation: "",
      award_name: "",
      summary: "",
      award_year: "",
    },
  ]);

  const [researcher, setResearcher] = useState([
    {
      name: "",
      country: "",
      agency: "",
      position: "",
      research_number: "",
      degree: "",
      major: "",
      year: "",
      role: "",
      recruit: "",
      time: "",
      first_start_year: "",
      first_end_year: "",
      last_start_year: "",
      last_end_year: "",
      period: "",
    },
    {
      name: "",
      country: "",
      agency: "",
      position: "",
      research_number: "",
      degree: "",
      major: "",
      year: "",
      role: "",
      recruit: "",
      time: "",
      first_start_year: "",
      first_end_year: "",
      last_start_year: "",
      last_end_year: "",
      period: "",
    },
    {
      name: "",
      country: "",
      agency: "",
      position: "",
      research_number: "",
      degree: "",
      major: "",
      year: "",
      role: "",
      recruit: "",
      time: "",
      first_start_year: "",
      first_end_year: "",
      last_start_year: "",
      last_end_year: "",
      period: "",
    },
    {
      name: "",
      country: "",
      agency: "",
      position: "",
      research_number: "",
      degree: "",
      major: "",
      year: "",
      role: "",
      recruit: "",
      time: "",
      first_start_year: "",
      first_end_year: "",
      last_start_year: "",
      last_end_year: "",
      period: "",
    },
    {
      name: "",
      country: "",
      agency: "",
      position: "",
      research_number: "",
      degree: "",
      major: "",
      year: "",
      role: "",
      recruit: "",
      time: "",
      first_start_year: "",
      first_end_year: "",
      last_start_year: "",
      last_end_year: "",
      period: "",
    },
    {
      name: "",
      country: "",
      agency: "",
      position: "",
      research_number: "",
      degree: "",
      major: "",
      year: "",
      role: "",
      recruit: "",
      time: "",
      first_start_year: "",
      first_end_year: "",
      last_start_year: "",
      last_end_year: "",
      period: "",
    },
  ]);
  const [researchSupport, setResearchSupport] = useState([
    {
      name: "",
      country: "",
      agency: "",
      position: "",
      degree: "",
      major: "",
      year: "",
      role: "",
      recruit: "",
      time: "",
      first_start_year: "",
      first_end_year: "",
      last_start_year: "",
      last_end_year: "",
      period: "",
    },
    {
      name: "",
      country: "",
      agency: "",
      position: "",
      degree: "",
      major: "",
      year: "",
      role: "",
      recruit: "",
      time: "",
      first_start_year: "",
      first_end_year: "",
      last_start_year: "",
      last_end_year: "",
      period: "",
    },
  ]);

  const [relatedAgency, setRelatedAgency] = useState([
    {
      agency_name: "",
      role: "",
      manager_name_kr: "",
      manager_name_en: "",
      manager_nation: "",
      manager_agency_name: "",
      manager_telephone: "",
      manager_department: "",
      manager_phone: "",
      manager_position: "",
      manager_email: "",
      manager_address: "",
      business_name_kr: "",
      business_name_en: "",
      business_agency_name: "",
      business_telephone: "",
      business_department: "",
      business_phone: "",
      business_position: "",
      business_email: "",
      business_address: "",
    },
    {
      agency_name: "",
      role: "",
      manager_name_kr: "",
      manager_name_en: "",
      manager_nation: "",
      manager_agency_name: "",
      manager_telephone: "",
      manager_department: "",
      manager_phone: "",
      manager_position: "",
      manager_email: "",
      manager_address: "",
      business_name_kr: "",
      business_name_en: "",
      business_agency_name: "",
      business_telephone: "",
      business_department: "",
      business_phone: "",
      business_position: "",
      business_email: "",
      business_address: "",
    },
  ]);

  const [currentStatus, setCurrentStatus] = useState([
    {
      agency_name: "",
      property_name: "",
      country_name: "",
      apply_number: "",
    },
    {
      agency_name: "",
      property_name: "",
      country_name: "",
      apply_number: "",
    },
    {
      agency_name: "",
      property_name: "",
      country_name: "",
      apply_number: "",
    },
  ]);

  const [executionPerformance, setExecutionPerformance] = useState([
    {
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      content: "",
      agency: "",
      etc: "",
    },
    {
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      content: "",
      agency: "",
      etc: "",
    },
    {
      name: "",
      organization_agency: "",
      affiliated_agency: "",
      total_duration: "",
      participation_period: "",
      content: "",
      agency: "",
      etc: "",
    },
  ]);

  const [techTransfer, setTechTransfer] = useState([
    {
      institution_name: "",
      transfer_type: "",
      contract_name: "",
      agency_name: "",
      tech_date: "",
      tech_cost: "",
      total_cost: "",
    },
    {
      institution_name: "",
      transfer_type: "",
      contract_name: "",
      agency_name: "",
      tech_date: "",
      tech_cost: "",
      total_cost: "",
    },
    {
      institution_name: "",
      transfer_type: "",
      contract_name: "",
      agency_name: "",
      tech_date: "",
      tech_cost: "",
      total_cost: "",
    },
  ]);

  const [commercialPerformance, setCommercialPerformance] = useState([
    {
      institution_name: "",
      way: "",
      form: "",
      country: "",
      name: "",
      content: "",
      company_name: "",
      domestic_cost: "",
      overseas_cost: "",
      cost_year: "",
      tech_lifespan: "",
    },
    {
      institution_name: "",
      way: "",
      form: "",
      country: "",
      name: "",
      content: "",
      company_name: "",
      domestic_cost: "",
      overseas_cost: "",
      cost_year: "",
      tech_lifespan: "",
    },
  ]);

  const [holdingStatus, setHoldingStatus] = useState([
    {
      holding_agency: "",
      equipment_name: "",
      standard: "",
      quantity: "",
      purpose: "",
      use: "",
      reflection_status: "",
    },
    {
      holding_agency: "",
      equipment_name: "",
      standard: "",
      quantity: "",
      purpose: "",
      use: "",
      reflection_status: "",
    },
    {
      holding_agency: "",
      equipment_name: "",
      standard: "",
      quantity: "",
      purpose: "",
      use: "",
      reflection_status: "",
    },
  ]);*/

  const getDateTimeStamp = (date) => {
    const [year, month] = date.split(".");
    const timeStamp = new Date(+year, month - 1, 1);

    return timeStamp.getTime();
  };

  const getSelectRoleData = (data) => {
    if (data === "신규") {
      return "new";
    } else if (data === "신규(전담)") {
      return "new_exclusive";
    } else if (data === "신규(청년의무)") {
      return "new_youth_obligation";
    } else if (data === "신규(청년추가)") {
      return "new_youth_extra";
    } else if (data === "신규(기타)") {
      return "new_etc";
    } else if (data === "기존") {
      return "existing";
    }
  };

  const getSelectTimeData = (data) => {
    if (data === "시간") {
      return "hour";
    } else if (data === "실습") {
      return "practice";
    }
  };
  const getDefaultData = (data) => {
    let content;
    data.content.forEach((item, index) => {
      if (item.subject === "주관연구개발기관 연구책임자 인적사항 표") {
        fourthPageData["supervisor_info"] = [
          item.content[0][2].content,
          item.content[0][4].content,
          item.content[1][2].content,
          item.content[1][4].content,
          item.content[2][2].content,
          item.content[2][4].content,
          item.content[3][2].content,
          item.content[3][4].content,
          item.content[4][2].content,
          item.content[4][4].content,
          item.content[5][2].content,
        ];
        setFourthPageData({ ...fourthPageData });
      } else if (item.subject === "주관연구개발기관 연구책임자 학력 표") {
        fourthPageData["name_thesis"][0] = item.content[2][1].content;
        content = [
          {
            startDate: getDateTimeStamp(
              item.content[1][0].content.substring(0, 7)
            ),
            endDate: getDateTimeStamp(item.content[1][0].content.substring(8)),
            school_name: item.content[1][1].content,
            major: item.content[1][2].content,
            academic_degree: item.content[1][3].content,
            professor: item.content[1][4].content,
          },
        ];
        setSupervisorEducation(content);
      } else if (item.subject === "주관연구개발기관 연구책임자 경력 표") {
        content = [
          {
            startDate: getDateTimeStamp(
              item.content[1][0].content.substring(0, 7)
            ),
            endDate: getDateTimeStamp(item.content[1][0].content.substring(8)),
            organization_name: item.content[1][1].content,
            position: item.content[1][2].content,
            note: item.content[1][3].content,
          },
        ];
        setSupervisorCareer(content);
      } else if (
        item.subject === "주관연구개발기관 연구책임자 주요 연구개발 실적 표"
      ) {
        content = [
          {
            agency: item.content[2][0].content,
            detail_project: item.content[2][1].content,
            name: item.content[2][2].content,
            organization_agency: item.content[2][3].content,
            affiliated_agency: item.content[3][3].content,
            total_duration: item.content[2][4].content.split("\n")[0],
            participation_period: item.content[2][4].content.split("\n")[1],
            role: item.content[2][5].content,
            etc: item.content[2][6].content,
          },
          {
            agency: item.content[4][0].content,
            detail_project: item.content[4][1].content,
            name: item.content[4][2].content,
            organization_agency: item.content[4][3].content,
            affiliated_agency: item.content[5][3].content,
            total_duration: item.content[4][4].content.split("\n")[0],
            participation_period: item.content[4][4].content.split("\n")[1],
            role: item.content[4][5].content,
            etc: item.content[4][6].content,
          },
          {
            agency: item.content[6][0].content,
            detail_project: item.content[6][1].content,
            name: item.content[6][2].content,
            organization_agency: item.content[6][3].content,
            affiliated_agency: item.content[7][3].content,
            total_duration: item.content[6][4].content.split("\n")[0],
            participation_period: item.content[6][4].content.split("\n")[1],
            role: item.content[6][5].content,
            etc: item.content[6][6].content,
          },
        ];
        setSupervisorPerformance(content);
      } else if (
        item.subject === "주관연구개발기관 연구책임자 대표적 논문 저서 실적 표"
      ) {
        content = [
          {
            sortation: item.content[1][0].content,
            thesis_name: item.content[1][1].content,
            paper: item.content[1][2].content,
            year: item.content[1][3].content,
            role: item.content[1][4].content,
            apply_number: item.content[1][5].content,
            etc: item.content[1][6].content,
          },
          {
            sortation: item.content[2][0].content,
            thesis_name: item.content[2][1].content,
            paper: item.content[2][2].content,
            year: item.content[2][3].content,
            role: item.content[2][4].content,
            apply_number: item.content[2][5].content,
            etc: item.content[2][6].content,
          },
          {
            sortation: item.content[3][0].content,
            thesis_name: item.content[3][1].content,
            paper: item.content[3][2].content,
            year: item.content[3][3].content,
            role: item.content[3][4].content,
            apply_number: item.content[3][5].content,
            etc: item.content[3][6].content,
          },
        ];
        setSupervisorThesis(content);
      } else if (
        item.subject ===
        "주관연구개발기관 연구책임자 지식재산권 출원 등록 실적 표"
      ) {
        content = [
          {
            sortation: item.content[1][0].content,
            name: item.content[1][1].content,
            country_name: item.content[1][2].content,
            apply_date: item.content[1][3].content,
            apply_number: item.content[1][4].content,
            etc: item.content[1][5].content,
          },
          {
            sortation: item.content[2][0].content,
            name: item.content[2][1].content,
            country_name: item.content[2][2].content,
            apply_date: item.content[2][3].content,
            apply_number: item.content[2][4].content,
            etc: item.content[2][5].content,
          },
          {
            sortation: item.content[3][0].content,
            name: item.content[3][1].content,
            country_name: item.content[3][2].content,
            apply_date: item.content[3][3].content,
            apply_number: item.content[3][4].content,
            etc: item.content[3][5].content,
          },
        ];
        setSupervisorApply(content);
      } else if (
        item.subject === "주관연구개발기관 연구책임자 그 밖의 대표적 실적 표"
      ) {
        content = [
          {
            sortation: item.content[1][0].content,
            award_name: item.content[1][1].content,
            summary: item.content[1][2].content,
            award_year: item.content[1][3].content,
          },
          {
            sortation: item.content[2][0].content,
            award_name: item.content[2][1].content,
            summary: item.content[2][2].content,
            award_year: item.content[2][3].content,
          },
          {
            sortation: item.content[3][0].content,
            award_name: item.content[3][1].content,
            summary: item.content[3][2].content,
            award_year: item.content[3][3].content,
          },
        ];
        setSupervisorAwards(content);
      } else if (item.subject === "공동연구개발기관 책임자 인적사항 표") {
        fourthPageData["joint_info"] = [
          item.content[0][2].content,
          item.content[0][4].content,
          item.content[1][2].content,
          item.content[1][4].content,
          item.content[2][2].content,
          item.content[2][4].content,
          item.content[3][2].content,
          item.content[3][4].content,
          item.content[4][2].content,
          item.content[4][4].content,
          item.content[5][2].content,
        ];
        setFourthPageData({ ...fourthPageData });
      } else if (item.subject === "공동연구개발기관 책임자 학력 표") {
        fourthPageData["name_thesis"][1] = item.content[2][1].content;
        content = [
          {
            startDate: getDateTimeStamp(
              item.content[1][0].content.substring(0, 7)
            ),
            endDate: getDateTimeStamp(item.content[1][0].content.substring(8)),
            school_name: item.content[1][1].content,
            major: item.content[1][2].content,
            academic_degree: item.content[1][3].content,
            professor: item.content[1][4].content,
          },
        ];
        setJointEducation(content);
      } else if (item.subject === "공동연구개발기관 책임자 경력 표") {
        content = [
          {
            startDate: getDateTimeStamp(
              item.content[1][0].content.substring(0, 7)
            ),
            endDate: getDateTimeStamp(item.content[1][0].content.substring(8)),
            organization_name: item.content[1][1].content,
            position: item.content[1][2].content,
            note: item.content[1][3].content,
          },
        ];
        setJointCareer(content);
      } else if (
        item.subject === "공동연구개발기관 책임자 주요 연구개발 실적 표"
      ) {
        content = [
          {
            agency: item.content[2][0].content,
            detail_project: item.content[2][1].content,
            name: item.content[2][2].content,
            organization_agency: item.content[2][3].content,
            affiliated_agency: item.content[3][3].content,
            total_duration: item.content[2][4].content.split("\n")[0],
            participation_period: item.content[2][4].content.split("\n")[1],
            role: item.content[2][5].content,
            etc: item.content[2][6].content,
          },
          {
            agency: item.content[4][0].content,
            detail_project: item.content[4][1].content,
            name: item.content[4][2].content,
            organization_agency: item.content[4][3].content,
            affiliated_agency: item.content[5][3].content,
            total_duration: item.content[4][4].content.split("\n")[0],
            participation_period: item.content[4][4].content.split("\n")[1],
            role: item.content[4][5].content,
            etc: item.content[4][6].content,
          },
          {
            agency: item.content[6][0].content,
            detail_project: item.content[6][1].content,
            name: item.content[6][2].content,
            organization_agency: item.content[6][3].content,
            affiliated_agency: item.content[7][3].content,
            total_duration: item.content[6][4].content.split("\n")[0],
            participation_period: item.content[6][4].content.split("\n")[1],
            role: item.content[6][5].content,
            etc: item.content[6][6].content,
          },
        ];
        setJointPerformance(content);
      } else if (
        item.subject === "공동연구개발기관 책임자 대표적 논문 저서 실적 표"
      ) {
        content = [
          {
            sortation: item.content[1][0].content,
            thesis_name: item.content[1][1].content,
            paper: item.content[1][2].content,
            year: item.content[1][3].content,
            role: item.content[1][4].content,
            apply_number: item.content[1][5].content,
            etc: item.content[1][6].content,
          },
          {
            sortation: item.content[2][0].content,
            thesis_name: item.content[2][1].content,
            paper: item.content[2][2].content,
            year: item.content[2][3].content,
            role: item.content[2][4].content,
            apply_number: item.content[2][5].content,
            etc: item.content[2][6].content,
          },
          {
            sortation: item.content[3][0].content,
            thesis_name: item.content[3][1].content,
            paper: item.content[3][2].content,
            year: item.content[3][3].content,
            role: item.content[3][4].content,
            apply_number: item.content[3][5].content,
            etc: item.content[3][6].content,
          },
        ];
        setJointThesis(content);
      } else if (
        item.subject === "공동연구개발기관 책임자 지식재산권 출원 등록 실적 표"
      ) {
        content = [
          {
            sortation: item.content[1][0].content,
            name: item.content[1][1].content,
            country_name: item.content[1][2].content,
            apply_date: item.content[1][3].content,
            apply_number: item.content[1][4].content,
            etc: item.content[1][5].content,
          },
          {
            sortation: item.content[2][0].content,
            name: item.content[2][1].content,
            country_name: item.content[2][2].content,
            apply_date: item.content[2][3].content,
            apply_number: item.content[2][4].content,
            etc: item.content[2][5].content,
          },
          {
            sortation: item.content[3][0].content,
            name: item.content[3][1].content,
            country_name: item.content[3][2].content,
            apply_date: item.content[3][3].content,
            apply_number: item.content[3][4].content,
            etc: item.content[3][5].content,
          },
        ];
        setJointApply(content);
      } else if (
        item.subject === "공동연구개발기관 책임자 그 밖의 대표적 실적 표"
      ) {
        content = [
          {
            sortation: item.content[1][0].content,
            award_name: item.content[1][1].content,
            summary: item.content[1][2].content,
            award_year: item.content[1][3].content,
          },
          {
            sortation: item.content[2][0].content,
            award_name: item.content[2][1].content,
            summary: item.content[2][2].content,
            award_year: item.content[2][3].content,
          },
          {
            sortation: item.content[3][0].content,
            award_name: item.content[3][1].content,
            summary: item.content[3][2].content,
            award_year: item.content[3][3].content,
          },
        ];
        setJointAwards(content);
      } else if (item.subject === "위탁연구개발기관 책임자 인적사항 표") {
        fourthPageData["commission_info"] = [
          item.content[0][2].content,
          item.content[0][4].content,
          item.content[1][2].content,
          item.content[1][4].content,
          item.content[2][2].content,
          item.content[2][4].content,
          item.content[3][2].content,
          item.content[3][4].content,
          item.content[4][2].content,
          item.content[4][4].content,
          item.content[5][2].content,
        ];
        setFourthPageData({ ...fourthPageData });
      } else if (item.subject === "위탁연구개발기관 책임자 학력 표") {
        fourthPageData["name_thesis"][2] = item.content[2][1].content;
        content = [
          {
            startDate: getDateTimeStamp(
              item.content[1][0].content.substring(0, 7)
            ),
            endDate: getDateTimeStamp(item.content[1][0].content.substring(8)),
            school_name: item.content[1][1].content,
            major: item.content[1][2].content,
            academic_degree: item.content[1][3].content,
            professor: item.content[1][4].content,
          },
        ];
        setCommissionEducation(content);
      } else if (item.subject === "위탁연구개발기관 책임자 경력 표") {
        content = [
          {
            startDate: getDateTimeStamp(
              item.content[1][0].content.substring(0, 7)
            ),
            endDate: getDateTimeStamp(item.content[1][0].content.substring(8)),
            organization_name: item.content[1][1].content,
            position: item.content[1][2].content,
            note: item.content[1][3].content,
          },
        ];
        setCommissionCareer(content);
      } else if (
        item.subject === "위탁연구개발기관 책임자 주요 연구개발 실적 표"
      ) {
        content = [
          {
            agency: item.content[2][0].content,
            detail_project: item.content[2][1].content,
            name: item.content[2][2].content,
            organization_agency: item.content[2][3].content,
            affiliated_agency: item.content[3][3].content,
            total_duration: item.content[2][4].content.split("\n")[0],
            participation_period: item.content[2][4].content.split("\n")[1],
            role: item.content[2][5].content,
            etc: item.content[2][6].content,
          },
          {
            agency: item.content[4][0].content,
            detail_project: item.content[4][1].content,
            name: item.content[4][2].content,
            organization_agency: item.content[4][3].content,
            affiliated_agency: item.content[5][3].content,
            total_duration: item.content[4][4].content.split("\n")[0],
            participation_period: item.content[4][4].content.split("\n")[1],
            role: item.content[4][5].content,
            etc: item.content[4][6].content,
          },
          {
            agency: item.content[6][0].content,
            detail_project: item.content[6][1].content,
            name: item.content[6][2].content,
            organization_agency: item.content[6][3].content,
            affiliated_agency: item.content[7][3].content,
            total_duration: item.content[6][4].content.split("\n")[0],
            participation_period: item.content[6][4].content.split("\n")[1],
            role: item.content[6][5].content,
            etc: item.content[6][6].content,
          },
        ];
        setCommissionPerformance(content);
      } else if (
        item.subject === "위탁연구개발기관 책임자 대표적 논문 저서 실적 표"
      ) {
        content = [
          {
            sortation: item.content[1][0].content,
            thesis_name: item.content[1][1].content,
            paper: item.content[1][2].content,
            year: item.content[1][3].content,
            role: item.content[1][4].content,
            apply_number: item.content[1][5].content,
            etc: item.content[1][6].content,
          },
          {
            sortation: item.content[2][0].content,
            thesis_name: item.content[2][1].content,
            paper: item.content[2][2].content,
            year: item.content[2][3].content,
            role: item.content[2][4].content,
            apply_number: item.content[2][5].content,
            etc: item.content[2][6].content,
          },
          {
            sortation: item.content[3][0].content,
            thesis_name: item.content[3][1].content,
            paper: item.content[3][2].content,
            year: item.content[3][3].content,
            role: item.content[3][4].content,
            apply_number: item.content[3][5].content,
            etc: item.content[3][6].content,
          },
        ];
        setCommissionThesis(content);
      } else if (
        item.subject === "위탁연구개발기관 책임자 지식재산권 출원 등록 실적 표"
      ) {
        content = [
          {
            sortation: item.content[1][0].content,
            name: item.content[1][1].content,
            country_name: item.content[1][2].content,
            apply_date: item.content[1][3].content,
            apply_number: item.content[1][4].content,
            etc: item.content[1][5].content,
          },
          {
            sortation: item.content[2][0].content,
            name: item.content[2][1].content,
            country_name: item.content[2][2].content,
            apply_date: item.content[2][3].content,
            apply_number: item.content[2][4].content,
            etc: item.content[2][5].content,
          },
          {
            sortation: item.content[3][0].content,
            name: item.content[3][1].content,
            country_name: item.content[3][2].content,
            apply_date: item.content[3][3].content,
            apply_number: item.content[3][4].content,
            etc: item.content[3][5].content,
          },
        ];
        setCommissionApply(content);
      } else if (
        item.subject === "위탁연구개발기관 책임자 그 밖의 대표적 실적 표"
      ) {
        content = [
          {
            sortation: item.content[1][0].content,
            award_name: item.content[1][1].content,
            summary: item.content[1][2].content,
            award_year: item.content[1][3].content,
          },
          {
            sortation: item.content[2][0].content,
            award_name: item.content[2][1].content,
            summary: item.content[2][2].content,
            award_year: item.content[2][3].content,
          },
          {
            sortation: item.content[3][0].content,
            award_name: item.content[3][1].content,
            summary: item.content[3][2].content,
            award_year: item.content[3][3].content,
          },
        ];
        setCommissionAwards(content);
      } else if (item.subject === "참여연구자 현황 표") {
        content = [
          {
            name: item.content[3][0].content,
            country: item.content[3][1].content,
            agency: item.content[3][2].content,
            position: item.content[3][3].content,
            research_number: item.content[3][4].content,
            degree: item.content[3][5].content,
            major: item.content[3][6].content,
            year: item.content[3][7].content,
            role: item.content[3][8].content,
            recruit: getSelectRoleData(item.content[3][9].content),
            time: getSelectTimeData(item.content[3][10].content),
            first_start_year: item.content[3][11].content,
            first_end_year: item.content[3][12].content,
            last_start_year: item.content[3][13].content,
            last_end_year: item.content[3][14].content,
            period: item.content[3][15].content,
          },
          {
            name: item.content[4][0].content,
            country: item.content[4][1].content,
            agency: item.content[4][2].content,
            position: item.content[4][3].content,
            research_number: item.content[4][4].content,
            degree: item.content[4][5].content,
            major: item.content[4][6].content,
            year: item.content[4][7].content,
            role: item.content[4][8].content,
            recruit: getSelectRoleData(item.content[4][9].content),
            time: getSelectTimeData(item.content[4][10].content),
            first_start_year: item.content[4][11].content,
            first_end_year: item.content[4][12].content,
            last_start_year: item.content[4][13].content,
            last_end_year: item.content[4][14].content,
            period: item.content[4][15].content,
          },
          {
            name: item.content[5][0].content,
            country: item.content[5][1].content,
            agency: item.content[5][2].content,
            position: item.content[5][3].content,
            research_number: item.content[5][4].content,
            degree: item.content[5][5].content,
            major: item.content[5][6].content,
            year: item.content[5][7].content,
            role: item.content[5][8].content,
            recruit: getSelectRoleData(item.content[5][9].content),
            time: getSelectTimeData(item.content[5][10].content),
            first_start_year: item.content[5][11].content,
            first_end_year: item.content[5][12].content,
            last_start_year: item.content[5][13].content,
            last_end_year: item.content[5][14].content,
            period: item.content[5][15].content,
          },
          {
            name: item.content[6][0].content,
            country: item.content[6][1].content,
            agency: item.content[6][2].content,
            position: item.content[6][3].content,
            research_number: item.content[6][4].content,
            degree: item.content[6][5].content,
            major: item.content[6][6].content,
            year: item.content[6][7].content,
            role: item.content[6][8].content,
            recruit: getSelectRoleData(item.content[6][9].content),
            time: getSelectTimeData(item.content[6][10].content),
            first_start_year: item.content[6][11].content,
            first_end_year: item.content[6][12].content,
            last_start_year: item.content[6][13].content,
            last_end_year: item.content[6][14].content,
            period: item.content[6][15].content,
          },
          {
            name: item.content[7][0].content,
            country: item.content[7][1].content,
            agency: item.content[7][2].content,
            position: item.content[7][3].content,
            research_number: item.content[7][4].content,
            degree: item.content[7][5].content,
            major: item.content[7][6].content,
            year: item.content[7][7].content,
            role: item.content[7][8].content,
            recruit: getSelectRoleData(item.content[7][9].content),
            time: getSelectTimeData(item.content[7][10].content),
            first_start_year: item.content[7][11].content,
            first_end_year: item.content[7][12].content,
            last_start_year: item.content[7][13].content,
            last_end_year: item.content[7][14].content,
            period: item.content[7][15].content,
          },
          {
            name: item.content[8][0].content,
            country: item.content[8][1].content,
            agency: item.content[8][2].content,
            position: item.content[8][3].content,
            research_number: item.content[8][4].content,
            degree: item.content[8][5].content,
            major: item.content[8][6].content,
            year: item.content[8][7].content,
            role: item.content[8][8].content,
            recruit: getSelectRoleData(item.content[8][9].content),
            time: getSelectTimeData(item.content[8][10].content),
            first_start_year: item.content[8][11].content,
            first_end_year: item.content[8][12].content,
            last_start_year: item.content[8][13].content,
            last_end_year: item.content[8][14].content,
            period: item.content[8][15].content,
          },
        ];
        setResearcher(content);
      } else if (item.subject === "연구지원인력 현황 표") {
        content = [
          {
            name: item.content[3][0].content,
            country: item.content[3][1].content,
            agency: item.content[3][2].content,
            position: item.content[3][3].content,
            degree: item.content[3][4].content,
            major: item.content[3][5].content,
            year: item.content[3][6].content,
            role: item.content[3][7].content,
            recruit: getSelectRoleData(item.content[3][8].content),
            time: getSelectTimeData(item.content[3][9].content),
            first_start_year: item.content[3][10].content,
            first_end_year: item.content[3][11].content,
            last_start_year: item.content[3][12].content,
            last_end_year: item.content[3][13].content,
            period: item.content[3][14].content,
          },
          {
            name: item.content[4][0].content,
            country: item.content[4][1].content,
            agency: item.content[4][2].content,
            position: item.content[4][3].content,
            degree: item.content[4][4].content,
            major: item.content[4][5].content,
            year: item.content[4][6].content,
            role: item.content[4][7].content,
            recruit: getSelectRoleData(item.content[4][8].content),
            time: getSelectTimeData(item.content[4][9].content),
            first_start_year: item.content[4][10].content,
            first_end_year: item.content[4][11].content,
            last_start_year: item.content[4][12].content,
            last_end_year: item.content[4][13].content,
            period: item.content[4][14].content,
          },
        ];
        setResearchSupport(content);
      } else if (item.subject === "연구개발기관이 아닌 관계 기관") {
        content = [
          {
            agency_name: "1",
            role: "1",
            manager_name_kr: item.content[1][3].content,
            manager_name_en: item.content[2][3].content,
            manager_nation: item.content[1][5].content,
            manager_agency_name: item.content[3][3].content,
            manager_telephone: item.content[3][5].content,
            manager_department: item.content[4][3].content,
            manager_phone: item.content[4][5].content,
            manager_position: item.content[5][3].content,
            manager_email: item.content[5][5].content,
            business_name_kr: item.content[6][3].content,
            business_name_en: item.content[7][3].content,
            business_agency_name: item.content[8][3].content,
            business_telephone: item.content[8][5].content,
            business_department: item.content[9][3].content,
            business_phone: item.content[9][5].content,
            business_position: item.content[10][3].content,
            business_email: item.content[10][5].content,
            business_address: item.content[11][3].content,
          },
          {
            agency_name: "1",
            role: "1",
            manager_name_kr: item.content[13][3].content,
            manager_name_en: item.content[14][3].content,
            manager_nation: item.content[13][5].content,
            manager_agency_name: item.content[15][3].content,
            manager_telephone: item.content[15][5].content,
            manager_department: item.content[16][3].content,
            manager_phone: item.content[16][5].content,
            manager_position: item.content[17][3].content,
            manager_email: item.content[17][5].content,
            business_name_kr: item.content[18][3].content,
            business_name_en: item.content[19][3].content,
            business_agency_name: item.content[20][3].content,
            business_telephone: item.content[20][5].content,
            business_department: item.content[21][3].content,
            business_phone: item.content[21][5].content,
            business_position: item.content[22][3].content,
            business_email: item.content[22][5].content,
            business_address: item.content[23][3].content,
          },
        ];
        setRelatedAgency(content);
      } else if (
        item.subject === "연구개발과제와 연관된 지식재산권 출원 및 등록 현황 표"
      ) {
        content = [
          {
            agency_name: item.content[1][0].content,
            property_name: item.content[1][1].content,
            country_name: item.content[1][2].content,
            apply_number: item.content[1][3].content,
          },
          {
            agency_name: item.content[2][0].content,
            property_name: item.content[2][1].content,
            country_name: item.content[2][2].content,
            apply_number: item.content[2][3].content,
          },
          {
            agency_name: item.content[3][0].content,
            property_name: item.content[3][1].content,
            country_name: item.content[3][2].content,
            apply_number: item.content[3][3].content,
          },
        ];
        setCurrentStatus(content);
      } else if (item.subject === "국가연구개발사업 주요 수행 실적 표") {
        content = [
          {
            name: item.content[2][0].content,
            organization_agency: item.content[2][1].content,
            affiliated_agency: item.content[3][1].content,
            total_duration: item.content[2][2].content.split("\n")[0],
            participation_period: item.content[2][2].content.split("\n")[1],
            content: item.content[2][3].content,
            agency: item.content[2][4].content,
            etc: item.content[2][5].content,
          },
          {
            name: item.content[4][0].content,
            organization_agency: item.content[4][1].content,
            affiliated_agency: item.content[5][1].content,
            total_duration: item.content[4][2].content.split("\n")[0],
            participation_period: item.content[4][2].content.split("\n")[1],
            content: item.content[4][3].content,
            agency: item.content[4][4].content,
            etc: item.content[4][5].content,
          },
          {
            name: item.content[6][0].content,
            organization_agency: item.content[6][1].content,
            affiliated_agency: item.content[7][1].content,
            total_duration: item.content[6][2].content.split("\n")[0],
            participation_period: item.content[6][2].content.split("\n")[1],
            content: item.content[6][3].content,
            agency: item.content[6][4].content,
            etc: item.content[6][5].content,
          },
        ];
        setExecutionPerformance(content);
      } else if (item.subject === "국가연구개발사업 기술이전 실적 표") {
        content = [
          {
            institution_name: item.content[1][0].content,
            transfer_type: item.content[1][1].content,
            contract_name: item.content[1][2].content,
            agency_name: item.content[1][3].content,
            tech_date: item.content[1][4].content,
            tech_cost: item.content[1][5].content,
            total_cost: item.content[1][6].content,
          },
          {
            institution_name: item.content[2][0].content,
            transfer_type: item.content[2][1].content,
            contract_name: item.content[2][2].content,
            agency_name: item.content[2][3].content,
            tech_date: item.content[2][4].content,
            tech_cost: item.content[2][5].content,
            total_cost: item.content[2][6].content,
          },
          {
            institution_name: item.content[3][0].content,
            transfer_type: item.content[3][1].content,
            contract_name: item.content[3][2].content,
            agency_name: item.content[3][3].content,
            tech_date: item.content[3][4].content,
            tech_cost: item.content[3][5].content,
            total_cost: item.content[3][6].content,
          },
        ];
        setTechTransfer(content);
      } else if (item.subject === "국가연구개발사업 사업화 실적 표") {
        content = [
          {
            institution_name: item.content[2][0].content,
            way: item.content[2][1].content,
            form: item.content[2][2].content,
            country: item.content[2][3].content,
            name: item.content[2][4].content,
            content: item.content[2][5].content,
            company_name: item.content[2][6].content,
            domestic_cost: item.content[2][7].content,
            overseas_cost: item.content[2][8].content,
            cost_year: item.content[2][9].content,
            tech_lifespan: item.content[2][10].content,
          },
          {
            institution_name: item.content[3][0].content,
            way: item.content[3][1].content,
            form: item.content[3][2].content,
            country: item.content[3][3].content,
            name: item.content[3][4].content,
            content: item.content[3][5].content,
            company_name: item.content[3][6].content,
            domestic_cost: item.content[3][7].content,
            overseas_cost: item.content[3][8].content,
            cost_year: item.content[3][9].content,
            tech_lifespan: item.content[3][10].content,
          },
        ];
        setCommercialPerformance(content);
      } else if (item.subject === "연구시설 장비 보유현황 표") {
        content = [
          {
            holding_agency: item.content[1][0].content,
            equipment_name: item.content[1][1].content,
            standard: item.content[1][2].content,
            quantity: item.content[1][3].content,
            purpose: item.content[1][4].content,
            use: item.content[1][5].content,
            reflection_status: item.content[1][6].content,
          },
          {
            holding_agency: item.content[2][0].content,
            equipment_name: item.content[2][1].content,
            standard: item.content[2][2].content,
            quantity: item.content[2][3].content,
            purpose: item.content[2][4].content,
            use: item.content[2][5].content,
            reflection_status: item.content[2][6].content,
          },
          {
            holding_agency: item.content[3][0].content,
            equipment_name: item.content[3][1].content,
            standard: item.content[3][2].content,
            quantity: item.content[3][3].content,
            purpose: item.content[3][4].content,
            use: item.content[3][5].content,
            reflection_status: item.content[3][6].content,
          },
        ];
        setHoldingStatus(content);
      } else if (item.subject === "연구개발기관 일반 현황 표") {
        fourthPageData["agency_name"] = [
          item.content[0][4].content,
          item.content[0][5].content,
          item.content[0][6].content,
        ];
        fourthPageData["business_number"] = [
          item.content[1][4].content,
          item.content[1][5].content,
          item.content[1][6].content,
        ];
        fourthPageData["corporate_number"] = [
          item.content[2][4].content,
          item.content[2][5].content,
          item.content[2][6].content,
        ];
        fourthPageData["representative"] = [
          item.content[3][4].content,
          item.content[3][5].content,
          item.content[3][6].content,
        ];
        fourthPageData["agency_type"] = [
          item.content[4][4].content,
          item.content[4][5].content,
          item.content[4][6].content,
        ];
        fourthPageData["stockholder"] = [
          item.content[5][4].content,
          item.content[5][5].content,
          item.content[5][6].content,
        ];
        fourthPageData["founded"] = [
          item.content[6][4].content,
          item.content[6][5].content,
          item.content[6][6].content,
        ];
        fourthPageData["main_item"] = [
          item.content[7][4].content,
          item.content[7][5].content,
          item.content[7][6].content,
        ];
        fourthPageData["employees"] = [
          item.content[8][4].content,
          item.content[8][5].content,
          item.content[8][6].content,
        ];
        fourthPageData["sales"] = [
          item.content[9][4].content,
          item.content[9][5].content,
          item.content[9][6].content,
        ];
        fourthPageData["rnd_per_sales"] = [
          item.content[10][4].content,
          item.content[10][5].content,
          item.content[10][6].content,
        ];
        fourthPageData["debt_ratio"] = [
          [
            item.content[11][4].content,
            item.content[11][5].content,
            item.content[11][6].content,
          ],
          [
            item.content[12][4].content,
            item.content[12][5].content,
            item.content[12][6].content,
          ],
          [
            item.content[13][4].content,
            item.content[13][5].content,
            item.content[13][6].content,
          ],
        ];
        fourthPageData["current_rate"] = [
          [
            item.content[14][4].content,
            item.content[14][5].content,
            item.content[14][6].content,
          ],
          [
            item.content[15][4].content,
            item.content[15][5].content,
            item.content[15][6].content,
          ],
          [
            item.content[16][4].content,
            item.content[16][5].content,
            item.content[16][6].content,
          ],
        ];
        fourthPageData["total_capital"] = [
          [
            item.content[17][4].content,
            item.content[17][5].content,
            item.content[17][6].content,
          ],
          [
            item.content[18][4].content,
            item.content[18][5].content,
            item.content[18][6].content,
          ],
          [
            item.content[19][4].content,
            item.content[19][5].content,
            item.content[19][6].content,
          ],
        ];
        fourthPageData["capital"] = [
          [
            item.content[20][4].content,
            item.content[20][5].content,
            item.content[20][6].content,
          ],
          [
            item.content[21][4].content,
            item.content[21][5].content,
            item.content[21][6].content,
          ],
          [
            item.content[22][4].content,
            item.content[22][5].content,
            item.content[22][6].content,
          ],
        ];
        fourthPageData["operating_profit"] = [
          [
            item.content[23][4].content,
            item.content[23][5].content,
            item.content[23][6].content,
          ],
          [
            item.content[24][4].content,
            item.content[24][5].content,
            item.content[24][6].content,
          ],
          [
            item.content[25][4].content,
            item.content[25][5].content,
            item.content[25][6].content,
          ],
        ];
        fourthPageData["compensation_ratio"] = [
          [
            item.content[26][4].content,
            item.content[26][5].content,
            item.content[26][6].content,
          ],
          [
            item.content[27][4].content,
            item.content[27][5].content,
            item.content[27][6].content,
          ],
          [
            item.content[28][4].content,
            item.content[28][5].content,
            item.content[28][6].content,
          ],
        ];
        fourthPageData["support_name"] = [
          item.content[29][4].content,
          item.content[29][5].content,
          item.content[29][6].content,
        ];
        fourthPageData["support_department"] = [
          item.content[30][4].content,
          item.content[30][5].content,
          item.content[30][6].content,
        ];
        fourthPageData["support_position"] = [
          item.content[31][4].content,
          item.content[31][5].content,
          item.content[31][6].content,
        ];
        fourthPageData["support_telephone"] = [
          item.content[32][4].content,
          item.content[32][5].content,
          item.content[32][6].content,
        ];
        fourthPageData["support_phone"] = [
          item.content[33][4].content,
          item.content[33][5].content,
          item.content[33][6].content,
        ];
        fourthPageData["support_email"] = [
          item.content[34][4].content,
          item.content[34][5].content,
          item.content[34][6].content,
        ];
        fourthPageData["support_fax"] = [
          item.content[35][4].content,
          item.content[35][5].content,
          item.content[35][6].content,
        ];
        setFourthPageData({ ...fourthPageData });
      }
    });
  };
  const addTableRows = (e) => {
    const { name } = e.target;
    let inputRows;
    if (name === "supervisorEducation") {
      inputRows = {
        startDate: null,
        endDate: null,
        school_name: "",
        major: "",
        academic_degree: "",
        professor: "",
      };
      setSupervisorEducation([...supervisorEducation, inputRows]);
    } else if (name === "supervisorCareer") {
      inputRows = {
        startDate: null,
        endDate: null,
        organization_name: "",
        position: "",
        note: "",
      };
      setSupervisorCareer([...supervisorCareer, inputRows]);
    } else if (name === "supervisorPerformance") {
      inputRows = {
        agency: "",
        detail_project: "",
        name: "",
        organization_agency: "",
        affiliated_agency: "",
        total_duration: "",
        participation_period: "",
        role: "",
        etc: "",
      };
      setSupervisorPerformance([...supervisorPerformance, inputRows]);
    } else if (name === "supervisorThesis") {
      inputRows = {
        sortation: "",
        thesis_name: "",
        paper: "",
        year: "",
        role: "",
        apply_number: "",
        etc: "",
      };
      setSupervisorThesis([...supervisorThesis, inputRows]);
    } else if (name === "supervisorApply") {
      inputRows = {
        sortation: "",
        name: "",
        country_name: "",
        apply_date: "",
        apply_number: "",
        etc: "",
      };
      setSupervisorApply([...supervisorApply, inputRows]);
    } else if (name === "supervisorAwards") {
      inputRows = {
        sortation: "",
        award_name: "",
        summary: "",
        award_year: "",
      };
      setSupervisorAwards([...supervisorAwards, inputRows]);
    } else if (name === "jointEducation") {
      inputRows = {
        startDate: null,
        endDate: null,
        school_name: "",
        major: "",
        academic_degree: "",
        professor: "",
      };
      setJointEducation([...jointEducation, inputRows]);
    } else if (name === "jointCareer") {
      inputRows = {
        startDate: null,
        endDate: null,
        organization_name: "",
        position: "",
        note: "",
      };
      setJointCareer([...jointCareer, inputRows]);
    } else if (name === "jointPerformance") {
      inputRows = {
        agency: "",
        detail_project: "",
        name: "",
        organization_agency: "",
        affiliated_agency: "",
        total_duration: "",
        participation_period: "",
        role: "",
        etc: "",
      };
      setJointPerformance([...jointPerformance, inputRows]);
    } else if (name === "jointThesis") {
      inputRows = {
        sortation: "",
        thesis_name: "",
        paper: "",
        year: "",
        role: "",
        apply_number: "",
        etc: "",
      };
      setJointThesis([...jointThesis, inputRows]);
    } else if (name === "jointApply") {
      inputRows = {
        sortation: "",
        name: "",
        country_name: "",
        apply_date: "",
        apply_number: "",
        etc: "",
      };
      setJointApply([...jointApply, inputRows]);
    } else if (name === "jointAwards") {
      inputRows = {
        sortation: "",
        award_name: "",
        summary: "",
        award_year: "",
      };
      setJointAwards([...jointAwards, inputRows]);
    } else if (name === "commissionEducation") {
      inputRows = {
        startDate: null,
        endDate: null,
        school_name: "",
        major: "",
        academic_degree: "",
        professor: "",
      };
      setCommissionEducation([...commissionEducation, inputRows]);
    } else if (name === "commissionCareer") {
      inputRows = {
        startDate: null,
        endDate: null,
        organization_name: "",
        position: "",
        note: "",
      };
      setCommissionCareer([...commissionCareer, inputRows]);
    } else if (name === "commissionPerformance") {
      inputRows = {
        agency: "",
        detail_project: "",
        name: "",
        organization_agency: "",
        affiliated_agency: "",
        total_duration: "",
        participation_period: "",
        role: "",
        etc: "",
      };
      setCommissionPerformance([...commissionPerformance, inputRows]);
    } else if (name === "commissionThesis") {
      inputRows = {
        sortation: "",
        thesis_name: "",
        paper: "",
        year: "",
        role: "",
        apply_number: "",
        etc: "",
      };
      setCommissionThesis([...commissionThesis, inputRows]);
    } else if (name === "commissionApply") {
      inputRows = {
        sortation: "",
        name: "",
        country_name: "",
        apply_date: "",
        apply_number: "",
        etc: "",
      };
      setCommissionApply([...commissionApply, inputRows]);
    } else if (name === "commissionAwards") {
      inputRows = {
        sortation: "",
        award_name: "",
        summary: "",
        award_year: "",
      };
      setCommissionAwards([...commissionAwards, inputRows]);
    } else if (name === "researchSupport") {
      inputRows = {
        name: "",
        country: "",
        agency: "",
        position: "",
        degree: "",
        major: "",
        year: "",
        role: "",
        recruit: "",
        time: "",
        first_start_year: "",
        first_end_year: "",
        last_start_year: "",
        last_end_year: "",
        period: "",
      };
      setResearchSupport([...researchSupport, inputRows]);
    } else if (name === "currentStatus") {
      inputRows = {
        agency_name: "",
        property_name: "",
        country_name: "",
        apply_number: "",
      };
      setCurrentStatus([...currentStatus, inputRows]);
    } else if (name === "executionPerformance") {
      inputRows = {
        name: "",
        organization_agency: "",
        affiliated_agency: "",
        total_duration: "",
        participation_period: "",
        content: "",
        agency: "",
        etc: "",
      };
      setExecutionPerformance([...executionPerformance, inputRows]);
    } else if (name === "techTransfer") {
      inputRows = {
        institution_name: "",
        transfer_type: "",
        contract_name: "",
        agency_name: "",
        tech_date: "",
        tech_cost: "",
        total_cost: "",
      };
      setTechTransfer([...techTransfer, inputRows]);
    } else if (name === "commercialPerformance") {
      inputRows = {
        institution_name: "",
        way: "",
        form: "",
        country: "",
        name: "",
        content: "",
        company_name: "",
        domestic_cost: "",
        overseas_cost: "",
        cost_year: "",
        tech_lifespan: "",
      };
      setCommercialPerformance([...commercialPerformance, inputRows]);
    } else if (name === "holdingStatus") {
      inputRows = {
        holding_agency: "",
        equipment_name: "",
        standard: "",
        quantity: "",
        purpose: "",
        use: "",
        reflection_status: "",
      };
      setHoldingStatus([...holdingStatus, inputRows]);
    } else if (name === "relatedAgency") {
      inputRows = {
        agency_name: "",
        role: "",
        manager_name_kr: "",
        manager_name_en: "",
        manager_nation: "",
        manager_agency_name: "",
        manager_telephone: "",
        manager_department: "",
        manager_phone: "",
        manager_position: "",
        manager_email: "",
        manager_address: "",
        business_name_kr: "",
        business_name_en: "",
        business_agency_name: "",
        business_telephone: "",
        business_department: "",
        business_phone: "",
        business_position: "",
        business_email: "",
        business_address: "",
      };
      setRelatedAgency([...relatedAgency, inputRows]);
    }
  };

  const deleteTableRows = (e, index) => {
    const { name } = e.target;
    if (name === "supervisorEducation") {
      setSupervisorEducation(supervisorEducation.filter((v, i) => i !== index));
    }
  };

  const handleChange = (e, idx, secondIdx) => {
    const { name, value } = e.target;
    if (
      name === "debt_ratio" ||
      name === "current_rate" ||
      name === "total_capital" ||
      name === "capital" ||
      name === "operating_profit" ||
      name === "compensation_ratio"
    ) {
      fourthPageData[name][idx][secondIdx] = value;
      setFourthPageData({ ...fourthPageData });
    } else {
      fourthPageData[name][idx] = value;
      setFourthPageData({ ...fourthPageData });
    }
  };

  const handleChangeTable = (e, index, itemName) => {
    const { name, value } = e.target;

    if (name === "supervisorEducation") {
      supervisorEducation[index][itemName] = value;
      setSupervisorEducation([...supervisorEducation]);
    } else if (name === "supervisorCareer") {
      supervisorCareer[index][itemName] = value;
      setSupervisorCareer([...supervisorCareer]);
    } else if (name === "supervisorPerformance") {
      supervisorPerformance[index][itemName] = value;
      setSupervisorPerformance([...supervisorPerformance]);
    } else if (name === "supervisorThesis") {
      supervisorThesis[index][itemName] = value;
      setSupervisorThesis([...supervisorThesis]);
    } else if (name === "supervisorApply") {
      supervisorApply[index][itemName] = value;
      setSupervisorApply([...supervisorApply]);
    } else if (name === "supervisorAwards") {
      supervisorAwards[index][itemName] = value;
      setSupervisorAwards([...supervisorAwards]);
    } else if (name === "jointEducation") {
      jointEducation[index][itemName] = value;
      setJointEducation([...jointEducation]);
    } else if (name === "jointCareer") {
      jointCareer[index][itemName] = value;
      setJointCareer([...jointCareer]);
    } else if (name === "jointPerformance") {
      jointPerformance[index][itemName] = value;
      setJointPerformance([...jointPerformance]);
    } else if (name === "jointThesis") {
      jointThesis[index][itemName] = value;
      setJointThesis([...jointThesis]);
    } else if (name === "jointApply") {
      jointApply[index][itemName] = value;
      setJointApply([...jointApply]);
    } else if (name === "jointAwards") {
      jointAwards[index][itemName] = value;
      setJointAwards([...jointAwards]);
    } else if (name === "commissionEducation") {
      commissionEducation[index][itemName] = value;
      setCommissionEducation([...commissionEducation]);
    } else if (name === "commissionCareer") {
      commissionCareer[index][itemName] = value;
      setCommissionCareer([...commissionCareer]);
    } else if (name === "commissionPerformance") {
      commissionPerformance[index][itemName] = value;
      setCommissionPerformance([...commissionPerformance]);
    } else if (name === "commissionThesis") {
      commissionThesis[index][itemName] = value;
      setCommissionThesis([...commissionThesis]);
    } else if (name === "commissionApply") {
      commissionApply[index][itemName] = value;
      setCommissionApply([...commissionApply]);
    } else if (name === "commissionAwards") {
      commissionAwards[index][itemName] = value;
      setCommissionAwards([...commissionAwards]);
    } else if (name === "researcher") {
      researcher[index][itemName] = value;
      setResearcher([...researcher]);
    } else if (name === "researchSupport") {
      researchSupport[index][itemName] = value;
      setResearchSupport([...researchSupport]);
    } else if (name === "currentStatus") {
      currentStatus[index][itemName] = value;
      setCurrentStatus([...currentStatus]);
    } else if (name === "executionPerformance") {
      executionPerformance[index][itemName] = value;
      setExecutionPerformance([...executionPerformance]);
    } else if (name === "techTransfer") {
      techTransfer[index][itemName] = value;
      setTechTransfer([...techTransfer]);
    } else if (name === "commercialPerformance") {
      commercialPerformance[index][itemName] = value;
      setCommercialPerformance([...commercialPerformance]);
    } else if (name === "holdingStatus") {
      holdingStatus[index][itemName] = value;
      setHoldingStatus([...holdingStatus]);
    } else if (name === "relatedAgency") {
      relatedAgency[index][itemName] = value;
      setRelatedAgency([...relatedAgency]);
    }
  };

  const handleDateChange = (date, index, name, type) => {
    let rowsInput = [...eval(name)];

    if (type === 0) {
      rowsInput[index].startDate = date;
    } else if (type === 1) {
      rowsInput[index].endDate = date;
    }
    // if (
    //   rowsInput[index].startDate > rowsInput[index].endDate &&
    //   rowsInput[index].endDate !== null
    // ) {
    //   alert("날짜를 잘못 입력하셨습니다.");
    //   return;
    // }
    if (name === "supervisorEducation") setSupervisorEducation(rowsInput);
    else if (name === "supervisorCareer") setSupervisorCareer(rowsInput);
    else if (name === "jointEducation") setJointEducation(rowsInput);
    else if (name === "jointCareer") setJointCareer(rowsInput);
    else if (name === "commissionEducation") setCommissionEducation(rowsInput);
    else if (name === "commissionCareer") setCommissionCareer(rowsInput);
  };

  const sendData = () => {
    let supervisorEduArr = [
      [
        { type: "text", content: "취득연월(최근 순으로 작성)" },
        { type: "text", content: "학교명" },
        { type: "text", content: "전공" },
        { type: "text", content: "학위" },
        { type: "text", content: "지도교수" },
      ],
    ];

    let supervisorCareerArr = [
      [
        { type: "text", content: "기간" },
        { type: "text", content: "기관명" },
        { type: "text", content: "직위" },
        { type: "text", content: "비고" },
      ],
    ];

    let supervisorPerformanceArr = [
      [
        { type: "text", content: "중앙행정기관\n(전문기관)" },
        { type: "text", content: "세부사업명" },
        { type: "text", content: "연구개발과제명" },
        { type: "text", content: "주관연구개발기관" },
        { type: "text", content: "연구개발기간\n(참여한 기간)" },
        { type: "text", content: "역할:\n연구책임자\n연구자" },
        { type: "text", content: "비고\n(신청/수행중/완료)" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "당시 소속기관" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
      ],
    ];

    let supervisorThesisArr = [
      [
        {
          type: "text",
          content: "구분\n(논문/저서)",
        },
        {
          type: "text",
          content: "논문명/저서명",
        },
        {
          type: "text",
          content: "게재지\n(권, 쪽)",
        },
        {
          type: "text",
          content: "게재연도\n(발표연도)",
        },
        {
          type: "text",
          content: "역할",
        },
        {
          type: "text",
          content: "등록번호\n(ISSN)",
        },
        {
          type: "text",
          content: "비고\n(피인용 지수)",
        },
      ],
    ];

    let supervisorApplyArr = [
      [
        {
          type: "text",
          content: "구분\n(특허/프로그램 등)",
        },
        {
          type: "text",
          content: "지식재산권명",
        },
        {
          type: "text",
          content: "국가명",
        },
        {
          type: "text",
          content: "출원ㆍ등록일",
        },
        {
          type: "text",
          content: "출원ㆍ등록번호/\n출원ㆍ등록자 수",
        },
        {
          type: "text",
          content: "비고",
        },
      ],
    ];

    let supervisorAwardArr = [
      [
        {
          type: "text",
          content: "구분",
        },
        {
          type: "text",
          content: "실적명",
        },
        {
          type: "text",
          content: "내용요약",
        },
        {
          type: "text",
          content: "실적연도",
        },
      ],
    ];

    let jointEduArr = [
      [
        { type: "text", content: "취득연월(최근 순으로 작성)" },
        { type: "text", content: "학교명" },
        { type: "text", content: "전공" },
        { type: "text", content: "학위" },
        { type: "text", content: "지도교수" },
      ],
    ];

    let jointCareerArr = [
      [
        { type: "text", content: "기간" },
        { type: "text", content: "기관명" },
        { type: "text", content: "직위" },
        { type: "text", content: "비고" },
      ],
    ];

    let jointPerformanceArr = [
      [
        { type: "text", content: "중앙행정기관\n(전문기관)" },
        { type: "text", content: "세부사업명" },
        { type: "text", content: "연구개발과제명" },
        { type: "text", content: "주관연구개발기관" },
        { type: "text", content: "연구개발기간\n(참여한 기간)" },
        { type: "text", content: "역할:\n연구책임자\n연구자" },
        { type: "text", content: "비고\n(신청/수행중/완료)" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "당시 소속기관" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
      ],
    ];

    let jointThesisArr = [
      [
        {
          type: "text",
          content: "구분\n(논문/저서)",
        },
        {
          type: "text",
          content: "논문명/저서명",
        },
        {
          type: "text",
          content: "게재지\n(권, 쪽)",
        },
        {
          type: "text",
          content: "게재연도\n(발표연도)",
        },
        {
          type: "text",
          content: "역할",
        },
        {
          type: "text",
          content: "등록번호\n(ISSN)",
        },
        {
          type: "text",
          content: "비고\n(피인용 지수)",
        },
      ],
    ];

    let jointApplyArr = [
      [
        {
          type: "text",
          content: "구분\n(특허/프로그램 등)",
        },
        {
          type: "text",
          content: "지식재산권명",
        },
        {
          type: "text",
          content: "국가명",
        },
        {
          type: "text",
          content: "출원ㆍ등록일",
        },
        {
          type: "text",
          content: "출원ㆍ등록번호/\n출원ㆍ등록자 수",
        },
        {
          type: "text",
          content: "비고",
        },
      ],
    ];

    let jointAwardArr = [
      [
        {
          type: "text",
          content: "구분",
        },
        {
          type: "text",
          content: "실적명",
        },
        {
          type: "text",
          content: "내용요약",
        },
        {
          type: "text",
          content: "실적연도",
        },
      ],
    ];
    supervisorEducation.map((el) => {
      const startDate = window.$Global.convertMonthDot(el.startDate);
      const endDate = window.$Global.convertMonthDot(el.endDate);

      let data = [
        {
          type: "text",
          content: `${startDate}~${endDate}`,
        },
        {
          type: "text",
          content: el.school_name,
        },
        {
          type: "text",
          content: el.major,
        },
        {
          type: "text",
          content: el.academic_degree,
        },
        {
          type: "text",
          content: el.professor,
        },
      ];
      supervisorEduArr.push(data);
    });
    supervisorEduArr.push([
      {
        type: "text",
        content: "최종학위 논문명(해당 시):",
      },
      {
        type: "text",
        content: fourthPageData.name_thesis[0],
      },
      {
        type: "text",
        content: "=&병합&=",
      },
      {
        type: "text",
        content: "=&병합&=",
      },
      {
        type: "text",
        content: "=&병합&=",
      },
    ]);
    supervisorCareer.map((el) => {
      const startDate = window.$Global.convertMonthDot(el.startDate);
      const endDate = window.$Global.convertMonthDot(el.endDate);

      let data = [
        {
          type: "text",
          content: `${startDate}~${endDate}`,
        },
        {
          type: "text",
          content: el.organization_name,
        },
        {
          type: "text",
          content: el.position,
        },
        {
          type: "text",
          content: el.note,
        },
      ];
      supervisorCareerArr.push(data);
    });

    supervisorPerformance.map((el) => {
      let data = [
        {
          type: "text",
          content: el.agency,
        },
        {
          type: "text",
          content: el.detail_project,
        },
        {
          type: "text",
          content: el.name,
        },
        {
          type: "text",
          content: el.organization_agency,
        },
        {
          type: "text",
          content: `${el.total_duration}\n${el.participation_period}`,
        },
        {
          type: "text",
          content: el.role,
        },
        {
          type: "text",
          content: el.etc,
        },
      ];
      supervisorPerformanceArr.push(data);
      data = [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: el.affiliated_agency,
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ];
      supervisorPerformanceArr.push(data);
    });

    supervisorThesis.map((el) => {
      let data = [
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.thesis_name,
        },
        {
          type: "text",
          content: el.paper,
        },
        {
          type: "text",
          content: el.year,
        },
        {
          type: "text",
          content: el.role,
        },
        {
          type: "text",
          content: el.apply_number,
        },
        {
          type: "text",
          content: el.etc,
        },
      ];
      supervisorThesisArr.push(data);
    });

    supervisorApply.map((el) => {
      let data = [
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.name,
        },
        {
          type: "text",
          content: el.country_name,
        },
        {
          type: "text",
          content: el.apply_date,
        },
        {
          type: "text",
          content: el.apply_number,
        },
        {
          type: "text",
          content: el.etc,
        },
      ];
      supervisorApplyArr.push(data);
    });

    supervisorAwards.map((el) => {
      let data = [
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.award_name,
        },
        {
          type: "text",
          content: el.summary,
        },
        {
          type: "text",
          content: el.award_year,
        },
      ];
      supervisorAwardArr.push(data);
    });

    jointEducation.map((el) => {
      const startDate = window.$Global.convertMonthDot(el.startDate);
      const endDate = window.$Global.convertMonthDot(el.endDate);

      let data = [
        {
          type: "text",
          content: `${startDate}~${endDate}`,
        },
        {
          type: "text",
          content: el.school_name,
        },
        {
          type: "text",
          content: el.major,
        },
        {
          type: "text",
          content: el.academic_degree,
        },
        {
          type: "text",
          content: el.professor,
        },
      ];
      jointEduArr.push(data);
    });

    jointEduArr.push([
      {
        type: "text",
        content: "최종학위 논문명(해당 시):",
      },
      {
        type: "text",
        content: fourthPageData.name_thesis[1],
      },
      {
        type: "text",
        content: "=&병합&=",
      },
      {
        type: "text",
        content: "=&병합&=",
      },
      {
        type: "text",
        content: "=&병합&=",
      },
    ]);

    jointCareer.map((el) => {
      const startDate = window.$Global.convertMonthDot(el.startDate);
      const endDate = window.$Global.convertMonthDot(el.endDate);

      let data = [
        {
          type: "text",
          content: `${startDate}~${endDate}`,
        },
        {
          type: "text",
          content: el.organization_name,
        },
        {
          type: "text",
          content: el.position,
        },
        {
          type: "text",
          content: el.note,
        },
      ];
      jointCareerArr.push(data);
    });

    jointPerformance.map((el) => {
      let data = [
        {
          type: "text",
          content: el.agency,
        },
        {
          type: "text",
          content: el.detail_project,
        },
        {
          type: "text",
          content: el.name,
        },
        {
          type: "text",
          content: el.organization_agency,
        },
        {
          type: "text",
          content: `${el.total_duration}\n${el.participation_period}`,
        },
        {
          type: "text",
          content: el.role,
        },
        {
          type: "text",
          content: el.etc,
        },
      ];
      jointPerformanceArr.push(data);
      data = [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: el.affiliated_agency,
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ];
      jointPerformanceArr.push(data);
    });

    jointThesis.map((el) => {
      let data = [
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.thesis_name,
        },
        {
          type: "text",
          content: el.paper,
        },
        {
          type: "text",
          content: el.year,
        },
        {
          type: "text",
          content: el.role,
        },
        {
          type: "text",
          content: el.apply_number,
        },
        {
          type: "text",
          content: el.etc,
        },
      ];
      jointThesisArr.push(data);
    });

    jointApply.map((el) => {
      let data = [
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.name,
        },
        {
          type: "text",
          content: el.country_name,
        },
        {
          type: "text",
          content: el.apply_date,
        },
        {
          type: "text",
          content: el.apply_number,
        },
        {
          type: "text",
          content: el.etc,
        },
      ];
      jointApplyArr.push(data);
    });

    jointAwards.map((el) => {
      let data = [
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.award_name,
        },
        {
          type: "text",
          content: el.summary,
        },
        {
          type: "text",
          content: el.award_year,
        },
      ];
      jointAwardArr.push(data);
    });

    let commissionEduArr = [
      [
        { type: "text", content: "취득연월(최근 순으로 작성)" },
        { type: "text", content: "학교명" },
        { type: "text", content: "전공" },
        { type: "text", content: "학위" },
        { type: "text", content: "지도교수" },
      ],
    ];

    let commissionCareerArr = [
      [
        { type: "text", content: "기간" },
        { type: "text", content: "기관명" },
        { type: "text", content: "직위" },
        { type: "text", content: "비고" },
      ],
    ];

    let commissionPerformanceArr = [
      [
        { type: "text", content: "중앙행정기관\n(전문기관)" },
        { type: "text", content: "세부사업명" },
        { type: "text", content: "연구개발과제명" },
        { type: "text", content: "주관연구개발기관" },
        { type: "text", content: "연구개발기간\n(참여한 기간)" },
        { type: "text", content: "역할:\n연구책임자\n연구자" },
        { type: "text", content: "비고\n(신청/수행중/완료)" },
      ],
      [
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "당시 소속기관" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
        { type: "text", content: "||&병합&||" },
      ],
    ];

    let commissionThesisArr = [
      [
        {
          type: "text",
          content: "구분\n(논문/저서)",
        },
        {
          type: "text",
          content: "논문명/저서명",
        },
        {
          type: "text",
          content: "게재지\n(권, 쪽)",
        },
        {
          type: "text",
          content: "게재연도\n(발표연도)",
        },
        {
          type: "text",
          content: "역할",
        },
        {
          type: "text",
          content: "등록번호\n(ISSN)",
        },
        {
          type: "text",
          content: "비고\n(피인용 지수)",
        },
      ],
    ];

    let commissionApplyArr = [
      [
        {
          type: "text",
          content: "구분\n(특허/프로그램 등)",
        },
        {
          type: "text",
          content: "지식재산권명",
        },
        {
          type: "text",
          content: "국가명",
        },
        {
          type: "text",
          content: "출원ㆍ등록일",
        },
        {
          type: "text",
          content: "출원ㆍ등록번호/\n출원ㆍ등록자 수",
        },
        {
          type: "text",
          content: "비고",
        },
      ],
    ];

    let commissionAwardArr = [
      [
        {
          type: "text",
          content: "구분",
        },
        {
          type: "text",
          content: "실적명",
        },
        {
          type: "text",
          content: "내용요약",
        },
        {
          type: "text",
          content: "실적연도",
        },
      ],
    ];
    commissionEducation.map((el) => {
      const startDate = window.$Global.convertMonthDot(el.startDate);
      const endDate = window.$Global.convertMonthDot(el.endDate);

      let data = [
        {
          type: "text",
          content: `${startDate}~${endDate}`,
        },
        {
          type: "text",
          content: el.school_name,
        },
        {
          type: "text",
          content: el.major,
        },
        {
          type: "text",
          content: el.academic_degree,
        },
        {
          type: "text",
          content: el.professor,
        },
      ];
      commissionEduArr.push(data);
    });

    commissionEduArr.push([
      {
        type: "text",
        content: "최종학위 논문명(해당 시):",
      },
      {
        type: "text",
        content: fourthPageData.name_thesis[2],
      },
      {
        type: "text",
        content: "=&병합&=",
      },
      {
        type: "text",
        content: "=&병합&=",
      },
      {
        type: "text",
        content: "=&병합&=",
      },
    ]);

    commissionCareer.map((el) => {
      const startDate = window.$Global.convertMonthDot(el.startDate);
      const endDate = window.$Global.convertMonthDot(el.endDate);

      let data = [
        {
          type: "text",
          content: `${startDate}~${endDate}`,
        },
        {
          type: "text",
          content: el.organization_name,
        },
        {
          type: "text",
          content: el.position,
        },
        {
          type: "text",
          content: el.note,
        },
      ];
      commissionCareerArr.push(data);
    });

    commissionPerformance.map((el) => {
      let data = [
        {
          type: "text",
          content: el.agency,
        },
        {
          type: "text",
          content: el.detail_project,
        },
        {
          type: "text",
          content: el.name,
        },
        {
          type: "text",
          content: el.organization_agency,
        },
        {
          type: "text",
          content: `${el.total_duration}\n${el.participation_period}`,
        },
        {
          type: "text",
          content: el.role,
        },
        {
          type: "text",
          content: el.etc,
        },
      ];
      commissionPerformanceArr.push(data);
      data = [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: el.affiliated_agency,
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ];
      commissionPerformanceArr.push(data);
    });

    commissionThesis.map((el) => {
      let data = [
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.thesis_name,
        },
        {
          type: "text",
          content: el.paper,
        },
        {
          type: "text",
          content: el.year,
        },
        {
          type: "text",
          content: el.role,
        },
        {
          type: "text",
          content: el.apply_number,
        },
        {
          type: "text",
          content: el.etc,
        },
      ];
      commissionThesisArr.push(data);
    });

    commissionApply.map((el) => {
      let data = [
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.name,
        },
        {
          type: "text",
          content: el.country_name,
        },
        {
          type: "text",
          content: el.apply_date,
        },
        {
          type: "text",
          content: el.apply_number,
        },
        {
          type: "text",
          content: el.etc,
        },
      ];
      commissionApplyArr.push(data);
    });

    commissionAwards.map((el) => {
      let data = [
        {
          type: "text",
          content: el.sortation,
        },
        {
          type: "text",
          content: el.award_name,
        },
        {
          type: "text",
          content: el.summary,
        },
        {
          type: "text",
          content: el.award_year,
        },
      ];
      commissionAwardArr.push(data);
    });

    let researcherArr = [
      [
        {
          type: "text",
          content: "성명",
        },
        {
          type: "text",
          content: "국적",
        },
        {
          type: "text",
          content: "소속\n기관",
        },
        {
          type: "text",
          content: "직위",
        },
        {
          type: "text",
          content: "국가\n연구자\n번호",
        },
        {
          type: "text",
          content: "학위 및 전공",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "담당역할",
        },
        {
          type: "text",
          content: "신규채용구분\n(해당 시 작성)",
        },
        {
          type: "text",
          content: "시간 선택제 근무 구분\n(해당 시 작성)",
        },
        {
          type: "text",
          content: "참여연도",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "총\n참여기간\n(개월)",
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "1단계",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "n단계",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "최종학위",
        },
        {
          type: "text",
          content: "전공",
        },
        {
          type: "text",
          content: "취득연도",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "1년",
        },
        {
          type: "text",
          content: "n년",
        },
        {
          type: "text",
          content: "1년",
        },
        {
          type: "text",
          content: "n년",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ],
    ];

    researcher.map((el) => {
      let data = [
        {
          type: "text",
          content: el.name,
        },
        {
          type: "text",
          content: el.country,
        },
        {
          type: "text",
          content: el.agency,
        },
        {
          type: "text",
          content: el.position,
        },
        {
          type: "text",
          content: el.research_number,
        },
        {
          type: "text",
          content: el.degree,
        },
        {
          type: "text",
          content: el.major,
        },
        {
          type: "text",
          content: el.year,
        },
        {
          type: "text",
          content: el.role,
        },
        {
          type: "text",
          content:
            el.recruit === "new"
              ? "신규"
              : el.recruit === "new_exclusive"
              ? "신규(전담)"
              : el.recruit === "new_youth_obligation"
              ? "신규(청년의무)"
              : el.recruit === "new_etc"
              ? "신규(기타)"
              : "기존",
        },
        {
          type: "text",
          content: el.time === "hour" ? "시간" : "실습",
        },
        {
          type: "text",
          content: el.first_start_year,
        },
        {
          type: "text",
          content: el.first_end_year,
        },
        {
          type: "text",
          content: el.last_start_year,
        },
        {
          type: "text",
          content: el.last_end_year,
        },
        {
          type: "text",
          content: el.period,
        },
      ];
      researcherArr.push(data);
    });

    let researchSupportArr = [
      [
        {
          type: "text",
          content: "성명",
        },
        {
          type: "text",
          content: "국적",
        },
        {
          type: "text",
          content: "소속\n기관",
        },
        {
          type: "text",
          content: "직위",
        },
        {
          type: "text",
          content: "학위 및 전공",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "담당역할",
        },
        {
          type: "text",
          content: "신규채용구분\n(해당 시 작성)",
        },
        {
          type: "text",
          content: "시간 선택제 근무 구분\n(해당 시 작성)",
        },
        {
          type: "text",
          content: "참여연도",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "총\n참여기간\n(개월)",
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "1단계",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "n단계",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "최종학위",
        },
        {
          type: "text",
          content: "전공",
        },
        {
          type: "text",
          content: "취득연도",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "1년",
        },
        {
          type: "text",
          content: "n년",
        },
        {
          type: "text",
          content: "1년",
        },
        {
          type: "text",
          content: "n년",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ],
    ];

    researchSupport.map((el) => {
      let data = [
        {
          type: "text",
          content: el.name,
        },
        {
          type: "text",
          content: el.country,
        },
        {
          type: "text",
          content: el.agency,
        },
        {
          type: "text",
          content: el.position,
        },
        {
          type: "text",
          content: el.degree,
        },
        {
          type: "text",
          content: el.major,
        },
        {
          type: "text",
          content: el.year,
        },
        {
          type: "text",
          content: el.role,
        },
        {
          type: "text",
          content:
            el.recruit === "new"
              ? "신규"
              : el.recruit === "new_exclusive"
              ? "신규(전담)"
              : el.recruit === "new_youth_obligation"
              ? "신규(청년의무)"
              : el.recruit === "new_etc"
              ? "신규(기타)"
              : "기존",
        },
        {
          type: "text",
          content: el.time === "hour" ? "시간" : "실습",
        },
        {
          type: "text",
          content: el.first_start_year,
        },
        {
          type: "text",
          content: el.first_end_year,
        },
        {
          type: "text",
          content: el.last_start_year,
        },
        {
          type: "text",
          content: el.last_end_year,
        },
        {
          type: "text",
          content: el.period,
        },
      ];
      researchSupportArr.push(data);
    });

    let relatedAgencyArr = [];
    relatedAgency.map((el, index) => {
      let data = [
        [
          {
            type: "text",
            content: `${kr_index[index]}. 기관명: ${el.agency_name} (역할: ${el.role})`,
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
        ],
        [
          {
            type: "text",
            content: "책임자",
          },
          {
            type: "text",
            content: "성명",
          },
          {
            type: "text",
            content: "국문",
          },
          {
            type: "text",
            content: el.manager_name_kr,
          },
          {
            type: "text",
            content: "국적",
          },
          {
            type: "text",
            content: el.manager_nation,
          },
        ],
        [
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "영문",
          },
          {
            type: "text",
            content: el.manager_name_en,
          },
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "||&병합&||",
          },
        ],
        [
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "기관명",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: el.manager_agency_name,
          },
          {
            type: "text",
            content: "전화번호",
          },
          {
            type: "text",
            content: el.manager_telephone,
          },
        ],
        [
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "부서",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: el.manager_department,
          },
          {
            type: "text",
            content: "휴대전화",
          },
          {
            type: "text",
            content: el.manager_phone,
          },
        ],
        [
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "직위",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: el.manager_position,
          },
          {
            type: "text",
            content: "전자우편",
          },
          {
            type: "text",
            content: el.manager_email,
          },
        ],
        [
          {
            type: "text",
            content: "실무담당자",
          },
          {
            type: "text",
            content: "국문",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: el.business_name_kr,
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
        ],
        [
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "영문",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: el.business_name_en,
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
        ],
        [
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "기관명",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: el.business_agency_name,
          },
          {
            type: "text",
            content: "전화번호",
          },
          {
            type: "text",
            content: el.business_telephone,
          },
        ],
        [
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "부서",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: el.business_department,
          },
          {
            type: "text",
            content: "휴대전화",
          },
          {
            type: "text",
            content: el.business_phone,
          },
        ],
        [
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "직위",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: el.business_position,
          },
          {
            type: "text",
            content: "전자우편",
          },
          {
            type: "text",
            content: el.business_email,
          },
        ],
        [
          {
            type: "text",
            content: "주소",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: el.business_address,
          },
          {
            type: "text",
            content: "=&병합&=",
          },
          {
            type: "text",
            content: "=&병합&=",
          },
        ],
      ];
      relatedAgencyArr = [...relatedAgencyArr].concat(data);
    });

    let currentStatusArr = [
      [
        {
          type: "text",
          content: "연구개발기관명\n(소유권자)",
        },
        {
          type: "text",
          content: "지식재산권명",
        },
        {
          type: "text",
          content: "국가명",
        },
        {
          type: "text",
          content: "출원ㆍ등록번호\n /출원ㆍ등록일",
        },
      ],
    ];

    currentStatus.map((el) => {
      let data = [
        {
          type: "text",
          content: el.agency_name,
        },
        {
          type: "text",
          content: el.property_name,
        },
        {
          type: "text",
          content: el.country_name,
        },
        {
          type: "text",
          content: el.apply_number,
        },
      ];
      currentStatusArr.push(data);
    });

    let executionPerformanceArr = [
      [
        {
          type: "text",
          content: "연구개발과제명",
        },
        {
          type: "text",
          content: "주관연구개발기관명",
        },
        {
          type: "text",
          content: "연구개발기간\n(참여기간)",
        },
        {
          type: "text",
          content: "수행내용",
        },
        {
          type: "text",
          content: "중앙행정기관\n(전문기관)",
        },
        {
          type: "text",
          content: "비고\n(수행중/완료)",
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "연구개발기관명 및 역할(주관/공동)",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ],
    ];

    executionPerformance.map((el) => {
      let data = [
        [
          {
            type: "text",
            content: el.name,
          },
          {
            type: "text",
            content: el.organization_agency,
          },
          {
            type: "text",
            content: `${el.total_duration}\n(${el.participation_period})`,
          },
          {
            type: "text",
            content: el.content,
          },
          {
            type: "text",
            content: el.agency,
          },
          {
            type: "text",
            content: el.etc,
          },
        ],
        [
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: el.affiliated_agency,
          },
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "||&병합&||",
          },
          {
            type: "text",
            content: "||&병합&||",
          },
        ],
      ];
      executionPerformanceArr.push(...data);
    });

    let techTransferArr = [
      [
        {
          type: "text",
          content: "연구개발기관명",
        },
        {
          type: "text",
          content: "기술이전 유형",
        },
        {
          type: "text",
          content: "기술실시계약명",
        },
        {
          type: "text",
          content: "기술실시기관명",
        },
        {
          type: "text",
          content: "기술실시발생일",
        },
        {
          type: "text",
          content: "기술료",
        },
        {
          type: "text",
          content: "기슐료 누적 징수액",
        },
      ],
    ];

    techTransfer.map((el) => {
      let data = [
        {
          type: "text",
          content: el.institution_name,
        },
        {
          type: "text",
          content: el.transfer_type,
        },
        {
          type: "text",
          content: el.contract_name,
        },
        {
          type: "text",
          content: el.agency_name,
        },
        {
          type: "text",
          content: el.tech_date,
        },
        {
          type: "text",
          content: el.tech_cost,
        },
        {
          type: "text",
          content: el.total_cost,
        },
      ];
      techTransferArr.push(data);
    });

    let commercialPerformanceArr = [
      [
        {
          type: "text",
          content: "연구개발기관명",
        },
        {
          type: "text",
          content: "사업화방식",
        },
        {
          type: "text",
          content: "사업화형태",
        },
        {
          type: "text",
          content: "지역",
        },
        {
          type: "text",
          content: "사업화명",
        },
        {
          type: "text",
          content: "내용",
        },
        {
          type: "text",
          content: "업체명",
        },
        {
          type: "text",
          content: "매출액",
        },
        {
          type: "text",
          content: "=&병합&=",
        },
        {
          type: "text",
          content: "매출발생연도",
        },
        {
          type: "text",
          content: "기술수명",
        },
      ],
      [
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "국내",
        },
        {
          type: "text",
          content: "국외",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
        {
          type: "text",
          content: "||&병합&||",
        },
      ],
    ];

    commercialPerformance.map((el) => {
      let data = [
        {
          type: "text",
          content: el.institution_name,
        },
        {
          type: "text",
          content: el.way,
        },
        {
          type: "text",
          content: el.form,
        },
        {
          type: "text",
          content: el.country,
        },
        {
          type: "text",
          content: el.name,
        },
        {
          type: "text",
          content: el.content,
        },
        {
          type: "text",
          content: el.company_name,
        },
        {
          type: "text",
          content: el.domestic_cost,
        },
        {
          type: "text",
          content: el.overseas_cost,
        },
        {
          type: "text",
          content: el.cost_year,
        },
        {
          type: "text",
          content: el.tech_lifespan,
        },
      ];
      commercialPerformanceArr.push(data);
    });

    let holdingStatusArr = [
      [
        {
          type: "text",
          content: "보유기관",
        },
        {
          type: "text",
          content: "연구시설ㆍ장비명",
        },
        {
          type: "text",
          content: "규격",
        },
        {
          type: "text",
          content: "수량",
        },
        {
          type: "text",
          content: "용도",
        },
        {
          type: "text",
          content: "활용시기",
        },
        {
          type: "text",
          content: '현물부담 반영여부 \n(해당 시 "○")',
        },
      ],
    ];

    holdingStatus.map((el) => {
      let data = [
        {
          type: "text",
          content: el.holding_agency,
        },
        {
          type: "text",
          content: el.equipment_name,
        },
        {
          type: "text",
          content: el.standard,
        },
        {
          type: "text",
          content: el.quantity,
        },
        {
          type: "text",
          content: el.purpose,
        },
        {
          type: "text",
          content: el.use,
        },
        {
          type: "text",
          content: el.reflection_status,
        },
      ];
      holdingStatusArr.push(data);
    });
    const content = [
      {
        subject: "연구개발기관 현황",
        type: "text",
        content: "1) 연구책임자 등 현황\n(1) 주관연구개발기관 연구책임자",
      },
      {
        subject: "주관연구개발기관 연구책임자 인적사항 제목",
        type: "text",
        content: "가. 인적사항",
      },
      {
        subject: "주관연구개발기관 연구책임자 인적사항 표",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "개인",
            },
            {
              type: "text",
              content: "국문",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[0],
            },
            {
              type: "text",
              content: "국적",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[1],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "영문",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[2],
            },
            {
              type: "text",
              content: "국가연구자번호",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[3],
            },
          ],
          [
            {
              type: "text",
              content: "직장",
            },
            {
              type: "text",
              content: "기관명",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[4],
            },
            {
              type: "text",
              content: "전화번호",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[5],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "부서",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[6],
            },
            {
              type: "text",
              content: "휴대전화",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[7],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "직위",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[8],
            },
            {
              type: "text",
              content: "전자우편",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[9],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "주소",
            },
            {
              type: "text",
              content: fourthPageData.supervisor_info[10],
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
          ],
        ],
      },
      {
        subject: "주관연구개발기관 연구책임자 학력 제목",
        type: "text",
        content:
          "나. 학력(연구개발과제 특성에 따라 선택적으로 적용이 가능합니다.)",
      },
      {
        subject: "주관연구개발기관 연구책임자 학력 표",
        type: "table",
        content: supervisorEduArr,
      },
      {
        subject: "주관연구개발기관 연구책임자 경력 제목",
        type: "text",
        content:
          "다. 경력(연구개발과제 특성에 따라 선택적으로 적용이 가능합니다.)",
      },
      {
        subject: "주관연구개발기관 연구책임자 경력 표",
        type: "table",
        content: supervisorCareerArr,
      },
      {
        subject: "주관연구개발기관 연구책임자 주요 연구개발 실적 제목",
        type: "text",
        content:
          "라. 주요 연구개발 실적(최근 5년간 5개 이내의 실적으로 작성하되, 신청 중이거나 수행중인 연구개발과제는 필수적으로 작성해야 합니다.)",
      },
      {
        subject: "주관연구개발기관 연구책임자 주요 연구개발 실적 표",
        type: "table",
        content: supervisorPerformanceArr,
      },
      {
        subject: "주관연구개발기관 연구책임자 대표적 논문 저서 실적 제목",
        type: "text",
        content:
          "마. 대표적 논문/저서 실적(최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "주관연구개발기관 연구책임자 대표적 논문 저서 실적 표",
        type: "table",
        content: supervisorThesisArr,
      },
      {
        subject: "주관연구개발기관 연구책임자 지식재산권 출원 등록 실적 제목",
        type: "text",
        content:
          "바. 지식재산권 출원ㆍ등록 실적(최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "주관연구개발기관 연구책임자 지식재산권 출원 등록 실적 표",
        type: "table",
        content: supervisorApplyArr,
      },
      {
        subject: "주관연구개발기관 연구책임자 그 밖의 대표적 실적 제목",
        type: "text",
        content:
          "사. 그 밖의 대표적 실적(최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "주관연구개발기관 연구책임자 그 밖의 대표적 실적 표",
        type: "table",
        content: supervisorAwardArr,
      },
      {
        subject: "공동연구개발기관 책임자 인적사항 제목",
        type: "text",
        content: "가. 인적사항",
      },
      {
        subject: "공동연구개발기관 책임자 인적사항 표",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "개인",
            },
            {
              type: "text",
              content: "국문",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[0],
            },
            {
              type: "text",
              content: "국적",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[1],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "영문",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[2],
            },
            {
              type: "text",
              content: "국가연구자번호",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[3],
            },
          ],
          [
            {
              type: "text",
              content: "직장",
            },
            {
              type: "text",
              content: "기관명",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[4],
            },
            {
              type: "text",
              content: "전화번호",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[5],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "부서",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[6],
            },
            {
              type: "text",
              content: "휴대전화",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[7],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "직위",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[8],
            },
            {
              type: "text",
              content: "전자우편",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[9],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "주소",
            },
            {
              type: "text",
              content: fourthPageData.joint_info[10],
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
          ],
        ],
      },
      {
        subject: "공동연구개발기관 책임자 학력 제목",
        type: "text",
        content:
          "나. 학력(연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "공동연구개발기관 책임자 학력 표",
        type: "table",
        content: jointEduArr,
      },
      {
        subject: "공동연구개발기관 책임자 경력 제목",
        type: "text",
        content:
          "다. 경력(연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "공동연구개발기관 책임자 경력 표",
        type: "table",
        content: jointCareerArr,
      },
      {
        subject: "공동연구개발기관 책임자 주요 연구개발 실적 제목",
        type: "text",
        content:
          "라. 주요 연구개발 실적(최근 5년간 5개 이내의 실적으로 작성하되, 신청 중이거나 수행 중인 연구개발과제는 필수적으로 작성해야 합니다)",
      },
      {
        subject: "공동연구개발기관 책임자 주요 연구개발 실적 표",
        type: "table",
        content: jointPerformanceArr,
      },
      {
        subject: "공동연구개발기관 책임자 대표적 논문 저서 실적 제목",
        type: "text",
        content:
          "마. 대표적 논문/저서 실적(최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "공동연구개발기관 책임자 대표적 논문 저서 실적 표",
        type: "table",
        content: jointThesisArr,
      },
      {
        subject: "공동연구개발기관 책임자 지식재산권 출원 등록 실적 제목",
        type: "text",
        content:
          "바. 지식재산권 출원ㆍ등록 실적(최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "공동연구개발기관 책임자 지식재산권 출원 등록 실적 표",
        type: "table",
        content: jointApplyArr,
      },
      {
        subject: "공동연구개발기관 책임자 그 밖의 대표적 실적 제목",
        type: "text",
        content:
          "사. 그 밖의 대표적 실적(최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "공동연구개발기관 책임자 그 밖의 대표적 실적 표",
        type: "table",
        content: jointAwardArr,
      },
      {
        subject: "위탁연구개발기관 책임자 인적사항 제목",
        type: "text",
        content: "가. 인적사항",
      },
      {
        subject: "위탁연구개발기관 책임자 인적사항 표",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "개인",
            },
            {
              type: "text",
              content: "국문",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[0],
            },
            {
              type: "text",
              content: "국적",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[1],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "영문",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[2],
            },
            {
              type: "text",
              content: "국가연구자번호",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[3],
            },
          ],
          [
            {
              type: "text",
              content: "직장",
            },
            {
              type: "text",
              content: "기관명",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[4],
            },
            {
              type: "text",
              content: "전화번호",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[5],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "부서",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[6],
            },
            {
              type: "text",
              content: "휴대전화",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[7],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "직위",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[8],
            },
            {
              type: "text",
              content: "전자우편",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[9],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "주소",
            },
            {
              type: "text",
              content: fourthPageData.commission_info[10],
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
          ],
        ],
      },
      {
        subject: "위탁연구개발기관 책임자 학력 제목",
        type: "text",
        content:
          "나. 학력(연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "위탁연구개발기관 책임자 학력 표",
        type: "table",
        content: commissionEduArr,
      },
      {
        subject: "위탁연구개발기관 책임자 경력 제목",
        type: "text",
        content:
          "다. 경력(연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "위탁연구개발기관 책임자 경력 표",
        type: "table",
        content: commissionCareerArr,
      },
      {
        subject: "위탁연구개발기관 책임자 주요 연구개발 실적 제목",
        type: "text",
        content:
          "라. 주요 연구개발 실적(최근 5년간 5개 이내의 실적으로 작성하되, 신청 중이거나 수행 중인 연구개발과제는 필수적으로 작성해야 합니다)",
      },
      {
        subject: "위탁연구개발기관 책임자 주요 연구개발 실적 표",
        type: "table",
        content: commissionPerformanceArr,
      },
      {
        subject: "위탁연구개발기관 책임자 대표적 논문 저서 실적 제목",
        type: "text",
        content:
          "마. 대표적 논문/저서 실적(최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "위탁연구개발기관 책임자 대표적 논문 저서 실적 표",
        type: "table",
        content: commissionThesisArr,
      },
      {
        subject: "위탁연구개발기관 책임자 지식재산권 출원 등록 실적 제목",
        type: "text",
        content:
          "바. 지식재산권 출원ㆍ등록 실적(최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "위탁연구개발기관 책임자 지식재산권 출원 등록 실적 표",
        type: "table",
        content: commissionApplyArr,
      },
      {
        subject: "위탁연구개발기관 책임자 그 밖의 대표적 실적 제목",
        type: "text",
        content:
          "사. 그 밖의 대표적 실적(최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에 따라 선택적으로 작성이 가능합니다)",
      },
      {
        subject: "위탁연구개발기관 책임자 그 밖의 대표적 실적 표",
        type: "table",
        content: commissionAwardArr,
      },
      {
        subject: "참여연구자 현황 제목",
        type: "text",
        content: "가. 참여연구자 현황",
      },
      {
        subject: "참여연구자 현황 표",
        type: "table",
        content: researcherArr,
      },
      {
        subject: "연구지원인력 현황 제목",
        type: "text",
        content:
          "나. 연구지원인력 현황(직접비에서 인건비를 지급하는 경우에만 작성합니다.)",
      },
      {
        subject: "연구지원인력 현황 표",
        type: "table",
        content: researchSupportArr,
      },
      {
        subject: "연구개발기관이 아닌 관계 기관",
        type: "table",
        content: relatedAgencyArr,
      },
      {
        subject: "연구개발과제와 연관된 지식재산권 출원 및 등록 현황 제목",
        type: "text",
        content:
          "(1) 연구개발과제와 연관된 지식재산권 출원 및 등록 현황(최근 5년간의 실적을 기재합니다)",
      },
      {
        subject: "연구개발과제와 연관된 지식재산권 출원 및 등록 현황 표",
        type: "table",
        content: currentStatusArr,
      },
      {
        subject: "국가연구개발사업 주요 수행 실적 제목",
        type: "text",
        content:
          "(2) 국가연구개발사업 주요 수행 실적(최근 5년간의 실적*을 기재합니다)",
      },
      {
        subject: "국가연구개발사업 주요 수행 실적 표",
        type: "table",
        content: executionPerformanceArr,
      },
      {
        subject: "국가연구개발사업 기술이전 실적 제목",
        type: "text",
        content:
          "(3) 국가연구개발사업 기술이전 실적(최근 5년간의 실적을 기재합니다)",
      },
      {
        subject: "국가연구개발사업 기술이전 실적 표",
        type: "table",
        content: techTransferArr,
      },
      {
        subject: "국가연구개발사업 사업화 실적 제목",
        type: "text",
        content:
          "(4) 국가연구개발사업 사업화 실적(최근 5년간의 실적을 기재합니다)",
      },
      {
        subject: "국가연구개발사업 사업화 실적 표",
        type: "table",
        content: commercialPerformanceArr,
      },
      {
        subject: "연구시설 장비 보유현황 제목",
        type: "text",
        content: "3) 연구시설ㆍ장비 보유현황(해당 시 작성합니다)",
      },
      {
        subject: "연구시설 장비 보유현황 표",
        type: "table",
        content: holdingStatusArr,
      },
      {
        subject: "연구개발기관 일반 현황 제목",
        type: "text",
        content:
          "4) 연구개발기관 일반 현황(기업정보 데이터베이스와 연계가 가능합니다)\n ※ 비영리기관의 경우 순번 5부터 순번 15까지의 사항은 생략할 수 있습니다.",
      },
      {
        subject: "연구개발기관 일반 현황 표",
        type: "table",
        content: [
          [
            {
              type: "text",
              content: "순번",
            },
            {
              type: "text",
              content: "구분",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "기관명",
            },
            {
              type: "text",
              content: fourthPageData.agency_name[0],
            },
            {
              type: "text",
              content: fourthPageData.agency_name[1],
            },
            {
              type: "text",
              content: fourthPageData.agency_name[2],
            },
          ],
          [
            {
              type: "text",
              content: "1",
            },
            {
              type: "text",
              content: "사업자등록번호",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.business_number[0],
            },
            {
              type: "text",
              content: fourthPageData.business_number[1],
            },
            {
              type: "text",
              content: fourthPageData.business_number[2],
            },
          ],
          [
            {
              type: "text",
              content: "2",
            },
            {
              type: "text",
              content: "법인등록번호",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.corporate_number[0],
            },
            {
              type: "text",
              content: fourthPageData.corporate_number[1],
            },
            {
              type: "text",
              content: fourthPageData.corporate_number[2],
            },
          ],
          [
            {
              type: "text",
              content: "3",
            },
            {
              type: "text",
              content: "대표자 성명/국적",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.representative[0],
            },
            {
              type: "text",
              content: fourthPageData.representative[1],
            },
            {
              type: "text",
              content: fourthPageData.representative[2],
            },
          ],
          [
            {
              type: "text",
              content: "4",
            },
            {
              type: "text",
              content: "기관유형\n(대학, 정부출연연, 중소기업 등)",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.agency_type[0],
            },
            {
              type: "text",
              content: fourthPageData.agency_type[1],
            },
            {
              type: "text",
              content: fourthPageData.agency_type[2],
            },
          ],
          [
            {
              type: "text",
              content: "5",
            },
            {
              type: "text",
              content: "최대 주주 성명/국적",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.stockholder[0],
            },
            {
              type: "text",
              content: fourthPageData.stockholder[1],
            },
            {
              type: "text",
              content: fourthPageData.stockholder[2],
            },
          ],
          [
            {
              type: "text",
              content: "6",
            },
            {
              type: "text",
              content: "설립 연월일",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.founded[0],
            },
            {
              type: "text",
              content: fourthPageData.founded[1],
            },
            {
              type: "text",
              content: fourthPageData.founded[2],
            },
          ],
          [
            {
              type: "text",
              content: "7",
            },
            {
              type: "text",
              content: "주생산 품목",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.main_item[0],
            },
            {
              type: "text",
              content: fourthPageData.main_item[1],
            },
            {
              type: "text",
              content: fourthPageData.main_item[2],
            },
          ],
          [
            {
              type: "text",
              content: "8",
            },
            {
              type: "text",
              content: "상시 종업원 수",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.employees[0],
            },
            {
              type: "text",
              content: fourthPageData.employees[1],
            },
            {
              type: "text",
              content: fourthPageData.employees[2],
            },
          ],
          [
            {
              type: "text",
              content: "9",
            },
            {
              type: "text",
              content: "전년도 매출액",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.sales[0],
            },
            {
              type: "text",
              content: fourthPageData.sales[1],
            },
            {
              type: "text",
              content: fourthPageData.sales[2],
            },
          ],
          [
            {
              type: "text",
              content: "10",
            },
            {
              type: "text",
              content: "매출액 대비 연구개발비 비율",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: fourthPageData.rnd_per_sales[0],
            },
            {
              type: "text",
              content: fourthPageData.rnd_per_sales[1],
            },
            {
              type: "text",
              content: fourthPageData.rnd_per_sales[2],
            },
          ],
          [
            {
              type: "text",
              content: "11",
            },
            {
              type: "text",
              content: "부채 비율\n(최근 3년 간 결산 기준)",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: year - 3,
            },
            {
              type: "text",
              content: fourthPageData.debt_ratio[0][0],
            },
            {
              type: "text",
              content: fourthPageData.debt_ratio[0][1],
            },
            {
              type: "text",
              content: fourthPageData.debt_ratio[0][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 2,
            },
            {
              type: "text",
              content: fourthPageData.debt_ratio[1][0],
            },
            {
              type: "text",
              content: fourthPageData.debt_ratio[1][1],
            },
            {
              type: "text",
              content: fourthPageData.debt_ratio[1][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 1,
            },
            {
              type: "text",
              content: fourthPageData.debt_ratio[2][0],
            },
            {
              type: "text",
              content: fourthPageData.debt_ratio[2][1],
            },
            {
              type: "text",
              content: fourthPageData.debt_ratio[2][2],
            },
          ],
          [
            {
              type: "text",
              content: "12",
            },
            {
              type: "text",
              content: "유동 비율\n(최근 3년 간 결산 기준)",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: year - 3,
            },
            {
              type: "text",
              content: fourthPageData.current_rate[0][0],
            },
            {
              type: "text",
              content: fourthPageData.current_rate[0][1],
            },
            {
              type: "text",
              content: fourthPageData.current_rate[0][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 2,
            },
            {
              type: "text",
              content: fourthPageData.current_rate[1][0],
            },
            {
              type: "text",
              content: fourthPageData.current_rate[1][1],
            },
            {
              type: "text",
              content: fourthPageData.current_rate[1][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 1,
            },
            {
              type: "text",
              content: fourthPageData.current_rate[2][0],
            },
            {
              type: "text",
              content: fourthPageData.current_rate[2][1],
            },
            {
              type: "text",
              content: fourthPageData.current_rate[2][2],
            },
          ],
          [
            {
              type: "text",
              content: "13",
            },
            {
              type: "text",
              content: "자본잠식 현황\n(최근 3년 간 결산 기준)",
            },
            {
              type: "text",
              content: "자본총계",
            },
            {
              type: "text",
              content: year - 3,
            },
            {
              type: "text",
              content: fourthPageData.total_capital[0][0],
            },
            {
              type: "text",
              content: fourthPageData.total_capital[0][1],
            },
            {
              type: "text",
              content: fourthPageData.total_capital[0][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 2,
            },
            {
              type: "text",
              content: fourthPageData.total_capital[1][0],
            },
            {
              type: "text",
              content: fourthPageData.total_capital[1][1],
            },
            {
              type: "text",
              content: fourthPageData.total_capital[1][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 1,
            },
            {
              type: "text",
              content: fourthPageData.total_capital[2][0],
            },
            {
              type: "text",
              content: fourthPageData.total_capital[2][1],
            },
            {
              type: "text",
              content: fourthPageData.total_capital[2][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "자본금",
            },
            {
              type: "text",
              content: year - 3,
            },
            {
              type: "text",
              content: fourthPageData.capital[0][0],
            },
            {
              type: "text",
              content: fourthPageData.capital[0][1],
            },
            {
              type: "text",
              content: fourthPageData.capital[0][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 2,
            },
            {
              type: "text",
              content: fourthPageData.capital[1][0],
            },
            {
              type: "text",
              content: fourthPageData.capital[1][1],
            },
            {
              type: "text",
              content: fourthPageData.capital[1][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 1,
            },
            {
              type: "text",
              content: fourthPageData.capital[2][0],
            },
            {
              type: "text",
              content: fourthPageData.capital[2][1],
            },
            {
              type: "text",
              content: fourthPageData.capital[2][2],
            },
          ],
          [
            {
              type: "text",
              content: "14",
            },
            {
              type: "text",
              content: "이자 보상 비율\n(최근 3년 간 결산 기준)",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: year - 3,
            },
            {
              type: "text",
              content: fourthPageData.operating_profit[0][0],
            },
            {
              type: "text",
              content: fourthPageData.operating_profit[0][1],
            },
            {
              type: "text",
              content: fourthPageData.operating_profit[0][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 2,
            },
            {
              type: "text",
              content: fourthPageData.operating_profit[1][0],
            },
            {
              type: "text",
              content: fourthPageData.operating_profit[1][1],
            },
            {
              type: "text",
              content: fourthPageData.operating_profit[1][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 1,
            },
            {
              type: "text",
              content: fourthPageData.operating_profit[2][0],
            },
            {
              type: "text",
              content: fourthPageData.operating_profit[2][1],
            },
            {
              type: "text",
              content: fourthPageData.operating_profit[2][2],
            },
          ],
          [
            {
              type: "text",
              content: "15",
            },
            {
              type: "text",
              content: "영업 이익\n(최근 3년 간 결산 기준)",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: year - 3,
            },
            {
              type: "text",
              content: fourthPageData.compensation_ratio[0][0],
            },
            {
              type: "text",
              content: fourthPageData.compensation_ratio[0][1],
            },
            {
              type: "text",
              content: fourthPageData.compensation_ratio[0][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 2,
            },
            {
              type: "text",
              content: fourthPageData.compensation_ratio[1][0],
            },
            {
              type: "text",
              content: fourthPageData.compensation_ratio[1][1],
            },
            {
              type: "text",
              content: fourthPageData.compensation_ratio[1][2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: year - 1,
            },
            {
              type: "text",
              content: fourthPageData.compensation_ratio[2][0],
            },
            {
              type: "text",
              content: fourthPageData.compensation_ratio[2][1],
            },
            {
              type: "text",
              content: fourthPageData.compensation_ratio[2][2],
            },
          ],
          [
            {
              type: "text",
              content: "16",
            },
            {
              type: "text",
              content:
                "연구개발기관의\n연구개발과제 지원 담당자\n\n(※ 대학의 경우 산학협력단의\n연구개발과제 지원 담당을 말하며,\n표지의 &quot;실무담당자&quot;와 다름)",
            },
            {
              type: "text",
              content: "=&병합&=",
            },
            {
              type: "text",
              content: "성명",
            },
            {
              type: "text",
              content: fourthPageData.support_name[0],
            },
            {
              type: "text",
              content: fourthPageData.support_name[1],
            },
            {
              type: "text",
              content: fourthPageData.support_name[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "부서",
            },
            {
              type: "text",
              content: fourthPageData.support_department[0],
            },
            {
              type: "text",
              content: fourthPageData.support_department[1],
            },
            {
              type: "text",
              content: fourthPageData.support_department[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "직위",
            },
            {
              type: "text",
              content: fourthPageData.support_position[0],
            },
            {
              type: "text",
              content: fourthPageData.support_position[1],
            },
            {
              type: "text",
              content: fourthPageData.support_position[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "직장전화",
            },
            {
              type: "text",
              content: fourthPageData.support_telephone[0],
            },
            {
              type: "text",
              content: fourthPageData.support_telephone[1],
            },
            {
              type: "text",
              content: fourthPageData.support_telephone[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "휴대전화",
            },
            {
              type: "text",
              content: fourthPageData.support_phone[0],
            },
            {
              type: "text",
              content: fourthPageData.support_phone[1],
            },
            {
              type: "text",
              content: fourthPageData.support_phone[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "전자우편",
            },
            {
              type: "text",
              content: fourthPageData.support_email[0],
            },
            {
              type: "text",
              content: fourthPageData.support_email[1],
            },
            {
              type: "text",
              content: fourthPageData.support_email[2],
            },
          ],
          [
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "||&병합&||",
            },
            {
              type: "text",
              content: "팩스",
            },
            {
              type: "text",
              content: fourthPageData.support_fax[0],
            },
            {
              type: "text",
              content: fourthPageData.support_fax[1],
            },
            {
              type: "text",
              content: fourthPageData.support_fax[2],
            },
          ],
        ],
      },
    ];
    sendDataToAPI(content);
  };

  const sendDataToAPI = (contentData) => {
    responseData.content = _.uniqBy(responseData.content, "subject");
    responseData.content.forEach((parentItem, index) => {
      contentData.forEach((childItem) => {
        if (parentItem.subject === childItem.subject) {
          responseData.content[index].content = childItem.content;
          setResponseData({ ...responseData });
        }
      });
    });

    if (window.sessionStorage.getItem("itemIdx") !== null) {
      const payload = {
        title: "R&D 사업계획서",
        content: [...responseData.content],
      };
      CommonAPI.updateBusinessData(
        window.sessionStorage.getItem("itemIdx"),
        payload
      ).then(() => history.push("/plan/rnd/list/fifth"));
    } else {
      const payload = {
        title: "R&D 사업계획서",
        category1_idx: 1,
        category2_idx: 11,
        content: [contentData],
      };
      CommonAPI.addBusinessData(payload).then(() =>
        history.push("/plan/rnd/list/first")
      );
    }
  };

  return (
    <div className="wrapper">
      <SideBar />
      <div className="rnd_wrapper fourth">
        <RnDText index={4} />
        <div className="rnd_plan">
          <div className="plan_text">
            <div>
              <h2>Step. 1 연구개발기관 현황</h2>
              <FirstInfoText />
            </div>
            <div>
              <span>*연구개발과제 협약 시 제출하는 계획입니다</span>
            </div>
          </div>
          <div className="sub_title_wrapper">
            <p className="sub_title">1) 연구책임자 등 현황</p>
            <FirstInfoText />
          </div>
          <div className="blue_bg_text">
            <p>(1) 주관연구개발기관 연구책임자</p>
          </div>
          <div className="plan_text">
            <p className="small_text">가. 인적사항</p>
            <BringingUpIcon />
          </div>
          <div className="personal_info_wrapper">
            <p className="text_letter_spacing blue_color_text">개인</p>
            <div className="plan_grid_items">
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">국문</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[0]}
                  onChange={(e) => handleChange(e, 0)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">국적</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[1]}
                  onChange={(e) => handleChange(e, 1)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">영문</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[2]}
                  onChange={(e) => handleChange(e, 2)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">
                  <span>국&nbsp; &nbsp; &nbsp; &nbsp;가</span>
                  <br />
                  연구자번호
                </p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[3]}
                  onChange={(e) => handleChange(e, 3)}
                />
              </div>
            </div>
          </div>
          <div className="company_info_wrapper">
            <p className="text_letter_spacing blue_color_text">직장</p>
            <div className="plan_grid_items">
              <div className="plan_grid_item">
                <p className="small_text">기 관 명</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[4]}
                  onChange={(e) => handleChange(e, 4)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">전화번호</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[5]}
                  onChange={(e) => handleChange(e, 5)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">부서</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[6]}
                  onChange={(e) => handleChange(e, 6)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">휴대전화</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[7]}
                  onChange={(e) => handleChange(e, 7)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">직위</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[8]}
                  onChange={(e) => handleChange(e, 8)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">전자우편</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[9]}
                  onChange={(e) => handleChange(e, 9)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">주소</p>
                <input
                  type="text"
                  placeholder="(우:   )"
                  name="supervisor_info"
                  value={fourthPageData.supervisor_info[10]}
                  onChange={(e) => handleChange(e, 10)}
                />
              </div>
            </div>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">나. 학력</p>
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                연구개발과제 특성에 따라 선택적으로 적용이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="supervisorEducation"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="academic_background_wrapper">
            <table className="academic_background_table">
              <colgroup>
                <col width={125} />
                <col width={20} />
                <col width={125} />
                <col width={180} />
                <col width={135} />
                <col width={135} />
                <col width={135} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={3}>
                    취득연월
                    <br />
                    <span className="extra_text">(YY.MM~YY.MM)</span>
                  </th>
                  <th>학 교 명</th>
                  <th>전 공</th>
                  <th>학 위</th>
                  <th>지도교수</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {supervisorEducation.map((data, index) => {
                  const {
                    school_name,
                    major,
                    academic_degree,
                    professor,
                    startDate,
                    endDate,
                  } = data;
                  return (
                    <tr key={index}>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2020.6"
                          dateFormat="yyyy.MM"
                          selected={startDate || ""}
                          value={startDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "supervisorEducation", 0)
                          }
                        />
                      </td>
                      <td>~</td>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2022.6"
                          dateFormat="yyyy.MM"
                          selected={endDate || ""}
                          value={endDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "supervisorEducation", 1)
                          }
                        />
                      </td>
                      <td className="school_name">
                        <input
                          type="text"
                          name="supervisorEducation"
                          value={school_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "school_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="supervisorEducation"
                          value={major}
                          onChange={(e) => handleChangeTable(e, index, "major")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="supervisorEducation"
                          value={academic_degree}
                          onChange={(e) =>
                            handleChangeTable(e, index, "academic_degree")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="supervisorEducation"
                          value={professor}
                          onChange={(e) =>
                            handleChangeTable(e, index, "professor")
                          }
                        />
                      </td>
                      <td>
                        <button
                          className="icon_del_trashcan"
                          name="supervisorEducation"
                          onClick={(e) => deleteTableRows(e, index)}
                        />
                      </td>
                    </tr>
                  );
                })}
                <tr className="final_degree_thesis_name">
                  <td colSpan={2}>
                    최종학위 논문명
                    <span className="extra_text">(해당 시 작성)</span>
                  </td>
                  <td colSpan={5}>
                    <input
                      type="text"
                      name="name_thesis"
                      value={fourthPageData.name_thesis[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">다. 경력</p>
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                연구개발과제 특성에 따라 선택적으로 적용이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="supervisorCareer"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="career_wrapper">
            <table className="career_table">
              <colgroup>
                <col width={125} />
                <col width={20} />
                <col width={125} />
                <col width={197} />
                <col width={197} />
                <col width={197} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={3}>
                    기간
                    <br />
                    <span className="extra_text">(YY.MM~YY.MM)</span>
                  </th>
                  <th>기관명</th>
                  <th>직위</th>
                  <th>비고</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {supervisorCareer.map((data, index) => {
                  const {
                    organization_name,
                    position,
                    note,
                    startDate,
                    endDate,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2020.6"
                          dateFormat="yyyy.MM"
                          selected={startDate || ""}
                          value={startDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "supervisorCareer", 0)
                          }
                        />
                      </td>
                      <td>~</td>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2022.6"
                          dateFormat="yyyy.MM"
                          selected={endDate || ""}
                          value={endDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "supervisorCareer", 1)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="supervisorCareer"
                          value={organization_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "organization_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="supervisorCareer"
                          value={position}
                          onChange={(e) =>
                            handleChangeTable(e, index, "position")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="supervisorCareer"
                          value={note}
                          onChange={(e) => handleChangeTable(e, index, "note")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">라. 주요 연구개발 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 신청 중이거나 수행 중인
                연구개발과제는 필수적으로 작성해야 합니다.
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="supervisorPerformance"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="performance_wrapper">
            <table className="performance_table">
              <colgroup>
                <col width={80} />
                <col width={125} />
                <col width={225} />
                <col width={125} />
                <col width={150} />
                <col width={80} />
                <col width={96} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2}>
                    중앙행정기관
                    <br />
                    <span className="extra_text">(전문기관)</span>
                  </th>
                  <th rowSpan={2}>세부사업</th>
                  <th rowSpan={2}>연구개발과제명</th>
                  <th style={{ backgroundColor: "#d8d8d8" }}>
                    주관연구 개발기관
                  </th>
                  <th>전체 연구개발기간</th>
                  <th rowSpan={2}>
                    역 할<br />
                    <span className="extra_text">책임자/연구자</span>
                  </th>
                  <th rowSpan={2}>
                    비 고<br />
                    <span className="extra_text">(신청/수행중/완료)</span>
                  </th>
                  <th rowSpan={2}>삭제</th>
                </tr>
                <tr>
                  <th>당시 소속기관</th>
                  <th style={{ backgroundColor: "#d8d8d8" }}>참여한 기간</th>
                </tr>
              </thead>
              <tbody>
                {supervisorPerformance.map((data, index) => {
                  const {
                    agency,
                    detail_project,
                    name,
                    organization_agency,
                    affiliated_agency,
                    total_duration,
                    participation_period,
                    role,
                    etc,
                  } = data;
                  return (
                    <>
                      <tr>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "80px" }}
                            name="supervisorPerformance"
                            value={agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "agency")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "125px" }}
                            placeholder="입력하세요"
                            name="supervisorPerformance"
                            value={detail_project}
                            onChange={(e) =>
                              handleChangeTable(e, index, "detail_project")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "225px" }}
                            placeholder="입력하세요"
                            name="supervisorPerformance"
                            value={name}
                            onChange={(e) =>
                              handleChangeTable(e, index, "name")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            style={{ width: "125px" }}
                            placeholder="입력하세요"
                            name="supervisorPerformance"
                            value={organization_agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "organization_agency")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="yy.mm.dd~yy.mm.dd"
                            name="supervisorPerformance"
                            value={total_duration}
                            onChange={(e) =>
                              handleChangeTable(e, index, "total_duration")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "80px" }}
                            placeholder="입력하세요"
                            name="supervisorPerformance"
                            value={role}
                            onChange={(e) =>
                              handleChangeTable(e, index, "role")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "96px" }}
                            placeholder="입력하세요"
                            name="supervisorPerformance"
                            value={etc}
                            onChange={(e) => handleChangeTable(e, index, "etc")}
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <button className="icon_del_trashcan" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            style={{ width: "125px" }}
                            placeholder="입력하세요"
                            name="supervisorPerformance"
                            value={affiliated_agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "affiliated_agency")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="yy.mm.dd~yy.mm.dd"
                            name="supervisorPerformance"
                            value={participation_period}
                            onChange={(e) =>
                              handleChangeTable(
                                e,
                                index,
                                "participation_period"
                              )
                            }
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">마. 대표적 논문/저서 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에
                따라 선택적으로 작성이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="supervisorThesis"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="result_ones_thesis">
            <table className="result_ones_thesis_table">
              <colgroup>
                <col width={80} />
                <col width={225} />
                <col width={45} />
                <col width={125} />
                <col width={125} />
                <col width={125} />
                <col width={140} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    구 분
                    <br />
                    <span className="extra_text">(논문/저서)</span>
                  </th>
                  <th>논문명/저서명</th>
                  <th>
                    게제지
                    <br />
                    <span className="extra_text">(권,쪽)</span>
                  </th>
                  <th>
                    게재연도
                    <br />
                    <span className="extra_text">(발표연도)</span>
                  </th>
                  <th>역 할</th>
                  <th>
                    등록번호
                    <br />
                    <span className="extra_text">(ISSN)</span>
                  </th>
                  <th>
                    비 고<br />
                    <span className="extra_text">(피인용 지수)</span>
                  </th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {supervisorThesis.map((data, index) => {
                  const {
                    sortation,
                    thesis_name,
                    paper,
                    year,
                    role,
                    apply_number,
                    etc,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="supervisorThesis"
                          value={sortation}
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="2"
                          name="supervisorThesis"
                          value={thesis_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "thesis_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="supervisorThesis"
                          value={paper}
                          onChange={(e) => handleChangeTable(e, index, "paper")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="~"
                          name="supervisorThesis"
                          value={year}
                          onChange={(e) => handleChangeTable(e, index, "year")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="supervisorThesis"
                          value={role}
                          onChange={(e) => handleChangeTable(e, index, "role")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="supervisorThesis"
                          value={apply_number}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_number")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="2. 예)설계도면 작성"
                          name="supervisorThesis"
                          value={etc}
                          onChange={(e) => handleChangeTable(e, index, "etc")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text result_ones_thesis_text">
            <div>
              <p className="small_text">바. 지식재산권 출원ㆍ등록 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에
                따라 선택적으로 작성이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="supervisorApply"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="result_ones_thesis">
            <table className="result_ones_thesis_table">
              <colgroup>
                <col width={80} />
                <col width={225} />
                <col width={45} />
                <col width={125} />
                <col width={255} />
                <col width={140} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    구 분
                    <br />
                    <span className="extra_text">특허/프로그램 등</span>
                  </th>
                  <th>지식재산권명</th>
                  <th>국가명</th>
                  <th>출원ㆍ등록일</th>
                  <th>출원ㆍ등록번호/ 출원ㆍ등록자 수</th>
                  <th>
                    비 고<br />
                    <span className="extra_text">(출원/등록)</span>
                  </th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {supervisorApply.map((data, index) => {
                  const {
                    sortation,
                    name,
                    country_name,
                    apply_date,
                    apply_number,
                    etc,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="supervisorApply"
                          value={sortation}
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="supervisorApply"
                          value={name}
                          onChange={(e) => handleChangeTable(e, index, "name")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="supervisorApply"
                          value={country_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "country_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="supervisorApply"
                          value={apply_date}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_date")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="supervisorApply"
                          value={apply_number}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_number")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="supervisorApply"
                          value={etc}
                          onChange={(e) => handleChangeTable(e, index, "etc")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text result_ones_thesis_text">
            <div>
              <p className="small_text">사. 그 밖의 수상경력 등 대표적 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에
                따라 선택적으로 작성이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="supervisorAwards"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="result_ones_thesis">
            <table className="result_ones_thesis_table">
              <colgroup>
                <col width={220} />
                <col width={220} />
                <col width={220} />
                <col width={220} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th>구 분</th>
                  <th>실적명</th>
                  <th>내용요약</th>
                  <th>실적연도</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {supervisorAwards.map((data, index) => {
                  const { sortation, award_name, summary, award_year } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          placeholder="산업통상자원부"
                          name="supervisorAwards"
                          value={sortation}
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요."
                          name="supervisorAwards"
                          value={award_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "award_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요."
                          name="supervisorAwards"
                          value={summary}
                          onChange={(e) =>
                            handleChangeTable(e, index, "summary")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="yyyy"
                          name="supervisorAwards"
                          value={award_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "award_year")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="blue_bg_text">
            <p>(2) 공동연구개발기관 책임자</p>
            <div>
              <p>※ 해당 시 작성합니다</p>
              <i className="icon_more_arrow_right_black" />
            </div>
          </div>
          <div className="plan_text">
            <p className="small_text">가. 인적사항</p>
            <BringingUpIcon />
          </div>
          <div className="personal_info_wrapper">
            <p className="text_letter_spacing blue_color_text">개인</p>
            <div className="plan_grid_items">
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">국문</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[0]}
                  onChange={(e) => handleChange(e, 0)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">국적</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[1]}
                  onChange={(e) => handleChange(e, 1)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">영문</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[2]}
                  onChange={(e) => handleChange(e, 2)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">
                  <span>국&nbsp; &nbsp; &nbsp; &nbsp;가</span>
                  <br />
                  연구자번호
                </p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[3]}
                  onChange={(e) => handleChange(e, 3)}
                />
              </div>
            </div>
          </div>
          <div className="company_info_wrapper">
            <p className="text_letter_spacing blue_color_text">직장</p>
            <div className="plan_grid_items">
              <div className="plan_grid_item">
                <p className="small_text">기 관 명</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[4]}
                  onChange={(e) => handleChange(e, 4)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">전화번호</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[5]}
                  onChange={(e) => handleChange(e, 5)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">부서</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[6]}
                  onChange={(e) => handleChange(e, 6)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">휴대전화</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[7]}
                  onChange={(e) => handleChange(e, 7)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">직위</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[8]}
                  onChange={(e) => handleChange(e, 8)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">전자우편</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="joint_info"
                  value={fourthPageData.joint_info[9]}
                  onChange={(e) => handleChange(e, 9)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">주소</p>
                <input
                  type="text"
                  placeholder="(우:   )"
                  name="joint_info"
                  value={fourthPageData.joint_info[10]}
                  onChange={(e) => handleChange(e, 10)}
                />
              </div>
            </div>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">나. 학력</p>
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                연구개발과제 특성에 따라 선택적으로 적용이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="jointEducation"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="academic_background_wrapper">
            <table className="academic_background_table">
              <colgroup>
                <col width={125} />
                <col width={20} />
                <col width={125} />
                <col width={180} />
                <col width={135} />
                <col width={135} />
                <col width={135} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={3}>
                    취득연월
                    <br />
                    <span className="extra_text">(YY.MM~YY.MM)</span>
                  </th>
                  <th>학 교 명</th>
                  <th>전 공</th>
                  <th>학 위</th>
                  <th>지도교수</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {jointEducation.map((data, index) => {
                  const {
                    school_name,
                    major,
                    academic_degree,
                    professor,
                    startDate,
                    endDate,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2020.6"
                          dateFormat="yyyy.MM"
                          selected={startDate || ""}
                          value={startDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "jointEducation", 0)
                          }
                        />
                      </td>
                      <td>~</td>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2022.6"
                          dateFormat="yyyy.MM"
                          selected={endDate || ""}
                          value={endDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "jointEducation", 1)
                          }
                        />
                      </td>
                      <td className="school_name">
                        <input
                          type="text"
                          name="jointEducation"
                          value={school_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "school_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="jointEducation"
                          value={major}
                          onChange={(e) => handleChangeTable(e, index, "major")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="jointEducation"
                          value={academic_degree}
                          onChange={(e) =>
                            handleChangeTable(e, index, "academic_degree")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="jointEducation"
                          value={professor}
                          onChange={(e) =>
                            handleChangeTable(e, index, "professor")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
                <tr className="final_degree_thesis_name">
                  <td colSpan={2}>
                    최종학위 논문명
                    <span className="extra_text">(해당 시 작성)</span>
                  </td>
                  <td colSpan={5}>
                    <input
                      type="text"
                      name="name_thesis"
                      value={fourthPageData.name_thesis[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">다. 경력</p>
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                연구개발과제 특성에 따라 선택적으로 적용이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="jointCareer"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="career_wrapper">
            <table className="career_table">
              <colgroup>
                <col width={125} />
                <col width={20} />
                <col width={125} />
                <col width={197} />
                <col width={197} />
                <col width={197} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={3}>
                    기간
                    <br />
                    <span className="extra_text">(YY.MM~YY.MM)</span>
                  </th>
                  <th>기관명</th>
                  <th>직위</th>
                  <th>비고</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {jointCareer.map((data, index) => {
                  const {
                    organization_name,
                    position,
                    note,
                    startDate,
                    endDate,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2020.6"
                          dateFormat="yyyy.MM"
                          selected={startDate || ""}
                          value={startDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "jointCareer", 0)
                          }
                        />
                      </td>
                      <td>~</td>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2022.6"
                          dateFormat="yyyy.MM"
                          selected={endDate || ""}
                          value={endDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "jointCareer", 1)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="jointCareer"
                          value={organization_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "organization_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="jointCareer"
                          value={position}
                          onChange={(e) =>
                            handleChangeTable(e, index, "position")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="jointCareer"
                          value={note}
                          onChange={(e) => handleChangeTable(e, index, "note")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">라. 주요 연구개발 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 신청 중이거나 수행 중인
                연구개발과제는 필수적으로 작성해야 합니다.
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="jointPerformance"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="performance_wrapper">
            <table className="performance_table">
              <colgroup>
                <col width={80} />
                <col width={125} />
                <col width={225} />
                <col width={125} />
                <col width={150} />
                <col width={80} />
                <col width={96} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2}>
                    중앙행정기관
                    <br />
                    <span className="extra_text">(전문기관)</span>
                  </th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    세부사업
                  </th>
                  <th rowSpan={2}>연구개발과제명</th>
                  <th style={{ backgroundColor: "#d8d8d8" }}>
                    주관연구 개발기관
                  </th>
                  <th>전체 연구개발기간</th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    역 할<br />
                    <span className="extra_text">책임자/연구자</span>
                  </th>
                  <th rowSpan={2}>
                    비 고<br />
                    <span className="extra_text">(신청/수행중/완료)</span>
                  </th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    삭제
                  </th>
                </tr>
                <tr>
                  <th>당시 소속기관</th>
                  <th style={{ backgroundColor: "#d8d8d8" }}>참여한 기간</th>
                </tr>
              </thead>
              <tbody>
                {jointPerformance.map((data, index) => {
                  const {
                    agency,
                    detail_project,
                    name,
                    organization_agency,
                    affiliated_agency,
                    total_duration,
                    participation_period,
                    role,
                    etc,
                  } = data;
                  return (
                    <>
                      <tr>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "80px" }}
                            name="jointPerformance"
                            value={agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "agency")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "125px" }}
                            placeholder="입력하세요"
                            name="jointPerformance"
                            value={detail_project}
                            onChange={(e) =>
                              handleChangeTable(e, index, "detail_project")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "225px" }}
                            placeholder="입력하세요"
                            name="jointPerformance"
                            value={name}
                            onChange={(e) =>
                              handleChangeTable(e, index, "name")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            style={{ width: "125px" }}
                            placeholder="입력하세요"
                            name="jointPerformance"
                            value={organization_agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "organization_agency")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="yy.mm.dd~yy.mm.dd"
                            name="jointPerformance"
                            value={total_duration}
                            onChange={(e) =>
                              handleChangeTable(e, index, "total_duration")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "80px" }}
                            placeholder="입력하세요"
                            name="jointPerformance"
                            value={role}
                            onChange={(e) =>
                              handleChangeTable(e, index, "role")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "96px" }}
                            placeholder="입력하세요"
                            name="jointPerformance"
                            value={etc}
                            onChange={(e) => handleChangeTable(e, index, "etc")}
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <button className="icon_del_trashcan" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            style={{ width: "125px" }}
                            placeholder="입력하세요"
                            name="jointPerformance"
                            value={affiliated_agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "affiliated_agency")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="yy.mm.dd~yy.mm.dd"
                            name="jointPerformance"
                            value={participation_period}
                            onChange={(e) =>
                              handleChangeTable(
                                e,
                                index,
                                "participation_period"
                              )
                            }
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">마. 대표적 논문/저서 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에
                따라 선택적으로 작성이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="jointThesis"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="result_ones_thesis">
            <table className="result_ones_thesis_table">
              <colgroup>
                <col width={80} />
                <col width={225} />
                <col width={45} />
                <col width={125} />
                <col width={125} />
                <col width={125} />
                <col width={140} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    구 분
                    <br />
                    <span className="extra_text">(논문/저서)</span>
                  </th>
                  <th>논문명/저서명</th>
                  <th>
                    게제지
                    <br />
                    <span className="extra_text">(권,쪽)</span>
                  </th>
                  <th>
                    게재연도
                    <br />
                    <span className="extra_text">(발표연도)</span>
                  </th>
                  <th>역 할</th>
                  <th>
                    등록번호
                    <br />
                    <span className="extra_text">(ISSN)</span>
                  </th>
                  <th>
                    비 고<br />
                    <span className="extra_text">(피인용 지수)</span>
                  </th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {jointThesis.map((data, index) => {
                  const {
                    sortation,
                    thesis_name,
                    paper,
                    year,
                    role,
                    apply_number,
                    etc,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="jointThesis"
                          value={sortation}
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="2"
                          name="jointThesis"
                          value={thesis_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "thesis_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="jointThesis"
                          value={paper}
                          onChange={(e) => handleChangeTable(e, index, "paper")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="~"
                          name="jointThesis"
                          value={year}
                          onChange={(e) => handleChangeTable(e, index, "year")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="jointThesis"
                          value={role}
                          onChange={(e) => handleChangeTable(e, index, "role")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="jointThesis"
                          value={apply_number}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_number")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="2. 예)설계도면 작성"
                          name="jointThesis"
                          value={etc}
                          onChange={(e) => handleChangeTable(e, index, "etc")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text result_ones_thesis_text">
            <div>
              <p className="small_text">바. 지식재산권 출원ㆍ등록 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에
                따라 선택적으로 작성이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="jointApply"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="result_ones_thesis">
            <table className="result_ones_thesis_table">
              <colgroup>
                <col width={80} />
                <col width={225} />
                <col width={45} />
                <col width={125} />
                <col width={255} />
                <col width={140} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    구 분
                    <br />
                    <span className="extra_text">특허/프로그램 등</span>
                  </th>
                  <th>지식재산권명</th>
                  <th>국가명</th>
                  <th>출원ㆍ등록일</th>
                  <th>출원ㆍ등록번호/ 출원ㆍ등록자 수</th>
                  <th>
                    비 고<br />
                    <span className="extra_text">(출원/등록)</span>
                  </th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {jointApply.map((data, index) => {
                  const {
                    sortation,
                    name,
                    country_name,
                    apply_date,
                    apply_number,
                    etc,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="jointApply"
                          value={sortation}
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="jointApply"
                          value={name}
                          onChange={(e) => handleChangeTable(e, index, "name")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="jointApply"
                          value={country_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "country_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="jointApply"
                          value={apply_date}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_date")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="jointApply"
                          value={apply_number}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_number")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="jointApply"
                          value={etc}
                          onChange={(e) => handleChangeTable(e, index, "etc")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text result_ones_thesis_text">
            <div>
              <p className="small_text">사. 그 밖의 수상경력 등 대표적 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에
                따라 선택적으로 작성이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="jointAwards"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="result_ones_thesis">
            <table className="result_ones_thesis_table">
              <colgroup>
                <col width={220} />
                <col width={220} />
                <col width={220} />
                <col width={220} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th>구 분</th>
                  <th>실적명</th>
                  <th>내용요약</th>
                  <th>실적연도</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {jointAwards.map((data, index) => {
                  const { sortation, award_name, summary, award_year } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          placeholder="산업통상자원부"
                          name="jointAwards"
                          value={sortation}
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요."
                          name="jointAwards"
                          value={award_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "award_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요."
                          name="jointAwards"
                          value={summary}
                          onChange={(e) =>
                            handleChangeTable(e, index, "summary")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="yyyy"
                          name="jointAwards"
                          value={award_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "award_year")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="blue_bg_text">
            <p>(3) 위탁연구개발기관 책임자</p>
            <div>
              <p>※ 해당 시 작성합니다</p>
              <i className="icon_more_arrow_right_black" />
            </div>
          </div>
          <div className="plan_text">
            <p className="small_text">가. 인적사항</p>
            <BringingUpIcon />
          </div>
          <div className="personal_info_wrapper">
            <p className="text_letter_spacing blue_color_text">개인</p>
            <div className="plan_grid_items">
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">국문</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[0]}
                  onChange={(e) => handleChange(e, 0)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">국적</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[1]}
                  onChange={(e) => handleChange(e, 1)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">영문</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[2]}
                  onChange={(e) => handleChange(e, 2)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">
                  <span>국&nbsp; &nbsp; &nbsp; &nbsp;가</span>
                  <br />
                  연구자번호
                </p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[3]}
                  onChange={(e) => handleChange(e, 3)}
                />
              </div>
            </div>
          </div>
          <div className="company_info_wrapper">
            <p className="text_letter_spacing blue_color_text">직장</p>
            <div className="plan_grid_items">
              <div className="plan_grid_item">
                <p className="small_text">기 관 명</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[4]}
                  onChange={(e) => handleChange(e, 4)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">전화번호</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[5]}
                  onChange={(e) => handleChange(e, 5)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">부서</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[6]}
                  onChange={(e) => handleChange(e, 6)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">휴대전화</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[7]}
                  onChange={(e) => handleChange(e, 7)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">직위</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[8]}
                  onChange={(e) => handleChange(e, 8)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="small_text">전자우편</p>
                <input
                  type="text"
                  placeholder="OOOOOOOOOO"
                  name="commission_info"
                  value={fourthPageData.commission_info[9]}
                  onChange={(e) => handleChange(e, 9)}
                />
              </div>
              <div className="plan_grid_item">
                <p className="text_letter_spacing small_text">주소</p>
                <input
                  type="text"
                  placeholder="(우:   )"
                  name="commission_info"
                  value={fourthPageData.commission_info[10]}
                  onChange={(e) => handleChange(e, 10)}
                />
              </div>
            </div>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">나. 학력</p>
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                연구개발과제 특성에 따라 선택적으로 적용이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="commissionEducation"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="academic_background_wrapper">
            <table className="academic_background_table">
              <colgroup>
                <col width={125} />
                <col width={20} />
                <col width={125} />
                <col width={180} />
                <col width={135} />
                <col width={135} />
                <col width={135} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={3}>
                    취득연월
                    <br />
                    <span className="extra_text">(YY.MM~YY.MM)</span>
                  </th>
                  <th>학 교 명</th>
                  <th>전 공</th>
                  <th>학 위</th>
                  <th>지도교수</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {commissionEducation.map((data, index) => {
                  const {
                    school_name,
                    major,
                    academic_degree,
                    professor,
                    startDate,
                    endDate,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2020.6"
                          dateFormat="yyyy.MM"
                          selected={startDate || ""}
                          value={startDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "commissionEducation", 0)
                          }
                        />
                      </td>
                      <td>~</td>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2022.6"
                          dateFormat="yyyy.MM"
                          selected={endDate || ""}
                          value={endDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "commissionEducation", 1)
                          }
                        />
                      </td>
                      <td className="school_name">
                        <input
                          type="text"
                          name="commissionEducation"
                          value={school_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "school_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commissionEducation"
                          value={major}
                          onChange={(e) => handleChangeTable(e, index, "major")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commissionEducation"
                          value={academic_degree}
                          onChange={(e) =>
                            handleChangeTable(e, index, "academic_degree")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commissionEducation"
                          value={professor}
                          onChange={(e) =>
                            handleChangeTable(e, index, "professor")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
                <tr className="final_degree_thesis_name">
                  <td colSpan={2}>
                    최종학위 논문명
                    <span className="extra_text">(해당 시 작성)</span>
                  </td>
                  <td colSpan={5}>
                    <input
                      type="text"
                      name="name_thesis"
                      value={fourthPageData.name_thesis[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">다. 경력</p>
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                연구개발과제 특성에 따라 선택적으로 적용이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="commissionCareer"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="career_wrapper">
            <table className="career_table">
              <colgroup>
                <col width={125} />
                <col width={20} />
                <col width={125} />
                <col width={197} />
                <col width={197} />
                <col width={197} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th colSpan={3}>
                    기간
                    <br />
                    <span className="extra_text">(YY.MM~YY.MM)</span>
                  </th>
                  <th>기관명</th>
                  <th>직위</th>
                  <th>비고</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {commissionCareer.map((data, index) => {
                  const {
                    organization_name,
                    position,
                    note,
                    startDate,
                    endDate,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2020.6"
                          dateFormat="yyyy.MM"
                          selected={startDate || ""}
                          value={startDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "commissionCareer", 0)
                          }
                        />
                      </td>
                      <td>~</td>
                      <td>
                        <DatePicker
                          showMonthYearPicker
                          placeholderText="2022.6"
                          dateFormat="yyyy.MM"
                          selected={endDate || ""}
                          value={endDate}
                          onChange={(e) =>
                            handleDateChange(e, index, "commissionCareer", 1)
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="commissionCareer"
                          value={organization_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "organization_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="commissionCareer"
                          value={position}
                          onChange={(e) =>
                            handleChangeTable(e, index, "position")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="commissionCareer"
                          value={note}
                          onChange={(e) => handleChangeTable(e, index, "note")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">라. 주요 연구개발 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 신청 중이거나 수행 중인
                연구개발과제는 필수적으로 작성해야 합니다.
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="commissionPerformance"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="performance_wrapper">
            <table className="performance_table">
              <colgroup>
                <col width={80} />
                <col width={125} />
                <col width={225} />
                <col width={125} />
                <col width={150} />
                <col width={80} />
                <col width={96} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2}>
                    중앙행정기관
                    <br />
                    <span className="extra_text">(전문기관)</span>
                  </th>
                  <th rowSpan={2}>세부사업</th>
                  <th rowSpan={2}>연구개발과제명</th>
                  <th style={{ backgroundColor: "#d8d8d8" }}>
                    주관연구 개발기관
                  </th>
                  <th>전체 연구개발기간</th>
                  <th rowSpan={2}>
                    역 할<br />
                    <span className="extra_text">책임자/연구자</span>
                  </th>
                  <th rowSpan={2}>
                    비 고<br />
                    <span className="extra_text">(신청/수행중/완료)</span>
                  </th>
                  <th rowSpan={2}>삭제</th>
                </tr>
                <tr>
                  <th>당시 소속기관</th>
                  <th style={{ backgroundColor: "#d8d8d8" }}>참여한 기간</th>
                </tr>
              </thead>
              <tbody>
                {commissionPerformance.map((data, index) => {
                  const {
                    agency,
                    detail_project,
                    name,
                    organization_agency,
                    affiliated_agency,
                    total_duration,
                    participation_period,
                    role,
                    etc,
                  } = data;
                  return (
                    <>
                      <tr>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "80px" }}
                            name="commissionPerformance"
                            value={agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "agency")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "125px" }}
                            placeholder="입력하세요"
                            name="commissionPerformance"
                            value={detail_project}
                            onChange={(e) =>
                              handleChangeTable(e, index, "detail_project")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "225px" }}
                            placeholder="입력하세요"
                            name="commissionPerformance"
                            value={name}
                            onChange={(e) =>
                              handleChangeTable(e, index, "name")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            style={{ width: "125px" }}
                            placeholder="입력하세요"
                            name="commissionPerformance"
                            value={organization_agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "organization_agency")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="yy.mm.dd~yy.mm.dd"
                            name="commissionPerformance"
                            value={total_duration}
                            onChange={(e) =>
                              handleChangeTable(e, index, "total_duration")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "80px" }}
                            placeholder="입력하세요"
                            name="commissionPerformance"
                            value={role}
                            onChange={(e) =>
                              handleChangeTable(e, index, "role")
                            }
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <input
                            type="text"
                            style={{ width: "96px" }}
                            placeholder="입력하세요"
                            name="commissionPerformance"
                            value={etc}
                            onChange={(e) => handleChangeTable(e, index, "etc")}
                          />
                        </td>
                        <td rowSpan={2} className="height_100">
                          <button className="icon_del_trashcan" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            style={{ width: "125px" }}
                            placeholder="입력하세요"
                            name="commissionPerformance"
                            value={affiliated_agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "affiliated_agency")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="yy.mm.dd~yy.mm.dd"
                            name="commissionPerformance"
                            value={participation_period}
                            onChange={(e) =>
                              handleChangeTable(
                                e,
                                index,
                                "participation_period"
                              )
                            }
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">마. 대표적 논문/저서 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에
                따라 선택적으로 작성이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="commissionThesis"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="result_ones_thesis">
            <table className="result_ones_thesis_table">
              <colgroup>
                <col width={80} />
                <col width={225} />
                <col width={45} />
                <col width={125} />
                <col width={125} />
                <col width={125} />
                <col width={140} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    구 분
                    <br />
                    <span className="extra_text">(논문/저서)</span>
                  </th>
                  <th>논문명/저서명</th>
                  <th>
                    게제지
                    <br />
                    <span className="extra_text">(권,쪽)</span>
                  </th>
                  <th>
                    게재연도
                    <br />
                    <span className="extra_text">(발표연도)</span>
                  </th>
                  <th>역 할</th>
                  <th>
                    등록번호
                    <br />
                    <span className="extra_text">(ISSN)</span>
                  </th>
                  <th>
                    비 고<br />
                    <span className="extra_text">(피인용 지수)</span>
                  </th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {commissionThesis.map((data, index) => {
                  const {
                    sortation,
                    thesis_name,
                    paper,
                    year,
                    role,
                    apply_number,
                    etc,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="commissionThesis"
                          value={sortation}
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="2"
                          name="commissionThesis"
                          value={thesis_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "thesis_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commissionThesis"
                          value={paper}
                          onChange={(e) => handleChangeTable(e, index, "paper")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="~"
                          name="commissionThesis"
                          value={year}
                          onChange={(e) => handleChangeTable(e, index, "year")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="commissionThesis"
                          value={role}
                          onChange={(e) => handleChangeTable(e, index, "role")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="commissionThesis"
                          value={apply_number}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_number")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="2. 예)설계도면 작성"
                          name="commissionThesis"
                          value={etc}
                          onChange={(e) => handleChangeTable(e, index, "etc")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text result_ones_thesis_text">
            <div>
              <p className="small_text">바. 지식재산권 출원ㆍ등록 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에
                따라 선택적으로 작성이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="commissionApply"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="result_ones_thesis">
            <table className="result_ones_thesis_table">
              <colgroup>
                <col width={80} />
                <col width={225} />
                <col width={45} />
                <col width={125} />
                <col width={255} />
                <col width={140} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    구 분
                    <br />
                    <span className="extra_text">특허/프로그램 등</span>
                  </th>
                  <th>지식재산권명</th>
                  <th>국가명</th>
                  <th>출원ㆍ등록일</th>
                  <th>출원ㆍ등록번호/ 출원ㆍ등록자 수</th>
                  <th>
                    비 고<br />
                    <span className="extra_text">(출원/등록)</span>
                  </th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {commissionApply.map((data, index) => {
                  const {
                    sortation,
                    name,
                    country_name,
                    apply_date,
                    apply_number,
                    etc,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="commissionApply"
                          value={sortation}
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commissionApply"
                          value={name}
                          onChange={(e) => handleChangeTable(e, index, "name")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commissionApply"
                          value={country_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "country_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="commissionApply"
                          value={apply_date}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_date")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="commissionApply"
                          value={apply_number}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_number")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="commissionApply"
                          value={etc}
                          onChange={(e) => handleChangeTable(e, index, "etc")}
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text result_ones_thesis_text">
            <div>
              <p className="small_text">사. 그 밖의 수상경력 등 대표적 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                최근 5년간 5개 이내의 실적으로 작성하되, 연구개발과제 특성에
                따라 선택적으로 작성이 가능합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="commissionAwards"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="result_ones_thesis">
            <table className="result_ones_thesis_table">
              <colgroup>
                <col width={220} />
                <col width={220} />
                <col width={220} />
                <col width={220} />
                <col width={49} />
              </colgroup>
              <thead>
                <tr>
                  <th>구 분</th>
                  <th>실적명</th>
                  <th>내용요약</th>
                  <th>실적연도</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {commissionAwards.map((data, index) => {
                  const { sortation, award_name, summary, award_year } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          placeholder="산업통상자원부"
                          name="commissionAwards"
                          value={sortation}
                          onChange={(e) =>
                            handleChangeTable(e, index, "sortation")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요."
                          name="commissionAwards"
                          value={award_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "award_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요."
                          name="commissionAwards"
                          value={summary}
                          onChange={(e) =>
                            handleChangeTable(e, index, "summary")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="yyyy"
                          name="commissionAwards"
                          value={award_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "award_year")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="blue_bg_text">
            <p>(4) 참여연구자 및 연구지원인력</p>
            <div>
              <p>※ 해당 시 작성합니다</p>
              <i className="icon_more_arrow_right_black" />
            </div>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">가. 참여연구자 현황</p>
              <FirstInfoText />
            </div>
            <div>
              <BringingUpIcon />
            </div>
          </div>
          <div className="researcher_wrapper">
            <table className="researcher_table">
              <colgroup>
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={50} />
                <col width={50} />
                <col width={50} />
                <col width={50} />
                <col width={50} />
              </colgroup>
              <thead>
                <tr style={{ height: "22px" }}>
                  <th rowSpan={3}>성 명</th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    국 적
                  </th>
                  <th rowSpan={3}>
                    소 속
                    <br />기 관
                  </th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    직 위
                  </th>
                  <th rowSpan={3}>
                    국 가<br />
                    연구자번호
                  </th>
                  <th colSpan={3} style={{ backgroundColor: "#d8d8d8" }}>
                    학위 및 전공
                  </th>
                  <th rowSpan={3}>담당역할</th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    신규채용
                    <br />
                    구분
                    <br />
                    <span className="extra_text">(해당 시 작성)</span>
                  </th>
                  <th rowSpan={3}>
                    시간선택제
                    <br />
                    근무 구분
                    <br />
                    <span className="extra_text">(해당 시 작성)</span>
                  </th>
                  <th colSpan={4} style={{ backgroundColor: "#d8d8d8" }}>
                    참여연도
                  </th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    총<br />
                    참여기간
                    <br />
                    <span className="extra_text">(개월)</span>
                  </th>
                </tr>
                <tr style={{ height: "20px" }}>
                  <th rowSpan={2}>
                    최종
                    <br />
                    학위
                  </th>
                  <th rowSpan={2}>전공</th>
                  <th rowSpan={2}>
                    취득
                    <br />
                    년도
                  </th>
                  <th colSpan={2}>1단계</th>
                  <th colSpan={2}>n단계</th>
                </tr>
                <tr>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    1년
                  </th>
                  <th>n년</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    1년
                  </th>
                  <th>n년</th>
                </tr>
              </thead>
              <tbody>
                {researcher.map((data, index) => {
                  const {
                    name,
                    country,
                    agency,
                    position,
                    research_number,
                    degree,
                    major,
                    year,
                    role,
                    recruit,
                    time,
                    first_start_year,
                    first_end_year,
                    last_start_year,
                    last_end_year,
                    period,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={name}
                          onChange={(e) => handleChangeTable(e, index, "name")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={country}
                          onChange={(e) =>
                            handleChangeTable(e, index, "country")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={agency}
                          onChange={(e) =>
                            handleChangeTable(e, index, "agency")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={position}
                          onChange={(e) =>
                            handleChangeTable(e, index, "position")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={research_number}
                          onChange={(e) =>
                            handleChangeTable(e, index, "research_number")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={degree}
                          onChange={(e) =>
                            handleChangeTable(e, index, "degree")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={major}
                          onChange={(e) => handleChangeTable(e, index, "major")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={year}
                          onChange={(e) => handleChangeTable(e, index, "year")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={role}
                          onChange={(e) => handleChangeTable(e, index, "role")}
                        />
                      </td>
                      <td>
                        <select
                          name="researcher"
                          onChange={(e) =>
                            handleChangeTable(e, index, "recruit")
                          }
                          value={recruit}
                        >
                          <option value="new">신규</option>
                          <option value="new_exclusive">신규(전담)</option>
                          <option value="new_youth_obligation">
                            신규(청년의무)
                          </option>
                          <option value="new_youth_extra">
                            신규(청년추가)
                          </option>
                          <option value="new_etc">신규(기타)</option>
                          <option value="existing">기존</option>
                        </select>
                      </td>
                      <td>
                        <select
                          name="researcher"
                          onChange={(e) => handleChangeTable(e, index, "time")}
                          value={time}
                        >
                          <option value="hour">시간</option>
                          <option value="practice">실습</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={first_start_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "first_start_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={first_end_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "first_end_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={last_start_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "last_start_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={last_end_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "last_end_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researcher"
                          value={period}
                          onChange={(e) =>
                            handleChangeTable(e, index, "period")
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">나. 연구지원인력 현황</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">
                직접비에서 인건비를 지급하는 경우에만 작성합니다.
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="researchSupport"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="researcher_wrapper">
            <table className="researcher_table">
              <colgroup>
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={59} />
                <col width={50} />
                <col width={50} />
                <col width={50} />
                <col width={50} />
                <col width={50} />
              </colgroup>
              <thead>
                <tr style={{ height: "22px" }}>
                  <th rowSpan={3}>성 명</th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    국 적
                  </th>
                  <th rowSpan={3}>
                    소 속
                    <br />기 관
                  </th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    직 위
                  </th>
                  <th colSpan={3} style={{ backgroundColor: "#d8d8d8" }}>
                    학위 및 전공
                  </th>
                  <th rowSpan={3}>담당역할</th>
                  <th
                    rowSpan={3}
                    colSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    신규채용
                    <br />
                    구분
                    <br />
                    <span className="extra_text">(해당 시 작성)</span>
                  </th>
                  <th rowSpan={3}>
                    시간선택제
                    <br />
                    근무 구분
                    <br />
                    <span className="extra_text">(해당 시 작성)</span>
                  </th>
                  <th colSpan={4} style={{ backgroundColor: "#d8d8d8" }}>
                    지원연도
                  </th>
                  <th
                    rowSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    총<br />
                    참여기간
                    <br />
                    <span className="extra_text">(개월)</span>
                  </th>
                </tr>
                <tr style={{ height: "20px" }}>
                  <th rowSpan={2}>
                    최종
                    <br />
                    학위
                  </th>
                  <th rowSpan={2}>전공</th>
                  <th rowSpan={2}>
                    취득
                    <br />
                    년도
                  </th>
                  <th colSpan={2}>1단계</th>
                  <th colSpan={2}>n단계</th>
                </tr>
                <tr>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    1년
                  </th>
                  <th>n년</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    1년
                  </th>
                  <th>n년</th>
                </tr>
              </thead>
              <tbody>
                {researchSupport.map((data, index) => {
                  const {
                    name,
                    country,
                    agency,
                    position,
                    degree,
                    major,
                    year,
                    role,
                    recruit,
                    time,
                    first_start_year,
                    first_end_year,
                    last_start_year,
                    last_end_year,
                    period,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={name}
                          onChange={(e) => handleChangeTable(e, index, "name")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={country}
                          onChange={(e) =>
                            handleChangeTable(e, index, "country")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={agency}
                          onChange={(e) =>
                            handleChangeTable(e, index, "agency")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={position}
                          onChange={(e) =>
                            handleChangeTable(e, index, "position")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={degree}
                          onChange={(e) =>
                            handleChangeTable(e, index, "degree")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={major}
                          onChange={(e) => handleChangeTable(e, index, "major")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={year}
                          onChange={(e) => handleChangeTable(e, index, "year")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={role}
                          onChange={(e) => handleChangeTable(e, index, "role")}
                        />
                      </td>
                      <td colSpan={2}>
                        <select
                          name="researchSupport"
                          onChange={(e) =>
                            handleChangeTable(e, index, "recruit")
                          }
                          value={recruit}
                        >
                          <option value="new">신규</option>
                          <option value="new_exclusive">신규(전담)</option>
                          <option value="new_youth_obligation">
                            신규(청년의무)
                          </option>
                          <option value="new_youth_extra">
                            신규(청년추가)
                          </option>
                          <option value="new_etc">신규(기타)</option>
                          <option value="existing">기존</option>
                        </select>
                      </td>
                      <td>
                        <select
                          name="researchSupport"
                          onChange={(e) => handleChangeTable(e, index, "time")}
                          value={time}
                        >
                          <option value="hour">시간</option>
                          <option value="practice">실습</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={first_start_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "first_start_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={first_end_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "first_end_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={last_start_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "last_start_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={last_end_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "last_end_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="researchSupport"
                          value={period}
                          onChange={(e) =>
                            handleChangeTable(e, index, "period")
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            className="blue_bg_text"
            style={{
              backgroundColor: "rgba(240, 240, 240, 0.2)",
            }}
          >
            <p style={{ color: "#1d334e" }}>
              (5) 연구개발기관이 아닌 관계 기관
            </p>
            <div>
              <p style={{ color: "#1d334e", marginRight: "45px" }}>
                ※ 해당 시 작성합니다
              </p>
            </div>
          </div>
          <div className="plan_text">
            <div style={{ marginLeft: "90px" }}>
              <p className="extra_text">
                ※ 연구개발비를 부담하나 사용하지 않는 기관(지방자치단체,
                수혜기관 등) 또는 연구개발비를 사용하지 않으나 연구개발정보를
                필요로 하는 기관에 한정하여 작성합니다
              </p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="relatedAgency"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          {relatedAgency.map((data, index) => {
            const {
              agency_name,
              role,
              manager_name_kr,
              manager_name_en,
              manager_nation,
              manager_agency_name,
              manager_telephone,
              manager_department,
              manager_phone,
              manager_position,
              manager_email,
              manager_address,
              business_name_kr,
              business_name_en,
              business_agency_name,
              business_telephone,
              business_department,
              business_phone,
              business_position,
              business_email,
              business_address,
            } = data;
            return (
              <>
                <div className="organization_name">
                  <p>{kr_index[index]}. &nbsp;기 관 명:</p>
                  <input
                    type="text"
                    placeholder="OOOOOOOOOO"
                    name="relatedAgency"
                    value={agency_name}
                    onChange={(e) => handleChangeTable(e, index, "agency_name")}
                  />
                  <p className="text_letter_spacing_10">역 할</p>
                  <input
                    type="text"
                    name="relatedAgency"
                    value={role}
                    onChange={(e) => handleChangeTable(e, index, "role")}
                  />
                  <button className="icon_del_trashcan" />
                </div>
                <div className="organization_items">
                  <p className="blue_color_text">책 임 자</p>
                  <div className="manager_grid organization_grid">
                    <div>
                      <p className="text_letter_spacing_10">국 문</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={manager_name_kr}
                        onChange={(e) =>
                          handleChangeTable(e, index, "manager_name_kr")
                        }
                      />
                    </div>
                    <div>
                      <p className="text_letter_spacing_10">국 적</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={manager_name_en}
                        onChange={(e) =>
                          handleChangeTable(e, index, "manager_name_en")
                        }
                      />
                    </div>
                    <div>
                      <p className="text_letter_spacing_10">영 문</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={manager_nation}
                        onChange={(e) =>
                          handleChangeTable(e, index, "manager_nation")
                        }
                      />
                    </div>
                    <div>
                      <p>기&nbsp; 관&nbsp; 명</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={manager_agency_name}
                        onChange={(e) =>
                          handleChangeTable(e, index, "manager_agency_name")
                        }
                      />
                    </div>
                    <div>
                      <p>전화번호</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={manager_telephone}
                        onChange={(e) =>
                          handleChangeTable(e, index, "manager_telephone")
                        }
                      />
                    </div>
                    <div>
                      <p className="text_letter_spacing_10">부 서</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={manager_department}
                        onChange={(e) =>
                          handleChangeTable(e, index, "manager_department")
                        }
                      />
                    </div>
                    <div>
                      <p>휴대전화</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={manager_phone}
                        onChange={(e) =>
                          handleChangeTable(e, index, "manager_phone")
                        }
                      />
                    </div>
                    <div>
                      <p className="text_letter_spacing_10">직 위</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={manager_position}
                        onChange={(e) =>
                          handleChangeTable(e, index, "manager_position")
                        }
                      />
                    </div>
                    <div>
                      <p>전자우편</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={manager_email}
                        onChange={(e) =>
                          handleChangeTable(e, index, "manager_email")
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="organization_items"
                  style={{ backgroundColor: "rgba(240,240,240,0.2)" }}
                >
                  <p className="blue_color_text">실무 담당자</p>
                  <div className="person_charge_grid organization_grid">
                    <div>
                      <p className="text_letter_spacing_10">국 문</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={business_name_kr}
                        onChange={(e) =>
                          handleChangeTable(e, index, "business_name_kr")
                        }
                      />
                    </div>
                    <div>
                      <p className="text_letter_spacing_10">영 문</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={business_name_en}
                        onChange={(e) =>
                          handleChangeTable(e, index, "business_name_en")
                        }
                      />
                    </div>
                    <div>
                      <p>기&nbsp; 관&nbsp; 명</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={business_agency_name}
                        onChange={(e) =>
                          handleChangeTable(e, index, "business_agency_name")
                        }
                      />
                    </div>
                    <div>
                      <p>전화번호</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={business_telephone}
                        onChange={(e) =>
                          handleChangeTable(e, index, "business_telephone")
                        }
                      />
                    </div>
                    <div>
                      <p className="text_letter_spacing_10">부 서</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={business_department}
                        onChange={(e) =>
                          handleChangeTable(e, index, "business_department")
                        }
                      />
                    </div>
                    <div>
                      <p>휴대전화</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={business_phone}
                        onChange={(e) =>
                          handleChangeTable(e, index, "business_phone")
                        }
                      />
                    </div>
                    <div>
                      <p className="text_letter_spacing_10">직 위</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={business_position}
                        onChange={(e) =>
                          handleChangeTable(e, index, "business_position")
                        }
                      />
                    </div>
                    <div>
                      <p>전자우편</p>
                      <input
                        type="text"
                        placeholder="OOOOOOOOOO"
                        name="relatedAgency"
                        value={business_email}
                        onChange={(e) =>
                          handleChangeTable(e, index, "business_email")
                        }
                      />
                    </div>
                    <div>
                      <p className="text_letter_spacing_10">주 소</p>
                      <input
                        type="text"
                        style={{ width: "845px" }}
                        placeholder="(우:    )"
                        name="relatedAgency"
                        value={business_address}
                        onChange={(e) =>
                          handleChangeTable(e, index, "business_address")
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="plan_text" style={{ padding: "20px 0 0 22px" }}>
            <div>
              <p className="big_text">2) 연구개발기관 연구개발 실적</p>
              <FirstInfoText />
            </div>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">
                (1) 연구개발과제와 연관된 지식재산권 출원 및 등록 현황
              </p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">최근 5년간의 실적을 기재합니다.</p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="currentStatus"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div>
            <table>
              <colgroup>
                <col width={220} />
                <col width={220} />
                <col width={220} />
                <col width={220} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th>연구개발기관명 (소유권자)</th>
                  <th>지식재산권명</th>
                  <th>국가명</th>
                  <th>출원ㆍ등록번호 /출원ㆍ등록일</th>
                  <th>삭제</th>
                </tr>
              </thead>
              <tbody>
                {currentStatus.map((data, index) => {
                  const {
                    agency_name,
                    property_name,
                    country_name,
                    apply_number,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          placeholder="OOOOOO"
                          name="currentStatus"
                          value={agency_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "agency_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="currentStatus"
                          value={property_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "property_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="currentStatus"
                          value={country_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "country_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="currentStatus"
                          value={apply_number}
                          onChange={(e) =>
                            handleChangeTable(e, index, "apply_number")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">(2) 국가연구개발사업 주요 수행 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">최근 5년간의 실적을 기재합니다.</p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="executionPerformance"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="key_performance">
            <table className="key_performance_table">
              <colgroup>
                <col width={300} />
                <col width={135} />
                <col width={150} />
                <col width={125} />
                <col width={80} />
                <col width={80} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr style={{ height: "23px" }}>
                  <th rowSpan={2}>연구개발과제명</th>
                  <th style={{ backgroundColor: "#d8d8d8" }}>
                    주관연구개발기관명
                  </th>
                  <th>연구개발기간</th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    수행내용
                  </th>
                  <th rowSpan={2}>
                    중앙행정기관
                    <br />
                    <span className="extra_text">(전문기관)</span>
                  </th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    비 고
                    <br />
                    <span className="extra_text">(수행중/완료)</span>
                  </th>
                  <th rowSpan={2}>삭 제</th>
                </tr>
                <tr>
                  <th>
                    연구개발기관명 및 역할
                    <br />
                    <span className="extra_text">(주관/공동)</span>
                  </th>
                  <th style={{ backgroundColor: "#d8d8d8" }}>참여한 기간</th>
                </tr>
              </thead>
              <tbody>
                {executionPerformance.map((data, index) => {
                  const {
                    name,
                    organization_agency,
                    affiliated_agency,
                    total_duration,
                    participation_period,
                    content,
                    agency,
                    etc,
                  } = data;
                  return (
                    <>
                      <tr>
                        <td rowSpan={2}>
                          <input
                            type="text"
                            style={{ height: "100%" }}
                            placeholder="입력하세요"
                            name="executionPerformance"
                            value={name}
                            onChange={(e) =>
                              handleChangeTable(e, index, "name")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="oo전자 / 공동"
                            name="executionPerformance"
                            value={organization_agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "organization_agency")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="yy.mm.dd~yy.mm.dd"
                            name="executionPerformance"
                            value={total_duration}
                            onChange={(e) =>
                              handleChangeTable(e, index, "total_duration")
                            }
                          />
                        </td>
                        <td rowSpan={2}>
                          <input
                            type="text"
                            style={{ height: "100%" }}
                            placeholder="820,000"
                            name="executionPerformance"
                            value={content}
                            onChange={(e) =>
                              handleChangeTable(e, index, "content")
                            }
                          />
                        </td>
                        <td rowSpan={2}>
                          <input
                            type="text"
                            style={{ height: "100%" }}
                            placeholder="820,000"
                            name="executionPerformance"
                            value={agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "agency")
                            }
                          />
                        </td>
                        <td rowSpan={2}>
                          <input
                            type="text"
                            style={{ height: "100%" }}
                            placeholder="820,000"
                            name="executionPerformance"
                            value={etc}
                            onChange={(e) => handleChangeTable(e, index, "etc")}
                          />
                        </td>
                        <td rowSpan={2}>
                          <button className="icon_del_trashcan" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            type="text"
                            placeholder="oo전자 / 공동"
                            name="executionPerformance"
                            value={affiliated_agency}
                            onChange={(e) =>
                              handleChangeTable(e, index, "affiliated_agency")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="yy.mm.dd~yy.mm.dd"
                            name="executionPerformance"
                            value={participation_period}
                            onChange={(e) =>
                              handleChangeTable(
                                e,
                                index,
                                "participation_period"
                              )
                            }
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">(3) 국가연구개발사업 기술이전 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">단위: 천원</p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="techTransfer"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="key_performance">
            <table>
              <colgroup>
                <col width={80} />
                <col width={125} />
                <col width={225} />
                <col width={125} />
                <col width={150} />
                <col width={80} />
                <col width={80} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th>연구개발기관명</th>
                  <th>기술이전 유형</th>
                  <th>기술실시계약명</th>
                  <th>기술실시기관명</th>
                  <th>기술실시발생일</th>
                  <th>기술료</th>
                  <th>
                    기술료
                    <br />
                    누적 징수액
                  </th>
                  <th>삭 제</th>
                </tr>
              </thead>
              <tbody>
                {techTransfer.map((data, index) => {
                  const {
                    institution_name,
                    transfer_type,
                    contract_name,
                    agency_name,
                    tech_date,
                    tech_cost,
                    total_cost,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="techTransfer"
                          value={institution_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "institution_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="techTransfer"
                          value={transfer_type}
                          onChange={(e) =>
                            handleChangeTable(e, index, "transfer_type")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="techTransfer"
                          value={contract_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "contract_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="techTransfer"
                          value={agency_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "agency_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="yy.mm.dd~yy.mm.dd"
                          name="techTransfer"
                          value={tech_date}
                          onChange={(e) =>
                            handleChangeTable(e, index, "tech_date")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="techTransfer"
                          value={tech_cost}
                          onChange={(e) =>
                            handleChangeTable(e, index, "tech_cost")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="techTransfer"
                          value={total_cost}
                          onChange={(e) =>
                            handleChangeTable(e, index, "total_cost")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="small_text">(4) 국가연구개발사업 사업화 실적</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">단위: 천원</p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="commercialPerformance"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="key_performance">
            <table className="key_performance_table">
              <colgroup>
                <col width={100} />
                <col width={80} />
                <col width={80} />
                <col width={80} />
                <col width={90} />
                <col width={90} />
                <col width={90} />
                <col width={80} />
                <col width={80} />
                <col width={80} />
                <col width={80} />
              </colgroup>
              <thead>
                <tr style={{ height: "22px" }}>
                  <th rowSpan={2}>연구개발 기관명</th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    사업화 방식
                    <br />
                    <span className="extra_text">(1)</span>
                  </th>
                  <th rowSpan={2}>
                    사업화 형태
                    <br />
                    <span className="extra_text">(2)</span>
                  </th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    지 역
                    <br />
                    <span className="extra_text">(3)</span>
                  </th>
                  <th rowSpan={2}>사업화명</th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    내 용
                  </th>
                  <th rowSpan={2}>업체명</th>
                  <th colSpan={2} style={{ backgroundColor: "#d8d8d8" }}>
                    매출액
                  </th>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}
                  >
                    매출발생 연도
                  </th>
                  <th rowSpan={2}>기술 수명</th>
                </tr>
                <tr>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.5)" }}>
                    국내
                  </th>
                  <th>국외</th>
                </tr>
              </thead>
              <tbody>
                {commercialPerformance.map((data, index) => {
                  const {
                    institution_name,
                    way,
                    form,
                    country,
                    name,
                    content,
                    company_name,
                    domestic_cost,
                    overseas_cost,
                    cost_year,
                    tech_lifespan,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={institution_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "institution_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={way}
                          onChange={(e) => handleChangeTable(e, index, "way")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={form}
                          onChange={(e) => handleChangeTable(e, index, "form")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={country}
                          onChange={(e) =>
                            handleChangeTable(e, index, "country")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={name}
                          onChange={(e) => handleChangeTable(e, index, "name")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={content}
                          onChange={(e) =>
                            handleChangeTable(e, index, "content")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={company_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "company_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={domestic_cost}
                          onChange={(e) =>
                            handleChangeTable(e, index, "domestic_cost")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={overseas_cost}
                          onChange={(e) =>
                            handleChangeTable(e, index, "overseas_cost")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={cost_year}
                          onChange={(e) =>
                            handleChangeTable(e, index, "cost_year")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="commercialPerformance"
                          value={tech_lifespan}
                          onChange={(e) =>
                            handleChangeTable(e, index, "tech_lifespan")
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="extra_info_text">
            * 1」 기술이전 또는 자기실시 중 해당사항을 기재합니다.
            <br />
            * 2」 신제품 개발, 기존 제품 개선, 신공정 개발, 기존 공정 개선
            등에서 해당하는 사항을 기재합니다.
            <br />
            * 3」 국내 또는 국외 중 해당사항을 기재합니다.
            <br />
            ※ 기술이전 및 사업화 실적은 국가연구개발사업 조사ㆍ분석에 등록된
            것이어야 합니다.
            <br />
          </div>
          <div className="plan_text">
            <div>
              <p className="big_text">3) 연구시설ㆍ장비 보유현황</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">최근 5년간의 실적을 기재합니다</p>
              <button>
                <img
                  src={buttonIcon}
                  alt="addColIcon"
                  name="holdingStatus"
                  onClick={(e) => addTableRows(e)}
                />
              </button>
            </div>
          </div>
          <div className="key_performance">
            <table className="key_performance_table">
              <colgroup>
                <col width={80} />
                <col width={200} />
                <col width={110} />
                <col width={110} />
                <col width={110} />
                <col width={110} />
                <col width={161} />
                <col width={65} />
              </colgroup>
              <thead>
                <tr>
                  <th>보유기관</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    연구시설ㆍ장비명
                  </th>
                  <th>규격</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    수량
                  </th>
                  <th>용도</th>
                  <th style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}>
                    활용시기
                  </th>
                  <th>
                    현물부담 반영여부
                    <br />
                    <span className="extra_text">(해당 시 O)</span>
                  </th>
                  <th>삭 제</th>
                </tr>
              </thead>
              <tbody>
                {holdingStatus.map((data, index) => {
                  const {
                    holding_agency,
                    equipment_name,
                    standard,
                    quantity,
                    purpose,
                    use,
                    reflection_status,
                  } = data;
                  return (
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="holdingStatus"
                          value={holding_agency}
                          onChange={(e) =>
                            handleChangeTable(e, index, "holding_agency")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="DMD소켓 구입(OO개x0000원)"
                          name="holdingStatus"
                          value={equipment_name}
                          onChange={(e) =>
                            handleChangeTable(e, index, "equipment_name")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="holdingStatus"
                          value={standard}
                          onChange={(e) =>
                            handleChangeTable(e, index, "standard")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="holdingStatus"
                          value={quantity}
                          onChange={(e) =>
                            handleChangeTable(e, index, "quantity")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="holdingStatus"
                          value={purpose}
                          onChange={(e) =>
                            handleChangeTable(e, index, "purpose")
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="입력하세요"
                          name="holdingStatus"
                          value={use}
                          onChange={(e) => handleChangeTable(e, index, "use")}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="holdingStatus"
                          value={reflection_status}
                          onChange={(e) =>
                            handleChangeTable(e, index, "reflection_status")
                          }
                        />
                      </td>
                      <td>
                        <button className="icon_del_trashcan" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="plan_text">
            <div>
              <p className="big_text">4) 연구개발기관 일반 현황</p>
              <FirstInfoText />
              <BringingUpIcon />
            </div>
            <div>
              <p className="extra_text">(단 위: 천원, 백분율)</p>
            </div>
          </div>
          <div className="general_conditions">
            <table className="general_conditions_table">
              <colgroup>
                <col width={45} />
                <col width={75} />
                <col width={108} />
                <col width={119} />
                <col width={185} />
                <col width={185} />
                <col width={185} />
              </colgroup>
              <thead>
                <tr style={{ height: "22px" }}>
                  <th
                    rowSpan={2}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    순번
                  </th>
                  <th
                    rowSpan={2}
                    colSpan={3}
                    style={{ backgroundColor: "rgba(216, 216, 216, 0.25)" }}
                  >
                    구 분
                  </th>
                  <th colSpan={3} style={{ backgroundColor: "#d8d8d8" }}>
                    기관명
                  </th>
                </tr>
                <tr>
                  <th>
                    <input
                      type="text"
                      placeholder="기관명1"
                      name="agency_name"
                      value={fourthPageData.agency_name[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      placeholder="기관명2"
                      name="agency_name"
                      value={fourthPageData.agency_name[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </th>
                  <th>
                    <input
                      type="text"
                      placeholder="기관명3"
                      name="agency_name"
                      value={fourthPageData.agency_name[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td colSpan={3}>사업자등록번호</td>
                  <td>
                    <input
                      type="text"
                      name="business_number"
                      value={fourthPageData.business_number[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="business_number"
                      value={fourthPageData.business_number[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="business_number"
                      value={fourthPageData.business_number[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td colSpan={3}>법인등록번호</td>
                  <td>
                    <input
                      type="text"
                      name="corporate_number"
                      value={fourthPageData.corporate_number[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="corporate_number"
                      value={fourthPageData.corporate_number[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="corporate_number"
                      value={fourthPageData.corporate_number[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td colSpan={3}>대표자 성명 및 국적</td>
                  <td>
                    <input
                      type="text"
                      name="representative"
                      value={fourthPageData.representative[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="representative"
                      value={fourthPageData.representative[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="representative"
                      value={fourthPageData.representative[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td colSpan={3}>기관 유형 (대학, 정부출연연, 중소기업 등)</td>
                  <td>
                    <input
                      type="text"
                      name="agency_type"
                      value={fourthPageData.agency_type[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="agency_type"
                      value={fourthPageData.agency_type[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="agency_type"
                      value={fourthPageData.agency_type[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td colSpan={3}>최대주주성명(국적)</td>
                  <td>
                    <input
                      type="text"
                      name="stockholder"
                      value={fourthPageData.stockholder[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="stockholder"
                      value={fourthPageData.stockholder[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="stockholder"
                      value={fourthPageData.stockholder[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td colSpan={3}>설립 연월일</td>
                  <td>
                    <input
                      type="text"
                      name="founded"
                      value={fourthPageData.founded[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="founded"
                      value={fourthPageData.founded[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="founded"
                      value={fourthPageData.founded[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>7</td>
                  <td colSpan={3}>주생산 품목</td>
                  <td>
                    <input
                      type="text"
                      name="main_item"
                      value={fourthPageData.main_item[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="main_item"
                      value={fourthPageData.main_item[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="main_item"
                      value={fourthPageData.main_item[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>8</td>
                  <td colSpan={3}>상시 종업원 수</td>
                  <td>
                    <input
                      type="text"
                      name="employees"
                      value={fourthPageData.employees[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="employees"
                      value={fourthPageData.employees[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="employees"
                      value={fourthPageData.employees[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>9</td>
                  <td colSpan={3}>직전년도 매출액</td>
                  <td>
                    <input
                      type="text"
                      name="sales"
                      value={fourthPageData.sales[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="sales"
                      value={fourthPageData.sales[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="sales"
                      value={fourthPageData.sales[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>10</td>
                  <td colSpan={3}>매출액 대비 연구개발비 비율</td>
                  <td>
                    <input
                      type="text"
                      name="rnd_per_sales"
                      value={fourthPageData.rnd_per_sales[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="rnd_per_sales"
                      value={fourthPageData.rnd_per_sales[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="rnd_per_sales"
                      value={fourthPageData.rnd_per_sales[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3}>11</td>
                  <td colSpan={2} rowSpan={3}>
                    부채비율
                    <br />
                    (최근 3년간 결산 기준)
                  </td>
                  <td>
                    <span>{year - 3}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="debt_ratio"
                      value={fourthPageData.debt_ratio[0][0]}
                      onChange={(e) => handleChange(e, 0, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="debt_ratio"
                      value={fourthPageData.debt_ratio[0][1]}
                      onChange={(e) => handleChange(e, 0, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="debt_ratio"
                      value={fourthPageData.debt_ratio[0][2]}
                      onChange={(e) => handleChange(e, 0, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 2}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="debt_ratio"
                      value={fourthPageData.debt_ratio[1][0]}
                      onChange={(e) => handleChange(e, 1, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="debt_ratio"
                      value={fourthPageData.debt_ratio[1][1]}
                      onChange={(e) => handleChange(e, 1, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="debt_ratio"
                      value={fourthPageData.debt_ratio[1][2]}
                      onChange={(e) => handleChange(e, 1, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 1}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="debt_ratio"
                      value={fourthPageData.debt_ratio[2][0]}
                      onChange={(e) => handleChange(e, 2, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="debt_ratio"
                      value={fourthPageData.debt_ratio[2][1]}
                      onChange={(e) => handleChange(e, 2, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="debt_ratio"
                      value={fourthPageData.debt_ratio[2][2]}
                      onChange={(e) => handleChange(e, 2, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3}>12</td>
                  <td colSpan={2} rowSpan={3}>
                    유동비율
                    <br />
                    (최근 3년간 결산 기준)
                  </td>
                  <td>
                    <span>{year - 3}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="current_rate"
                      value={fourthPageData.current_rate[0][0]}
                      onChange={(e) => handleChange(e, 0, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="current_rate"
                      value={fourthPageData.current_rate[0][1]}
                      onChange={(e) => handleChange(e, 0, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="current_rate"
                      value={fourthPageData.current_rate[0][2]}
                      onChange={(e) => handleChange(e, 0, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 2}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="current_rate"
                      value={fourthPageData.current_rate[1][0]}
                      onChange={(e) => handleChange(e, 1, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="current_rate"
                      value={fourthPageData.current_rate[1][1]}
                      onChange={(e) => handleChange(e, 1, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="current_rate"
                      value={fourthPageData.current_rate[1][2]}
                      onChange={(e) => handleChange(e, 1, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 1}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="current_rate"
                      value={fourthPageData.current_rate[2][0]}
                      onChange={(e) => handleChange(e, 2, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="current_rate"
                      value={fourthPageData.current_rate[2][1]}
                      onChange={(e) => handleChange(e, 2, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="current_rate"
                      value={fourthPageData.current_rate[2][2]}
                      onChange={(e) => handleChange(e, 2, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={6}>13</td>
                  <td rowSpan={6}>
                    자본잠식
                    <br />
                    현황
                  </td>
                  <td rowSpan={3}>자본총계</td>
                  <td>
                    <span>{year - 3}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="total_capital"
                      value={fourthPageData.total_capital[0][0]}
                      onChange={(e) => handleChange(e, 0, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="total_capital"
                      value={fourthPageData.total_capital[0][1]}
                      onChange={(e) => handleChange(e, 0, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="total_capital"
                      value={fourthPageData.total_capital[0][2]}
                      onChange={(e) => handleChange(e, 0, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 2}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="total_capital"
                      value={fourthPageData.total_capital[1][0]}
                      onChange={(e) => handleChange(e, 1, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="total_capital"
                      value={fourthPageData.total_capital[1][1]}
                      onChange={(e) => handleChange(e, 1, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="total_capital"
                      value={fourthPageData.total_capital[1][2]}
                      onChange={(e) => handleChange(e, 1, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 1}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="total_capital"
                      value={fourthPageData.total_capital[2][0]}
                      onChange={(e) => handleChange(e, 2, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="total_capital"
                      value={fourthPageData.total_capital[2][1]}
                      onChange={(e) => handleChange(e, 2, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="total_capital"
                      value={fourthPageData.total_capital[2][2]}
                      onChange={(e) => handleChange(e, 2, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3}>자본금</td>
                  <td>
                    <span>{year - 3}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="capital"
                      value={fourthPageData.capital[0][0]}
                      onChange={(e) => handleChange(e, 0, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="capital"
                      value={fourthPageData.capital[0][1]}
                      onChange={(e) => handleChange(e, 0, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="capital"
                      value={fourthPageData.capital[0][2]}
                      onChange={(e) => handleChange(e, 0, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 2}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="capital"
                      value={fourthPageData.capital[1][0]}
                      onChange={(e) => handleChange(e, 1, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="capital"
                      value={fourthPageData.capital[1][1]}
                      onChange={(e) => handleChange(e, 1, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="capital"
                      value={fourthPageData.capital[1][2]}
                      onChange={(e) => handleChange(e, 1, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 1}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="capital"
                      value={fourthPageData.capital[2][0]}
                      onChange={(e) => handleChange(e, 2, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="capital"
                      value={fourthPageData.capital[2][1]}
                      onChange={(e) => handleChange(e, 2, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="capital"
                      value={fourthPageData.capital[2][2]}
                      onChange={(e) => handleChange(e, 2, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3}>14</td>
                  <td colSpan={2} rowSpan={3}>
                    영업 이익
                    <br />
                    (최근 3년간 결산 기준)
                  </td>
                  <td>
                    <span>{year - 3}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="operating_profit"
                      value={fourthPageData.operating_profit[0][0]}
                      onChange={(e) => handleChange(e, 0, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="operating_profit"
                      value={fourthPageData.operating_profit[0][1]}
                      onChange={(e) => handleChange(e, 0, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="operating_profit"
                      value={fourthPageData.operating_profit[0][2]}
                      onChange={(e) => handleChange(e, 0, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 2}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="operating_profit"
                      value={fourthPageData.operating_profit[1][0]}
                      onChange={(e) => handleChange(e, 1, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="operating_profit"
                      value={fourthPageData.operating_profit[1][1]}
                      onChange={(e) => handleChange(e, 1, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="operating_profit"
                      value={fourthPageData.operating_profit[1][2]}
                      onChange={(e) => handleChange(e, 1, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 1}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="operating_profit"
                      value={fourthPageData.operating_profit[2][0]}
                      onChange={(e) => handleChange(e, 2, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="operating_profit"
                      value={fourthPageData.operating_profit[2][1]}
                      onChange={(e) => handleChange(e, 2, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="operating_profit"
                      value={fourthPageData.operating_profit[2][2]}
                      onChange={(e) => handleChange(e, 2, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={3}>15</td>
                  <td colSpan={2} rowSpan={3}>
                    이자 보상 비율
                    <br />
                    (최근 3년간 결산 기준)
                  </td>
                  <td>
                    <span>{year - 3}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="compensation_ratio"
                      value={fourthPageData.compensation_ratio[0][0]}
                      onChange={(e) => handleChange(e, 0, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="compensation_ratio"
                      value={fourthPageData.compensation_ratio[0][1]}
                      onChange={(e) => handleChange(e, 0, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="compensation_ratio"
                      value={fourthPageData.compensation_ratio[0][2]}
                      onChange={(e) => handleChange(e, 0, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 2}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="compensation_ratio"
                      value={fourthPageData.compensation_ratio[1][0]}
                      onChange={(e) => handleChange(e, 1, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="compensation_ratio"
                      value={fourthPageData.compensation_ratio[1][1]}
                      onChange={(e) => handleChange(e, 1, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="compensation_ratio"
                      value={fourthPageData.compensation_ratio[1][2]}
                      onChange={(e) => handleChange(e, 1, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{year - 1}년</span>
                  </td>
                  <td>
                    <input
                      type="text"
                      name="compensation_ratio"
                      value={fourthPageData.compensation_ratio[2][0]}
                      onChange={(e) => handleChange(e, 2, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="compensation_ratio"
                      value={fourthPageData.compensation_ratio[2][1]}
                      onChange={(e) => handleChange(e, 2, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="compensation_ratio"
                      value={fourthPageData.compensation_ratio[2][2]}
                      onChange={(e) => handleChange(e, 2, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td rowSpan={7}>16</td>
                  <td rowSpan={7} colSpan={2}>
                    연구개발기관의
                    <br />
                    연구개발과제 지원 담당자
                  </td>
                  <td>성&emsp;&nbsp;명</td>
                  <td>
                    <input
                      type="text"
                      name="support_name"
                      value={fourthPageData.support_name[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_name"
                      value={fourthPageData.support_name[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_name"
                      value={fourthPageData.support_name[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>부&emsp;&nbsp;서</td>
                  <td>
                    <input
                      type="text"
                      name="support_department"
                      value={fourthPageData.support_department[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_department"
                      value={fourthPageData.support_department[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_department"
                      value={fourthPageData.support_department[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>직&emsp;&nbsp;위</td>
                  <td>
                    <input
                      type="text"
                      name="support_position"
                      value={fourthPageData.support_position[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_position"
                      value={fourthPageData.support_position[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_position"
                      value={fourthPageData.support_position[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>직장전화</td>
                  <td>
                    <input
                      type="text"
                      name="support_telephone"
                      value={fourthPageData.support_telephone[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_telephone"
                      value={fourthPageData.support_telephone[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_telephone"
                      value={fourthPageData.support_telephone[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>휴대전화</td>
                  <td>
                    <input
                      type="text"
                      name="support_phone"
                      value={fourthPageData.support_phone[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_phone"
                      value={fourthPageData.support_phone[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_phone"
                      value={fourthPageData.support_phone[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>전자우편</td>
                  <td>
                    <input
                      type="text"
                      name="support_email"
                      value={fourthPageData.support_email[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_email"
                      value={fourthPageData.support_email[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_email"
                      value={fourthPageData.support_email[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>팩&emsp;&nbsp;스</td>
                  <td>
                    <input
                      type="text"
                      name="support_fax"
                      value={fourthPageData.support_fax[0]}
                      onChange={(e) => handleChange(e, 0)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_fax"
                      value={fourthPageData.support_fax[1]}
                      onChange={(e) => handleChange(e, 1)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="support_fax"
                      value={fourthPageData.support_fax[2]}
                      onChange={(e) => handleChange(e, 2)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="prev_next_button">
          <button
            className="prev_button"
            onClick={() => history.push("/plan/rnd/list/third")}
          >
            <img src={prevButton} alt="prevButton" />
          </button>
          <button
            className="next_button"
            // onClick={() => history.push("/plan/rnd/list/fifth")}
            onClick={() => sendData()}
          >
            <img src={nextButton} alt="nextButton" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RnDPlanFourth;
