import React, { useState } from "react";

const reasons = ["논문", "학술지 게재", "연구보고서", "기타"];

const ReasonAndInputRow = ({
  editing,
  content,
  selectClass,
  onChange,
  onClick1,
  onClick2,
  haveFile,
}) => {
  const [state, setState] = useState({
    reason: content.reason,
    reason_comment: content.reason_comment,
  });

  const handleChange = (e, field) => {
    const newValue = e.target.value;

    setState((prevState) => {
      if (field === "reason" && newValue !== "기타") {
        // '기타'가 아니면 reason_comment 초기화
        // '기타'가 아닐 때 reason_comment를 초기화하고 부모 컴포넌트로 변경 사항 전달
        if (onChange) {
          onChange({ target: { value: "" } }, "reason_comment"); // 빈 값 전달
        }
        return {
          ...prevState,
          reason: newValue,
          reason_comment: "", // reason_comment 초기화
        };
      } else {
        // 일반적인 상태 업데이트
        return {
          ...prevState,
          [field]: newValue,
        };
      }
    });

    // 부모 컴포넌트로 변경 사항 전달
    if (onChange) {
      onChange(e, field);
    }
  };

  return (
    <>
      {editing ? (
        <>
          <select
            className={selectClass}
            value={state.reason}
            onChange={(e) => handleChange(e, "reason")}
          >
            <option value="" disabled hidden>
              선택
            </option>
            {reasons.map((item, idx) => (
              <option key={idx} value={item}>
                {item}
              </option>
            ))}
          </select>

          <input
            type="text"
            placeholder="기타 입력"
            value={state.reason_comment}
            onChange={(e) => handleChange(e, "reason_comment")}
            disabled={state.reason !== "기타"}
          />

          <div className="btns">
            <button
              className={haveFile ? "icon_download_attached" : "icon_download"}
              onClick={onClick1}
            />
            <button className="icon_upload" onClick={onClick2} />
          </div>
        </>
      ) : (
        <>
          <input type="text" readOnly value={state.reason} />
          <input type="text" readOnly value={state.reason_comment} disabled />
          <div className="btns">
            <button
              className={haveFile ? "icon_download_attached" : "icon_download"}
              onClick={onClick1}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ReasonAndInputRow;
