import { useRef, useEffect, useState } from "react";
import "./css/ToggleContent.scss";

const ToggleContent = ({ isOpen, children }) => {
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");
  useEffect(() => {
    const updateMaxHeight = () => {
      if (contentRef.current) {
        const newMaxHeight = isOpen
          ? `${contentRef.current.scrollHeight}px`
          : "0px";
        setMaxHeight(newMaxHeight);
      }
    };

    // setTimeout 사용하여 약간의 지연을 줌
    const timer = setTimeout(updateMaxHeight, 100);

    return () => clearTimeout(timer);
  }, [isOpen, children]);

  return (
    <div
      className="toggle-content"
      ref={contentRef}
      style={{
        maxHeight,
      }}
    >
      <div className="toggle-content-children">{children}</div>
    </div>
  );
};

export default ToggleContent;
