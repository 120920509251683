import "../../admin/inventor/css/InventorList.scss";
import { useHistory } from "react-router-dom";
import Pagination from "../../common/Pagination";
import AdminAPI from "../../../API/admin";
import React, { useEffect, useState } from "react";

const AdminGiveUpAnnual = ({ location }) => {
  const history = useHistory();
  const { company_idx } = location.state;
  const [companyInfo, setCompanyInfo] = useState({});
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [giveUpList, setGiveUpList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    getCompanyInfo(company_idx);
  }, []);

  useEffect(() => {
    getGiveUpList();
  }, [curPage]);

  const getGiveUpList = (dummy_company_idx) => {
    let params = {
      count: 10,
      page: curPage,
      keyword: searchInput, // 검색창 입력시 호출하는 변수
      dummyCompanyIdx: companyInfo.dummy_company_idx || dummy_company_idx,
    };

    AdminAPI.getAdminCompanyGiveUpList(params).then((res) => {
      res.data.items.map((item) => {
        let arr = [];
        if (item.idx === null) {
          arr.push({
            idx: "",
            inventor_name: "N/A",
            is_completed: 0,
            user_addr: "N/A",
            user_email: "N/A",
            user_tel_no: "N/A",
            is_folding: false,
          });
          item.inventor_list = arr;
          return;
        }

        let idx = item.idx.split(",");
        let inventor_name = item.inventor_name.split(",");
        let is_completed = item.is_completed.split(",");
        let user_addr = item.user_addr.split("|");
        let user_email = item.user_email.split(",");
        let user_tel_no = item.user_tel_no.split(",");

        idx.map((el, index) => {
          arr.push({
            idx: el,
            inventor_name:
              inventor_name[index] == "null" ? "N/A" : inventor_name[index],
            is_completed: Number(is_completed[index]),
            user_addr: user_addr[index] == "null" ? "N/A" : user_addr[index],
            user_email: user_email[index] == "null" ? "N/A" : user_email[index],
            user_tel_no:
              user_tel_no[index] == "null" ? "N/A" : user_tel_no[index],
            is_folding: false,
          });
        });
        item.inventor_list = arr;
      });

      setLast(res.data.last);
      setGiveUpList(res.data.items);
    });
  };

  const getCompanyInfo = (company_idx) => {
    AdminAPI.getAdminCompanyInfo(company_idx).then((res) => {
      getGiveUpList(res.data.dummy_company_idx);
      setCompanyInfo(res.data);
    });
  };

  const updateCheckBox = (item) => {
    const { idx, is_completed } = item;
    AdminAPI.updateInventorInfo(idx, { is_completed }).then(() => {
      getGiveUpList();
    });
  };

  return (
    <div id="InventorList">
      <div className="header">
        <h2>"{companyInfo.name}"의 포기권 모아보기</h2>
        <button onClick={() => history.goBack()}>목록으로 돌아가기</button>
      </div>
      <div className="inventor_list">
        <table>
          <colgroup>
            <col width="50px" />
            <col width="120px" />
            <col width="120px" />
            <col width="120px" />
            <col width="120px" />
            <col width="120px" />
            <col width="120px" />
            <col width="460px" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>구분</th>
              <th>특허번호</th>
              <th>등록일</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>주소</th>
              <th>확인</th>
            </tr>
          </thead>
          <tbody>
            {giveUpList.map((item, index) => {
              let inventorName = item.inventor_list[0].inventor_name;
              if (item.inventor_list.length > 1) {
                inventorName += ` 외 ${item.inventor_list.length - 1}명`;
              }
              return (
                <>
                  <tr
                    onClick={() =>
                      history.push({
                        pathname: "/admin/annual/giveup/inventor/list",
                        state: item,
                      })
                    }
                  >
                    <td>{(curPage - 1) * 10 + (index + 1)}</td>
                    <td>{companyInfo.name}</td>
                    <td>{item.apply_number}</td>
                    <td>{item.register_at}</td>
                    <td
                      onClick={(e) => {
                        e.stopPropagation();
                        item.is_folding = !item.is_folding;
                        setGiveUpList([...giveUpList]);
                      }}
                    >
                      <p>
                        {inventorName}
                        {inventorName !== "N/A" && (
                          <i
                            className={
                              item.is_folding
                                ? "icon_arrow_up_gray"
                                : "icon_arrow_down_gray"
                            }
                          />
                        )}
                      </p>
                    </td>
                    <td>{item.inventor_list[0].user_tel_no}</td>
                    <td>{item.inventor_list[0].user_email}</td>
                    <td>{item.inventor_list[0].user_addr}</td>
                    <td onClick={(e) => e.stopPropagation()}>
                      <input
                        type="checkbox"
                        checked={item.inventor_list[0].is_completed}
                        value={item.inventor_list[0].is_completed}
                        onChange={() => {
                          item.inventor_list[0].is_completed = Number(
                            !item.inventor_list[0].is_completed
                          );
                          updateCheckBox(item.inventor_list[0]);
                        }}
                      />
                    </td>
                  </tr>
                  {item.is_folding && (
                    <table className="inventor_child_table">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>이름</th>
                          <th>전화번호</th>
                          <th>이메일</th>
                          <th>주소</th>
                          <th>확인</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.inventor_list.map((child_item, index) => {
                          return (
                            <tr className="inventor_info">
                              <td>{index + 1}</td>
                              <td>{child_item.inventor_name}</td>
                              <td>{child_item.user_tel_no}</td>
                              <td>{child_item.user_email}</td>
                              <td>{child_item.user_addr}</td>
                              <td onClick={(e) => e.stopPropagation()}>
                                <input
                                  type="checkbox"
                                  checked={child_item.is_completed}
                                  value={child_item.is_completed}
                                  onChange={() => {
                                    child_item.is_completed = Number(
                                      !child_item.is_completed
                                    );
                                    updateCheckBox(child_item);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
    </div>
  );
};

export default AdminGiveUpAnnual;
