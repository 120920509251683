import React from "react";
import AnnualPay from "../../API/annualPay";

const PaymentState = ({
  state,
  companyList,
  idx,
  annual_pay_idx,
  showPatent,
}) => {
  const annualGiveUp = (idx, annual_pay_idx) => {
    const check = window.confirm("연차료 납부를 포기하시겠습니까?");
    if (check) {
      switch (showPatent) {
        case "patent":
          AnnualPay.patentPayGiveUp(idx, annual_pay_idx).then(() => {
            alert("연차료 납부 포기 신청이 완료되었습니다.");
            window.location.reload();
          });
          break;
        case "trademark":
          AnnualPay.tradeMarkPayGiveUp(idx, annual_pay_idx).then(() => {
            alert("연차료 납부 포기 신청이 완료되었습니다.");
            window.location.reload();
          });
          break;
        case "design":
          AnnualPay.designPayGiveUp(idx, annual_pay_idx).then(() => {
            alert("연차료 납부 포기 신청이 완료되었습니다.");
            window.location.reload();
          });
          break;
        default:
          AnnualPay.patentPayGiveUp(idx, annual_pay_idx).then(() => {
            alert("연차료 납부 포기 신청이 완료되었습니다.");
            window.location.reload();
          });
          break;
      }
    }
  };

  const annualPayment = (idx, annual_pay_idx, type) => {
    const check = window.confirm(
      type === "company"
        ? "기업체 납부를 선택하시겠습니까?"
        : "연차료를 납부하시겠습니까?"
    );

    const payload = {
      type,
    };

    if (check) {
      switch (showPatent) {
        case "patent":
          AnnualPay.patentApplication(idx, annual_pay_idx, payload).then(() => {
            alert("연차료 납부 신청이 완료되었습니다.");
            window.location.reload();
          });
          break;
        case "trademark":
          AnnualPay.tradeMarkApplication(idx, annual_pay_idx, payload).then(
            () => {
              alert("연차료 납부 신청이 완료되었습니다.");
              window.location.reload();
            }
          );
          break;
        case "design":
          AnnualPay.designApplication(idx, annual_pay_idx, payload).then(() => {
            alert("연차료 납부 신청이 완료되었습니다.");
            window.location.reload();
          });
          break;
        default:
          AnnualPay.patentApplication(idx, annual_pay_idx, payload).then(() => {
            alert("연차료 납부 신청이 완료되었습니다.");
            window.location.reload();
          });
          break;
      }
    }
  };

  if (state === 0) {
    return (
      <div className="annual_payment">
        <button
          onClick={() => {
            annualGiveUp(idx, annual_pay_idx);
          }}
        >
          포기
        </button>
        <button
          onClick={() => {
            annualPayment(idx, annual_pay_idx);
          }}
        >
          납부
        </button>
        <button
          onClick={() => {
            annualPayment(idx, annual_pay_idx, "company");
          }}
        >
          기업체 납부
        </button>
      </div>
    );
  } else if (state === 1) {
    return <p style={{ color: "#343434" }}>연차료 납부 요청</p>;
  } else if (state === 2) {
    return <p style={{ color: "#343434" }}>연차료 납부 대기</p>;
  } else if (state === 3) {
    return <p style={{ color: "#05ac9e" }}>연차료 납부 완료</p>;
  } else if (state === 4) {
    return <p style={{ color: "#05ac9e" }}>연차료 입금 요청</p>;
  } else if (state === 5) {
    return <p style={{ color: "#05ac9e" }}>기업체 납부</p>;
  } else if (state === 6) {
    return <p style={{ color: "#05ac9e" }}>연차료 납부 제외</p>;
  } else if (state === 99) {
    return <p style={{ color: "#e92f2c" }}>연차료 납부 포기</p>;
  } else {
    return null;
  }
};

export default PaymentState;
