import React from "react";
import "./css/Tooltip.scss";

const Tooltip = ({ text, show, position = "top", customStyle = {} }) => {
  if (!show) return null;

  return (
    <div className={`tooltip tooltip-${position}`} style={customStyle}>
      {text.split("\n").map((line, idx) => (
        <p key={idx}>{line}</p>
      ))}
    </div>
  );
};

export default Tooltip;
