const FilterButton = ({ selectedFilter, filterArr, setSelectedFilter }) => {
  return (
    <ul
      className="category"
      style={{ display: "flex", justifyContent: "start", gap: "10px" }}
    >
      {filterArr.map((filter) => (
        <li key={filter}>
          <button
            className={selectedFilter === filter && "on"}
            onClick={() => setSelectedFilter(filter, false)}
          >
            {filter}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default FilterButton;
