import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CommonFunc from "./common";
import Path from "./const/path";
import { useLocation } from "react-router-dom";
import memberContext from "../../store/Member";
import CommonAPI from "../../API/common";

function IntroMenu({ headHide, activeTab, auth, location, isExceptionURL }) {
  const isViewTypeIpnow = window.$Global.isIpnow(location);
  const isLogin = Boolean(useContext(memberContext).idx);
  const isStatisticPath = sessionStorage.getItem("statistics_service"); // 자산실사보고서 가능 판별
  const isSimpleStatisticPath = sessionStorage.getItem(
    "simple_statistics_service"
  ); // 심플통계 가능 판별
  const isTransferStatisticPath = sessionStorage.getItem(
    "transfer_statistics_service"
  );
  const redirect = () => {
    window.$Global.redirectHome(auth);
  };

  const onClickChangeView = () => {
    window.$Global.toggleViewType();
  };

  const path = [
    ...Path.match_ip_service,
    isStatisticPath === "true" && {
      url: "/dueDi/dashboard",
      redirectURL: "/dueDi/dashboard",
      name: "특허 통계 (자산실사)",
    },
    isSimpleStatisticPath === "true" && {
      url: "/kautum/statistic",
      redirectURL: "/kautum/statistic",
      name: "심플 통계",
    },
    isTransferStatisticPath === "true" && {
      url: "/tech/transfer",
      redirectURL: "/tech/transfer",
      name: "기술이전관리",
    },
  ].filter(Boolean);

  return (
    <div id="IntroMenu">
      {!headHide && !isExceptionURL && (
        <div className="intro_link">
          {isViewTypeIpnow ? (
            <img
              src={require("../../assets/images/common/logo_ipnow.svg").default}
              style={{ width: 120 }}
              onClick={redirect}
              alt="이미지"
            />
          ) : (
            <img
              src={
                require("../../assets/images/common/logo_findBiz.svg").default
              }
              style={{ width: 160 }}
              onClick={redirect}
              alt="이미지"
            />
          )}
          {!auth && (
            <div className="sign">
              {/*{isViewTypeIpnow ? (
                <button
                  className="btn_change btn_findbiz"
                  onClick={onClickChangeView}
                >
                  BizNavi전환
                </button>
              ) : (
                <button
                  className="btn_change btn_ipnow"
                  onClick={onClickChangeView}
                >
                  IPNOW 전환
                </button>
              )}*/}
              <Link className="btn_login" to="/login">
                로그인
              </Link>
              <Link
                className="btn_signup"
                to="/signup/type"
                style={{
                  background: isViewTypeIpnow
                    ? "linear-gradient(to right, #ff7a00 32%, #ffa600)"
                    : "linear-gradient(to right, #4593f5, #6bd3ff)",
                }}
              >
                무료 회원가입
              </Link>
            </div>
          )}
        </div>
      )}
      <div className={isLogin ? "tab_list_wrapper_login" : "tab_list_wrapper"}>
        <ul className="tab_list" id={!isViewTypeIpnow && "Intro_tab_list"}>
          {CommonFunc.DrawTab(
            isViewTypeIpnow
              ? isLogin
                ? path
                : Path.match_ip_service
              : isLogin
              ? Path.match_intro_login
              : Path.match_intro,
            activeTab,
            "bold",
            isLogin
          )}
        </ul>
        {!isLogin && (
          <div className="tab_list_service">
            <Link
              to="/intro/service/detail"
              className={
                location.pathname === "/intro/service/detail" && "active"
              }
            >
              서비스 소개
            </Link>
            {/*<Link*/}
            {/*  to="/intro/price"*/}
            {/*  className={location.pathname === "/intro/price" && "active"}*/}
            {/*>*/}
            {/*  이용요금*/}
            {/*</Link>*/}
            <Link
              to="/intro/service"
              className={location.pathname === "/intro/service" && "active"}
            >
              Q&A
            </Link>
            <h2>|</h2>
            {isViewTypeIpnow ? (
              <button
                className="btn_change btn_findbiz"
                onClick={onClickChangeView}
              >
                <p>
                  <span>비즈내비</span>&nbsp;바로가기&nbsp;&gt;
                </p>
              </button>
            ) : (
              <div className="btn_change btn_ipnow" onClick={onClickChangeView}>
                <p>
                  <span>아이피나우</span>&nbsp;바로가기&nbsp;&gt;
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default IntroMenu;
