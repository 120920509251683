import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TradeMarkAPI from "../../API/trademark";
import styles from "./css/TradeMarkEtc.module.scss";
import MemberContext from "../../store/Member";
import PatentAPI from "../../API/patent";

const TradeMarkEtc = () => {
  const history = useHistory();
  const { trademark_idx } = useParams();
  const { auth, manage_company_idx } = useContext(MemberContext);
  const fileRef = useRef();
  const [tradeMarkData, setTradeMarkData] = useState({});
  const [resData, setResData] = useState([]);

  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);

  const fetchTradeMarkInfo = async () => {
    try {
      let res;

      if (isCompany) {
        res = await TradeMarkAPI.getTradeMarkInfo(trademark_idx);
      } else if (isAgent) {
        res = await TradeMarkAPI.getTradeMarkInfoAgent(
          manage_company_idx,
          trademark_idx
        );
      }

      if (res?.data) {
        setTradeMarkData(res.data);
      }
    } catch (error) {
      console.error("Error fetching TradeMarkInfo:", error);
    }
  };

  const fetchETCFile = async () => {
    try {
      let res;

      if (isCompany) {
        res = await TradeMarkAPI.getTradeMarkEtcFile(trademark_idx);
      } else if (isAgent) {
        res = await TradeMarkAPI.getTradeMarkEtcFileAgent(
          manage_company_idx,
          trademark_idx
        );
      }

      if (res?.data?.items) {
        setResData(res.data.items);
      }
    } catch (error) {
      console.error("Error fetching ETC files:", error);
    }
  };

  useEffect(() => {
    fetchTradeMarkInfo();
    fetchETCFile();
  }, []);

  const onClickDownload = (idx) => {
    if (isCompany) {
      TradeMarkAPI.downloadTradeMarkEtcFile(trademark_idx, idx);
    } else if (isAgent) {
      TradeMarkAPI.downloadTradeMarkEtcFileAgent(
        manage_company_idx,
        trademark_idx,
        idx
      );
    }
  };

  const onClickDelete = (idx) => {
    if (isCompany) {
      TradeMarkAPI.deleteTradeMarkEtcFile(trademark_idx, idx).then(() => {
        alert("삭제되었습니다");
        fetchETCFile();
      });
    } else if (isAgent) {
      TradeMarkAPI.deleteTradeMarkEtcFileAgent(
        manage_company_idx,
        trademark_idx,
        idx
      ).then(() => {
        alert("삭제되었습니다");
        fetchETCFile();
      });
    }
  };

  const uploadFile = (e) => {
    let files = e.target.files;
    let formData = new FormData();
    Array.from(files).forEach((file) => formData.append("file", file));
    if (isCompany) {
      TradeMarkAPI.uploadTradeMarkEtcFile(trademark_idx, formData).then(() => {
        alert("업로드되었습니다");
        fetchETCFile();
      });
    } else if (isAgent) {
      TradeMarkAPI.uploadTradeMarkEtcFileAgent(
        manage_company_idx,
        trademark_idx,
        formData
      ).then(() => {
        alert("업로드되었습니다.");
        fetchETCFile();
      });
    }
  };

  let tbody = [];

  if (resData.length) {
    tbody = resData.map((item, idx) => {
      return (
        <tr>
          <td>{idx + 1}</td>
          <td>{item.file_name + "." + item.file_type}</td>
          <td>{window.$Global.convertDate(item.created_at)}</td>
          <td>
            <button
              className={styles.iconDownload}
              onClick={() => onClickDownload(item.idx)}
            />
          </td>
          <td
            className={styles.deleteButton}
            onClick={() => onClickDelete(item.idx)}
          >
            삭제하기
          </td>
        </tr>
      );
    });
  } else {
    tbody = window.$Global.notTd(5, "기타 관련 파일이 존재하지 않습니다");
  }

  return (
    <div className={styles.patentETC}>
      <div className={styles.header}>
        <h2 className={styles.title}>기타 관련 파일</h2>
        <div className={styles.patentInfo}>
          관리번호{" "}
          {tradeMarkData.manage_number != "null"
            ? tradeMarkData.manage_number
            : "N/A"}
          <i
            className={`icon_flag_${window.$Global.convertNationCodeToStr(
              tradeMarkData.nation
            )} ${styles.flag}`}
          />
        </div>
        <div className={styles.btns}>
          <button
            className={styles.btnUpload}
            style={{ margin: 0 }}
            onClick={() => fileRef.current.click()}
          >
            업로드하기
          </button>
          <button className={styles.btnSave} onClick={() => history.goBack()}>
            이전
          </button>
        </div>
      </div>
      <table className={styles.list}>
        <thead>
          <tr>
            <th>순번</th>
            <th>파일명</th>
            <th>업로드일</th>
            <th>다운로드</th>
            <th>삭제하기</th>
          </tr>
        </thead>
        <tbody>{tbody}</tbody>
      </table>
      <input type="file" ref={fileRef} onChange={uploadFile} multiple hidden />
    </div>
  );
};

export default TradeMarkEtc;
