import API from "../../util/api";
import APIV4 from "../../util/apiV4";

export default {
  getCompanyGrade() {
    return API.get("/manager/company/grade");
  },
  getCompanyConfig() {
    return API.get("/manager/company/config");
  },
  getAgentConfig(manage_company_idx) {
    return API.get(`/agent/company/${manage_company_idx}/config`);
  },
  updateCompanyConfig(payload) {
    return API.post("/manager/company/config", payload);
  },
  getCompanyDetail() {
    return API.get("/manager/company/detail");
  },
  updatedCompanyDetail(payload) {
    return API.patch("/manager/company/detail", payload);
  },
  getCompanySummary(payload) {
    // 기업요약
    return APIV4.get("/company/summary", { params: payload });
  },
  getAvgSalary(payload) {
    // 평균급여
    return APIV4.get("/company/avgIncome", { params: payload });
  },
  getJointQuit(payload) {
    // 입퇴사현황
    return APIV4.get("/company/getJoinQuit", { params: payload });
  },
  getNews(payload) {
    // 기업뉴스
    return APIV4.get("/company/getNews", { params: payload });
  },
  getFinance(payload) {
    // 재무상태표,손익계산서,재무상태표
    return APIV4.get("/company/getFinance", { params: payload });
  },
  getIPC(payload) {
    // 기업분류 - IPC 분류정보 가져오기
    return APIV4.get("/company/getIPC", { params: payload });
  },
  getBid(payload) {
    // 기업과제 낙찰 정보 조회
    return APIV4.get("/company/getBidInfo", { params: payload });
  },
  getClt(payload) {
    // 거래처기업 조회
    return APIV4.get("/company/getRelation", { params: payload });
  },
  deleteClt(payload) {
    // 거래처기업 삭제
    return APIV4.delete("/company/deleteRelation", { params: payload });
  },
  getCltAll(payload) {
    // 거래처 기업 전체 리스트
    return APIV4.get("/company/getCltManager", { params: payload });
  },
  getCltGroup(payload) {
    // 거래처기업 그룹 조회
    return APIV4.get("/company/getRelationGroup", { params: payload });
  },
  addCltGroup(payload) {
    // 거래처기업 그룹 추가
    return APIV4.post("/company/addRelationGroup", payload);
  },
  deleteCltGroup(payload) {
    // 거래처기업 그룹 삭제
    return APIV4.delete("/company/deleteRelationGroup", { params: payload });
  },
  updateCltGroup(payload) {
    // 거래처기업 그룹 업데이트
    return APIV4.patch("/company/updateRelationGroup", payload);
  },
  addClt(payload) {
    return APIV4.post("/company/addRelation", payload);
  },
  getCertification(payload) {
    // 기업 인증정보 가져오기
    return APIV4.get("/company/getCertification", { params: payload });
  },
  getSupplier(payload) {
    // 주요 품목 현황 + 주요 거래기관 현황
    return APIV4.get("/company/getSupplier", { params: payload });
  },
  getDesign(payload) {
    // 디자인권 조회
    return APIV4.get("/company/getDesign", { params: payload });
  },
  getInterest(payload) {
    // 관심기업 조회
    return APIV4.get("/company/getRelationMyPage", { params: payload });
  },
  addInterest(payload) {
    // 관심기업 추가
    return APIV4.post("/company/addInterest", payload);
  },
  deleteInterest(payload) {
    // 관심기업 제거
    return APIV4.delete("/company/deleteInterest", { params: payload });
  },
  cancelInterest(payload) {
    // 관심기업 해제
    return APIV4.delete("/company/cancelInterest", { params: payload });
  },
  getNationalRnd(payload) {
    // 국가 R&D 정보
    return APIV4.get("/company/getNationalRnd", { params: payload });
  },
  getCorpRnd(payload) {
    // 기업 R&D 정보
    return APIV4.get("/company/getCorpRnd", { params: payload });
  },
  getCla(payload) {
    // 기업 최신활동
    return APIV4.get("/company/getCla", { params: payload });
  },
  getRelationMyPage(payload) {
    // 관련기업조회 - 마이페이지
    return APIV4.get("/company/getRelationMyPage", { params: payload });
  },
  getInsight(payload) {
    // 재무 인사이트
    return APIV4.get("/company/getInsight", { params: payload });
  },
};
