import React, { useEffect, useState } from "react";
import styles from "./css/AccountModal.module.scss";
import InputField from "./InputField";
import InventionAPI from "../../API/invention";

const AccountModal = ({ inventor, isManaging, onClose }) => {
  const [form, setForm] = useState({
    userId: isManaging ? inventor.member_id : "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");

  // ✅ overlay 클릭 시 모달 닫기 (단, 내부 클릭은 방지)
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // ✅ ESC 키를 누르면 모달 닫기
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]); // onClose가 변경될 때마다 이벤트 리스너 등록/해제

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleDeleteAccount = async (inventor_idx) => {
    // 사용자 확인 알림
    const isConfirmed = window.confirm(
      "계정을 삭제하면 복구할 수 없습니다. \n삭제하시겠습니까?"
    );
    if (!isConfirmed) return; // 취소하면 종료

    try {
      // 삭제 요청
      await InventionAPI.deleteInventorAccount(inventor_idx);

      // 성공 메시지
      alert("계정이 성공적으로 삭제되었습니다.");
      window.location.reload(); // ✅ 페이지 전체 새로고침 → 강제 리렌더링
      // UI 업데이트 (모달 닫기 or 목록 다시 불러오기)
      onClose();
    } catch (error) {
      console.error("계정 삭제 실패:", error);

      // 에러 핸들링
      alert("계정 삭제에 실패했습니다. 다시 시도해 주세요.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.userId || !form.password || !form.confirmPassword) {
      setError("모든 필드를 입력해주세요.");
      return;
    }

    if (form.password !== form.confirmPassword) {
      setError("비밀번호가 일치하지 않습니다.");
      return;
    }

    try {
      if (isManaging) {
        // 계정 관리 모드 - 비밀번호 업데이트
        const formData = { password: form.password };
        await InventionAPI.updateInventorAccount(
          formData,
          inventor.inventor_idx
        );
        alert("비밀번호가 성공적으로 변경되었습니다.");
      } else {
        // 계정 생성 모드
        const formData = {
          name: inventor.inventor_name,
          id: form.userId,
          password: form.password,
          email: inventor.email,
          inventor: true,
          inventor_idx: inventor.inventor_idx,
          rank: 2,
        };

        await InventionAPI.connectInventorAccount(formData);
        alert("계정이 성공적으로 생성되었습니다.");
        window.location.reload(); // ✅ 페이지 전체 새로고침 → 강제 리렌더링
      }
      onClose();
    } catch (error) {
      console.error("계정 처리 실패:", error);
      setError("서버 오류로 계정 처리에 실패했습니다.");
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContainer}>
        <header className={styles.modalHeader}>
          <h2>{isManaging ? "계정 관리" : "계정 연결"}</h2>
        </header>
        <div className={styles.inventorInfo}>
          <div className={styles.infoRow}>
            <span className={styles.label}>👤 발명자:</span>
            <span className={styles.value}>{inventor.inventor_name}</span>
          </div>
          <div className={styles.infoRow}>
            <span className={styles.label}>📧 이메일:</span>
            <span className={styles.value}>{inventor.email}</span>
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputWithDelete}>
            <InputField
              label="아이디"
              name="userId"
              required
              placeholder="아이디"
              value={form.userId}
              readOnly={isManaging} // ✅ isManaging이 true면 readOnly 적용
              onChange={handleChange}
            />
            {isManaging && (
              <button
                type="button"
                className={styles.deleteButton}
                onClick={() => handleDeleteAccount(inventor.inventor_idx)}
              >
                계정 삭제
              </button>
            )}
          </div>
          <InputField
            label={isManaging ? "새 비밀번호" : "비밀번호"} // ✅ isManaging이 true면 "새 비밀번호"
            name="password"
            required
            type="password"
            placeholder="비밀번호"
            value={form.password}
            onChange={handleChange}
          />
          <InputField
            label={isManaging ? "새 비밀번호 확인" : "비밀번호 확인"} // ✅ isManaging이 true면 "새 비밀번호 확인"
            name="confirmPassword"
            required
            type="password"
            placeholder="비밀번호 확인"
            value={form.confirmPassword}
            onChange={handleChange}
          />

          {error && <p className={styles.error}>{error}</p>}
          <div className={styles.modalFooter}>
            <button
              type="button"
              onClick={onClose}
              className={styles.cancelButton}
            >
              취소
            </button>
            <button type="submit" className={styles.submitButton}>
              {isManaging ? "적용" : "연결"}{" "}
              {/* ✅ isManaging이 true면 "관리", 아니면 "생성" */}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AccountModal;
