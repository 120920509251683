import React, { useEffect, useRef, useState } from "react";
import "./css/AdminCommunityPosting.scss";
import SunEditor from "suneditor-react";
import AdminAPI from "../../API/admin";
import commonFunc from "./commonFunc/common";
import qs from "query-string";
import _ from "lodash";

function AdminCommunityPosting({ history, location }) {
  const { idx, type } = qs.parse(location.search);
  const isView = type == "view";
  const isEdit = type == "edit";
  const editorRef = useRef(null);
  const [category, setCategory] = useState([]);
  const [postInput, setPostInput] = useState({
    file: [],
    fileView: [],
    title: "",
    category: 0,
  });
  const [content, setContent] = useState("");

  useEffect(() => {
    if (isView) {
      AdminAPI.getCommunityInfo(idx).then((res) => {
        let data = res.data;
        let copy = _.cloneDeep(postInput);
        copy.title = data.title;
        copy.category = data.category;
        copy.fileView = data.files;
        setPostInput(copy);
        setContent(data.content);
      });
    }
    commonFunc.convertCommunityCategory().then((res) => setCategory(res));
  }, [type]);

  const onClickEditGo = () => {
    history.push(`/admin/community/posting?idx=${idx}&type=edit`);
  };

  const onClickEdit = async () => {
    let payload = {
      title: postInput.title,
      content: await parseContent(),
      category: postInput.category,
    };

    let formData = new FormData();

    postInput.file.forEach((item) => {
      formData.append("file", item);
    });

    await AdminAPI.updateNotify(idx, payload);

    if (postInput.file.length) {
      await AdminAPI.uploadNotifyFile(idx, formData);
    }

    alert("수정되었습니다");
    history.push(`/admin/community/posting?idx=${idx}&type=view`);
  };

  const onClickPost = async () => {
    let formData = await parseFormData();

    await AdminAPI.addNotify(formData).then(() => {
      alert("등록되었습니다");
      history.push("/admin/community/list");
    });
  };

  const parseContent = async () => {
    let parse_result = content || "";

    let src = parse_result.match(
      new RegExp('src=\\"data:image\\/([a-zA-Z]*);base64,([^\\"]*)\\"', "g")
    );
    if (src != null) {
      for (let i = 0; i < src.length; i++) {
        let data_uri = src[i].replace('src="', "");
        if (data_uri.substr(0, 5) == "https") {
          continue;
        }
        let dataUri = data_uri.replace('"', "");
        let imgUrl = window.$Global.getCDN(
          await saveFile(window.$Global.dataURItoBlob(dataUri))
        );
        parse_result = parse_result.replace(dataUri, imgUrl);
      }
    }

    return parse_result;
  };

  const parseFormData = async () => {
    let formData = new FormData();

    postInput.file.forEach((item) => {
      formData.append("file", item);
    });

    formData.append("title", postInput.title);
    formData.append("content", await parseContent());
    formData.append("category", postInput.category);

    return formData;
  };

  const saveFile = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    return await AdminAPI.uploadImage(formData).then(
      (res) => res.data[0].file_key
    );
  };

  const onClickDeleteFile = (depth, file_idx) => {
    AdminAPI.deleteNotifyFile(idx, file_idx).then(() => {
      let copy = _.cloneDeep(postInput);
      copy.fileView.splice(depth, 1);
      setPostInput(copy);
    });
  };

  return (
    <div id="AdminCommunityPosting">
      <h2 className="page_title">커뮤니티 게시글 등록</h2>
      <div className="articles">
        <div className="field">
          <span>제목</span>
          {isView ? (
            <p>{postInput.title}</p>
          ) : (
            <input
              type="text"
              value={postInput.title}
              onChange={(e) =>
                setPostInput({ ...postInput, title: e.target.value })
              }
              placeholder="제목"
            />
          )}
        </div>

        <div className="field">
          <span>첨부파일</span>
          {isView ? (
            <div>
              {postInput.fileView.length ? (
                postInput.fileView.map((item, idx) => {
                  return (
                    <p>
                      {item.file_name + "." + item.file_type}
                      <button
                        className="icon_badge_del"
                        onClick={() => onClickDeleteFile(idx, item.idx)}
                      />
                    </p>
                  );
                })
              ) : (
                <p>첨부파일 없음</p>
              )}
            </div>
          ) : (
            <input
              type="file"
              onChange={(e) =>
                setPostInput({ ...postInput, file: e.target.files })
              }
              multiple
            />
          )}
        </div>

        <div className="field">
          <span>카테고리</span>
          {isView ? (
            <p>{category[postInput.category]?.name}</p>
          ) : (
            <select
              onChange={(e) =>
                setPostInput({ ...postInput, category: Number(e.target.value) })
              }
            >
              {category.map((item, idx) => {
                return (
                  <option
                    value={item.value}
                    onChange={(e) =>
                      setPostInput({ ...postInput, category: e.target.value })
                    }
                  >
                    {item.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>

        <div className="content">
          {isView ? (
            <p
              className="sun-editor-editable"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : (
            <SunEditor
              lang="ko"
              ref={editorRef}
              setContents={content}
              onChange={(e) => setContent(e)}
              setOptions={{
                height: 400,
                buttonList: [
                  ["undo", "redo", "font", "fontSize", "formatBlock"],
                  [
                    "bold",
                    "underline",
                    "italic",
                    "strike",
                    "subscript",
                    "superscript",
                    "removeFormat",
                  ],
                  [
                    "fontColor",
                    "hiliteColor",
                    "outdent",
                    "indent",
                    "align",
                    "horizontalRule",
                    "list",
                    "table",
                  ],
                  [
                    "link",
                    "image",
                    "video",
                    "fullScreen",
                    "codeView",
                    "preview",
                    "print",
                  ],
                  // ['link', 'image', 'video', 'fullScreen', 'showBlocks', 'codeView', 'preview', 'print', 'save']
                ],
              }}
            />
          )}
        </div>
      </div>

      <div className="btns">
        {isView ? (
          <button className="btn_post" onClick={onClickEditGo}>
            수정
          </button>
        ) : isEdit ? (
          <button className="btn_post" onClick={onClickEdit}>
            수정 완료
          </button>
        ) : (
          <button className="btn_post" onClick={onClickPost}>
            등록
          </button>
        )}
        <button
          className="btn_list"
          onClick={() => history.push("/admin/community/list")}
        >
          목록
        </button>
      </div>
    </div>
  );
}

export default AdminCommunityPosting;
