import { useEffect, useState } from "react";
import Menu from "./common/Menu";
import styles from "./css/ManageCompanyInfo.module.scss";
import ManageCompanyInfoForm from "./ManageCompanyInfoForm";
import CompanyAPI from "../../API/company";
import convertTimestamp from "../../util/convertTimestamp";
const ManageCompanyInfo = () => {
  const [companyInfo, setCompanyInfo] = useState({
    representative: "", // 대표자
    establishment_at: "", // 개업일 (YYYY-MM-DD 형식으로 저장)
    licence_number: "", // 사업자등록번호
    corporate_number: "", // 법인 등록번호
    location: "", // 사업장 소재지
    corporate_address: "", // 본점 소재지
    manager_name: "", // 대표 담당자 이름
    manager_email: "", // 대표 담당자 이메일
    manager_phone_number: "", // 대표 담당자 번호
  });
  console.log(companyInfo);

  // ✅ YYYY-MM-DD → 유닉스 타임 변환 함수
  const convertDateToUnix = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString).getTime();
  };

  // ✅ 회사 정보를 불러오는 API 요청 함수
  const getCompanyDetail = async () => {
    try {
      const response = await CompanyAPI.getCompanyDetail();
      const data = response.data[0]; // API 응답 데이터

      // 📌 개업일을 유닉스 타임에서 ISO 날짜 문자열(YYYY-MM-DD)로 변환
      const formattedData = {
        ...data,
        establishment_at: data.establishment_at
          ? convertTimestamp(data.establishment_at)
          : "",
      };

      setCompanyInfo(formattedData);
    } catch (error) {
      console.error("회사 정보 불러오기 실패:", error);
      alert("회사 정보를 불러오는 중 오류가 발생했습니다.");
    }
  };

  // ✅ 첫 렌더링 시 API 호출
  useEffect(() => {
    getCompanyDetail();
  }, []);

  // 📌 입력 값 업데이트 함수
  const handleChange = (key, value) => {
    setCompanyInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  // ✅ 저장 버튼 클릭 시 실행되는 함수
  const onClickSave = async () => {
    try {
      const payload = {
        ...companyInfo,
        establishment_at: companyInfo.establishment_at
          ? convertDateToUnix(companyInfo.establishment_at)
          : 0, // YYYY-MM-DD → 유닉스 변환
      };

      await CompanyAPI.updatedCompanyDetail(payload);
      alert("회사 정보가 성공적으로 저장되었습니다!");
    } catch (error) {
      console.error("회사 정보 저장 중 오류 발생:", error);
      alert("회사 정보를 저장하는 동안 오류가 발생했습니다.");
    }
  };

  return (
    <div className={styles.manageCompanyInfo}>
      <Menu number={12} />
      <div className={styles.wrapper}>
        <header>
          <h1>회사 정보 관리</h1>
          <button className={styles.saveButton} onClick={onClickSave}>
            저장하기
          </button>
        </header>
        <main>
          <ManageCompanyInfoForm
            companyInfo={companyInfo}
            onChange={handleChange}
          />
        </main>
      </div>
    </div>
  );
};

export default ManageCompanyInfo;
