import React from "react";
import { Link } from 'react-router-dom';
import "../css/Menu.scss";

function Menu({curPage, setCurPage}) {

    return (
        <div id="myPageMenu">
            <h2>My 페이지</h2>
            <ul>
                <li onClick={() => setCurPage(1)} className={curPage == 1 ? "active" : ""}>
                    <Link to="/mypage/info">
                        사용자 정보
                    </Link>
                </li>
                {/* <li onClick={() => setCurPage(2)} className={curPage == 2 ? "active" : ""}>
                    <Link to="/mypage/info">
                        기술이전 후보기업
                    </Link>
                </li> */}
            </ul>
        </div>
    )
}

export default Menu;
