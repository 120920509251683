import React, { useEffect, useState } from "react";
import styles from "./css/InventorModal.module.scss";
import InputField from "./InputField";

export default function InventorModal({ onClose, onAdd }) {
  const [name, setName] = useState("");
  const [engName, setEngName] = useState("");
  const [employeeNo, setEmployeeNo] = useState("");
  const [birth, setBirth] = useState("");
  const [position, setPosition] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [branch, setBranch] = useState("");
  const [address, setAddress] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const newInventor = {
      inventor_name: name,
      inventor_name_en: engName,
      birth_date: birth,
      phone_number: number,
      inventor_addr: address,
      email,
      is_employed: status,
      employee_no: employeeNo,
      position: position,
      branch_name: branch,
    };
    onAdd(newInventor);
    onClose();
  };

  // ✅ overlay 클릭 시 모달 닫기 (단, 내부 클릭은 방지)
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // ✅ ESC 키를 누르면 모달 닫기
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]); // onClose가 변경될 때마다 이벤트 리스너 등록/해제

  return (
    <div className={styles.overlay} onClick={handleOverlayClick}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h2>발명자 추가</h2>
          <p>
            <i className="icon_red_dot" />
            필수 입력항목입니다.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <InputField
            label="이름"
            required
            type="text"
            placeholder="이름 입력"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputField
            label="영문명"
            type="text"
            placeholder="영문명 입력"
            value={engName}
            onChange={(e) => setEngName(e.target.value)}
          />
          <InputField
            label="사번"
            type="text"
            placeholder="사번 입력"
            value={employeeNo}
            onChange={(e) => setEmployeeNo(e.target.value)}
          />
          <InputField
            label="생년월일"
            type="text"
            placeholder="YYYYMMDD"
            value={birth}
            onChange={(e) => setBirth(e.target.value)}
          />
          <InputField
            label="직급"
            type="text"
            placeholder="직급 입력"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
          <InputField
            label="전화"
            type="text"
            placeholder="전화 입력"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
          <InputField
            label="이메일"
            required
            type="email"
            placeholder="email@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <InputField
            label="지사명"
            type="text"
            placeholder="지사명 입력"
            value={branch}
            onChange={(e) => setBranch(e.target.value)}
          />
          <InputField
            label="주소"
            type="text"
            placeholder="주소 입력"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />

          <label className={styles.label}>상태</label>
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className={styles.select}
          >
            <option value="0">재직</option>
            <option value="1">퇴직</option>
          </select>

          <div className={styles.modalFooter}>
            <button
              type="button"
              className={styles.secondaryButton}
              onClick={onClose}
            >
              취소
            </button>
            <button type="submit" className={styles.primaryButton}>
              추가
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
