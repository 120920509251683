// pages/InventorMember.js
import React, { useContext, useEffect, useState } from "react";
import memberContext from "../../store/Member";
import Menu from "./common/Menu";
import "./css/InventorMember.scss";
import useInventorAPI from "../../hooks/useInventorAPI";
import InventorModal from "./InventorModal";
import Pagination from "../common/Pagination";
import AccountModal from "./AccountModal";

export default function InventorMember() {
  const memberInfo = useContext(memberContext);
  const isCompany = window.$Global.checkAuth("특허회사", memberInfo.auth);
  const isAgent = window.$Global.checkAuth("대리인", memberInfo.auth);

  // 커스텀 훅
  const {
    data,
    setData,
    getInventors,
    addInventorAPI,
    updateInventorAPI,
    deleteInventorAPI,
  } = useInventorAPI({ isCompany, isAgent });

  const [showModal, setShowModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false); // ✅ 계정 생성 모달 상태
  const [selectedInventor, setSelectedInventor] = useState(null); // ✅ 계정 생성 시 선택된 발명자 정보
  const [isManaging, setIsManaging] = useState(false); // ✅ 계정 관리 여부 상태 추가
  const [searchField, setSearchField] = useState("inventor_name"); // 검색 필드 (이름 또는 이메일)
  const [searchKeyword, setSearchKeyword] = useState(""); // 검색 키워드

  // 마운트 시 발명자 목록 로드
  useEffect(() => {
    getInventors();
  }, [getInventors]);

  const handleSearch = () => {
    // 검색 키워드와 필드를 기반으로 API 호출
    getInventors({
      page: 1,
      keyword_type: searchField,
      keyword: searchKeyword,
    });
  };

  const handlePageChange = (newPage) => {
    getInventors({ page: newPage });
  };
  /** 자식 모달에서 온 데이터 → 훅의 addInventorAPI 호출 */
  const addInventor = (obj) => {
    // DB 반영
    addInventorAPI(obj);
  };

  /** 편집 모드 시 필드 변경 */
  const handleChange = (e, item, key) => {
    item[key] = e.target.value;
    setData({ ...data }); // 얕은 복사 후 갱신
  };

  /** 편집 모드 토글 */
  const toggleEdit = (item) => {
    item.isEdit = !item.isEdit;
    setData({ ...data });
  };

  // 모달 열기/닫기
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  // ✅ 계정 생성 모달 열기
  const openAccountModal = (inventor, isManaging) => {
    setSelectedInventor(inventor);
    setIsManaging(isManaging);
    setShowAccountModal(true);
  };

  const closeAccountModal = () => {
    setShowAccountModal(false);
    setSelectedInventor(null);
  };

  return (
    <div id="InventorMember">
      <Menu number={17} />
      <div className="inventor_wrapper">
        <header>
          <div>
            <p>발명자 목록</p>
            <button onClick={openModal}>+ 추가하기</button>
          </div>
          <form
            className="search_bar"
            onSubmit={(e) => {
              e.preventDefault(); // 기본 폼 제출 동작 방지
              handleSearch(); // 검색 함수 호출
            }}
          >
            <select
              id="searchField"
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
            >
              <option value="inventor_name">이름</option>
              <option value="email">이메일</option>
            </select>
            <input
              id="searchKeyword"
              type="text"
              placeholder={`${
                searchField === "inventor_name" ? "이름" : "이메일"
              }을 입력하세요`}
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />

            <button type="submit">검색</button>
          </form>
        </header>

        <div className="table_wrapper custom_scroll">
          <table>
            <thead>
              <tr>
                <th>순번</th>
                <th>이름</th>
                <th>영문명</th>
                <th>사번</th>
                <th>생년월일</th>
                <th>직급</th>
                <th>전화</th>
                <th>이메일</th>
                <th>지사명</th>
                <th>주소</th>
                <th>상태</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.data?.map((item, index) =>
                item.isEdit ? (
                  <tr key={item.inventor_idx ?? `temp-${index}`}>
                    <td>{10 * (data.page - 1) + index + 1}</td>
                    <td>
                      <input
                        type="text"
                        value={item.inventor_name || ""}
                        onChange={(e) => handleChange(e, item, "inventor_name")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.inventor_name_en || ""}
                        onChange={(e) =>
                          handleChange(e, item, "inventor_name_en")
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.employee_no || ""}
                        onChange={(e) =>
                          handleChange(e, item, "employee_no")
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.birth_date || ""}
                        onChange={(e) => handleChange(e, item, "birth_date")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.position || ""}
                        onChange={(e) => handleChange(e, item, "position")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.phone_number || ""}
                        onChange={(e) => handleChange(e, item, "phone_number")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.email || ""}
                        onChange={(e) => handleChange(e, item, "email")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.branch_name || ""}
                        onChange={(e) => handleChange(e, item, "branch_name")}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={item.inventor_addr || ""}
                        onChange={(e) => handleChange(e, item, "inventor_addr")}
                      />
                    </td>
                    <td>
                      <select
                        value={item.is_employed}
                        onChange={(e) => handleChange(e, item, "is_employed")}
                      >
                        <option value="0">재직</option>
                        <option value="1">퇴직</option>
                      </select>
                    </td>
                    <td>
                      <i
                        className="icon_check_black"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          item.idx
                            ? updateInventorAPI(item)
                            : addInventorAPI(item)
                        }
                      />
                      <i
                        className="icon_badge_del"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteInventorAPI(item, index)}
                      />
                    </td>
                  </tr>
                ) : (
                  <tr key={item.inventor_idx ?? `temp-${index}`}>
                    <td>{10 * (data.page - 1) + index + 1}</td>
                    <td>{item.inventor_name}</td>
                    <td>{item.inventor_name_en}</td>
                    <td>{item.employee_no}</td>
                    <td>{item.birth_date}</td>
                    <td>{item.position}</td>
                    <td>{item.phone_number}</td>
                    <td>{item.email}</td>
                    <td>{item.branch_name}</td>
                    <td>{item.inventor_addr}</td>
                    <td>
                      {item.is_employed === 0
                        ? "재직"
                        : item.is_employed === 1
                        ? "퇴직"
                        : "선택 안 됨"}
                    </td>
                    <td>
                      <div className="btn-group">
                        {/* 수정 버튼 */}
                        <button
                          className="btn btn-outline-dark"
                          onClick={() => toggleEdit(item)}
                        >
                          수정
                        </button>

                        {/* 계정 연결 / 계정 관리 버튼 (조건부 렌더링) */}
                        {item.member_idx ? (
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => openAccountModal(item, true)}
                          >
                            계정 관리
                          </button>
                        ) : (
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => openAccountModal(item, false)}
                          >
                            계정 연결
                          </button>
                        )}
                        {/* 삭제 버튼 */}
                        <i
                          className="icon_badge_del"
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteInventorAPI(item, index)}
                        />
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {data?.data?.length > 0 && (
          <Pagination
            curPage={+data.page}
            lastNum={Math.ceil(data.total_count / 10)}
            onClick={handlePageChange}
            disableMoveToTop={true}
          />
        )}
      </div>

      {/* 발명자 추가 모달 */}
      {showModal && <InventorModal onClose={closeModal} onAdd={addInventor} />}
      {/* 계정 생성 모달 */}
      {showAccountModal && (
        <AccountModal
          inventor={selectedInventor}
          isManaging={isManaging}
          onClose={closeAccountModal}
        />
      )}
    </div>
  );
}
