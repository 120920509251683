import PDFDocument from "../common/PDFDocument";
import React, { useContext, useEffect, useState } from "react";
import CommonAPI from "../../API/common";
import "./css/IntroService.scss";
import CommonContext from "../../store/Common";

const IntroGovProject = () => {
  const [PDFList, setPDFList] = useState([]);
  const [filePDF, setFilePDF] = useState(null);
  const { isMobile } = useContext(CommonContext);

  useEffect(() => {
    CommonAPI.getTaskPDFList().then((res) => {
      setPDFList(res.data.items.sort((a, b) => a.order - b.order));
    });
  }, []);

  return (
    <div id="IntroService">
      <img
        src={
          require(isMobile
            ? "../../assets/images/intro/page_banner_top_mo.png"
            : "../../assets/images/intro/page_banner_top.png").default
        }
        className="banner_top"
        alt="탑 배너"
      />
      <div className="upload_area">
        <h2 className="title">2023년도 정부지원사업 핵심공고</h2>
        <div className="upload_list">
          {PDFList.map((item) => {
            return (
              <div
                key={item.idx}
                className="upload_item"
                onClick={() => setFilePDF(item)}
              >
                {isMobile && (
                  <img
                    src={
                      require("../../assets/images/common/img-gov-pdf.svg")
                        .default
                    }
                  />
                )}
                <p>{item.title}</p>
                <button className="btn_pdf_download">
                  PDF 내려받기
                  <i className="icon_download_attached_white" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
      {filePDF && <PDFDocument file={filePDF} setFile={setFilePDF} />}
    </div>
  );
};

export default IntroGovProject;
