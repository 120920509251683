import "./css/AnnualFeeReviewSecond.scss";
import React, { useEffect, useState } from "react";
import AnnualPay from "../../API/annualPay";
import AdminAPI from "../../API/admin";

const AnnualFeeReviewSecond = ({
  annualFeeData,
  setShowPopup,
  showPatent,
  isAdmin,
}) => {
  const [data, setData] = useState([]);
  const [sendData, setSendData] = useState({
    memo: "",
    file: [],
    adminMemo: "",
    adminFile: [],
  });
  const [fileName, setFileName] = useState([]);
  const [reviewstate, setreviewstate] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (sendData.memo !== undefined && sendData.memo !== null) {
      // sendData.memo 값이 존재하는 경우
      if (sendData.memo === "") {
        setreviewstate(0); // sendData.memo에 아무 값도 없는 경우
      } else {
        setreviewstate(1); // sendData.memo에 값이 있는 경우
      }
    }
  }, [sendData]);

  const fetchData = () => {
    const getDetail = (apiMethod, idx1, idx2) => {
      apiMethod(idx1, idx2).then((res) => {
        setData(res.data);
        setSendData({
          memo: res.data.memo,
          file: res.data.file,
          adminMemo: res.data.adminMemo, // GET으로 데이터 불러왔을때 Server에서 보내준 값 넣어주시면 됩니다. (관리자 메모)
          adminFile: res.data.adminFile, // GET으로 데이터 불러왔을때 Server에서 보내준 값 넣어주시면 됩니다. (관리자 첨부파일)
        });
      });
    };

    switch (showPatent) {
      case "patent":
        return isAdmin
          ? getDetail(
              AdminAPI.getAdminPatentAnnualPay,
              annualFeeData.company_idx,
              annualFeeData.annual_pay_idx
            )
          : getDetail(
              AnnualPay.getPatentPayDetail,
              annualFeeData.patent_idx,
              annualFeeData.annual_pay_idx
            );
      case "trademark":
        return isAdmin
          ? getDetail(
              AdminAPI.getAdminTradeMarkAnnualPay,
              annualFeeData.company_idx,
              annualFeeData.annual_pay_idx
            )
          : getDetail(
              AnnualPay.getTrademarkPayDetail,
              annualFeeData.trademark_idx,
              annualFeeData.annual_pay_idx
            );
      case "design":
        return isAdmin
          ? getDetail(
              AdminAPI.getAdminDesignAnnualPay,
              annualFeeData.company_idx,
              annualFeeData.annual_pay_idx
            )
          : getDetail(
              AnnualPay.getDesignPayDetail,
              annualFeeData.design_idx,
              annualFeeData.annual_pay_idx
            );
      default:
        return;
    }
  };

  const handleChange = (e) => {
    if (e.currentTarget.name === "memo") {
      setSendData({
        ...sendData,
        memo: e.currentTarget.value,
      });
    } else if (e.currentTarget.name === "adminMemo") {
      setSendData({
        ...sendData,
        adminMemo: e.currentTarget.value,
      });
    } else if (e.currentTarget.name === "file") {
      setFileName([]);
      const fileArray = Array.from(e.currentTarget.files);
      fileArray.map((el) => {
        sendData.file.push(el);
        setFileName((prevState) => [...prevState, el.name]);
      });
      e.currentTarget.value = null;
    } else if (e.currentTarget.name === "admin_file") {
      setFileName([]);
      const fileArray = Array.from(e.currentTarget.files);
      fileArray.map((el) => {
        sendData.adminFile.push(el);
        setFileName((prevState) => [...prevState, el.name]);
      });
      e.currentTarget.value = null;
    }
  };

  const handleClick = (e) => {
    const formData = new FormData();
    const comment_idx = data.comment.find(
      (item) => item.my_comment === true
    ).idx;

    formData.append("memo", isAdmin ? sendData.adminMemo : sendData.memo);
    let checkFile = [];
    sendData.file.map((el) => {
      formData.append("file", el);
      if (el.file_key) {
        checkFile.push(el.file_key);
      }
    });
    formData.append("checkFile", checkFile);

    sendData.adminFile.map((el) => {
      formData.append("adminFile", el);
    });

    formData.append("isAdmin", isAdmin);
    formData.append("companyIdx", annualFeeData.company_idx);

    let obj = {
      isAdmin,
      comment: isAdmin ? sendData.adminMemo : sendData.memo,
      companyIdx: annualFeeData.company_idx,
      deletedFiles: deletedFiles,
    };

    switch (showPatent) {
      case "patent":
        AnnualPay.uploadPatentPayData(
          annualFeeData.patent_idx,
          annualFeeData.annual_pay_idx,
          formData
        )
          .then(() =>
            AnnualPay.updatePatentComment(
              annualFeeData.patent_idx,
              annualFeeData.annual_pay_idx,
              comment_idx,
              obj
            )
          )
          .then(() => {
            alert("등록되었습니다.");
            fetchData();
            setDeletedFiles([]);
          });
        break;
      case "trademark":
        AnnualPay.uploadTradeMarkPayData(
          annualFeeData.trademark_idx,
          annualFeeData.annual_pay_idx,
          formData
        )
          .then(() =>
            AnnualPay.updateTrademarkComment(
              annualFeeData.trademark_idx,
              annualFeeData.annual_pay_idx,
              comment_idx,
              obj
            )
          )
          .then(() => {
            alert("등록되었습니다.");
            fetchData();
            setDeletedFiles([]);
          });
        break;
      case "design":
        AnnualPay.uploadDesignPayData(
          annualFeeData.design_idx,
          annualFeeData.annual_pay_idx,
          formData
        )
          .then(() =>
            AnnualPay.updateDesignComment(
              annualFeeData.design_idx,
              annualFeeData.annual_pay_idx,
              comment_idx,
              obj
            )
          )
          .then(() => {
            alert("등록되었습니다.");
            fetchData();
            setDeletedFiles([]);
          });
        break;
    }
  };

  const deleteFile = (data, name, e) => {
    let check = false;
    if (data.lastModified) {
      check = true;
    }

    if (name === "admin") {
      const fileArray = sendData?.adminFile?.filter((el) => {
        const isMatch = check
          ? el.lastModified !== data.lastModified
          : el.file_key !== data.file_key;
        if (!isMatch) {
          setDeletedFiles((prevFiles) => [
            ...prevFiles,
            { idx: el.idx, file_key: el.file_key },
          ]);
        }
        return isMatch;
      });
      setSendData({
        ...sendData,
        adminFile: fileArray,
      });
    } else {
      const fileArray = sendData?.file?.filter((el) => {
        const isMatch = check
          ? el.lastModified !== data.lastModified
          : el.file_key !== data.file_key;
        if (!isMatch) {
          setDeletedFiles((prevFiles) => [
            ...prevFiles,
            { idx: el.idx, file_key: el.file_key },
          ]);
        }
        return isMatch;
      });
      setSendData({
        ...sendData,
        file: fileArray,
      });
    }
  };
  const downloadFile = (file_idx) => {
    let url = (function () {
      switch (showPatent) {
        case "patent":
          return isAdmin
            ? `admin/company/${annualFeeData.company_idx}/patent/annualPay/${annualFeeData.annual_pay_idx}/file/${file_idx}/download`
            : `/manager/patent/${annualFeeData.patent_idx}/annualPay/${annualFeeData.annual_pay_idx}/file/${file_idx}/download`;
        case "trademark":
          return isAdmin
            ? `admin/company/${annualFeeData.company_idx}/trademark/annualPay/${annualFeeData.annual_pay_idx}/file/${file_idx}/download`
            : `/manager/trademark/${annualFeeData.trademark_idx}/annualPay/${annualFeeData.annual_pay_idx}/file/${file_idx}/download`;
        case "design":
          return isAdmin
            ? `admin/company/${annualFeeData.company_idx}/design/annualPay/${annualFeeData.annual_pay_idx}/file/${file_idx}/download`
            : `/manager/design/${annualFeeData.design_idx}/annualPay/${annualFeeData.annual_pay_idx}/file/${file_idx}/download`;
      }
    })();
    window.$Global.fileDownloadSync(url);
  };

  return (
    <div id="AnnualFeeReviewSecond" className="custom_scroll">
      <div className="annual_fee_review_header">
        <div>
          <h2>연차료 납부 검토</h2>
          <i
            className="icon_exit_gray"
            onClick={() =>
              setShowPopup({
                first: false,
                second: false,
              })
            }
          />
        </div>
        {/*<button*/}
        {/*  onClick={() =>*/}
        {/*    window.open(`/patent/detail/${annualFeeData.patent_idx}`, "_blank")*/}
        {/*  }*/}
        {/*>*/}
        {/*  포기/양도 확인 메일 송부*/}
        {/*</button>*/}
      </div>
      <div className="annual_fee_review_body">
        <div className="review_manage_number">
          <p>관리번호</p>
          <p>{annualFeeData.manage_number}</p>
        </div>
        <div
          className="review_manage_number"
          style={{ paddingBottom: "20.5px" }}
        >
          <p>특허연차</p>
          <p>{data.payment_year}년차</p>
          <button
            onClick={() => {
              isAdmin
                ? setShowPopup({
                    first: false,
                    second: false,
                  })
                : setShowPopup({
                    first: true,
                    second: false,
                  });
            }}
          >
            목록으로 돌아가기
          </button>
        </div>
        <div className="review_manage_number">
          <p>회사명</p>
          <p>{data.comment?.find((item) => item.my_comment === true).name}</p>
        </div>
        <textarea
          placeholder="입력하세요."
          name="memo"
          value={sendData.memo}
          onChange={handleChange}
          readOnly={isAdmin}
        />
        {data.history?.length > 0 && (
          <div className="review_history">
            <h5>History</h5>
            <table>
              <thead>
                <tr>
                  <th>Comment</th>
                  <th>작성일시</th>
                </tr>
              </thead>
              <tbody>
                {data.history?.map((item) => {
                  return (
                    <tr>
                      <td>{item.comment}</td>
                      <td>{window.$Global.convertDate(item.created_at)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <div
          className="review_manage_number review_upload_file"
          style={{ borderBottom: "none" }}
        >
          <p>첨부파일</p>
          <div>
            <div className="file_list_items">
              {sendData?.file?.map((el) => {
                return (
                  <div className="file_list_item">
                    <p
                      onClick={() =>
                        el.file_key.length > 0 && downloadFile(el.idx)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {el.name || el.file_name}
                      {el.file_type ? `.${el.file_type}` : ""}
                    </p>
                    {!isAdmin && (
                      <i
                        className="icon_badge_del"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteFile(el)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            {!isAdmin && (
              <div className="file_input">
                <label htmlFor="file">첨부파일 등록하기</label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  multiple
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        </div>
        <div className="review_manage_comment">
          <p className="title">공동 출원인</p>
          <p className="commenter">
            {data?.comment?.length === 0 ? (
              <span>없음</span>
            ) : (
              data?.comment?.map((el) => {
                return <span>{el.name}</span>;
              })
            )}
          </p>
        </div>
        {data.comment
          ?.filter((item) => item.my_comment === false)
          .map((item) => {
            return (
              <>
                <div className="review_manage_number">
                  <p>회사명</p>
                  <p>{item.name}</p>
                </div>
                <textarea
                  placeholder="입력하세요."
                  name="memo"
                  value={item.comment}
                  onChange={handleChange}
                  readOnly={true}
                />
              </>
            );
          })}
      </div>
      <div className="annual_fee_review_body">
        <h2>IPNOW 관리자</h2>
        <textarea
          placeholder="입력하세요."
          name="adminMemo"
          value={sendData.adminMemo}
          onChange={handleChange}
          readOnly={!isAdmin}
        />
        <div
          className="review_manage_number review_upload_file"
          style={{ borderBottom: "none" }}
        >
          <p>첨부파일</p>
          <div>
            <div className="file_list_items">
              {sendData?.adminFile?.map((el) => {
                return (
                  <div className="file_list_item">
                    <p
                      onClick={() =>
                        el.file_key.length > 0 && downloadFile(el.idx)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {el.name || el.file_name}
                      {el.file_type ? `.${el.file_type}` : ""}
                    </p>
                    {isAdmin && (
                      <i
                        className="icon_badge_del"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteFile(el, "admin")}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            {isAdmin && (
              <div className="file_input">
                <label htmlFor="file">첨부파일 등록하기</label>
                <input
                  type="file"
                  name="admin_file"
                  id="file"
                  multiple
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="review_manage_footer">
        <button onClick={handleClick}>저&emsp;장</button>
      </div>
    </div>
  );
};

export default AnnualFeeReviewSecond;
