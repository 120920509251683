import links from "../../../const/links";

export default {
  match_admin: [
    { url: "/admin/check", name: "가입자 확인" },
    {
      url: "/admin/list/news",
      url2: "/admin/list/youtube",
      name: "IP담보대출",
    },
    // { url: '/admin/list/video', name: '동영상' },
    {
      url: "/admin/service",
      redirectURL: "/admin/service/list",
      name: "서비스",
    },
    {
      url: "/admin/manage",
      redirectURL: "/admin/manage/list",
      name: "관리메뉴",
    },
    // { url: '/admin/list/document', name: '문서' },
    { url: "/admin/list/edu", name: "특허교육자료" },
    {
      url: "/admin/community",
      redirectURL: "/admin/community/list",
      name: "커뮤니티",
    },
    { url: "/admin/invest", name: "특허투자자" },
    { url: "/admin/freeUs", name: "미국출원" },
    { url: "/admin/account", name: "계정생성" },
    { url: "/admin/annual", name: "연차료관리" },
    {
      url: "/admin/annual/sales",
      redirectURL: "/admin/annual/sales",
      name: "매출관리",
    },
  ],

  match_inventor: [
    { url: "/patent/", redirectURL: "/patent/list", name: "특허" },
    {
      url: "/invention/",
      redirectURL: "/invention/list",
      name: "직무발명신고서",
    },
  ],

  match_intro_ipnow: [{ url: "/people", name: "People" }],

  match_intro_login: [
    { url: "/", name: "HOME" },
    { url: links.taskManage, name: "정부사업 찾기" },
    { url: links.marketInfo, name: "시장 정보" },
    { url: "/intro/page", name: "2023 부처별 통합공고" },
    { url: "/intro/page2", name: "2023 정부지원사업" },
    {
      url: links.exposition,
      name: "정부사업 주요 소식",
      child_Tab: [
        {
          url: links.exposition,
          name: "행사정보",
        },
        {
          url: links.businessDescription,
          name: "사업설명회 영상",
        },
        {
          url: links.govSupport,
          name: "정부지원 정책",
        },
      ],
    },
  ],
  match_intro: [
    { url: links.taskManage, name: "정부사업 찾기" },
    { url: links.marketInfo, name: "시장 정보" },
    { url: "/intro/page", name: "2023 부처별 통합공고" },
    { url: "/intro/page2", name: "2023 정부지원사업" },
    {
      url: links.exposition,
      name: "정부사업 주요 소식",
      child_Tab: [
        {
          url: links.exposition,
          name: "행사정보",
        },
        {
          url: links.businessDescription,
          name: "사업설명회 영상",
        },
        {
          url: links.govSupport,
          name: "정부지원 정책",
        },
      ],
    },
  ],

  match_openMarket: [{ url: "/trade/list", name: "상표거래" }],

  match_findx: [
    { url: links.taskManage, name: "사업 리스트" },
    // { url: '/consortium/list', name: '사업 파트너 기업' },
    { url: links.marketInfo, name: "시장 정보 보기" },
    { url: "/docTrade/list", name: "제안서 샘플마켓" },
    { url: "/exposition/list", name: "교육·행사·경진" },
    { url: "/businessDescription/list", name: "사업 설명회" },
    { url: "/govSupport/list", name: "정부지원 정책알림" },
    { url: "/consultCom/list", name: "컨설팅 기업" },
  ],

  match_ip_service: [
    { url: "/patent/", redirectURL: "/patent/list", name: "특허" },
    { url: "/tradeMark/", redirectURL: "/tradeMark/list", name: "상표" },
    { url: "/design/", redirectURL: "/design/list", name: "디자인" },
    { url: "/deadline/", redirectURL: "/deadline/list", name: "기한관리" },
    { url: "/annual/graph", redirectURL: "/annual/graph", name: "연차료관리" },
    // {
    //   url: "/kautum/statistic",
    //   redirectURL: "/kautum/statistic",
    //   name: "심플 통계",
    // },
    // { url: '/application/', redirectURL: '/application/list', name: '특허 활용 정보', activeTab: 3},
    // { url: '/trade/', redirectURL: '/trade/list', name: '상표거래', activeTab: 4},
  ],

  match_ip_service_login: [
    { url: "/patent/", redirectURL: "/patent/list", name: "특허" },
    { url: "/tradeMark/", redirectURL: "/tradeMark/list", name: "상표" },
    { url: "/design/", redirectURL: "/design/list", name: "디자인" },
    { url: "/deadline/", redirectURL: "/deadline/list", name: "기한관리" },
    { url: "/annual/graph", redirectURL: "/annual/graph", name: "연차료관리" },
    {
      url: "/kautum/statistic",
      redirectURL: "/kautum/statistic",
      name: "심플 통계",
    },
  ],

  match_due_service_login: [
    { url: "/patent/", redirectURL: "/patent/list", name: "특허" },
    { url: "/tradeMark/", redirectURL: "/tradeMark/list", name: "상표" },
    { url: "/design/", redirectURL: "/design/list", name: "디자인" },
    { url: "/deadline/", redirectURL: "/deadline/list", name: "기한관리" },
    { url: "/annual/graph", redirectURL: "/annual/graph", name: "연차료관리" },
    {
      url: "/dueDi/dashboard",
      redirectURL: "/dueDi/dashboard",
      name: "특허 통계 (자산실사)",
    },
    {
      url: "/tech/transfer",
      redirectURL: "/tech/transfer",
      name: "기술이전관리",
    },
  ],

  match_ip_statistic_login: [
    { url: "/patent/", redirectURL: "/patent/list", name: "특허" },
    { url: "/tradeMark/", redirectURL: "/tradeMark/list", name: "상표" },
    { url: "/design/", redirectURL: "/design/list", name: "디자인" },
    { url: "/deadline/", redirectURL: "/deadline/list", name: "기한관리" },
    { url: "/annual/graph", redirectURL: "/annual/graph", name: "연차료관리" },
    {
      url: "/dueDi/dashboard",
      redirectURL: "/dueDi/dashboard",
      name: "특허 통계 (자산실사)",
    },
    {
      url: "/kautum/statistic",
      redirectURL: "/kautum/statistic",
      name: "심플 통계",
    },
    {
      url: "/tech/transfer",
      redirectURL: "/tech/transfer",
      name: "기술이전관리",
    },
  ],

  match_groupware: [
    { url: "/renewal/groupware", name: "그룹웨어 HOME" },
    { url: "/renewal/groupware", name: "메일" },
    { url: "/renewal/groupware", name: "업무공유" },
    { url: "/renewal/groupware", name: "전자결재" },
    { url: "/renewal/groupware", name: "오피스폼" },
  ],
};
