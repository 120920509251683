// hooks/useInventorAPI.js
import { useCallback, useState } from "react";
import API from "../util/api";

export default function useInventorAPI({ isCompany, isAgent }) {
  const [data, setData] = useState([]);

  /** 발명자 목록 조회 */
  const getInventors = useCallback(
    async ({ page = 1, keyword_type, keyword, count = 10 } = {}) => {
      const params = {
        page,
        count,
        ...(keyword_type && keyword && { keyword_type, keyword }), // 검색 조건 추가
      };
      try {
        const res = await API.get("/manager/inventor/getInventor", {
          params,
        });
        const { page, total_count, data } = res.data;
        const newData = data.map((item) => {
          return { ...item, isEdit: false };
        });
        setData({
          page,
          total_count,
          data: newData,
        });
      } catch (error) {
        console.error("발명자 목록 조회 실패:", error);
      }
    },
    []
  );

  /** 발명자 추가 */
  const addInventorAPI = useCallback(
    async (item) => {
      // if (!window.$Global.validateBirthDate(item.birth_date)) {
      //   alert("유효하지 않은 생년월일 입니다.");
      //   return;
      // }
      if (!window.$Global.validateEmailAddress(item.email)) {
        alert("유효하지 않은 이메일 입니다.");
        return;
      }
      let payload = {
        birth_date: item.birth_date,
        email: item.email,
        phone_number: item.phone_number,
        inventor_name: item.inventor_name,
        inventor_name_en: item.inventor_name_en,
        inventor_addr: item.inventor_addr,
        is_employed: item.is_employed,
        employee_no: item.employee_no,
        position: item.position,
        branch_name: item.branch_name,
      };

      try {
        if (isCompany) {
          // 특허회사 권한
          await API.post("/manager/inventor", payload);
          getInventors(); // 재조회
        } else if (isAgent) {
          // 대리인 권한
          await API.post("/agent/inventor", payload);
          // 필요한 경우 getInventors() 실행
        } else {
          alert("권한이 없습니다.");
        }
      } catch (error) {
        console.error("발명자 추가 실패:", error);
      }
    },
    [isCompany, isAgent, getInventors]
  );

  /** 발명자 수정 */
  const updateInventorAPI = useCallback(
    async (item) => {
      if (!window.$Global.validateBirthDate(item.birth_date)) {
        alert("유효하지 않은 생년월일 입니다.");
        return;
      }
      if (!window.$Global.validateEmailAddress(item.email)) {
        alert("유효하지 않은 이메일 입니다.");
        return;
      }
      let payload = {
        birth_date: item.birth_date,
        phone_number: item.phone_number,
        email: item.email,
        inventor_name: item.inventor_name,
        inventor_name_en: item.inventor_name_en,
        inventor_addr: item.inventor_addr,
        is_employed: item.is_employed,
        employee_no: item.employee_no,
        position: item.position,
        branch_name: item.branch_name,
      };

      try {
        await API.patch(`/manager/inventor/${item.inventor_idx}`, payload);
        getInventors();
      } catch (error) {
        console.error("발명자 업데이트 실패:", error);
      }
    },
    [getInventors]
  );

  /* 발명자 삭제 */
  const deleteInventorAPI = useCallback(
    async (item, index) => {
      // 사용자에게 확인 요청
      const isConfirmed = window.confirm(
        "연결된 계정이 있을 경우 함께 삭제되며, 복구할 수 없습니다. \n삭제하시겠습니까?"
      );

      if (!isConfirmed) return; // 취소 시 함수 종료

      if (item.idx) {
        try {
          // 삭제 요청 리스트 생성
          const deleteRequests = [
            API.delete(`/manager/inventor/${item.inventor_idx}`),
          ];

          // 만약 item.member_idx가 존재하면 추가 요청
          if (item.member_idx) {
            deleteRequests.push(
              API.delete(`/manager/inventor/member/${item.inventor_idx}`)
            );
          }

          // 병렬로 실행
          await Promise.all(deleteRequests);

          getInventors(); // 목록 갱신
        } catch (error) {
          console.error("발명자 삭제 실패:", error);
        }
      } else {
        // 아직 DB에 없는 임시 항목 (화면에서만 제거)
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
      }
    },
    [getInventors]
  );

  return {
    data,
    setData,
    getInventors,
    addInventorAPI,
    updateInventorAPI,
    deleteInventorAPI,
  };
}
