import React, { useEffect } from "react";
import Styles from "./confirmModal.module.scss";
import { Xicon } from "./icons";

const ConfirmModal = ({ type, onConfirm, onCancel, message }) => {
  useEffect(() => {
    if (type === "SUCCESS" || type === "ERROR") {
      const timer = setTimeout(() => {
        onConfirm && onConfirm();
        onCancel && onCancel();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [type, onConfirm, onCancel]);
  return (
    <>
      {type === "CONFIRM_DELETE" ? (
        <div className={`${Styles["confirm-div"]} ${Styles["delete-modal"]}`}>
          <h1>해당 수금액을 삭제하시겠습니까?</h1>
          <div>
            <button onClick={onConfirm} type="button">
              확인
            </button>
            <button onClick={onCancel} type="button">
              취소
            </button>
          </div>
        </div>
      ) : type === "ERROR" ? (
        <div
          className={`${Styles["new-toast"]}`}
          style={{ backgroundColor: "#E42B2B" }}
        >
          <h1>{message ?? "문제가 발생했습니다. 다시 시도해 주세요."}</h1>
          <button onClick={onCancel} type="button">
            {Xicon()}
          </button>
        </div>
      ) : (
        <div
          className={`${Styles["new-toast"]}`}
          style={{ backgroundColor: "#13C53C" }}
        >
          <h1>{message ?? "수금액 추가에 성공했습니다."}</h1>
          <button onClick={onConfirm} type="button">
            {Xicon()}
          </button>
        </div>
      )}
    </>
  );
};

export default ConfirmModal;
