import React, { forwardRef, useLayoutEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../datepicker/datepicker.scss";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
registerLocale("ko", ko);
setDefaultLocale(ko);

const CustomInput = forwardRef(
  (
    {
      value = "",
      onClick,
      className,
      onChange,
      onInputChange,
      onClear,
      placeholderTxt,
      disabledd,
    },
    ref
  ) => {
    const handleInputChange = (e) => {
      let inputValue = e.target.value;

      // 입력값이 비어있을 경우 날짜를 지우는 처리
      if (inputValue === "") {
        onClear();
        return;
      }

      // 숫자만 추출
      inputValue = inputValue.replace(/\D/g, "");

      if (inputValue.length > 4 && inputValue.length <= 6) {
        inputValue = `${inputValue.slice(0, 4)}-${inputValue.slice(4)}`;
      } else if (inputValue.length > 6) {
        inputValue = `${inputValue.slice(0, 4)}-${inputValue.slice(
          4,
          6
        )}-${inputValue.slice(6, 8)}`;
      }

      const syntheticEvent = {
        target: {
          value: inputValue,
        },
      };

      if (/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) onInputChange(syntheticEvent);
      onChange(syntheticEvent);
    };

    const handleKeyDown = (e) => {
      // 백스페이스나 Delete 키를 누르고 값이 없거나 모든 텍스트가 선택된 경우
      if (
        (e.key === "Backspace" || e.key === "Delete") &&
        (e.target.value === "" ||
          (e.target.selectionStart === 0 &&
            e.target.selectionEnd === e.target.value.length))
      ) {
        onClear();
      }
    };

    return (
      <div
        className="datepicker-input-container"
        style={{ position: "relative", display: "inline-block" }}
      >
        <input
          type="text"
          value={value}
          className={className}
          onClick={onClick}
          ref={ref}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          maxLength={10}
          placeholder={placeholderTxt}
          disabled={disabledd}
        />
        {value && !disabledd && (
          <button
            type="button"
            onClick={onClear}
            className="datepicker-clear-button"
            aria-label="날짜 지우기"
          >
            ×
          </button>
        )}
      </div>
    );
  }
);

function DatePickerCustom({
  data,
  name = "",
  onChangeDatePicker,
  minDate,
  maxDate,
  readOnly,
  placeholderTxt,
  disabledd = false,
}) {
  const [inputValue, setInputValue] = useState(data || "");

  useLayoutEffect(() => setInputValue(data || ""), [data]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (/^\d{4}-\d{2}-\d{2}$/.test(newValue)) {
      onChangeDatePicker(new Date(newValue));
    }
  };

  const handleClear = () => {
    setInputValue("");
    onChangeDatePicker(null); // null을 전달하여 날짜가 선택되지 않았음을 알림
  };

  const setWeekendColor = (date) => {
    return date.getDay() === 0 || date.getDay() === 6 ? "color_red" : "";
  };

  const range = (start, end) => {
    return new Array(end - start).fill().map((d, i) => i + start);
  };

  const years = range(1900, getYear(new Date()) + 20, 1);
  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="date_header">
          <div className="select_boxes">
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
              style={{
                width: 70,
                height: 24,
                margin: 0,
                marginRight: 15,
                padding: 0,
                paddingLeft: 10,
                border: "none",
                zIndex: 100,
              }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={months[getMonth(date)]} // 수정: months 배열 인덱스 직접 사용
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
              style={{
                width: 60,
                height: 24,
                margin: 0,
                padding: 0,
                paddingLeft: 5,
                border: "none",
              }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <button
            className="btn_date_next"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          />
        </div>
      )}
      selected={inputValue === "" ? null : new Date(inputValue)} // 문자열을 Date 객체로 변환
      onSelect={(date) => {
        const formattedDate = date ? date.toISOString().split("T")[0] : "";
        setInputValue(formattedDate);
        onChangeDatePicker(date);
      }}
      shouldCloseOnSelect={true}
      customInput={
        <CustomInput
          value={inputValue}
          placeholderTxt={placeholderTxt}
          disabledd={disabledd}
          onInputChange={handleInputChange}
          onClear={handleClear}
        />
      }
      dayClassName={(date) => setWeekendColor(date)}
      minDate={minDate}
      maxDate={maxDate}
      name={name}
      readOnly={readOnly}
      dateFormat="yyyy-MM-dd"
    />
  );
}

export default DatePickerCustom;
