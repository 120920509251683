import React, { useState } from "react";
import Tooltip from "./Tooltip";

const InventorAddSection = ({
  editing,
  inventorTag,
  openSearchModalHandler,
}) => {
  const [showInventorTooltip, setShowInventorTooltip] = useState(false);

  return (
    <>
      <div className="rows" style={{ float: "left" }}>
        {editing && (
          <div className="share-input row between plus">
            <h2 className="color_green">
              발명자 추가
              <i
                className="icon_warning_empty"
                onMouseEnter={() => setShowInventorTooltip(true)}
                onMouseLeave={() => setShowInventorTooltip(false)}
              />
            </h2>
            <Tooltip
              text={`발명자는 '관리자' 페이지의\n'발명자 추가하기'에서 추가할 수 있습니다.`}
              show={showInventorTooltip}
              position="top"
            />

            <button
              className="icon_green_add"
              onClick={openSearchModalHandler}
            />
          </div>
        )}
      </div>
      {inventorTag}
    </>
  );
};
export default InventorAddSection;
