import React, { useState } from "react";
import InfoCompanyAPI from "../../API/infoCompany";
import PopupGrade from "./PopupGrade";

const SearchInterestedForm = () => {
  const input_ref = React.useRef();
  const [keyword, setKeyword] = useState("");
  const [keyword_list, setKeywordList] = useState("");
  const [keyword_list_show, setKeywordListShow] = useState(false);
  const [mouse, setMouse] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [popupShow, setPopupShow] = useState(false);

  const onBlur = () => {
    if (!mouse) {
      setKeywordListShow(false);
    }
  };

  const onChangeInput = (value) => {
    setKeyword(value);
    setKeywordList([]);
    setKeywordListShow();
  };

  const getSearchCompany = () => {
    if (!keyword.length) {
      setKeywordList([]);
      return;
    }
    InfoCompanyAPI.getInfoCompany(keyword).then((res) => {
      if (res.data.length) {
        setKeywordList(
          res.data.map((item, index) => {
            return (
              <li
                key={index}
                className="active"
                onClick={() => {
                  onClickCalcAnnualFee(item.name, item.company_code);
                }}
                onBlur={onBlur}
              >
                <div>
                  <h2 style={{ fontSize: "14px" }}>{item.name}</h2>
                  <p>
                    {item.name_en &&
                      item.name_en.replace(/(\s*)/g, "") &&
                      `영문법인명 ${item.name_en}`}
                    {item.corporate_number &&
                      item.corporate_number.replace(/(\s*)/g, "") &&
                      ` / 법인등록번호 ${item.corporate_number}`}
                    {item.license_number &&
                      item.license_number.replace(/(\s*)/g, "") &&
                      ` / 사업자 등록번호 ${item.license_number}`}
                    {item.representative && ` / 대표자 ${item.representative}`}
                    {item.establishment_at &&
                      ` / 설립일 ${window.$Global.convertDate(
                        item.establishment_at
                      )}`}
                  </p>
                </div>
              </li>
            );
          })
        );
      } else {
        setKeywordList([
          <li>
            <div>
              <p>입력하신 회사가 등록되지 않았습니다.</p>
            </div>
          </li>,
        ]);
      }
      setKeywordListShow(true);
      input_ref.current.focus();
    });
  };

  const onClickCalcAnnualFee = (name, company_code) => {
    if (company_code === null) {
      alert("기술역량 등급 및 등록특허 등급 정보를 조회할 수 없는 회사입니다.");
      return;
    }
    setCompanyName(name);
    setCompanyCode(company_code);
    setKeywordListShow(false);
    setPopupShow(true);
    setMouse(false);
  };

  return (
    <>
      <div className="search_form">
        <input
          type="text"
          ref={input_ref}
          value={keyword}
          onChange={(e) => onChangeInput(e.target.value)}
          onFocus={() => setKeywordListShow(true)}
          onBlur={onBlur}
          onKeyUp={(e) => {
            if (e.key === "Enter") getSearchCompany();
          }}
          placeholder="회사명을 입력하여 검색하면 기업기술 역량 및 특허등급을 확인 하실 수 있습니다."
        />
        {keyword_list_show && Boolean(keyword_list.length) && (
          <ul
            className="keyword_list custom_scroll no_focus_outline"
            onMouseEnter={() => setMouse(true)}
            onMouseLeave={() => setMouse(false)}
          >
            {keyword_list}
          </ul>
        )}
        <button
          className="btn_search icon_search_gray"
          onClick={getSearchCompany}
        />
      </div>
      {popupShow && (
        <PopupGrade
          companyName={companyName}
          companyCode={companyCode}
          onClickClose={() => setPopupShow(false)}
          all={true}
        />
      )}
    </>
  );
};

export default SearchInterestedForm;
