import React, { useContext, useEffect, useState } from "react";
import "./css/MenuTab.scss";
import MemberContext from "../../store/Member";
import IntroMenu from "./IntroMenu";
import AdminMenu from "./AdminMenu";
import Exception from "../../util/exception";
import Path from "./const/path";
import CommonFunc from "./common/index";
import { useLocation } from "react-router-dom";

function MenuTab({ history }) {
  const { auth, admin } = useContext(MemberContext);
  const location = useLocation();
  const isLogin = auth != null;
  const isInvestor = window.$Global.checkAuth("투자자", auth);
  const isInventor = window.$Global.checkAuth("특허회사_발명자만", auth);
  const isAdmin = admin; // 슈퍼 계정인지 판별
  const isStatisticPath = sessionStorage.getItem("statistics_service"); // 자산실사보고서 가능 판별
  const isSimpleStatisticPath = sessionStorage.getItem(
    "simple_statistics_service"
  ); // 심플통계 가능 판별
  const isTransferStatisticPath = sessionStorage.getItem(
    "transfer_statistics_service"
  );
  const isViewTypeIpnow = window.$Global.isIpnow(location);
  const pathname = history.location.pathname.toString();
  const isIntroPath = Exception.exceptionIntro(pathname);
  const isCalcPath = pathname.indexOf("calc") > -1;
  const isExceptionURL =
    Exception.exceptionRoot(pathname) || (isIntroPath && isLogin) || isCalcPath;

  let activeTab = 0;
  const getActiveTab = (path) => {
    return window.location.href.indexOf(window.location.host + path) > -1;
  };
  const path = [
    ...Path.match_ip_service,
    isStatisticPath === "true" && {
      url: "/dueDi/dashboard",
      redirectURL: "/dueDi/dashboard",
      name: "특허 통계 (자산실사)",
    },
    isSimpleStatisticPath === "true" && {
      url: "/kautum/statistic",
      redirectURL: "/kautum/statistic",
      name: "심플 통계",
    },
    isTransferStatisticPath === 'true' && {
      url: "/tech/transfer",
      redirectURL: "/tech/transfer",
      name: "기술이전관리",
    },
  ].filter(Boolean);

  const isIntro = getActiveTab("/intro/");
  const isOpenMarket = getActiveTab("/trade/");
  const isFindx =
    getActiveTab("/taskManage/") ||
    getActiveTab("/docTrade/") ||
    getActiveTab("/exposition/") ||
    getActiveTab("/businessDescription/") ||
    getActiveTab("/govSupport/") ||
    getActiveTab("/consortium/") ||
    getActiveTab("/consultCom/") ||
    getActiveTab("/matchingBiz/") ||
    getActiveTab("/marketInfo/") ||
    getActiveTab("/researchTech/");

  const isGroupWare = getActiveTab("/renewal/");
  const isStatistic = getActiveTab("/dueDi/");
  const isKautum = getActiveTab("/kautum/");
  const isTech = getActiveTab("/tech/transfer");

  const findActiveTab = (obj, idx) => {
    let keys = Object.keys(obj);
    let condition = false;
    keys.forEach((key) => {
      if (key.startsWith("url")) {
        condition = getActiveTab(obj[key]);
      }
    });

    if (condition) activeTab = idx + 1;
  };

  if (isAdmin) {
    Path.match_admin.map((item, idx) => findActiveTab(item, idx));
  } else if (isIntro || !isLogin) {
    Path.match_intro.map((item, idx) => findActiveTab(item, idx));
  } else if (isOpenMarket) {
    Path.match_openMarket.map((item, idx) => findActiveTab(item, idx));
  } else if (isFindx) {
    Path.match_findx.map((item, idx) => findActiveTab(item, idx));
  } else if (isGroupWare) {
    Path.match_groupware.map((item, idx) => findActiveTab(item, idx));
  } else if (isStatistic || isKautum || isTech) {
    path.map((item, idx) => findActiveTab(item, idx));
  } else {
    Path.match_ip_service_login.map((item, idx) => findActiveTab(item, idx));
  }
  return (
    <div
      id="MenuTab"
      className={`pc ${isIntroPath ? "true" : ""}`}
      style={{
        borderBottom: isViewTypeIpnow
          ? "1px solid #f6a310"
          : "1px solid #4593f5",
      }}
    >
      <div className="wrap">
        {isOpenMarket ? (
          <ul>
            {CommonFunc.DrawTab(Path.match_openMarket, activeTab, "bold")}
          </ul>
        ) : isFindx && isLogin ? (
          <ul>{CommonFunc.DrawTab(Path.match_findx, activeTab, "bold")}</ul>
        ) : isGroupWare && isLogin ? (
          <ul>{CommonFunc.DrawTab(Path.match_groupware, activeTab, "bold")}</ul>
        ) : isInventor && isLogin ? (
          <ul>{CommonFunc.DrawTab(Path.match_inventor, activeTab, "bold")}</ul>
        ) : !isAdmin &&
          !isInvestor &&
          !isIntroPath &&
          isLogin &&
          !isExceptionURL ? (
          <ul>{CommonFunc.DrawTab(path, activeTab, "bold")}</ul>
        ) : (
          !isAdmin &&
          !isInvestor && (
            <IntroMenu
              activeTab={activeTab}
              auth={auth}
              location={location}
              isExceptionURL={isExceptionURL}
            />
          )
        )}
        {isExceptionURL && isAdmin && (
          <IntroMenu
            activeTab={activeTab}
            auth={auth}
            location={location}
            isExceptionURL={isExceptionURL}
          />
        )}
        {isAdmin && !isExceptionURL && <AdminMenu activeTab={activeTab} />}
      </div>
    </div>
  );
}

export default MenuTab;
