import React, { useEffect, useState } from "react";
import Pagination from "../../common/Pagination";
import PatentAPI from "../../../API/patent";
import { CURRENT_YEAR } from "../../dueDiligence/constants/years_config";
import MiniLoading from "../../loading/MiniLoading";

const DetailData = ({ companyStatusYear, companyData }) => {
  const [detailData, setDetailData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const getDetailData = async () => {
    const { year, isAccumulated } = companyStatusYear;
    setIsLoading(true);
    const data = await PatentAPI.getTransferStatus({
      page: page,
      count: 5,
      apply_at: year,
      ...(isAccumulated && { sub_apply_at: CURRENT_YEAR }),
      last_holder: companyData.company_code,
    });
    setDetailData(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getDetailData();
  }, [companyData, page]);

  const formatKeywords = (keywords) => {
    if (!keywords) return ""; // keywords가 null, undefined, 빈 문자열이면 빈 값 반환

    return keywords
      .split(",")
      .map((word) => `#${word.trim()}`)
      .join(" ");
  };

  return (
    <div>
      <div
        style={{
          float: "right",
          position: "relative",
          maxWidth: "880px",
        }}
        className="categoryTableWrapper"
      >
        <strong style={{ position: "absolute", top: "-30px" }}>
          {companyData.transferee}
        </strong>
        <table className="researcher-table">
          <thead>
            <tr>
              <th colSpan={2} className="grid-header" style={{ width: "65px" }}>
                기술이전 된 특허
              </th>
              <th className="grid-header" style={{ width: "20px" }}>
                IPC
              </th>
              <th className="grid-header" style={{ width: "40px" }}>
                제품
              </th>
              <th className="grid-header" style={{ width: "40px" }}>
                키워드
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              detailData?.list?.map((univData, index) => {
                return (
                  <tr
                    style={{ lineHeight: "1.2" }}
                    key={index}
                    className="no-hover"
                  >
                    <td style={{ width: "50px" }} className="grid-cell">
                      {univData.company_name}
                    </td>
                    <td style={{ width: "60px" }} className="grid-cell">
                      {univData.invention_name}
                    </td>
                    <td className="grid-cell">{univData.ipc}</td>
                    <td className="grid-cell">{univData.products}</td>
                    <td className="grid-cell">
                      {formatKeywords(univData.keywords)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <div style={{ height: "400px" }}>
                <MiniLoading show={isLoading} />
              </div>
            )}
            {detailData.total_count === 0 && (
              <tr>
                <td colSpan={5}>데이터가 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
        {detailData.total_count > 0 && (
          <Pagination
            curPage={page}
            onClick={setPage}
            lastNum={Math.ceil(detailData.total_count / 5)}
            disableMoveToTop={true}
          />
        )}
      </div>
    </div>
  );
};

export default DetailData;
