import React, { useEffect, useState, useContext, useRef } from "react";
import "../css/CompetitorDetail.scss";
import { FaRegHeart } from "react-icons/fa6";
import { Bar, Doughnut, HorizontalBar, Line, Pie } from "react-chartjs-2";
import logo from "../../../assets/images/common/logo_findBiz.svg";
import { useLocation } from "react-router-dom";
import CompanyAPI from "../../../API/company";
import CommonAPI from "../../../API/common";
import InfoAPI from "../../../API/info";
import qs from "query-string";
import axios from "axios";
import Slider from "react-slick";
import CommonContext from "../../../store/Common";
import MemberContext from "../../../store/Member";
import CompetitorEmployeePop from "./CompetitorEmployeePop";
import CompetitorAccount from "./CompetitorAccount";
import moment from "moment";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pagination from "../../common/Pagination";
import CompetitorFinanceStatus from "./CompetitorFinanceStatus";
import CompetitorBlur from "./CompetitorBlur";
import AuthPopup from "./AuthPopup";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CompetitorDetailPop = ({ closePopup, applicant, bizno }) => {
  const history = useHistory();
  const [filter, setFilter] = useState("normal");
  const [showPatentOnly, setShowPatentOnly] = useState(false);
  const location = useLocation();
  const [companyCountData, setCompanyCountData] = useState({});
  const [patentList, setPatentList] = useState([]);
  const [trademarkList, setTrademarkList] = useState([]);
  const [designList, setDesignList] = useState([]);
  const [companySkillList, setCompanySkillList] = useState([]);
  const [gradeData, setGradeData] = useState({});
  const member_idx = localStorage.getItem("idx");
  const { auth } = useContext(MemberContext);
  const isLogin = auth != null;

  const [finance, setFinance] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});
  const [companyInfoV3, setCompanyInfoV3] = useState({});
  const [companyFinance, setCompanyFinance] = useState({});
  const [companyBidInfo, setCompanyBidInfo] = useState([]);
  const [companyCompList, setCompanyCompList] = useState([]);
  const [companyTechGrade, setCompanyTechGrade] = useState({});
  const [companyTechPatent, setCompanyTechPatent] = useState([]);
  const [companyFirstTag, setCompanyFirstTag] = useState("0");
  const [companyStatistics, setCompanyStatistics] = useState({});
  const { isMobile } = useContext(CommonContext);
  const [showAllItemsTxt, setShowAllItemsTxt] = useState("모두보기");
  const [showAllItems, setShowAllItems] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [isEmpPopOpen, setIsEmpPopOpen] = useState(false); // 평균급여, 입퇴사현황 팝업
  const [employeeType, setEmployeeType] = useState("year"); // 팝업 타입(year:연도별 평균연봉 | month:월별 입/퇴사자 추이)
  const [isAccountPopOpen, setIsAccountPopOpen] = useState(false); // 거래처기업 팝업
  const [salaryData, setSalaryData] = useState({});
  const [monthData, setMonthData] = useState({});
  const [newsPage, setNewsPage] = useState(1); // 기업뉴스 페이지
  const [newsCount, setNewsCount] = useState(8); // 기업뉴스 페이지당 개수
  const [last, setLast] = useState(1); // 기업뉴스 마지막 페이지
  const [newsList, setNewsList] = useState([]); // 기업뉴스
  const [financeDetail, setFinanceDetail] = useState({}); // 5개년 재무상태표
  const [financePlData, setFinancePlData] = useState({}); // 5개년 손익계산서
  const [financeStatus, setFinanceStatus] = useState({}); // 연도별 재무상태표
  const [isFianacePopOpen, setIsFianacePopOpen] = useState(false);
  const [compIPC, setCompIPC] = useState([]); // 기업분류-IPC
  const [bidInfo, setBidInfo] = useState({}); // 기업과제 낙찰 정보
  const [compCerti, setCompCerti] = useState({}); // 기업인증현황
  const [compCltGroup, setCompCltGroup] = useState([]); // 거래처 그룹
  const [compClt, setCompClt] = useState({});
  const [demandList, setDemandList] = useState([]); // 주요 거래기관
  const [supplyList, setSupplyList] = useState([]); // 주요 품목
  const [groupTagNew, setGroupTagNew] = useState([]);
  const [nationalRnd, setNationalRnd] = useState([]); // 국가 R&D 과제
  const [nationRndPage, setNationRndPage] = useState(1); // 국가 R&D 정보 페이지수
  const [nationRndCount, setNationRndCount] = useState(10); // 국가 R&D 정보 페이지당 개수
  const [nationRndLast, setNationRndLast] = useState(1); // 국가 R&D 마지막 페이지
  const [corpRnd, setCorpRnd] = useState([]); // 기업 R&D 과제
  const [corpRndPage, setCorpRndPage] = useState(1); // 기업 R&D 정보 페이지수
  const [corpRndCount, setCorpRndCount] = useState(10); // 기업 R&D 정보 페이지당 개수
  const [corpRndLast, setCorpRndLast] = useState(1); // 기업 R&D 정보 마지막 페이지
  const [claData, setClaData] = useState({}); // 기업 최신활동
  const [insightData, setInsightData] = useState({});
  const [competitorGroups, setCompetitorGroups] = useState([]);
  const [flagType, setFlagType] = useState(1);
  const [popup, setPopup] = useState(false);
  const [popupKeyword, setPopupKeyword] = useState("");
  const sliderRef = useRef(null);

  const handleNavigate = (item) => {
    history.replace({
      pathname: `/competitor/info/${item.info.company_bizno}`,
    });
  };

  const getPastelColor = () => {
    const r = Math.floor(Math.random() * 127 + 127); // 127 ~ 254
    const g = Math.floor(Math.random() * 127 + 127); // 127 ~ 254
    const b = Math.floor(Math.random() * 127 + 127); // 127 ~ 254
    return `rgb(${r}, ${g}, ${b})`;
  };

  const getBackgroundColors = (dataLength) => {
    const colors = [];
    for (let i = 0; i < dataLength; i++) {
      colors.push(getPastelColor());
    }
    return colors;
  };

  useEffect(() => {
    getCompanyInfo();
    if (applicant != 0) {
      getPatentInfo();
    }
    getCompanySummary(); // 기업요약
    getNews(); // 기업뉴스
    getFinance(); // 재무
    getIPC(); // 기업분류
    getBid(); // 기업과제 낙찰 정보
    getCertification(); // 기업인증정보
    getSupplier(); // 주요 품목 현황 + 주요 거래기관 현황
    getNationalRnd(); // 국가 R&D 정보
    getCorpRnd(); // 기업 R&D 정보
    getCla(); // 기업 최신활동
    getInsight(); // 재무 인사이트
  }, []);

  useEffect(() => {
    getNews();
  }, [newsPage]);

  useEffect(() => {
    getNationalRnd();
  }, [nationRndPage]);

  useEffect(() => {
    getCorpRnd();
  }, [corpRndPage]);

  // 기업요약
  const getCompanySummary = () => {
    CompanyAPI.getCompanySummary({
      biz_no: bizno,
      member_idx: member_idx,
    }).then((res) => {
      //console.log("summary:", res);
      if (res.data.code === "00") {
        setCompanyInfo(res.data.data.info[0] || {});
        setGroupTagNew(res.data.data.grouptag || []);
      }
    });
  };

  // 관심기업 추가
  const addInterest = () => {
    CompanyAPI.addClt({
      biz_no: bizno,
      owner_idx: member_idx,
      applicant: applicant,
      flag: 0,
    }).then((res) => {
      //console.log("addInterest:", res);
      if (res.data.code !== "00") {
        alert("관심기업 추가에 실패했습니다.");
      }
    });
  };

  // 관심기업 취소
  const cancelInterest = () => {
    CompanyAPI.deleteClt({
      biz_no: bizno,
      owner_idx: member_idx,
      flag: 0,
    }).then((res) => {
      //console.log("deleteInterest:", res);
      if (res.data.code !== "00") {
        alert("관심기업 취소에 실패했습니다.");
      }
    });
  };

  // 평균급여
  const getAvgSalary = () => {
    CompanyAPI.getAvgSalary({ biz_no: bizno }).then((res) => {
      //console.log("salary:", res);
      if (res.data.code === "00") {
        setSalaryData(res.data.data.salary || {});
      }
    });
  };

  // 입퇴사현황
  const getJointQuit = () => {
    CompanyAPI.getJointQuit({ biz_no: bizno }).then((res) => {
      //console.log("getJoinQuit:", res);
      if (res.data.code === "00") {
        setMonthData(res.data.data.work || {});
      }
    });
  };

  // 기업뉴스
  const getNews = () => {
    CompanyAPI.getNews({
      biz_no: bizno,
      page: newsPage,
      count: newsCount,
    }).then((res) => {
      //console.log("getNews:", res);
      if (res.data.code === "00") {
        setLast(res.data.data.last);
        if (res.data.data.items.length) {
          setNewsList(res.data.data.items || []);
          goToFirstSlide();
        }
      }
    });
  };

  // 재무상태표,손익계산서,재무상태표
  const getFinance = () => {
    CompanyAPI.getFinance({ biz_no: bizno }).then((res) => {
      //console.log("getFinance:", res);
      if (res.data.code === "00") {
        setFinanceDetail(res.data.data.financeDetail || {});
        setFinancePlData(res.data.data.financePlData || {});
        setFinanceStatus(res.data.data.financeStatus || {});
      }
    });
  };

  // 기업분류 - IPC 분류정보 가져오기
  const getIPC = () => {
    CompanyAPI.getIPC({ biz_no: bizno }).then((res) => {
      //console.log("getIPC:", res);
      if (res.data.code === "00") {
        setCompIPC(res.data.data.ipc_number.items || []);
      }
    });
  };

  // 기업과제 낙찰 정보 조회
  const getBid = () => {
    CompanyAPI.getBid({ biz_no: bizno }).then((res) => {
      //console.log("getBidInfo:", res);
      if (res.data.code === "00") {
        setBidInfo(res.data.data?.bidinfo || {});
      }
    });
  };

  // 주요 품목 현황 + 주요 거래기관 현황
  const getSupplier = () => {
    CompanyAPI.getSupplier({ biz_no: bizno }).then((res) => {
      //console.log("getSupplier:", res);
      if (res.data.code === "00") {
        setDemandList(res.data?.data?.demandList || []);
        setSupplyList(res.data?.data?.itemList || []);
      }
    });
  };

  // 거래처기업 조회
  // const getClt = () => {
  //   CompanyAPI.getClt({ member_idx: member_idx, group_idx: 9 }).then((res) => {
  //     //console.log("getClt:", res);
  //     if (res.data.code === "00") {
  //     }
  //   });
  // };

  // 거래처 기업 추가
  const addClt = (idx, name) => {
    return CompanyAPI.addClt({
      owner_idx: member_idx,
      group_idx: idx,
      biz_no: bizno,
      applicant: applicant,
      flag: flagType,
    }).then((res) => {
      //console.log("addClt:", res);
      getCltGroup(flagType);
      return res;
    });
  };

  // 거래처기업 그룹 조회
  const getCltGroup = (flag) => {
    CompanyAPI.getCltGroup({ owner_idx: member_idx, flag: flag }).then(
      (res) => {
        //console.log("getCltGroup:", res);
        if (res.data.code === "00") {
          if (flag === 1) {
            setCompCltGroup(res.data?.data?.clientGroups || []);
          } else if (flag === 2) {
            setCompetitorGroups(res.data?.data?.competitorGroups || []);
          }
        }
      }
    );
  };

  // 거래처기업 그룹 추가
  const addCltGroup = (group_name) => {
    CompanyAPI.addCltGroup({
      owner_idx: member_idx,
      group_name: group_name,
      biz_no: bizno,
      flag: flagType,
    }).then((res) => {
      //console.log("addCltGroup:", res);
      if (res.data.code === "00") {
        getCltGroup(flagType);
      } else {
        alert("이미 존재하는 폴더명입니다.");
      }
    });
  };

  // 기업 인증정보 가져오기
  const getCertification = async () => {
    CompanyAPI.getCertification({ biz_no: bizno }).then((res) => {
      //console.log("getCertification :", res);
      if (res.data.code === "00") {
        setCompCerti(res.data?.data?.certification || {});
      }
    });
  };

  // 국가 R&D 정보
  const getNationalRnd = () => {
    CompanyAPI.getNationalRnd({
      biz_no: bizno,
      page: nationRndPage,
      count: nationRndCount,
    }).then((res) => {
      //console.log("getNationalRnd:", res);
      if (res.data?.code === "00") {
        setNationalRnd(res.data.data?.nationalRnd || []);
        setNationRndLast(
          Math.ceil(res.data.data?.total_count / nationRndCount)
        );
      }
    });
  };

  // 기업 R&D 정보
  const getCorpRnd = () => {
    CompanyAPI.getCorpRnd({
      biz_no: bizno,
      page: corpRndPage,
      count: corpRndCount,
    }).then((res) => {
      //console.log("getCorpRnd:", res);
      if (res.data?.code === "00") {
        setCorpRnd(res.data.data.corpRnd || []);
        setCorpRndLast(Math.ceil(res.data.data?.total_count / corpRndCount));
      }
    });
  };

  // 기업 최신활동
  const getCla = () => {
    CompanyAPI.getCla({ biz_no: bizno }).then((res) => {
      //console.log("getCla:", res);
      if (res.data?.code === "00") {
        setClaData(res.data?.data || {});
      }
    });
  };

  // 재무 인사이트
  const getInsight = () => {
    CompanyAPI.getInsight({ biz_no: bizno }).then((res) => {
      //console.log("getInsight:", res);
      if (res.data?.code === "00") {
        setInsightData(res.data?.data || {});
      }
    });
  };

  const getCompanyInfo = async () => {
    //https://dev.biznavi.co.kr
    await axios
      .get(`https://dev.biznavi.co.kr/api/v3/get_company_info`, {
        params: { company: bizno },
      })
      .then((res) => {
        //console.log("res:", res);
        setFinance(Object.values(res.data.finance || {}));
        setCompanyInfoV3(res.data.info);
        setCompanyFinance(res.data.finance);
        setCompanyBidInfo(res.data.bidinfo);
        setCompanyCompList(res.data.competitorlist);
        setCompanyTechGrade(res.data.tech_grade);
        setCompanyTechPatent(res.data.patent);
        setCompanyFirstTag(res.data.info.grouptags[0]?.company_group_idx || "");
        setCompanyStatistics(res.data.statistics);
      });
  };

  const getPatentInfo = () => {
    InfoAPI.getCompanyPatent({
      company_code: applicant,
      page: 1,
      count: 5,
    }).then((res) => {
      setPatentList(res.data.items);
    });

    InfoAPI.getTradeMark({ company_code: applicant, page: 1, count: 30 }).then(
      (res) => {
        res.data.items.map((item) => {
          let obj = {};
          let classArr = (item.class && item.class.split("|")) || [];
          let productArr = (item.product && item.product.split("|")) || [];
          for (let i = 0; i < classArr.length; i++) {
            obj[classArr[i]] = [
              ...Object.values(obj[classArr[i]] || {}),
              productArr[i],
            ];
          }
          item.classification = obj;
        });
        setTrademarkList(res.data.items);
      }
    );

    // InfoAPI.getDesign({ company_code: applicant, page: 1, count: 30 }).then(
    //   (res) => {
    //     setDesignList(res.data.items);
    //   }
    // );

    CompanyAPI.getDesign({ biz_no: bizno, page: 1, count: 30 }).then((res) => {
      setDesignList(res.data?.data?.items || []);
    });

    CommonAPI.getIpInfo({ company_code: applicant }).then((res) => {
      setCompanyCountData(res.data);
    });

    // PatentAPI.getGradePatent(idx).then((res) => {
    //   setCompanySkillList(res.data.patent.slice(0, 2));
    // });
  };

  const sortAndSetSupplyList = (array) => {
    const sortedData = [...array].sort(
      (a, b) => parseFloat(b.ratio) - parseFloat(a.ratio)
    );
    return sortedData;
  };

  const getCompanySmartGrade = () => {
    CompanyAPI.getCompanyGrade().then((res) => setGradeData(res.data));
  };

  const patentDoughnutChartData = {
    labels: ["출원", "등록", "포기"],
    datasets: [
      {
        label: "Data",
        data: Array.from(
          Object.values(companyCountData?.patent_statistic || {})
        ).slice(0, 3),
        borderColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
        backgroundColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
      },
    ],
  };

  const trademarkDoughnutChartData = {
    labels: ["출원", "등록", "포기"],
    datasets: [
      {
        label: "Data",
        data: Array.from(
          Object.values(companyCountData?.trademark_statistic || {})
        ).slice(0, 3),
        borderColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
        backgroundColor: [
          "rgba(255,255,255,0.3)",
          "#ffffff",
          "rgba(255,255,255,0.15)",
        ],
      },
    ],
  };

  const patentDoughnutChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  const annualFeeChartData = {
    labels: companyCountData?.annual_pay?.map((item) => item.year + "년"),
    datasets: [
      {
        label: "Data",
        data: companyCountData?.annual_pay?.map((item) => item.cost) || 0,
        borderColor: ["#ed3d30", "#ffca10", "#5a90ff", "#2febed"],
        backgroundColor: ["#ed3d30", "#ffca10", "#5a90ff", "#2febed"],
      },
    ],
  };

  const annualFeeChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        anchor: "end",
        align: "top",
        font: {
          size: 8,
          weight: "bold",
        },
        formatter: function (value) {
          return window.$Global.commaify(value);
        },
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
            max: window.$Global.calcGraphMaxNumber(
              companyCountData.annual_pay &&
                companyCountData.annual_pay.map((item) => item.cost)
            ),
            callback: function (value) {
              return window.$Global.commaify(value);
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return window.$Global.commaify(tooltipItem.value) + "원";
        },
      },
    },
  };

  const yearlyChartData = {
    labels: Object.keys(companyCountData.annual_apply_cnt || {}).map(
      (item) => item.slice(2) + "년"
    ),
    datasets: [
      {
        label: "Data 1",
        type: "line",
        data: Object.values(companyCountData.annual_apply_cnt || {}).map(
          (item) => item.cnt
        ),
        fill: false,
        borderColor: "#bcec45",
        backgroundColor: "#0095ae",
        pointBackgroundColor: "#0095ae",
        lineTension: 0,
      },
      {
        label: "Data 2",
        type: "line",
        data: Object.values(companyCountData.annual_register_cnt || {}).map(
          (item) => item.cnt
        ),
        fill: false,
        borderColor: "#ffc500",
        backgroundColor: "#ffac12",
        pointBackgroundColor: "#ffac12",
        lineTension: 0,
      },
    ],
  };

  const yearlyChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display:
          Math.max(
            ...Object.values(companyCountData.annual_apply_cnt || {})
              .map((item) => item.cnt)
              .concat(
                Object.values(companyCountData.annual_register_cnt || {}).map(
                  (item) => item.cnt
                )
              )
          ) === 0
            ? false
            : true,
        align: "top",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            max: window.$Global.calcGraphMaxNumber(
              Object.values(companyCountData.annual_apply_cnt || {})
                .map((item) => item.cnt)
                .concat(
                  Object.values(companyCountData.annual_register_cnt || {}).map(
                    (item) => item.cnt
                  )
                )
            ),
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
  };

  //재무정보 차트 데이터
  const profitMixedChartData = {
    labels: ["2019", "2020", "2021", "2022"],
    datasets: [
      {
        label: "자본",
        data: [
          (companyFinance || {})[2019]?.enpTcptAmt?.val / 1000000,
          (companyFinance || {})[2020]?.enpTcptAmt?.val / 1000000,
          (companyFinance || {})[2021]?.enpTcptAmt?.val / 1000000,
          (companyFinance || {})[2022]?.enpTcptAmt?.val / 1000000,
        ],
        borderColor: "#0078f1",
        barThickness: 40,
        order: 2,
        backgroundColor: "#0078f1",
      },
      {
        label: "부채",
        data: [
          (companyFinance || {})[2019]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2020]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2021]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2022]?.enpTdbtAmt?.val / 1000000,
        ],
        borderColor: "#4593f5",
        barThickness: 40,
        order: 3,
        backgroundColor: "#4593f5",
      },
      {
        label: "자산",
        data: [
          (companyFinance || {})[2019]?.enpTcptAmt?.val / 1000000 +
            (companyFinance || {})[2019]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2020]?.enpTcptAmt?.val / 1000000 +
            (companyFinance || {})[2020]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2021]?.enpTcptAmt?.val / 1000000 +
            (companyFinance || {})[2021]?.enpTdbtAmt?.val / 1000000,
          (companyFinance || {})[2022]?.enpTcptAmt?.val / 1000000 +
            (companyFinance || {})[2022]?.enpTdbtAmt?.val / 1000000,
        ],
        type: "line",
        fill: false,
        order: 1,
        borderWidth: 6,
        borderColor: "rgba(33, 198, 227, 0.35)",
        backgroundColor: "#21c6e3",
        pointBackgroundColor: "#21c6e3",
      },
    ],
  };

  // 손익 계산서 차트 정보
  const profitAndLossMixedChartData = {
    labels: ["2019", "2020", "2021", "2022"],
    datasets: [
      {
        label: "매출액",
        data: [
          (companyFinance || {})[2019]?.enpSaleAmt?.val / 1000000,
          (companyFinance || {})[2020]?.enpSaleAmt?.val / 1000000,
          (companyFinance || {})[2021]?.enpSaleAmt?.val / 1000000,
          (companyFinance || {})[2022]?.enpSaleAmt?.val / 1000000,
        ],
        borderColor: "#45ca9c",
        barThickness: 40,
        order: 2,
        backgroundColor: "rgba(178, 211, 221, 0.2)",
      },
      {
        label: "영업이익",
        data: [
          (companyFinance || {})[2019]?.enpBzopPft?.val / 1000000,
          (companyFinance || {})[2020]?.enpBzopPft?.val / 1000000,
          (companyFinance || {})[2021]?.enpBzopPft?.val / 1000000,
          (companyFinance || {})[2022]?.enpBzopPft?.val / 1000000,
        ],
        borderColor: "#5143ce",
        barThickness: 40,
        order: 3,
        backgroundColor: "rgba(69, 147, 245, 0.2)",
      },
      {
        label: "당기순이익",
        data: [
          (companyFinance || {})[2019]?.enpCrtmNpf?.val / 1000000,
          (companyFinance || {})[2020]?.enpCrtmNpf?.val / 1000000,
          (companyFinance || {})[2021]?.enpCrtmNpf?.val / 1000000,
          (companyFinance || {})[2022]?.enpCrtmNpf?.val / 1000000,
        ],
        type: "line",
        fill: false,
        order: 1,
        borderWidth: 6,
        borderColor: "#b1b1b1",
        backgroundColor: "rgba(236, 236, 236, 0.2)",
        //pointBackgroundColor: "#21c6e3",
      },
    ],
  };

  // 기업 분류 분석
  const compIPCChartData = {
    labels: compIPC.map((item) => item.ipc_code),

    datasets: [
      {
        label: "Data",
        data: compIPC.map((item) => item.cnt),
        backgroundColor: getBackgroundColors(compIPC.length),
      },
    ],
  };

  const compIPCChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const profitMixedChartOption = {
    responsive: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 11,
        boxHeight: 11,
        usePointStyle: true,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
  };

  const category_settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    draggable: false,
    nextArrow: (
      <div className="">
        <i className="icon_slider_next"></i>
      </div>
    ),
    prevArrow: (
      <div className="">
        <i className="icon_slider_prev"></i>
      </div>
    ),
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  // const analysisChartData = {
  //   labels: ["특허건수", "매출", "영업이익", "입찰수", "낙찰수", "낙찰률"],
  //   datasets: [
  //     {
  //       label: "조회 기업",
  //       data: [...myData],
  //       type: "line",
  //       fill: false,
  //       backgroundColor: "rgba(0, 120, 241, 0.35)",
  //       borderColor: "rgba(0, 120, 241, 0.35)",
  //       pointBackgroundColor: "#0078f1",
  //       borderWidth: 6,
  //       lineTension: 0,
  //       pointRadius: 8,
  //     },
  //     {
  //       label: "평균",
  //       data: [...my1Data],
  //       type: "line",
  //       fill: false,
  //       borderColor: "rgba(0, 221, 119, 0.35)",
  //       pointBackgroundColor: "#00dd77",
  //       borderWidth: 6,
  //       lineTension: 0,
  //       pointRadius: 8,
  //     },
  //     {
  //       label: "상위 10%",
  //       data: [...my2Data],
  //       type: "line",
  //       fill: false,
  //       borderColor: "rgb(255, 99, 71)",
  //       pointBackgroundColor: "rgb(255, 99, 71)",
  //       borderWidth: 6,
  //       lineTension: 0,
  //       pointRadius: 8,
  //     },
  //   ],
  // };
  //
  // const analysisChartOption = {
  //   responsive: false,
  //   legend: {
  //     display: true,
  //     position: "bottom",
  //     labels: {
  //       boxWidth: 11,
  //       boxHeight: 11,
  //       usePointStyle: true,
  //     },
  //   },
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       align: "top",
  //     },
  //   },
  //   scales: {
  //     xAxes: [
  //       {
  //         ticks: {
  //           display: true,
  //         },
  //         gridLines: {
  //           display: false,
  //         },
  //       },
  //     ],
  //     yAxes: [
  //       {
  //         ticks: {
  //           display: true,
  //           beginAtZero: true,
  //         },
  //         gridLines: {
  //           display: true,
  //         },
  //       },
  //     ],
  //   },
  // };

  const auctionCompanyChartData = {
    labels: ["지원 수", "낙찰 수"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.my?.bid || 0,
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.my?.win || 0,
        ],
        borderColor: "#0078f1",
        backgroundColor: "#0078f1",
      },
    ],
  };

  const auctionAvgChartData = {
    labels: ["지원 수", "낙찰 수"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.["avg"]?.bid ||
            0,
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.["avg"]?.win ||
            0,
        ],
        borderColor: "#00ba78",
        backgroundColor: "#00ba78",
      },
    ],
  };

  const auction10AvgChartData = {
    labels: ["지원 수", "낙찰 수"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.["10avg"]
            ?.bid || 0,
          companyStatistics?.task_cnt?.[companyFirstTag]?.data?.["10avg"]
            ?.win || 0,
        ],
        borderColor: "#00ba78",
        backgroundColor: "#00ba78",
      },
    ],
  };

  const salesChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.sales?.[companyFirstTag]?.data?.["10avg"] || 0,
          companyStatistics?.sales?.[companyFirstTag]?.data?.["avg"] || 0,
          companyStatistics?.sales?.[companyFirstTag]?.data?.["my"] || 0,
        ],
        borderColor: "#00b894",
        backgroundColor: "#00b894",
      },
    ],
  };

  const profitChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.profit?.[companyFirstTag]?.data?.["10avg"] || 0,
          companyStatistics?.profit?.[companyFirstTag]?.data?.["avg"] || 0,
          companyStatistics?.profit?.[companyFirstTag]?.data?.["my"] || 0,
        ],
        borderColor: "#0984e3",
        backgroundColor: "#0984e3",
      },
    ],
  };

  const techChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          window.$Global.convertTechGradeToNumber(
            companyStatistics?.patent?.[companyFirstTag]?.data?.["10avg"]
              ?.tgrade
          ) || 0,
          window.$Global.convertTechGradeToNumber(
            companyStatistics?.patent?.[companyFirstTag]?.data?.["avg"]?.tgrade
          ) || 0,
          window.$Global.convertTechGradeToNumber(
            companyStatistics?.patent?.[companyFirstTag]?.data?.["my"]?.tgrade
          ) || 0,
        ],
        borderColor: "#00cec9",
        backgroundColor: "#00cec9",
      },
    ],
  };

  const patentChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.patent?.[companyFirstTag]?.data?.["10avg"]
            ?.ipcnt || 0,
          companyStatistics?.patent?.[companyFirstTag]?.data?.["avg"]?.ipcnt ||
            0,
          companyStatistics?.patent?.[companyFirstTag]?.data?.["my"]?.ipcnt ||
            0,
        ],
        borderColor: "#6c5ce7",
        backgroundColor: "#6c5ce7",
      },
    ],
  };

  const tradeMarkChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.sales?.[companyFirstTag]?.data?.["10avg"] || 0,
          companyStatistics?.sales?.[companyFirstTag]?.data?.["avg"] || 0,
          companyStatistics?.sales?.[companyFirstTag]?.data?.["my"] || 0,
        ],
        borderColor: "#00ba78",
        backgroundColor: "#00ba78",
      },
    ],
  };

  const auctionBizChartData = {
    labels: ["상위10%", "평균", "해당 기업"],
    datasets: [
      {
        label: "Data",
        data: [
          companyStatistics?.task_rate?.[companyFirstTag]?.data?.["10avg"] || 0,
          companyStatistics?.task_rate?.[companyFirstTag]?.data?.["avg"] || 0,
          companyStatistics?.task_rate?.[companyFirstTag]?.data?.["my"] || 0,
        ],
        borderColor: "#b2bec3",
        backgroundColor: "#b2bec3",
      },
    ],
  };

  const auctionCompanyChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        color: "black",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
          stacked: true,
          barThickness: 25,
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
  };

  const auctionMoneyChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value) {
          return window.$Global.commaify(Math.floor(value / 1000000));
        },
        color: "black",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: true,
          },
          stacked: true,
          barThickness: 25,
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
            callback: function (label, index, labels) {
              return Math.round(label / 1000000);
            },
          },
          gridLines: {
            display: false,
          },
          stacked: true,
        },
      ],
    },
  };

  const insightOptions = (labels, label, data, amount) => {
    let options = {
      //fill: false,
      labels: Object.keys(labels || {}),
      datasets: [
        {
          label: label,
          data: amount
            ? Object.values(data || {}).map((value) =>
                Math.round(value * 0.001)
              )
            : Object.values(data || {}).map((value) => Math.round(value)),
          type: "line",
          borderColor: "rgb(72, 58, 204)",
          backgroundColor: "rgba(72, 58, 204, 0.2)",
          lineTension: 0,
          borderWidth: 2,
        },
      ],
    };

    return options;
  };

  const salesTrendChartData = insightOptions(
    insightData?.sales,
    "매출액 추이",
    insightData?.sales,
    true
  );

  const profitTrendChartData = insightOptions(
    insightData?.profit,
    "영업이익 추이",
    insightData?.profit,
    true
  );

  const revGrowthChartData = insightOptions(
    insightData?.insights?.rev_growth_rate,
    "매출액 증가율",
    insightData?.insights?.rev_growth_rate,
    false
  );

  const opProfitGrowthChartData = insightOptions(
    insightData?.insights?.op_profit_growth_rate,
    "영업이익 증가율",
    insightData?.insights?.op_profit_growth_rate,
    false
  );

  const currentRatioChartData = insightOptions(
    insightData?.insights?.current_ratio,
    "유동비율",
    insightData?.insights?.current_ratio,
    false
  );

  const debtRatioChartData = insightOptions(
    insightData?.insights?.debt_ratio,
    "부채비율",
    insightData?.insights?.debt_ratio,
    false
  );

  const opProfitMarginChartData = insightOptions(
    insightData?.insights?.op_profit_margin,
    "영업이익율",
    insightData?.insights?.op_profit_margin,
    false
  );

  const arTurnoverChartData = insightOptions(
    insightData?.insights?.ar_turnover,
    "매출채권 회전율",
    insightData?.insights?.ar_turnover,
    false
  );

  const growthFirstChartData = {
    labels: ["2019년", "2020년", "2021년", "2022년"],
    datasets: [
      {
        label: "기업 매출",
        type: "line",
        data: [
          (companyFinance || {})[2019]?.enpSaleAmt?.val,
          (companyFinance || {})[2020]?.enpSaleAmt?.val,
          (companyFinance || {})[2021]?.enpSaleAmt?.val,
          (companyFinance || {})[2022]?.enpSaleAmt?.val,
        ],
        //fill: false,
        borderColor: "rgb(0, 120, 241)",
        backgroundColor: "rgba(0, 120, 241, 0.35)",
        lineTension: 0,
        borderWidth: 2,
      },
    ],
  };

  const growthSecondChartData = {
    labels: ["2019년", "2020년", "2021년", "2022년"],
    datasets: [
      {
        label: "기업 영업이익",
        type: "line",
        data: [
          (companyFinance || {})[2019]?.enpBzopPft?.val,
          (companyFinance || {})[2020]?.enpBzopPft?.val,
          (companyFinance || {})[2021]?.enpBzopPft?.val,
          (companyFinance || {})[2022]?.enpBzopPft?.val,
        ],
        //fill: false,
        borderColor: "rgb(0, 221, 119)",
        backgroundColor: "rgba(0, 221, 119, 0.35)",
        lineTension: 0,
        borderWidth: 2,
      },
    ],
  };

  const growthThirdChartData = {
    labels: ["2019년", "2020년", "2021년", "2022년"],
    datasets: [
      {
        label: "기업 부채비율",
        type: "line",
        data: [
          (companyFinance || {})[2019]?.rateDebt?.val,
          (companyFinance || {})[2020]?.rateDebt?.val,
          (companyFinance || {})[2021]?.rateDebt?.val,
          (companyFinance || {})[2022]?.rateDebt?.val,
        ],
        //fill: false,
        borderColor: "rgb(255, 166, 0)",
        backgroundColor: "rgba(255, 166, 0, 0.35)",
        lineTension: 0,
        borderWidth: 2,
      },
    ],
  };

  const growthFourthChartData = {
    labels: ["2019년", "2020년", "2021년", "2022년"],
    datasets: [
      {
        label: "기업 영업이익율",
        type: "line",
        data: [
          (companyFinance || {})[2019]?.rateOpm?.val,
          (companyFinance || {})[2020]?.rateOpm?.val,
          (companyFinance || {})[2021]?.rateOpm?.val,
          (companyFinance || {})[2022]?.rateOpm?.val,
        ],
        //fill: false,
        borderColor: "rgb(172, 127, 255)",
        backgroundColor: "rgba(172, 127, 255, 0.35)",
        lineTension: 0,
        borderWidth: 2,
      },
    ],
  };

  const insightChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value) {
          return window.$Global.commaify(Math.round(value * 0.001));
        },
        align: "top",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            callback: function (value, index, labels) {
              return window.$Global.commaify(Math.round(value));
            },
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
  };

  const growthWonChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value) {
          return window.$Global.commaify(Math.round(value / 1000000));
        },
        align: "top",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            callback: function (value, index, labels) {
              return Math.round(value / 1000000);
            },
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
  };

  const growthPercentChartOption = {
    responsive: false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: function (value) {
          return window.$Global.commaify(value) + "%";
        },
        align: "top",
      },
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
            steps: 10,
            max: 100,
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
  };

  // const tabs = {
  //   0: useMoveScroll("기업정보"),
  //   1: useMoveScroll("재무·손익"),
  //   2: useMoveScroll("기업 기술력 평가"),
  //   3: useMoveScroll("핵심 기술정보"),
  //   4: useMoveScroll("특허·상표·디자인"),
  //   5: useMoveScroll("정부사업 경쟁률 분석정보"),
  //   6: useMoveScroll("경쟁현황 종합분석"),
  //   7: useMoveScroll("기업가치 성장분석"),
  //   8: useMoveScroll("보도자료"),
  //   length: 9,
  // };

  const emptyChartData = (obj) => {
    if (obj) {
      let arr = Object.values(obj).filter((el) => el !== null);

      return arr.length === 0;
    }
  };

  const emptyFieldData = () => {
    if (
      Object.keys(companyStatistics?.task_cnt || {}).includes("") &&
      Object.keys(companyStatistics?.patent || {}).includes("") &&
      Object.keys(companyStatistics?.sales || {}).includes("") &&
      Object.keys(companyStatistics?.profit || {}).includes("") &&
      Object.keys(companyStatistics?.task_rate || {}).includes("") &&
      companyBidInfo?.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleExpandClick = (key) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // 현재 항목의 상태를 토글
    }));
  };

  const handleEmployeePop = (type) => {
    getAvgSalary();
    getJointQuit();
    setEmployeeType(type);
    setIsEmpPopOpen(true);
  };

  const maskCompRegNum = (company_regno) => {
    if (!company_regno) {
      return "";
    }

    const showLength = Math.ceil(company_regno.length / 2);
    const maskLength = company_regno.length - showLength;

    const maskedRegNo = `${company_regno.slice(0, showLength)}${"*".repeat(
      maskLength
    )}`;

    return maskedRegNo;
  };

  const sliceDate = (date) => {
    if (!date || date.length !== 8) {
      return "";
    }

    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);

    return year + "." + month + "." + day;
  };

  const goNews = (link) => {
    if (link) {
      window.open(link);
    } else {
      alert("해당 뉴스로 이동할 수 없습니다.");
    }
  };

  const goToFirstSlide = () => {
    sliderRef.current.slickGoTo(0);
  };

  const goUpdateForm = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSdk3QQCsFznE169_zPeEPYboovf0ZnOAVpT2wZcSbByKG9TIQ/viewform"
    );
  };

  const handleClt = (flag) => {
    setFlagType(flag);
    getCltGroup(flag);
    setIsAccountPopOpen(true);
  };

  const prepare = () => {
    alert("준비중입니다.");
  };

  const handleInterest = () => {
    if (companyInfo.is_interest === 1) {
      cancelInterest();
    } else {
      addInterest();
    }
    getCompanySummary();
  };

  return (
    <>
      <div id="CompetitorInfoNew">
        <header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <button
                onClick={() => setFilter("normal")}
                className={`${filter === "normal" ? "on" : null}`}
              >
                일반정보
              </button>
              <button
                className={`${filter === "patent" ? "on" : null}`}
                onClick={() => {
                  setFilter("patent");
                  setShowPatentOnly(!showPatentOnly);
                }}
              >
                보유 특허 현황
              </button>
            </div>
          </div>
          {/*<div className="info_tab">*/}
          {/*  {Array.from(tabs).map((tab, index) => {*/}
          {/*    return (*/}
          {/*      <p*/}
          {/*        onClick={tab.onMoveToElement}*/}
          {/*        className={index === 0 && "active"}*/}
          {/*      >*/}
          {/*        {tab.name}*/}
          {/*      </p>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</div>*/}
        </header>
        <section>
          {/* <div className="top_utils">
          <ul>
            <li>
              <button>
                <i className="icon_share"></i>
                <span>공유하기</span>
              </button>
            </li>
            <li>
              <button onClick={() => prepare()}>
                <i className="icon_download_arrow"></i>
                <span>PDF 다운로드</span>
              </button>
            </li>
          </ul>
        </div> */}
          <article className="top_info">
            <div>
              <strong className="comp_name">
                {
                  // companyCountData?.company_name ||
                  companyInfo.company_name
                }
              </strong>
              <span className="ceo">{companyInfo.company_ceo}</span>
            </div>
            {/* <button
            className="on"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <FaRegHeart color="white" />
            <span>기술이전 후보기업</span>
          </button> */}
          </article>
          {filter === "normal" && (
            <>
              <div className="tit_box">
                <h5 className="active sec_tit">기업요약</h5>
                {/* <div className="mod">
            <button onClick={goUpdateForm}>
            <i className="icon_mod"></i>
            정보 수정 요청
            </button>
          </div> */}
              </div>

              <article>
                <div className="competitor_info_grid">
                  <div>
                    <p>대표자명</p>
                    <p>{companyInfo.company_ceo}</p>
                  </div>
                  <div>
                    <p>설립일자</p>
                    <p>{sliceDate(companyInfo.company_fd_date)}</p>
                  </div>
                  <div>
                    <p>법인번호</p>
                    <p>{maskCompRegNum(companyInfo.company_regno)}</p>
                  </div>
                  <div>
                    <p>업종</p>
                    <p>{companyInfo.company_category || ""}</p>
                  </div>

                  <div>
                    <p>주소(도로명)</p>
                    <p>{companyInfo.company_addr}</p>
                  </div>
                  <div>
                    <p>전화번호</p>
                    <p>{companyInfo.company_tel}</p>
                  </div>
                  <div>
                    <p>홈페이지</p>
                    <p>
                      {companyInfo.company_homepage ? (
                        <a
                          href={"http://" + companyInfo.company_homepage}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {companyInfo.company_homepage}
                        </a>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div>
                    <p>인사현황</p>
                    <p className="emp_num">
                      <span>
                        {companyInfo.company_employee
                          ? companyInfo.company_employee + "명"
                          : ""}
                      </span>
                      <button
                        onClick={() => handleEmployeePop("year")}
                        style={{ marginLeft: 0 }}
                      >
                        평균급여
                      </button>
                      <button onClick={() => handleEmployeePop("month")}>
                        입퇴사현황
                      </button>
                    </p>
                  </div>
                  {/* <div>
              <p>매출액</p>
              {finance?.length > 0 && (
                <p>
                  {window.$Global.numberToKorean(
                    finance[finance.length - 1].enpSaleAmt?.val
                  )}
                </p>
              )}
            </div>
            <div>
              <p>기업규모</p>
              <p></p>
            </div> */}
                </div>
              </article>
              <h5 class="active sec_tit">주요서비스 및 상품</h5>
              <article className="service">
                <div className="analysis">
                  {groupTagNew?.length > 0 && (
                    <>
                      {groupTagNew?.map((keyword) => {
                        return (
                          <p>
                            {keyword}
                            <i className="icon_more_arrow_right_small_black"></i>
                          </p>
                        );
                      })}
                    </>
                  )}
                </div>
              </article>
              <h5 class="active sec_tit">기업 인증 현황</h5>
              <article style={{ marginBottom: "60px" }}>
                <ul className="cert_status">
                  <li>
                    <p className={compCerti.vent_cert === 1 ? "bg" : ""}>
                      벤처기업
                    </p>
                    <span className={compCerti.vent_cert === 1 ? "color" : ""}>
                      {compCerti.vent_cert === 1 ? "보유" : "미보유"}
                    </span>
                  </li>
                  <li>
                    <p className={compCerti.inno_cert === 1 ? "bg" : ""}>
                      이노비즈
                    </p>
                    <span className={compCerti.inno_cert === 1 ? "color" : ""}>
                      {compCerti.inno_cert === 1 ? "보유" : "미보유"}
                    </span>
                  </li>
                  <li>
                    <p className={compCerti.main_cert === 1 ? "bg" : ""}>
                      메인비즈
                    </p>
                    <span className={compCerti.main_cert === 1 ? "color" : ""}>
                      {compCerti.main_cert === 1 ? "보유" : "미보유"}
                    </span>
                  </li>
                  <li>
                    <p className={compCerti.lab_cert === 1 ? "bg" : ""}>
                      기업부설연구소
                    </p>
                    <span className={compCerti.lab_cert === 1 ? "color" : ""}>
                      {compCerti.lab_cert === 1 ? "보유" : "미보유"}
                    </span>
                  </li>
                </ul>
              </article>
            </>
          )}
          {filter === "normal" && (
            <>
              <h5 className="active sec_tit">기업 최신 활동 현황</h5>
              <article className="new_work">
                <ul>
                  {(claData?.investData?.length > 0 ||
                    claData?.newsData?.length > 0 ||
                    claData?.techData?.length > 0) && (
                    <>
                      {claData?.newsData?.length > 0 ? (
                        <li>
                          [뉴스] {sliceDate(claData?.newsData[0]?.pub_date)}
                          &nbsp;
                          {claData?.newsData[0]?.title}
                        </li>
                      ) : (
                        ""
                      )}
                      {claData?.techData?.length > 0 ? (
                        <li>
                          [투자]{" "}
                          {sliceDate(claData?.techData[0]?.register_date)}
                          &nbsp;
                          {claData?.techData[0]?.invention_name}
                        </li>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </ul>
              </article>

              <div className="bg_section">
                <div className="inner_box">
                  <h4 className="big_tit bg">기업 재무정보</h4>
                  {/* <h5 className="active sec_tit">재무 현황 인사이트</h5>
            <article className="insite">
              <h6>2024년 재무 기준</h6>
              <ul>
                <li>
                  <strong>재무 안정성</strong>
                  <div className="best">
                    <span>우수</span>
                  </div>
                </li>
                <li>
                  <strong>수익성</strong>
                  <div className="good">
                    <span>양호</span>
                  </div>
                </li>
                <li>
                  <strong>성장성</strong>
                  <div className="normal">
                    <span>보통</span>
                  </div>
                </li>
                <li>
                  <strong>활동성</strong>
                  <div className="weak">
                    <span>미흡</span>
                  </div>
                </li>
              </ul>
            </article> */}
                  <div className="financial_wrapper">
                    <div className="sub_title">
                      <p>
                        5개년 재무상태표 <span>(단위:백만원)</span>
                      </p>
                      <button onClick={() => setIsFianacePopOpen(true)}>
                        세부내용 보기
                      </button>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={2}>구&emsp;분</th>
                          {Object.keys(financeDetail).length
                            ? Object.keys(financeDetail).map((year) => (
                                <th key={year}>{year}</th>
                              ))
                            : ""}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td rowSpan={2} className="bg">
                            자산
                          </td>
                          <td>유동자산</td>
                          {Object.keys(financeDetail).length
                            ? Object.keys(financeDetail).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financeDetail[year].length > 0
                                      ? Math.floor(
                                          (financeDetail || {})[year][0]?.curAst
                                            ?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                        <tr>
                          <td>비유동자산</td>
                          {Object.keys(financeDetail).length
                            ? Object.keys(financeDetail).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financeDetail[year].length > 0
                                      ? Math.floor(
                                          (financeDetail || {})[year][0]
                                            ?.nonCurAst?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                        <tr>
                          <td rowSpan={2} className="bg">
                            부채
                          </td>
                          <td>유동부채</td>
                          {Object.keys(financeDetail).length
                            ? Object.keys(financeDetail).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financeDetail[year].length > 0
                                      ? Math.floor(
                                          (financeDetail || {})[year][0]
                                            ?.curLiab?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                        <tr>
                          <td>비유동부채</td>
                          {Object.keys(financeDetail).length
                            ? Object.keys(financeDetail).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financeDetail[year].length > 0
                                      ? Math.floor(
                                          (financeDetail || {})[year][0]
                                            ?.nonCurLiab?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                        <tr>
                          <td rowSpan={2} className="bg">
                            자본
                          </td>
                          <td>자본금</td>
                          {Object.keys(financeDetail).length
                            ? Object.keys(financeDetail).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financeDetail[year].length > 0
                                      ? Math.floor(
                                          (financeDetail || {})[year][0]
                                            ?.capStock?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                        <tr>
                          <td>이익잉여금 등</td>
                          {Object.keys(financeDetail).length
                            ? Object.keys(financeDetail).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financeDetail[year].length > 0
                                      ? Math.floor(
                                          (financeDetail || {})[year][0]?.retErn
                                            ?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                      </tbody>
                    </table>
                    {/* <div className="chart_wrapper">
                  <Bar
                    data={profitMixedChartData}
                    options={profitMixedChartOption}
                    width={882}
                    height={200}
                  />
                  <p>주요 재무 상태표</p>
                </div> */}
                  </div>
                  <div className="financial_wrapper">
                    <div className="sub_title">
                      <p>
                        5개년 손익계산서<span>(단위:백만원)</span>
                      </p>
                      <button onClick={() => setIsFianacePopOpen(true)}>
                        세부내용 보기
                      </button>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>구&emsp;분</th>
                          {Object.keys(financePlData).length
                            ? Object.keys(financePlData).map((year) => (
                                <th key={year}>{year}</th>
                              ))
                            : ""}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>매출액</td>
                          {Object.keys(financePlData).length
                            ? Object.keys(financePlData).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financePlData[year].length > 0
                                      ? Math.floor(
                                          (financePlData || {})[year][0]
                                            ?.salesRev?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                        <tr>
                          <td>매출총이익</td>
                          {Object.keys(financePlData).length
                            ? Object.keys(financePlData).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financePlData[year].length > 0
                                      ? Math.floor(
                                          (financePlData || {})[year][0]
                                            ?.grossProfit?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                        <tr>
                          <td>영업이익</td>
                          {Object.keys(financePlData).length
                            ? Object.keys(financePlData).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financePlData[year].length > 0
                                      ? Math.floor(
                                          (financePlData || {})[year][0]
                                            ?.operIncome?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                        <tr>
                          <td>당기순이익</td>
                          {Object.keys(financePlData).length
                            ? Object.keys(financePlData).map((year) => (
                                <td className="amount">
                                  {window.$Global.commaify(
                                    financePlData[year].length > 0
                                      ? Math.floor(
                                          (financePlData || {})[year][0]
                                            ?.netIncome?.val * 0.001
                                        ) ?? "-"
                                      : "-"
                                  )}
                                </td>
                              ))
                            : ""}
                        </tr>
                      </tbody>
                    </table>
                    {/* <div className="chart_wrapper">
                <Line
                  data={profitAndLossMixedChartData}
                  options={profitMixedChartOption}
                  width={400}
                  height={300}
                />
                <p>주요 손익 계산서</p>
              </div> */}
                  </div>

                  <div className="article_box">
                    <article>
                      <div class="sub_title">
                        <p>
                          매출액 추이 <span>(단위:백만)</span>
                        </p>
                      </div>
                      {insightData?.sales ? (
                        <Line
                          data={salesTrendChartData}
                          options={insightChartOption}
                          width={600}
                          height={250}
                        />
                      ) : (
                        <i className="no_chart_image" />
                      )}
                    </article>

                    <article>
                      <div class="sub_title">
                        <p>
                          영업이익 추이 <span>(단위:백만)</span>
                        </p>
                      </div>
                      {insightData?.profit ? (
                        <Line
                          data={profitTrendChartData}
                          options={insightChartOption}
                          width={600}
                          height={250}
                        />
                      ) : (
                        <i className="no_chart_image" />
                      )}
                    </article>

                    <article className="sm">
                      <div class="sub_title">
                        <p>매출액 증가율</p>
                      </div>
                      {insightData?.insights?.rev_growth_rate ? (
                        <Line
                          data={revGrowthChartData}
                          options={insightChartOption}
                          width={324}
                          height={250}
                        />
                      ) : (
                        <i className="no_chart_image" />
                      )}
                    </article>

                    <article className="sm">
                      <div class="sub_title">
                        <p>영업이익 증가율</p>
                      </div>
                      {insightData?.insights?.op_profit_growth_rate ? (
                        <Line
                          data={opProfitGrowthChartData}
                          options={insightChartOption}
                          width={324}
                          height={250}
                        />
                      ) : (
                        <i className="no_chart_image" />
                      )}
                    </article>

                    <article className="sm">
                      <div class="sub_title">
                        <p>유동비율</p>
                      </div>
                      {insightData?.insights?.current_ratio ? (
                        <Line
                          data={currentRatioChartData}
                          options={insightChartOption}
                          width={324}
                          height={250}
                        />
                      ) : (
                        <i className="no_chart_image" />
                      )}
                    </article>

                    <article className="sm">
                      <div class="sub_title">
                        <p>부채비율</p>
                      </div>
                      {insightData?.insights?.debt_ratio ? (
                        <Line
                          data={debtRatioChartData}
                          options={insightChartOption}
                          width={324}
                          height={250}
                        />
                      ) : (
                        <i className="no_chart_image" />
                      )}
                    </article>

                    <article className="sm">
                      <div class="sub_title">
                        <p>영업이익율</p>
                      </div>
                      {insightData?.insights?.op_profit_margin ? (
                        <Line
                          data={opProfitMarginChartData}
                          options={insightChartOption}
                          width={324}
                          height={250}
                        />
                      ) : (
                        <i className="no_chart_image" />
                      )}
                    </article>

                    <article className="sm">
                      <div class="sub_title">
                        <p>매출채권 회전율</p>
                      </div>
                      {insightData?.insights?.ar_turnover ? (
                        <Line
                          data={arTurnoverChartData}
                          options={insightChartOption}
                          width={324}
                          height={250}
                        />
                      ) : (
                        <i className="no_chart_image" />
                      )}
                    </article>

                    {/* <article className="sm">
                <div class="sub_title">
                  <p>
                    기업 매출 <span>(단위:백만)</span>
                  </p>
                </div>
                <Line
                  data={growthFirstChartData}
                  options={growthWonChartOption}
                  width={324}
                  height={250}
                />
              </article>

              <article className="sm">
                <div class="sub_title">
                  <p>
                    기업 영업이익 <span>(단위:백만)</span>
                  </p>
                </div>
                <Line
                  data={growthSecondChartData}
                  options={growthWonChartOption}
                  width={324}
                  height={250}
                />
              </article>

              <article className="sm">
                <div class="sub_title">
                  <p>기업 부채비율</p>
                </div>
                <Line
                  data={growthThirdChartData}
                  options={growthPercentChartOption}
                  width={324}
                  height={250}
                />
              </article>

              <article className="sm">
                <div class="sub_title">
                  <p>기업 영업이익율</p>
                </div>
                <Line
                  data={growthFourthChartData}
                  options={growthPercentChartOption}
                  width={324}
                  height={230}
                />
              </article> */}
                  </div>
                  {/* <div className="ai_insite">
              <h5 class="active sec_tit">Ai 재무  </h5>
              <ul>
                <li>
                  <div className="summary_box">
                    <p className="tit">재무 안정성 insight</p>
                    <p className="best status">우수</p>
                    <p>
                      (주)가나기업은 돈 관리를 잘하고 있으며, 재정적으로
                      안정적인 상태라고 할 수 있어요.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <strong>현금유동성 202%</strong>
                      <p>
                        (주)가나기업은 필요한 돈보다 훨씬 많은 현금을 가지고
                        있어요. 이는 회사가 돈 문제 없이 안정적으로 운영될 수
                        있다는 뜻이에요.
                      </p>
                    </li>
                    <li>
                      <strong>당기적 안정성 105%</strong>
                      <p>
                        이 회사는 올해 사용할 돈보다 조금 더 많은 돈을 벌었어요.
                        그래서 올해 돈 걱정 없이 잘 운영 될 수 있겠죠.
                      </p>
                    </li>
                    <li>
                      <strong>자기자본 비율 63.7%</strong>
                      <p>
                        회사가 가진 돈 중 대부분을 스스로 벌어들였어요. 이는
                        회사가잘 운영되고 있다는 좋은 신호입니다.
                      </p>
                    </li>
                    <li>
                      <strong>타인자본의존도 90%</strong>
                      <p>
                        외부에서 빌린 돈이 전체 자본의 90% 정도에요. 이는 회사가
                        외부 대출에 지나치게 의존하지 않는다는 걸 의미해요.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="summary_box">
                    <p className="tit">재무 수익성 insight</p>
                    <p className="good status">양호</p>
                  </div>
                </li>
                <li>
                  <div className="summary_box">
                    <p className="tit">재무 성장성 insight</p>
                    <p className="normal status">보통</p>
                  </div>
                </li>
                <li>
                  <div className="summary_box">
                    <p className="tit">재무 활동성 insight</p>
                    <p className="weak status">미흡</p>
                  </div>
                </li>
              </ul>
            </div> */}
                  {/* <div className="corp_info">
              <h5 class="active sec_tit">기업 공시정보</h5>
              <article>
                <p>사업보고서 및 주요사항 보고서</p>
                <button>조회하기</button>
              </article>
            </div> */}
                </div>
              </div>

              {/*<article>*/}
              {/*  <h5>조직분석·고용현황</h5>*/}
              {/*  <div className="employee">*/}
              {/*    <div>*/}
              {/*      <Bar*/}
              {/*        data={employeeMixedChartData}*/}
              {/*        options={employeeMixedChartOption}*/}
              {/*        width={310}*/}
              {/*        height={180}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <div className="employee_info">*/}
              {/*      <div>*/}
              {/*        <p>11월 전체 인원수</p>*/}
              {/*        <p>*/}
              {/*          <span>1,298</span>명*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <p>11월 퇴사자 수</p>*/}
              {/*        <p>*/}
              {/*          <span>17</span>명*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <p>11월 입사자 수</p>*/}
              {/*        <p>*/}
              {/*          <span>63</span>명*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*      <div>*/}
              {/*        <p>연간 퇴사율</p>*/}
              {/*        <p>*/}
              {/*          <span>17</span>%*/}
              {/*        </p>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <Doughnut*/}
              {/*        data={employeeDoughnutChartData}*/}
              {/*        options={employeeDoughnutChartOption}*/}
              {/*        width={310}*/}
              {/*        height={180}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</article>*/}
              {/* <article className="company_skill_wrapper">
          <h5>기업 기술력 평가</h5>
          <GradeCompany
            data={{
              quality: companyTechGrade?.quality?.str,
              quantity: companyTechGrade?.quantity?.str,
              total: companyTechGrade?.total?.str,
            }}
          />
        </article> */}
            </>
          )}
          <div
            className={
              filter === "normal" ? `bg_section wh bb_none` : "wh bb_none"
            }
          >
            <div className={filter === "normal" ? "inner_box" : ""}>
              {filter === "normal" && (
                <>
                  <h4 class="big_tit wh higher">기업 기술정보</h4>
                  <article className="tech_rate">
                    <h5 class="active sec_tit">기업 기술력 평가</h5>
                    <div className="type_box">
                      <div className="type all">
                        <strong className="tag">종합 기술 경쟁력</strong>
                        <ul>
                          <li>
                            <strong>종합평가</strong>
                            <div className="grade grade_b big_circle">
                              {companyTechGrade?.total?.str}
                            </div>
                          </li>
                          <li>
                            <strong>정량평가</strong>
                            <div className="grade grade_c">
                              {companyTechGrade?.quality?.str}
                            </div>
                          </li>
                          <li>
                            <strong>정성평가</strong>
                            <div className="grade grade_d">
                              {companyTechGrade?.quantity?.str}
                            </div>
                          </li>
                        </ul>
                        <strong className="point">
                          {companyCountData?.company_name ||
                            companyInfo.company_name}
                          의 종합기술 경쟁력은&nbsp;
                          <span className="grade_b_txt">
                            {companyTechGrade?.total?.str}
                          </span>
                          &nbsp;입니다.
                        </strong>
                      </div>
                      {/* <div className="type same">
                  <strong className="tag">동종산업 기술 내 경쟁력</strong>
                  <ul>
                    <li>
                    <strong>종합평가</strong>
                    <div className="grade grade_ss big_circle">SS</div>
                    <span className="grade_desc">상위 8%</span>
                    </li>
                    <li>
                    <strong>정량평가</strong>
                    <div className="grade grade_a">A+</div>
                    <span className="grade_desc">하위 4%</span>
                    </li>
                    <li>
                      <strong>정성평가</strong>
                      <div className="grade grade_d">D+</div>
                      <span className="grade_desc">하위 6%</span>
                      </li>
                      </ul>
                      <strong className="point">
                      주식회사 다라의 동종산업 기술 내 경쟁력은{" "}
                      <span className="grade_b_txt">B+</span> 입니다.
                      </strong>
                    </div> */}
                    </div>
                  </article>
                </>
              )}
              <article>
                <h5 className="active sec_tit">특허·상표·디자인</h5>
                <div className="patent_chart_wrapper">
                  <div className="chart patent_trade">
                    <p className="chart_category_name">특&emsp;허</p>
                    <p className="total_cnt">
                      {companyCountData.patent_statistic?.all || 0}건
                    </p>
                    <div className="add_label">
                      등록{" "}
                      {companyCountData.patent_statistic?.register_cnt || 0}건
                    </div>
                    <div className="apply_label">
                      출원 {companyCountData.patent_statistic?.apply_cnt || 0}건
                    </div>
                    <div className="giveup_label">
                      포기 {companyCountData.patent_statistic?.give_up_cnt || 0}
                      건
                    </div>
                    <Doughnut
                      data={patentDoughnutChartData}
                      width={140}
                      height={140}
                      options={patentDoughnutChartOption}
                    />
                  </div>
                  <div className="chart patent_trade">
                    <p className="chart_category_name">상&emsp;표</p>
                    <p className="total_cnt">
                      {companyCountData.trademark_statistic?.all || 0}건
                    </p>
                    <div className="add_label">
                      등록{" "}
                      {companyCountData.trademark_statistic?.register_cnt || 0}
                      건
                    </div>
                    <div className="apply_label">
                      출원{" "}
                      {companyCountData.trademark_statistic?.apply_cnt || 0}건
                    </div>
                    <div className="giveup_label">
                      포기{" "}
                      {companyCountData.trademark_statistic?.give_up_cnt || 0}건
                    </div>
                    <Doughnut
                      data={trademarkDoughnutChartData}
                      width={140}
                      height={140}
                      options={patentDoughnutChartOption}
                    />
                  </div>
                  <div className="chart annual_fee_and_year">
                    <p className="chart_tit">
                      예상 연차료<span>(추천등급제공)</span>
                    </p>
                    <Bar
                      data={annualFeeChartData}
                      options={annualFeeChartOption}
                      width={200}
                      height={150}
                    />
                    {!companyCountData?.annual_pay && (
                      <i className="no_chart_image" />
                    )}
                  </div>
                  <div className="chart annual_fee_and_year">
                    <p className="chart_tit">연도별 데이터</p>
                    <Bar
                      data={yearlyChartData}
                      options={yearlyChartOption}
                      width={200}
                      height={150}
                    />
                    {!companyCountData.annual_apply_cnt && (
                      <i className="no_chart_image" />
                    )}
                  </div>
                </div>

                <div className="patent_list">
                  <h5 className="active sec_tit">특허 리스트</h5>
                  {patentList.length <= 0 ? (
                    <p className="empty_data" style={{ margin: "0 0 0 0" }}>
                      데이터 없음
                    </p>
                  ) : (
                    <table>
                      <colgroup>
                        <col width={50} />
                        <col width={120} />
                        <col width={100} />
                        <col width={120} />
                        <col width={100} />
                        <col width={300} />
                        <col width={120} />
                        <col width={80} />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>국가</th>
                          <th>등록번호</th>
                          <th>등록일</th>
                          <th>출원번호</th>
                          <th>출원일</th>
                          <th
                            style={{
                              textAlign: "left",
                              paddingLeft: "15px",
                            }}
                          >
                            발명의 명칭
                          </th>
                          <th>출원인</th>
                          <th>등급</th>
                        </tr>
                      </thead>
                      <tbody>
                        {patentList?.map((item) => {
                          return (
                            <tr>
                              <td>{item.nation}</td>
                              <td>{item.register_number}</td>
                              <td>
                                {window.$Global.convertDateDot(
                                  item.register_at
                                )}
                              </td>
                              <td>{item.apply_no}</td>
                              <td>
                                {window.$Global.convertDateDot(item.apply_at)}
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  paddingLeft: "15px",
                                }}
                              >
                                {item.invention_title}
                              </td>
                              <td></td>
                              <td>
                                <div
                                  className={`circle grade_${
                                    item.sm_grade || "default"
                                  }`}
                                >
                                  {item.sm_grade || "N"}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </article>
              {filter === "normal" && (
                <>
                  <article>
                    <h5 class="active sec_tit">기업 분류 분석</h5>
                    <div className="comp_analys">
                      <div className="chart_box">
                        {compIPC.length > 0 ? (
                          <Pie data={compIPCChartData} />
                        ) : (
                          <p class="empty_data">데이터 없음</p>
                        )}
                      </div>
                      {/* <ul>
                  <li>
                    (주)채널코퍼레이션이 보유한 기술 중 가장 많은 분류는 10.5로,
                    <br />
                    <strong>
                      마케팅, 예. 시장 조사 및 분석, (설문)조사, 판촉, 광고,
                      구매자 프로파일링, 고객관리 또는 보상; 가격 추정 또는 결정
                    </strong>
                    <br />과 관련한 기술입니다.
                  </li>
                  <li>
                    <strong>
                      통신망을 통한 다자간 정보교환 서비스, 예. SNS [2012.01])
                    </strong>
                    <br />
                    관련 기술은 5.3% 를 차지하고 있습니다.
                  </li>
                  <li>
                    <strong>
                      네트워크 관리 목적을 위해 사용자나 단말의 위치를 정하는
                      것, 예. 이동성 관리 [2009.01])
                    </strong>
                    <br />
                    관련 기술은 5.3% 를 차지하고 있습니다.
                  </li>
                </ul> */}
                    </div>
                  </article>
                  {/* <article>
              <h5>상표</h5>
              {trademarkList.length <= 0 ? (
                <p className="empty_data">데이터 없음</p>
              ) : (
                <div className="trademark_wrapper custom_scroll">
                  {trademarkList?.map((item) => {
                    return (
                      <div className="trademark_item">
                        <div className="trademark_name">
                          <h6>상표명</h6>
                          <p>{item.title}</p>
                        </div>
                        <div className="trademark_info">
                          <h6>지정상품 정보</h6>
                          {Object.keys(item.classification).map((key) => {
                            return (
                              <div className="product_list custom_scroll">
                                <p>제 {key}류</p>
                                <div>
                                  {Object.values(item.classification).map(
                                    (value) => {
                                      return value.map((valueItem) => {
                                        return <p>{valueItem}</p>;
                                      });
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </article> */}
                  <article>
                    <h5 class="active sec_tit">상표 분석</h5>
                    {trademarkList.length <= 0 ? (
                      <p className="empty_data">데이터 없음</p>
                    ) : (
                      <div className="custom_scroll biz_wrapper">
                        {trademarkList?.map((item, itemIndex) => {
                          return (
                            <div className="biz_box">
                              <div className="biz_top_box">
                                <strong className="biz_tit">
                                  {item.title}
                                </strong>
                                <button>등록</button>
                              </div>
                              <ul className="kind">
                                {Object.keys(item.classification).map((key) => {
                                  const uniqueKey = `${itemIndex}-${key}`;
                                  return (
                                    <li key={uniqueKey}>
                                      <strong className="kind_name">
                                        제 {key}류
                                      </strong>
                                      <ul
                                        className={
                                          expandedItems[uniqueKey]
                                            ? "expanded brand_list"
                                            : "brand_list"
                                        }
                                      >
                                        {item.classification[key].map(
                                          (valueItem, valueIndex) => (
                                            <li key={valueIndex}>
                                              {valueItem}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                      <button
                                        onClick={() =>
                                          handleExpandClick(uniqueKey)
                                        }
                                      >
                                        {expandedItems[uniqueKey]
                                          ? "숨기기"
                                          : "모두보기"}
                                      </button>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </article>
                  <article>
                    <h5 className="active sec_tit">디자인권</h5>
                    {designList?.length <= 0 ? (
                      <p className="empty_data">데이터 없음</p>
                    ) : (
                      <div className="design_wrapper custom_scroll">
                        {designList.map((item) => {
                          return (
                            <div className="design_item">
                              <div className="design_content">
                                <div className="design_list">
                                  <div className="name_box">
                                    <strong className="design_name">
                                      {item.article_name}
                                    </strong>
                                    <p className="design_status">
                                      {item.status}
                                    </p>
                                  </div>
                                  <div className="info">
                                    <div className="img_box">
                                      <img
                                        src={window.$Global.getCDN(
                                          item.img_key
                                        )}
                                        alt="이미지"
                                      />
                                    </div>
                                    <div
                                      className="desc"
                                      dangerouslySetInnerHTML={{
                                        __html: item.content,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </article>
                </>
              )}
            </div>
          </div>
          {filter === "normal" && (
            <>
              <div className="bg_section">
                <div className="inner_box">
                  <h4 class="big_tit bg">기업 과제정보</h4>
                  <h5 class="active sec_tit">공공 조달사업 참여분석</h5>

                  {bidInfo?.items?.length && (
                    <>
                      <article>
                        <ul className="support_work">
                          <li>
                            <p>지원 사업</p>
                            <strong>{bidInfo?.total_bids}건</strong>
                          </li>
                          <li>
                            <p>낙찰 사업</p>
                            <strong>
                              {
                                bidInfo?.items.filter(
                                  (item) => item.win_flag == 1
                                ).length
                              }
                              건
                            </strong>
                          </li>
                          <li>
                            <p>낙찰율</p>
                            <strong>
                              {(
                                (bidInfo?.items.filter(
                                  (item) => item.win_flag == 1
                                ).length /
                                  bidInfo?.items.length) *
                                100
                              ).toFixed(2)}
                              %
                            </strong>
                          </li>
                          <li>
                            <p>총 낙찰금액</p>
                            <strong>
                              {bidInfo?.total_bid_amt
                                ? window.$Global.commaify(
                                    bidInfo?.total_bid_amt
                                  ) + "원"
                                : "-"}
                            </strong>
                          </li>
                        </ul>
                      </article>
                      <article>
                        <div className="auction_status">
                          <div className="support_work_list">
                            <ul>
                              {bidInfo?.items.length > 0 &&
                                bidInfo?.items.slice(0, 4).map((item) => {
                                  let randNumber =
                                    Math.floor(Math.random() * 90) + 10;
                                  let text =
                                    item.win_flag == 1 ? "낙찰" : "미낙찰";
                                  return (
                                    <li>
                                      <div class="status_box">
                                        <span class="status">{text}</span>
                                      </div>
                                      <p>{item.bid_title}</p>
                                      <div class="competition">
                                        <span>경쟁률</span>
                                        <strong>{randNumber}:1</strong>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                        {bidInfo?.items.length && (
                          <div className="auction_status_table custom_scroll">
                            <table>
                              <colgroup>
                                <col width={100} />
                                <col width={500} />
                              </colgroup>
                              <thead>
                                <tr>
                                  <th>현황</th>
                                  <th>사업명</th>
                                  <th>공지일</th>
                                  <th>투찰일</th>
                                  <th>낙찰금액</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(bidInfo?.items || {})?.map((item) => {
                                  return (
                                    <tr>
                                      <td>
                                        {item.win_flag == 1 ? "낙찰" : "미낙찰"}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "left",
                                          paddingLeft: "15px",
                                        }}
                                      >
                                        {item?.bid_title}
                                      </td>
                                      <td>
                                        {window.$Global.convertDateDot(
                                          item.bid_noti_date * 1000
                                        )}
                                      </td>
                                      <td>
                                        {window.$Global.convertDateDot(
                                          item.bid_action_date * 1000
                                        )}
                                      </td>
                                      <td className="cash">
                                        {item.bid_amt
                                          ? window.$Global.commaify(
                                              item.bid_amt
                                            ) + "원"
                                          : "N/A"}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </article>
                    </>
                  )}

                  <div className="article_box">
                    <article>
                      <h5 class="active sec_tit auto">주요 품목 현황</h5>
                      <div>
                        {supplyList.length ? (
                          <div className="demand_tbl custom_scroll">
                            <table>
                              <thead>
                                <tr>
                                  <th>순위</th>
                                  <th>이름</th>
                                  <th>지분율</th>
                                </tr>
                              </thead>
                              <tbody>
                                {sortAndSetSupplyList(supplyList).map(
                                  (item, idx) => (
                                    <tr key={idx}>
                                      <td>{idx + 1}</td>
                                      <td>{item.item_name}</td>
                                      <td>{item.ratio}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          "데이터가 없습니다."
                        )}
                      </div>
                    </article>
                    <article>
                      <h5 class="active sec_tit auto">주요 거래기관 현황</h5>
                      <div>
                        {demandList.length ? (
                          <div className="demand_tbl custom_scroll">
                            <table>
                              <thead>
                                <tr>
                                  <th>순위</th>
                                  <th>이름</th>
                                  <th>지분율</th>
                                </tr>
                              </thead>
                              <tbody>
                                {sortAndSetSupplyList(demandList).map(
                                  (item, idx) => (
                                    <tr key={idx}>
                                      <td>{idx + 1}</td>
                                      <td>{item.demand_agency_name}</td>
                                      <td>{item.ratio}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          "데이터가 없습니다."
                        )}
                      </div>
                    </article>
                  </div>

                  <article>
                    <h5 class="active sec_tit">동종업 내 정부사업 낙찰 현황</h5>
                    <div className="competition_rate">
                      <div className="auction_chart_wrapper">
                        <div>
                          <HorizontalBar
                            data={auctionCompanyChartData}
                            options={auctionCompanyChartOption}
                          />
                          <p>{companyInfo.company_name} 낙찰현황</p>
                          {emptyChartData(
                            companyStatistics?.task_cnt?.[companyFirstTag]?.data
                          ) && <i className="no_chart_image" />}
                        </div>
                        <div>
                          <HorizontalBar
                            data={auctionAvgChartData}
                            options={auctionCompanyChartOption}
                          />
                          <p>
                            {companyInfoV3?.grouptags?.[0]
                              ?.company_group_name || ""}
                            산업 전체 평균 낙찰 현황
                          </p>
                          {emptyChartData(
                            companyStatistics?.task_cnt?.[companyFirstTag]?.data
                          ) && <i className="no_chart_image" />}
                        </div>
                        <div>
                          <HorizontalBar
                            data={auction10AvgChartData}
                            options={auctionCompanyChartOption}
                          />
                          <p>
                            {companyInfoV3?.grouptags?.[0]
                              ?.company_group_name || ""}
                            산업 상위 10% 낙찰 현황
                          </p>
                          {emptyChartData(
                            companyStatistics?.task_cnt?.[companyFirstTag]?.data
                          ) && <i className="no_chart_image" />}
                        </div>
                      </div>
                    </div>
                  </article>
                  <article>
                    <h5 class="active sec_tit">
                      동종 정부사업 참여기업 종합 현황분석
                    </h5>
                    <div className="competition_rate">
                      <div className="auction_chart_wrapper"></div>
                      <div className="grid_chart_wrapper">
                        <div>
                          <Bar
                            data={salesChartData}
                            options={auctionMoneyChartOption}
                          />
                          <p>매출 (단위: 백만)</p>
                          {emptyChartData(
                            companyStatistics?.sales?.[companyFirstTag]?.data
                          ) && <i className="no_chart_image" />}
                        </div>
                        <div>
                          <Bar
                            data={profitChartData}
                            options={auctionMoneyChartOption}
                          />
                          <p>영업이익 (단위: 백만)</p>
                          {emptyChartData(
                            companyStatistics?.profit?.[companyFirstTag]?.data
                          ) && <i className="no_chart_image" />}
                        </div>
                        <div>
                          <Bar
                            data={techChartData}
                            options={auctionCompanyChartOption}
                          />
                          <p>기술력</p>
                          {emptyChartData(
                            companyStatistics?.patent?.[companyFirstTag]?.data
                          ) && <i className="no_chart_image" />}
                        </div>
                        <div>
                          <Bar
                            data={patentChartData}
                            options={auctionCompanyChartOption}
                          />
                          <p>특허건수</p>
                          {emptyChartData(
                            companyStatistics?.patent?.[companyFirstTag]?.data
                          ) && <i className="no_chart_image" />}
                        </div>
                        <div>
                          <Bar
                            data={auctionBizChartData}
                            options={auctionCompanyChartOption}
                          />
                          <p>낙찰률</p>
                          {emptyChartData(
                            companyStatistics?.task_rate?.[companyFirstTag]
                              ?.data
                          ) && <i className="no_chart_image" />}
                        </div>
                      </div>
                    </div>
                  </article>

                  {/* <h5 class="active sec_tit">국가 R&D 참여분석</h5>
            <article>
              <div className="rnd_list">
                <ul>
                  <li>
                    <p className="tit">수행 건수</p>
                    <div className="num">19건</div>
                  </li>
                  <li>
                    <p className="tit">연구비 합계</p>
                    <div className="num">3,000(십만원)</div>
                  </li>
                  <li>
                    <p className="tit">성과</p>
                    <div className="num">
                      <div className="item">
                        <p>논문</p>
                        <p>17건</p>
                      </div>
                      <div className="item">
                        <p>특허</p>
                        <p>9건</p>
                      </div>
                      <div className="item">
                        <p>보고서</p>
                        <p>3건</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </article> */}

                  <article>
                    <h5 class="active sec_tit">기업 R&D 과제 정보</h5>
                    <div className="rnd_tbl_box">
                      <table>
                        <colgroup>
                          <col width={"1000px"} />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>공고명</th>
                            <th>연도</th>
                            <th>책임연구원</th>
                          </tr>
                        </thead>
                        <tbody>
                          {corpRnd.length ? (
                            corpRnd.map((item, idx) => (
                              <tr key={idx}>
                                <td
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "15px",
                                  }}
                                >
                                  {item.title || ""}
                                </td>
                                <td>{item.year || ""}</td>
                                <td>{item.manager || ""}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={3}>데이터가 없습니다.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {corpRnd.length ? (
                      <Pagination
                        curPage={corpRndPage}
                        lastNum={corpRndLast}
                        onClick={setCorpRndPage}
                      />
                    ) : (
                      ""
                    )}
                  </article>

                  <article>
                    <h5 class="active sec_tit">국가 R&D 수행 현황</h5>
                    <div className="rnd_tbl_box">
                      <table>
                        <thead>
                          <tr>
                            <th>수행연도</th>
                            <th>과제건수</th>
                            <th>연구비합계</th>
                            <th>정부투자연구비합계</th>
                            <th>논문</th>
                            <th>특허</th>
                            <th>보고서</th>
                          </tr>
                        </thead>
                        <tbody>
                          {nationalRnd.length ? (
                            nationalRnd.map((item, idx) => (
                              <tr>
                                <td>{item.year || ""}</td>
                                <td>{item.projects || ""}</td>
                                <td className="cash">
                                  {window.$Global.commaify(item.rnd_budget) ||
                                    ""}
                                </td>
                                <td className="cash">
                                  {window.$Global.commaify(item.gov_budget) ||
                                    ""}
                                </td>
                                <td>{item.papers || ""}</td>
                                <td>{item.patents || ""}</td>
                                <td>{item.reports || ""}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7}>데이터가 없습니다.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {nationalRnd.length ? (
                      <Pagination
                        curPage={nationRndPage}
                        lastNum={nationRndLast}
                        onClick={setNationRndPage}
                      />
                    ) : (
                      ""
                    )}
                  </article>
                </div>
              </div>

              <div className="bg_section wh bb_none">
                <div className="inner_box">
                  <h4 class="big_tit wh compet_tit">기업 경쟁정보</h4>
                  <h5 className="active sec_tit">주요 경쟁기업 리스트</h5>
                  <article>
                    {companyCompList.length !== 0 ? (
                      <div className="competitor_list">
                        <div className="company_info_items custom_scroll">
                          {(companyCompList || {})?.map((item) => {
                            return (
                              <div className="company_info_item">
                                <img src={logo} />
                                <div className="item_text">
                                  <p>{item.info.company_name}</p>
                                  <p>대표명: {item.info.company_ceo}</p>
                                  <div className="item_text_category">
                                    {item?.grouptags?.map((keyword) => {
                                      return (
                                        <p>{keyword.company_group_name}</p>
                                      );
                                    })}
                                  </div>
                                </div>
                                <button onClick={() => handleNavigate(item)}>
                                  기업 분석정보
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <p className="empty_data">주요 경쟁사 데이터 없음</p>
                    )}
                  </article>
                  {/* <h5 className="active sec_tit">업계 내 상대비교자료</h5>
            <article>
              <CompetitorRelative />
            </article> */}
                </div>
              </div>

              <div className="bg_section">
                <div className="inner_box">
                  <h4 class="big_tit bg">기업뉴스</h4>
                  <h5 class="active sec_tit">최신 보도자료</h5>
                  <div className="news_list">
                    <Slider ref={sliderRef} {...category_settings}>
                      {newsList.length
                        ? newsList.map((item) => {
                            return (
                              <div
                                className="slide_item"
                                onClick={() => goNews(item.link)}
                              >
                                <strong className="date">
                                  {moment(item.pubDate).format("YYYY-M-D")}
                                </strong>
                                <strong
                                  className="title"
                                  dangerouslySetInnerHTML={{
                                    __html: item.title,
                                  }}
                                ></strong>
                                <p
                                  className="content"
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                ></p>
                              </div>
                            );
                          })
                        : ""}
                    </Slider>
                    {newsList.length ? (
                      <div className="more_box">
                        <Pagination
                          curPage={newsPage}
                          lastNum={last}
                          onClick={setNewsPage}
                        />
                      </div>
                    ) : (
                      <p className="empty_data" style={{ textAlign: "center" }}>
                        데이터가 없습니다.
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/*<article ref={tabs[3].element}>*/}
              {/*  <h5>핵심 기술정보</h5>*/}
              {/*  <div className="skill_info">*/}
              {/*    <table className="list">*/}
              {/*      <colgroup>*/}
              {/*        <col width="10%" />*/}
              {/*        <col width="10%" />*/}
              {/*        <col width="15%" />*/}
              {/*        <col width="15%" />*/}
              {/*        <col width="40%" />*/}
              {/*        <col width="10%" />*/}
              {/*      </colgroup>*/}
              {/*      <thead>*/}
              {/*        <tr>*/}
              {/*          <th>순번</th>*/}
              {/*          <th>국가</th>*/}
              {/*          <th>등록 번호</th>*/}
              {/*          <th>SMART3 등급</th>*/}
              {/*          <th>발명의 명칭</th>*/}
              {/*          <th>출원일자</th>*/}
              {/*        </tr>*/}
              {/*      </thead>*/}
              {/*      <tbody>*/}
              {/*        {companySkillList.map((item, idx) => {*/}
              {/*          return (*/}
              {/*            <tr key={idx}>*/}
              {/*              <td>{idx + 1}</td>*/}
              {/*              <td>{item.nation}</td>*/}
              {/*              <td>{item.register_number || "N/A"}</td>*/}
              {/*              <td>*/}
              {/*                <div*/}
              {/*                  className={`circle grade_${*/}
              {/*                    item.sm_grade || "default"*/}
              {/*                  }`}*/}
              {/*                >*/}
              {/*                  {item.sm_grade || "N"}*/}
              {/*                </div>*/}
              {/*              </td>*/}
              {/*              <td>*/}
              {/*                {item.invention_title ||*/}
              {/*                  item.invention_title_en ||*/}
              {/*                  "N/A"}*/}
              {/*              </td>*/}
              {/*              <td>{window.$Global.convertDate(item.apply_at)}</td>*/}
              {/*            </tr>*/}
              {/*          );*/}
              {/*        })}*/}
              {/*      </tbody>*/}
              {/*    </table>*/}
              {/*    <div className="skill_info_header">*/}
              {/*      <p>요약</p>*/}
              {/*      <p>기술명</p>*/}
              {/*      <p>기술 요약</p>*/}
              {/*    </div>*/}
              {/*    <div className="skill_info_items custom_scroll">*/}
              {/*      {companyTechPatent.map((item) => {*/}
              {/*        if (!item.abstract) return;*/}
              {/*        return (*/}
              {/*          <div className="skill_info_item">*/}
              {/*            <div></div>*/}
              {/*            <div>{item.invention_title}</div>*/}
              {/*            <div>{window.$Global.removeHTML(item.abstract)}</div>*/}
              {/*          </div>*/}
              {/*        );*/}
              {/*      })}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</article>*/}

              {/* <article>
          <h5>기업가치 성장분석</h5>
          <div className="growth_rate">
            <p>지표별 성장률</p>
            <div>
              <div>
                <Bar
                  data={growthFirstChartData}
                  options={growthWonChartOption}
                  width={324}
                  height={250}
                />
                <p>
                  기업 매출 <span>(단위:백만)</span>
                </p>
              </div>
              <div>
                <Bar
                  data={growthSecondChartData}
                  options={growthWonChartOption}
                  width={324}
                  height={250}
                />
                <p>
                  기업 영업이익 <span>(단위:백만)</span>
                </p>
              </div>
              <div>
                <Bar
                  data={growthThirdChartData}
                  options={growthPercentChartOption}
                  width={324}
                  height={250}
                />
                <p>기업 부채비율</p>
              </div>
              <div>
                <Bar
                  data={growthFourthChartData}
                  options={growthPercentChartOption}
                  width={324}
                  height={230}
                />
                <p>기업 영업이익율</p>
              </div>
            </div>
          </div>
          {/*  <div className="growth_compare_rate">*/}
              {/*    <p>산업 내 기업가치 성장률 비교분석</p>*/}
              {/*    <div>*/}
              {/*      <Bar*/}
              {/*        data={growthCompareChartData}*/}
              {/*        options={growthCompareChartOption}*/}
              {/*        width={766}*/}
              {/*        height={230}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <p>*/}
              {/*        (주)에듀헬스퓨쳐는 최근 4년간 매출에서 15%의 증가율을 보였고,*/}
              {/*        2022년 3% 소폭 감소하였다.*/}
              {/*      </p>*/}
              {/*      <p>*/}
              {/*        (주)에듀헬스퓨처는 최근 4년간 기술력에서 30%의 증가율을 보였다.*/}
              {/*      </p>*/}
              {/*      <p>*/}
              {/*        (주)에듀헬스퓨처는 최근 4년간 영업이익에서 43.5%의 증가율을*/}
              {/*        보였다.*/}
              {/*      </p>*/}
              {/*      <p>*/}
              {/*        (주)에듀헬스퓨처는 최근 4년간 인력 비중이 8%의 감소율을 보였다.*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</article> */}
              {/*<article ref={tabs[8].element}>*/}
              {/*  <h5>관련뉴스</h5>*/}
              {/*  <div className="competitor_news">*/}
              {/*    <div>*/}
              {/*      <p>*/}
              {/*        '웨어러블 헬스케어' 에듀헬스퓨쳐, 2024 CES 간다…“헬스케어 미래*/}
              {/*        제시”*/}
              {/*      </p>*/}
              {/*      <p>*/}
              {/*        디지털 헬스케어 기업 (주)에듀헬스퓨쳐는 미국 라스베가스 에서*/}
              {/*        개최되는 ‘CES2023’에 ‘글로벌 헬스 이노베이터’로 선정돼 공식*/}
              {/*        초청을 받았다고 17일 밝혔다. 전 세계 스타트업 및 성장기업 가운데*/}
              {/*        세상을 선도할 만한 앞선 기술력을 가진 기업으로 인정받은 결과다.*/}
              {/*        이에 따라 이다인 에듀헬스퓨쳐 대표가 웨어러블 기기를 기반으로 한*/}
              {/*        미래 헬스케어 혁신 솔루션을 설명할 예정이다.*/}
              {/*      </p>*/}
              {/*      <p>[매일경제 2023-01-16]</p>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <p>*/}
              {/*        '웨어러블 헬스케어' 에듀헬스퓨쳐, 2024 CES 간다…“헬스케어 미래*/}
              {/*        제시”*/}
              {/*      </p>*/}
              {/*      <p>*/}
              {/*        디지털 헬스케어 기업 (주)에듀헬스퓨쳐는 미국 라스베가스 에서*/}
              {/*        개최되는 ‘CES2023’에 ‘글로벌 헬스 이노베이터’로 선정돼 공식*/}
              {/*        초청을 받았다고 17일 밝혔다. 전 세계 스타트업 및 성장기업 가운데*/}
              {/*        세상을 선도할 만한 앞선 기술력을 가진 기업으로 인정받은 결과다.*/}
              {/*        이에 따라 이다인 에듀헬스퓨쳐 대표가 웨어러블 기기를 기반으로 한*/}
              {/*        미래 헬스케어 혁신 솔루션을 설명할 예정이다.*/}
              {/*      </p>*/}
              {/*      <p>[매일경제 2023-01-16]</p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</article>*/}
            </>
          )}
        </section>

        <button
          className="close"
          onClick={() => {
            closePopup();
          }}
        >
          <i className="icon_exit_gray"></i>
        </button>
      </div>
      {isFianacePopOpen && (
        <CompetitorFinanceStatus
          data={financeStatus}
          togglePop={setIsFianacePopOpen}
        />
      )}
      {isEmpPopOpen && (
        <CompetitorEmployeePop
          type={employeeType}
          salaryData={salaryData}
          monthData={monthData}
          setIsEmpPopOpen={setIsEmpPopOpen}
        />
      )}
      {isAccountPopOpen && (
        <CompetitorAccount
          group={compCltGroup}
          setIsAccountPopOpen={setIsAccountPopOpen}
          name={companyCountData?.company_name || companyInfo.company_name}
          addClt={addClt}
          addCltGroup={addCltGroup}
          flagType={flagType}
          competitorGroups={competitorGroups}
        />
      )}
    </>
  );
};

export default CompetitorDetailPop;
