import React from "react";
import "./css/MyPageContainer.scss";
import MyPageTemplate from "./MyPageTemplate";
import MyPageTab from "./MyPageTab";
import {useLocation} from "react-router-dom";
import qs from "query-string";
import MyPageFindbizAccountPw from "./findbiz/MyPageFindbizAccountPw";
import MyPageFindbizAccountEdit from "./findbiz/MyPageFindbizAccountEdit";
import MypageCandidateCompany from "./candidate/MypageCandidateCompany";

function MyPageContainer() {
	const location = useLocation();
	const query = qs.parse(location.search);
	const depth1 = Number(query.depth1 || 0);
	const depth2 = Number(query.depth2 || 0);
	const tab = {
		title: "마이페이지",
		list: [
			{
				classNameIcon: "icon_user_outline",
				activeClassNameIcon: "icon_user_outline_blue",
				title: "사용자정보",
				child: ["개인정보 수정", "비밀번호 변경"],
				component: [MyPageFindbizAccountEdit, MyPageFindbizAccountPw]
			},
			// {
			// 	classNameIcon: "icon_building_outline",
			// 	activeClassNameIcon: "icon_building_outline_blue",
			// 	title: "기술이전 후보기업",
			// 	child: ["특허별 목록"],
			// 	component: [MypageCandidateCompany]
			// },
			// {
			// 	classNameIcon: "icon_matching",
			// 	activeClassNameIcon: "icon_matching_blue",
			// 	title: "이용 요금",
			// 	child: ["이용 요금 확인하기", "적립금 확인하기"],
			// 	component: [MyPageForm]
			// },
			// {
			// 	classNameIcon: "icon_notification",
			// 	activeClassNameIcon: "icon_notification_blue",
			// 	title: "관심공고 알림 공고 설정",
			// 	child: [],
			// 	component: [MyPageForm]
			// }
		]
	};

	const Component = tab.list[depth1]?.component[depth2];

	return (
		<div id="MyPageContainer">
			<MyPageTab tab={tab} activeDepth1={depth1} activeDepth2={depth2}/>
			<MyPageTemplate title={""} Component={Component}/>
		</div>
	)
}

export default MyPageContainer;