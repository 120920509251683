import React, { useCallback, useEffect, useState } from "react";
import DBox from "../../dueDiligence/Dbox";
import ToggleSlide from "../../dueDiligence/ToggleSlide";
import ContentLayout from "../../dueDiligence/ContentLayout";
import MiniLoading from "../../loading/MiniLoading";
import { Bar, HorizontalBar, Pie } from "react-chartjs-2";
import InfoTable from "../../dueDiligence/InfoTable";
import RowDetailChartPopupTable from "../../dueDiligence/RowDetailChartPopupTable";
import YearButtonList from "../../dueDiligence/YearButtonList";
import { CURRENT_YEAR } from "../../dueDiligence/constants/years_config";
import StaticAPI from "../../../API/statistic";
import PatentAPI from "../../../API/patent";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { country } from "../../dueDiligence/chartData";
import {
  colors,
  techTransfer,
  techEval,
  transferChartData,
  univHeaderData,
} from "./CurrentSituationConst";
import Pagination from "../../common/Pagination";
import CompanyPopup from "./CompanyPopup";
import ExpandableInfoTable from "../../dueDiligence/ExpandableInfoTable";
import TableComponent from "./TableComponent";

const COMPANY_CODE = sessionStorage.getItem("company_code");

const getDummyData = () => {
  return [
    {
      no: "1",
      univ: "부산대학교",
      count: "242",
      inventor: "박교수",
      grade: "ccc",
      ipc: "A30",
      keyword: ["#이차전지", "#촉매"],
    },
  ];
};

const CurrentSituation = () => {
  const memberIdx = sessionStorage.getItem("idx");
  const [inventorStatusYear, setInventorStatusYear] = useState({
    year: CURRENT_YEAR - 10,
    isAccumulated: true,
  });
  const [universityMatrixYear, setUniversityMatrixYear] = useState({
    year: CURRENT_YEAR - 10,
    isAccumulated: true,
  });
  const [registerMaintenanceExpiryByYear, setRegisterMaintenanceExpiryByYear] =
    useState({
      chartData: {},
      tableData: {},
    }); // 연차별 이전특허의 현황

  const [companyStatusYear, setCompanyStatusYear] = useState({
    year: CURRENT_YEAR - 10,
    isAccumulated: true,
  });
  const [univStatusYear, setUnivStatusYear] = useState({
    year: CURRENT_YEAR - 10,
    isAccumulated: true,
  });
  const [patentGradeStatusYear, setPatentGradeStatusYear] = useState({
    year: CURRENT_YEAR - 10,
    isAccumulated: true,
  });
  const [loadingStates, setLoadingStates] = useState({
    isLoadingRightData: false,
  });

  const [rightData, setRightData] = useState({
    chartData: {},
    tableData: {},
  }); // 전체 누적 기술이전 건수
  const [ipcRightData, setIpcRightData] = useState({
    chartData: {},
    tableData: {},
  }); // 국내 기술이전 통계 -  기술분야

  const setIsLoading = (key, value) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const setLoading = (key, value) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const getYearsAgo = (years) => {
    const yearsAgo = CURRENT_YEAR - years;
    return yearsAgo;
  };

  const getTransferInventor = useCallback(
    async (page = 1) => {
      const { year, isAccumulated } = inventorStatusYear;
      const res = await PatentAPI.getTransferInventor({
        apply_at: year,
        count: 10,
        page,
        ...(isAccumulated && { sub_apply_at: CURRENT_YEAR }),
      });
      return res.data || [];
    },
    [inventorStatusYear]
  );

  const getTransferRank = useCallback(
    async (page = 1) => {
      const { year, isAccumulated } = universityMatrixYear;
      const res = await PatentAPI.getTransferRank({
        apply_at: year,
        count: 10,
        page,
        ...(isAccumulated && { sub_apply_at: CURRENT_YEAR }),
      });
      return res.data || [];
    },
    [universityMatrixYear]
  );

  const getTransferCompany = useCallback(
    async (page = 1) => {
      const { year, isAccumulated } = companyStatusYear;
      const res = await PatentAPI.getTransferCompany({
        apply_at: year,
        count: 10,
        page,
        ...(isAccumulated && { sub_apply_at: CURRENT_YEAR }),
        company_code: COMPANY_CODE,
      });
      return res.data || [];
    },
    [companyStatusYear]
  );

  // const getTransferRank = useCallback(
  //   async (page = 1) => {
  //     const { year, isAccumulated } = universityMatrixYear;
  //     const res = await PatentAPI.getTransferRank({
  //       apply_at: year,
  //       count: 10,
  //       page,
  //       ...(isAccumulated && { sub_apply_at: CURRENT_YEAR }),
  //     });
  //     return res.data || [];
  //   },
  //   [universityMatrixYear]
  // );

  const getTransferGrade = useCallback(async () => {
    const { year, isAccumulated } = patentGradeStatusYear;
    const res = await PatentAPI.getTransferGrade({
      apply_at: year,
      count: 10,
      ...(isAccumulated && { sub_apply_at: CURRENT_YEAR }),
    });
    return res.data || [];
  }, [patentGradeStatusYear]);

  const getRightTransfer = () => {
    setIsLoading(true);
    setLoading("isLoadingIpcRightData", true);
    setLoading("isLoadingRightData", true);
    setLoading("isLoadingRightAliveData", true);
    setLoading("isLoadingKoreaData", true);
    StaticAPI.getRightTransfer({
      member: memberIdx,
      apply_at: getYearsAgo(10),
    })
      .then((res) => {
        if (res.status === 200) {
          setIpcRightData({
            chartData:
              {
                labels: res.data?.ipcRightData?.chartData?.labels || [],
                datasets:
                  [
                    {
                      data: res.data?.ipcRightData?.chartData?.datasets[0]
                        ?.data,
                      backgroundColor: colors,
                      hoverBackgroundColor: colors,
                      borderWidth: 0,
                    },
                  ] || [],
              } || {},
            tableData: res.data?.ipcRightData?.tableData || {},
          });
          setRightData({
            chartData:
              {
                labels: res.data?.rightData?.chartData?.rightData?.labels || [],
                datasets:
                  res.data?.rightData?.chartData?.rightData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[7],
                      borderColor: colors[0],
                      barThickness: 30,
                      order: 2,
                      yAxisID: "y-axis-1",
                      ...(dataset.type === "line" && {
                        fill: false,
                        tension: 0,
                        order: 1,
                      }),
                    })
                  ) || [],
              } || {},
            tableData: res.data?.rightData?.tableData || {},
          });
        }
        //console.log("getRightTransfer:", res);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed with error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setLoading("isLoadingIpcRightData", false);
        setLoading("isLoadingRightData", false);
        setLoading("isLoadingRightAliveData", false);
        setLoading("isLoadingKoreaData", false);
      });
  };

  const getAlive = () => {
    setIsLoading(true);
    setLoading("isLoadingAliveGradeData", true);
    setLoading("isLoadingAliveAvgData", true);
    setLoading("isLoadingAliveDurationData", true);
    setLoading("isLoadingAliveIPCMatrixData", true);
    setLoading("isLoadingAliveGradeMatrixData", true);
    setLoading("isLoadingAliveRegisterAliveByYear", true);
    setLoading("isLoadingRegisterGiveUpData", true);
    setLoading("isLoadingRegisterMaintenanceByYear", true);
    setLoading("isLoadingRegisterMaintenanceExpiryByYear", true);
    setLoading("isLoadingRegisterPatentByYear", true);
    StaticAPI.getAlive({
      member: memberIdx,
      apply_at: getYearsAgo(10),
    })
      .then((res) => {
        //console.log("getAlive:", res);
        if (res.status === 200) {
          setRegisterMaintenanceExpiryByYear({
            chartData: {
              labels:
                res.data?.registerMaintenanceExpiryByYear?.chartData?.labels,
              units:
                res.data?.registerMaintenanceExpiryByYear?.chartData?.units,
              datasets:
                res.data?.registerMaintenanceExpiryByYear?.chartData?.datasets?.map(
                  (dataset, index) => ({
                    ...dataset,
                    backgroundColor: colors[index % colors.length],
                    borderColor: colors[index % colors.length],
                    barThickness: 30,
                  })
                ),
            },
            tableData: res.data?.registerMaintenanceExpiryByYear?.tableData,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed with error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setLoading("isLoadingAliveGradeData", false);
        setLoading("isLoadingAliveAvgData", false);
        setLoading("isLoadingAliveDurationData", false);
        setLoading("isLoadingAliveIPCMatrixData", false);
        setLoading("isLoadingAliveGradeMatrixData", false);
        setLoading("isLoadingAliveRegisterAliveByYear", false);
        setLoading("isLoadingRegisterGiveUpData", false);
        setLoading("isLoadingRegisterMaintenanceByYear", false);
        setLoading("isLoadingRegisterMaintenanceExpiryByYear", false);
        setLoading("isLoadingRegisterPatentByYear", false);
      });
  };

  const chartHeight = 250;

  useEffect(() => {
    getRightTransfer();
    getAlive();
  }, []);

  return (
    <div className="info_slide">
      <DBox
        content={
          <ToggleSlide
            title="기술이전분석"
            content={
              <>
                <ContentLayout
                  title="기관별 기술이전 현황"
                  content={
                    <>
                      <div className="categoryTableWrapper">
                        <YearButtonList
                          selectedYear={universityMatrixYear.year}
                          isAccumulated={universityMatrixYear.isAccumulated}
                          onSelectYear={(year, isAccumulated) =>
                            setUniversityMatrixYear({
                              year,
                              isAccumulated,
                            })
                          }
                        />
                        <ExpandableInfoTable
                          yearData={universityMatrixYear}
                          getData={getTransferRank}
                        />
                      </div>
                    </>
                  }
                />
                <ContentLayout
                  title="기술 이전 현황"
                  content={
                    loadingStates.isLoadingRightData ? (
                      <MiniLoading show={loadingStates.isLoadingRightData} />
                    ) : (
                      <>
                        <div className="chart_box">
                          <Bar
                            data={rightData.chartData}
                            options={techTransfer.options}
                            height={chartHeight}
                          />
                        </div>
                        <InfoTable data={rightData.tableData} units="건수" />
                      </>
                    )
                  }
                  isTable={true}
                />
                <ContentLayout
                  title="IPC별 기술이전 현황"
                  content={
                    loadingStates.isLoadingIpcRightData ? (
                      <MiniLoading show={loadingStates.isLoadingIpcRightData} />
                    ) : (
                      <>
                        <div className="chart_box">
                          <Pie
                            data={ipcRightData.chartData}
                            options={country.options}
                            height={chartHeight}
                            plugins={ChartDataLabels}
                          />
                        </div>
                        <InfoTable data={ipcRightData.tableData} units="건수" />
                      </>
                    )
                  }
                  isTable={true}
                />

                <ContentLayout
                  title="발명자별 기술이전 현황"
                  content={
                    loadingStates.isLoadingIpcRightData ? (
                      <MiniLoading show={loadingStates.isLoadingIpcRightData} />
                    ) : (
                      <>
                        <div className="categoryTableWrapper">
                          <YearButtonList
                            selectedYear={inventorStatusYear.year}
                            isAccumulated={inventorStatusYear.isAccumulated}
                            onSelectYear={(year, isAccumulated) =>
                              setInventorStatusYear({
                                year,
                                isAccumulated,
                              })
                            }
                          />
                          <div className="category">
                            <RowDetailChartPopupTable
                              tableKey="inventorStatus"
                              status={inventorStatusYear}
                              getData={getTransferInventor}
                            />
                          </div>
                        </div>
                      </>
                    )
                  }
                />

                <ContentLayout
                  title="기업별 기술이전 현황"
                  content={
                    <>
                      <div className="categoryTableWrapper">
                        <YearButtonList
                          selectedYear={companyStatusYear.year}
                          isAccumulated={companyStatusYear.isAccumulated}
                          onSelectYear={(year, isAccumulated) =>
                            setCompanyStatusYear({ year, isAccumulated })
                          }
                        />
                        <div className="category">
                          <RowDetailChartPopupTable
                            tableKey="companyStatus"
                            status={companyStatusYear}
                            getData={getTransferCompany}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
                <ContentLayout
                  title="특허등급별 기술이전 현황"
                  content={
                    <>
                      <div className="categoryTableWrapper">
                        <YearButtonList
                          selectedYear={patentGradeStatusYear.year}
                          isAccumulated={patentGradeStatusYear.isAccumulated}
                          onSelectYear={(year, isAccumulated) =>
                            setPatentGradeStatusYear({
                              year,
                              isAccumulated,
                            })
                          }
                        />
                        <div className="category">
                          <RowDetailChartPopupTable
                            tableKey="patentGradeStatus"
                            status={patentGradeStatusYear}
                            getData={getTransferGrade}
                          />
                        </div>
                      </div>
                    </>
                  }
                />
                <ContentLayout
                  title="연차별 이전특허의 현황"
                  content={
                    loadingStates.isLoadingRegisterMaintenanceExpiryByYear ? (
                      <MiniLoading
                        show={
                          loadingStates.isLoadingRegisterMaintenanceExpiryByYear
                        }
                      />
                    ) : (
                      <>
                        <div className="chart_box">
                          <Bar
                            data={registerMaintenanceExpiryByYear.chartData}
                            options={techEval.options}
                            height={chartHeight}
                          />
                        </div>
                        <InfoTable
                          data={registerMaintenanceExpiryByYear.tableData}
                          units={
                            registerMaintenanceExpiryByYear.chartData.units
                          }
                        />
                      </>
                    )
                  }
                  isTable={true}
                />
              </>
            }
          />
        }
      />
    </div>
  );
};

export default CurrentSituation;
