import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { createPortal } from "react-dom";
import useClickOutside from "../../hooks/useClickOutside";
import "./css/Modal.scss";

const Modal = forwardRef(
  ({ children, onClose, contentClassName = "" }, ref) => {
    const modalRef = useRef();
    const containerRef = useClickOutside(() => {
      if (!onClose) return; // ✅ onClose가 없으면 바깥 클릭 무시
      document.body.style.overflow = "initial";
      modalRef.current?.close();
      onClose && onClose();
    });
    useImperativeHandle(ref, () => ({
      open() {
        modalRef.current?.showModal();
      },
      close() {
        modalRef.current?.close();
      },
    }));

    return createPortal(
      <dialog id="modal" ref={modalRef}>
        <div ref={containerRef} className={`modal-content ${contentClassName}`}>
          {children}
        </div>
      </dialog>,
      document.getElementById("root")
    );
  }
);

export default Modal;
