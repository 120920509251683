import { useEffect, useState, useMemo } from "react";
import "./css/ExpandableInfoTable.scss";
import Pagination from "../common/Pagination";
import CompanyPopup from "../techTransfer/CurrentSituation/CompanyPopup";

const ExpandableInfoTable = ({ yearData, getData }) => {
  const [rowData, setRowData] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [companyPopup, setCompanyPopup] = useState(false);
  const [page, setPage] = useState(1);
  {
    /* 더보기 기능 */
  }
  // const [showAll, setShowAll] = useState(false);
  {
    /* 더보기 기능 */
  }
  // 최대 표시할 행 수
  // const MAX_ROWS = 11;
  const fetchData = async (page) => {
    try {
      const data = await getData(page);
      setRowData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const widths = [1, 3, 1, 2, 2, 2, 3];

  const handlePageChagne = (newPage) => {
    setPage(newPage);
    fetchData(newPage);
  };

  // 상태 변경 시 데이터 가져오기

  useEffect(() => {
    fetchData();
    {
      /* 더보기 기능 */
    }
    // setShowAll(false); // Reset view state on new data fetch
  }, [getData]);

  {
    /* 더보기 기능 */
  }
  // const displayedRows = useMemo(() => {
  //   return showAll ? rowData.rows : rowData.rows.slice(0, MAX_ROWS);
  // }, [rowData, showAll]);
  {
    /* 더보기 기능 */
  }
  // 버튼 클릭 핸들러
  // const handleToggleShowAll = () => {
  //   setShowAll((prev) => !prev); // 상태를 토글
  // };
  const transformItem = (item, idx) => {
    if (item === 0) {
      return "";
    } else if (idx === 3) {
      return `${item}건`;
    } else if (typeof item === "string" && item.includes(",")) {
      return item
        .split(",")
        .map((word) => `#${word.trim()}`)
        .join(" ");
    }
    return window.$Global.commaify(item);
  };

  return (
    <div className="categoryTableWrapper">
      <div className="tbl_wrap">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              {rowData?.data?.headers?.map((header, idx) => (
                <th key={header} style={{ width: widths[idx] }}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* 우리 대학교 */}
            {rowData?.data?.rows?.length > 0 ? (
              rowData?.data?.rows?.map((row, rowIndex) =>
                Array.isArray(row) ? (
                  <tr
                    onClick={() => {
                      setSelectedData(row);
                      setCompanyPopup(true);
                    }}
                    key={rowIndex}
                    style={{ cursor: "pointer" }}
                  >
                    {row.map((item, idx) =>
                      idx !== 2 ? (
                        <td
                          key={idx}
                          style={{
                            wordBreak: "keep-all",
                            textAlign: "center",
                          }}
                        >
                          {transformItem(item, idx)}
                        </td>
                      ) : (
                        ""
                      )
                    )}
                  </tr>
                ) : (
                  ""
                )
              )
            ) : (
              <tr>
                <td colSpan={rowData?.data?.headers?.length}>
                  데이터가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          curPage={+rowData.page}
          lastNum={Math.ceil(rowData.total_count / 10)}
          onClick={handlePageChagne}
          disableMoveToTop={true}
        />
        {/* 더보기 기능 */}
        {/* {rowData?.rows?.length > MAX_ROWS && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <button
              onClick={handleToggleShowAll}
              style={{
                backgroundColor: "#4e7ce9",
                color: "white",
                width: "100%",
                border: "2px solid white",
                padding: "10px 150px",
                cursor: "pointer",
                borderRadius: "5px",
                textAlign: "center",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              <span>{showAll ? "대학교 정보 접기" : "대학교 정보 더보기"}</span>
              <i
                className={
                  showAll ? "icon_arrow_up_white" : "icon_arrow_down_white"
                }
                style={{ fontSize: "14px" }}
              />
            </button>
          </div>
        )} */}
      </div>
      {companyPopup && (
        <CompanyPopup
          header={rowData?.data?.headers}
          closePopup={() => {
            setCompanyPopup(false);
          }}
          yearData={yearData}
          selectedData={selectedData}
        />
      )}
    </div>
  );
};

export default ExpandableInfoTable;
