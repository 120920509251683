import { useEffect, useState, useCallback } from "react";
import "../css/TechTransfer.scss";
import Pagination from "../../common/Pagination";
import CandidatePop from "./CandidatePop";
import transfer from "../../../API/transfer";
import AnnualFeeReport from "../../annualFee/AnnualFeeReport";
import MiniLoading from "../../loading/MiniLoading";

const Filter = [
  { value: "특허명", key: "title" },
  { value: "출원번호", key: "apply_number" },
  { value: "등록번호", key: "register_number" },
  { value: "발명자", key: "inventor" },
];

const SearchDemand = ({ patentData, searchParams, setSearchParams }) => {
  const [curReportIdx, setCurReportIdx] = useState();
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState("title");
  const [candiPopup, setCandiPopup] = useState(false);
  const [reportIdx, setReportIdx] = useState({
    patent_idx: "",
    family_idx: "",
  });
  const [annualFeeData, setAnnualFeeData] = useState({
    manage_number: "",
    annual_pay_idx: "",
    patent_idx: "",
    trademark_idx: "",
    design_idx: "",
    nation: 0,
  });
  const [reportPopup, setReportPopup] = useState(false);

  const handleSearch = async () => {
    setSearchParams((prev) => ({
      ...prev,
      searchFlag: filter,
      searchKeyword: keyword,
      page: 1, // 검색 시 1페이지로 초기화
    }));
  };

  const formatKeywords = (keywords) => {
    if (!keywords) return ""; // keywords가 null, undefined, 빈 문자열이면 빈 값 반환

    return keywords
      .split(",")
      .map((word) => `#${word.trim()}`)
      .join(" ");
  };

  return (
    <div id="TechTransfer">
      <div className="search_inner">
        <strong style={{ fontSize: "20px" }}>수요기업검색</strong>
        <div style={{ position: "relative" }}>
          <input
            type="text"
            value={keyword}
            onChange={(e) => {
              if (e.target.value.length < 50) setKeyword(e.target.value);
            }}
            placeholder={"검색어를 입력하세요."}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSearch();
            }}
          />
          <select
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            style={{
              fontSize: "16px",
              backgroundColor: "#fff",
              outline: "none",
              cursor: "pointer",
            }}
            defaultValue={filter}
          >
            {Filter.map((element, idx) => (
              <option key={idx} value={element.key}>
                {element.value}
              </option>
            ))}
          </select>
          <button className="icon_search_blue" onClick={handleSearch} />
        </div>
      </div>
      <p>* 기술이전 대상기술을 검색하세요.</p>
      <div className="categoryTableWrapper">
        <table className="researcher-table">
          <thead>
            <tr>
              <th className="grid-header" style={{ width: "70px" }}>
                출원번호
              </th>
              <th className="grid-header" style={{ width: "50px" }}>
                출원일
              </th>
              <th
                className="grid-header"
                style={{
                  width: "120px",
                  textAlign: "left",
                  paddingLeft: "15px",
                }}
              >
                특허명
              </th>
              <th className="grid-header" style={{ width: "50px" }}>
                발명자
              </th>
              <th className="grid-header" style={{ width: "30px" }}>
                특허등급
              </th>
              <th className="grid-header" style={{ width: "50px" }}>
                IPC
              </th>
              <th className="grid-header" style={{ width: "120px" }}>
                키워드
              </th>
              <th className="grid-header" style={{ width: "120px" }}>
                관련제품
              </th>
              <th className="grid-header" style={{ width: "55px" }}>
                특허활용보고서
              </th>
            </tr>
          </thead>
          <tbody>
            {patentData.list && patentData.list.length > 0 ? (
              patentData.list.map((row, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      if (row.report_idx) {
                        setCurReportIdx(row.report_idx);
                        setCandiPopup(true);
                      }
                    }}
                    style={{ cursor: "pointer", lineHeight: "1.2" }}
                  >
                    <td className="grid-cell">{row.apply_number}</td>
                    <td className="grid-cell">{row.apply_at}</td>
                    <td
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                      className="grid-cell"
                    >
                      {row.title}
                    </td>
                    <td className="grid-cell">{row.inventors}</td>
                    <td className="grid-cell">{row.now_grade}</td>
                    <td className="grid-cell">{row.ipc_code}</td>
                    <td className="grid-cell">{formatKeywords(row.keyword)}</td>
                    <td className="grid-cell">{row.allied_products}</td>
                    <td
                      onClick={(e) => e.stopPropagation()}
                      className="grid-cell"
                    >
                      {row.report_idx ? (
                        <button
                          onClick={() => {
                            console.log(row);
                            setReportIdx({
                              patent_idx: row.idx,
                              family_idx: row.family_idx,
                            });
                            setAnnualFeeData((prevData) => ({
                              ...prevData, // 기존 데이터 유지
                              nation: row.nation, // nation 값만 변경
                            }));
                            setReportPopup(true);
                          }}
                        >
                          확인하기
                        </button>
                      ) : (
                        <button>생성하기</button>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={9} style={{ textAlign: "center" }}>
                  검색 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {candiPopup && (
          <CandidatePop
            report_idx={curReportIdx}
            closePopup={() => {
              setCandiPopup(false);
            }}
          />
        )}
        {reportPopup && (
          <div
            onClick={() => {
              setReportPopup(false);
            }}
            id="CandidatePop"
          >
            <AnnualFeeReport
              setIsReportShow={setReportPopup}
              nation={reportIdx.nation}
              patent_idx={reportIdx.patent_idx}
              family_idx={reportIdx.family_idx}
              annualFeeData={annualFeeData}
            />
          </div>
        )}
        {patentData.list?.length > 0 && (
          <Pagination
            curPage={searchParams.page}
            lastNum={Math.ceil(patentData.total_count / 10)}
            onClick={(newPage) => {
              setSearchParams((prev) => ({
                ...prev,
                page: newPage,
              }));
            }}
            disableMoveToTop={true}
          />
        )}
      </div>
    </div>
  );
};

export default SearchDemand;
