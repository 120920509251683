import React, { useState, forwardRef, useCallback } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
  Button,
} from "@mui/material";
import { AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import API from "../../util/api";
import Modal from "./Modal";

const InventorSearchModal = forwardRef(
  (
    {
      onClose,
      onSubmit,
      selectingInventors = [],
      onSelectInventor,
      onRemoveInventor,
      contentClassName = "modal-inventor-search",
    },
    ref
  ) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [isFocused, setIsFocused] = useState(false); // ✅ 포커스 상태 관리
    const [searchInventors, setSearchInventors] = useState([]); // 검색하여 나온 발명자
    /** 발명자 목록 조회 */
    const getInventors = useCallback(
      async ({ page = 1, keyword_type, keyword } = {}) => {
        const params = {
          page,
          ...(keyword_type && keyword && { keyword_type, keyword }), // 검색 조건 추가
        };
        try {
          const res = await API.get("/manager/inventor/getInventor", {
            params,
          });
          const { data } = res.data;
          const newData = data.map((item) => {
            return { ...item };
          });

          setSearchInventors(newData);
        } catch (error) {
          console.error("발명자 목록 조회 실패:", error);
        }
      },
      []
    );

    // 검색 기능
    const handleSearch = () => {
      // 검색 키워드와 필드를 기반으로 API 호출
      if (searchQuery) {
        getInventors({
          keyword_type: "inventor_name",
          keyword: searchQuery,
        });
        setSearchQuery("");
      }
    };

    // 발명자 선택 핸들러
    const handleSelectInventor = (inventor) => {
      if (onSelectInventor) {
        onSelectInventor(inventor);
      }
    };

    // 발명자 제거 핸들러
    const handleRemoveInventor = (inventorIdx) => {
      if (onRemoveInventor) {
        onRemoveInventor(inventorIdx);
      }
    };

    // 저장 핸들러
    const handleSubmit = () => {
      if (onSubmit) {
        onSubmit(selectingInventors);
        setSearchInventors([]);
      }
      if (onClose) {
        onClose();
      }
    };

    // 모달 닫기 핸들러
    const closeSearchModalHandler = () => {
      if (onClose) {
        onClose();
        setSearchQuery("");
        setSearchInventors([]);
      }
    };

    return (
      <Modal
        ref={ref}
        onClose={closeSearchModalHandler}
        contentClassName={contentClassName}
      >
        {/* 모달 헤더 (닫기 버튼 포함) */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              fontSize: "24px",
            }}
          >
            발명자 검색하기
          </h2>
          <IconButton onClick={closeSearchModalHandler} sx={{ color: "gray" }}>
            <AiOutlineClose size={24} />
          </IconButton>
        </div>

        {/* 검색 입력 필드 */}
        <TextField
          label="검색어 입력"
          variant="standard"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyPress={(e) => e.key === "Enter" && handleSearch()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch}>
                  <AiOutlineSearch
                    size={32}
                    color={isFocused ? "#1976d2" : ""}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-input": {
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              fontSize: "16px",
            },
          }}
        />

        {/* 발명자 목록 테이블 */}
        <TableContainer
          component={Paper}
          sx={{
            mt: 2,
            maxHeight: 480,
            overflow: "auto",
            width: "100%",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "15%" }}>이름</TableCell>
                <TableCell sx={{ width: "20%" }}>영문명</TableCell>
                <TableCell sx={{ width: "15%", textAlign: "right" }}>
                  생년월일
                </TableCell>
                <TableCell sx={{ width: "30%" }}>Email</TableCell>
                <TableCell sx={{ width: "30%" }}>주소</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchInventors.map((inventor, index) => {
                const isDisabled = selectingInventors.some(
                  (item) =>
                    item.inventor_idx === inventor.inventor_idx ||
                    item.idx === inventor.idx
                );
                return (
                  <TableRow
                    key={index}
                    hover={!isDisabled}
                    onClick={() =>
                      !isDisabled && handleSelectInventor(inventor)
                    }
                    sx={{
                      cursor: isDisabled ? "not-allowed" : "pointer",
                      backgroundColor: isDisabled ? "#f0f0f0" : "inherit",
                      pointerEvents: isDisabled ? "none" : "auto",
                    }}
                  >
                    <TableCell sx={{ width: "15%" }}>
                      {inventor.inventor_name}
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>
                      {inventor.inventor_name_en}
                    </TableCell>
                    <TableCell sx={{ width: "15%", textAlign: "right" }}>
                      {inventor.birth_date}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "25%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {inventor.email}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "30%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {inventor.inventor_addr}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {/* 선택된 발명자 목록 UI */}
        {selectingInventors.length > 0 && (
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              flexWrap: "wrap",
              gap: "8px",
            }}
          >
            {selectingInventors.map((inventor) => (
              <Chip
                key={inventor.inventor_idx}
                label={inventor.inventor_name}
                variant="outlined"
                onDelete={() => handleRemoveInventor(inventor.inventor_idx)}
              />
            ))}
          </div>
        )}

        {/* 저장 버튼 */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            저장하기
          </Button>
        </div>
      </Modal>
    );
  }
);

export default InventorSearchModal;
