import React from "react";
import InputField from "./InputField";
import styles from "./css/ManageCompanyInfoForm.module.scss";

const ManageCompanyInfoForm = ({ companyInfo, onChange }) => {
  return (
    <form className={styles.formWrapper}>
      <div className={styles.row}>
        <InputField
          label="대표자"
          placeholder="대표자를 입력해주세요"
          value={companyInfo.representative}
          onChange={(e) => onChange("representative", e.target.value)}
        />
        <InputField
          label="사업자 등록번호"
          required
          placeholder="등록번호를 입력해주세요"
          value={companyInfo.licence_number}
          onChange={(e) => onChange("licence_number", e.target.value)}
        />
      </div>
      <div className={styles.row}>
        <InputField
          label="개업일"
          type="date" // 📌 날짜 입력을 위한 date 타입 추가
          style={{ height: "42px" }} // ✅ style 속성으로 전달
          value={companyInfo.establishment_at}
          onChange={(e) => onChange("establishment_at", e.target.value)}
        />
        <InputField
          label="법인 등록번호"
          placeholder="등록번호를 입력해주세요"
          value={companyInfo.corporate_number}
          onChange={(e) => onChange("corporate_number", e.target.value)}
        />
      </div>
      <InputField
        label="사업장 소재지"
        className={styles.fullWidth}
        placeholder="사업장 소재지를 입력해주세요"
        value={companyInfo.location}
        onChange={(e) => onChange("location", e.target.value)}
      />
      <InputField
        label="본점 소재지"
        className={styles.fullWidth}
        placeholder="본 점 소재지를 입력해주세요"
        value={companyInfo.corporate_address}
        onChange={(e) => onChange("corporate_address", e.target.value)}
      />
      <div className={styles.row}>
        <InputField
          label="대표 담당자 이름"
          placeholder="이름을 입력해주세요"
          value={companyInfo.manager_name}
          onChange={(e) => onChange("manager_name", e.target.value)}
        />
        <InputField
          label="대표 담당자 이메일"
          placeholder="이메일을 입력해주세요"
          value={companyInfo.manager_email}
          onChange={(e) => onChange("manager_email", e.target.value)}
        />
        <InputField
          label="대표 담당자 번호"
          placeholder="“-” 구분없이 입력"
          value={companyInfo.manager_phone_number}
          onChange={(e) => onChange("manager_phone_number", e.target.value)}
        />
      </div>
    </form>
  );
};

export default ManageCompanyInfoForm;
