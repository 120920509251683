import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import "./css/InventionDetail.scss";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";
import API from "../../util/api";
import InventionAPI from "../../API/invention";
import PatentAPI from "../../API/patent";
import InputRow from "./row/InputRow";
import FileRow from "./row/FileRow";
import DynamicSelectRow from "./row/DynamicSelectRow";
import PopupPatentSelect from "./PopupPatentSelect";
import MemberContext from "../../store/Member";
import _ from "lodash";
import { nationType } from "../../types/nationType";
import FieldRow from "./row/FieldRow";
import ShareInput from "./row/ShareInputRow";
import TitleAndDeleteRow from "./row/TitleAndDeleteRow";
import ReasonAndInputRow from "./row/ReasonAndInputRow";
import DatePickerCustom from "../datepicker/DatePickerCustom";

import convertTimestamp from "../../util/convertTimestamp";
import Modal from "../common/Modal";

import InventorAddSection from "../common/InventorAddSection";
import InventorSearchModal from "../common/InventorSearchModal";

function InventionDetail({ history }) {
  let inventorTag, applicantorsTag, inventionPublishTag;
  const { invention_idx } = useParams();
  const { company_idx, company } = useContext(MemberContext);
  const isInventor = window.sessionStorage.getItem("rank") == 2;
  const inventionType = invention_idx !== "new"; // true 수정, false 등록
  const INVENTOR = "발명자";
  const APPLICANTORS = "공동출원인";
  const INVENTIONPUBLISH = "발명의 공개";
  const INVENTION_UPLOAD_FILE_VIEW_LIST = "inventionUploadIFileViewList";
  const DESCRIPTION_UPLOAD_FILE_VIEW_LIST = "descriptionUploadIFileViewList";
  const RELATIVE_DATA = "relativeData";

  const isNew = invention_idx == "new";
  const [nation, setNation] = useState(0);
  const [type, setType] = useState(0);
  const [inventorSelect, setInventorSelect] = useState([]);
  const [inventionStateMenu, setInventionStateMenu] = useState(false);
  const [inventionState, setInventionState] = useState(0);
  const [downPopupShow, setDownPopupShow] = useState(false);
  const [upPopupShow, setUpPopupShow] = useState(false);
  const [popupPatentSelectShow, setPopupPatentSelectShow] = useState(false);
  const [editing, setEditing] = useState(false);
  const [fileViewList, setFileViewList] = useState([]); // 다운로드 팝업에 보여줄 저장된 파일 리스트
  const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트
  const [inventionUploadIFileViewList, setInventionUploadFileViewList] =
    useState([]); // 직무 발명 신고서 업로드 파일 리스트
  const [
    descriptionUploadIFileViewList,
    setDescriptionInventionUploadFileViewList,
  ] = useState([]); // 발명 설명서 업로드 파일 리스트
  const [inventionName, setInventionName] = useState(null); // 발명의 명칭 국문
  const [inventionNameEn, setInventionNameEn] = useState(null); // 발명의 명칭 영문
  const [inventionManageNumber, setInventionManageNumber] = useState(""); // 발명의 관리번호
  const [inventors, setInventors] = useState([]); // 발명자 목록
  const [applicantors, setApplicantors] = useState([]); // 공동 출원인
  const [applicantorsHistory, setApplicantorsHistory] = useState([]);

  const [inventionPublish, setInventionPublish] = useState([]); // 발명의 공개
  const [inventionPublishHistory, setInventionPublishHistory] = useState([]);
  const [firstApplyPatent, setFirstApplyPatent] = useState(null); // 선출원 특허 선택

  const [responseData, setResponseData] = useState({});
  const [historyData, setHistoryData] = useState({});
  const [autoCompleteInventionPublish, setAutoCompleteInventionPublish] =
    useState([]);
  const [paymentManagerSelectTag, setPaymentManagerSelectTag] = useState([]);
  const [paymentManageCompany, setPaymentManageCompany] = useState({}); // 연차료 관리 회사
  const [patentManageCompany, setPatentManageCompany] = useState({}); // 특허 관리 회사
  const [fileType, setFileType] = useState("");
  const [timer, setTimer] = useState(0); // 디바운싱 타이머
  const [toggle, setToggle] = useState(false); // 발명 설명서 작성 토글 여부
  const [inventionDetailsState, setInventionDetailsState] = useState({
    gov_funding_program: "",
    gov_project_title: "",
    gov_project_id: "",
    gov_task_code: "",
    gov_total_research_period: "",
    gov_current_research_period: "",
    gov_ministry: "",
    gov_lead_institution: "",
    gov_research_institution: "",
    private_funding_program: "",
    private_project_title: "",
    private_project_id: "",
    private_total_research_period: "",
    private_current_research_period: "",
    private_contractor: "",
    private_research_institution: "",
    invention_detail: "",
  });

  const [selectingInventors, setSelectingInventors] = useState([]);

  const [patentIdx, setPatentIdx] = useState(""); // 발명신고서 제출 후 특허 상세 idx

  // Modal Logic
  const regModalRef = useRef();
  const openRegModalHandler = () => {
    regModalRef.current.open();
  };
  const closeRegModalHandler = () => {
    regModalRef.current.close();
  };
  // Modal Logic
  const searchModalRef = useRef();
  const openSearchModalHandler = () => {
    setSelectingInventors([...inventors]);
    searchModalRef.current.open();
  };
  const closeSearchModalHandler = () => {
    searchModalRef.current.close();
  };

  const handleInventionDetailChange = (field, value) => {
    setInventionDetailsState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // ✅ 클릭한 발명자를 추가 목록에 넣기
  const handleSelectInventor = (inventor) => {
    if (
      !selectingInventors.some(
        (item) => item.inventor_idx === inventor.inventor_idx
      )
    ) {
      setSelectingInventors((prev) => [...prev, inventor]); // ✅ 기존 상태를 안전하게 유지하면서 업데이트
    }
  };
  // ✅ 추가 목록에서 삭제하는 함수
  const handleRemoveInventor = (inventor_idx) => {
    setSelectingInventors(
      selectingInventors.filter((item) => item.inventor_idx !== inventor_idx)
    );
  };
  const handleSubmit = () => {
    const sortedInventors = selectingInventors.map((inventor, index) => ({
      ...inventor,
      share: 0,
      sort: index + 1, // ✅ index + 1 추가
    }));
    setInventors(sortedInventors);
    closeSearchModalHandler();
  };

  useEffect(() => {
    // setInventorSelect(res.data.researcher || []);
    // AccountAPI.getAcoountInventor().then((res) => {
    //   let data = res.data;
    //   setInventorSelect(data.manager.concat(data.general) || []);
    // });
    drawInventionData();

    if (!inventionType) {
      setEditing(true);
    } else {
      setToggle(true);
    }
  }, [isNew]);

  useEffect(() => {
    setPaymentManagerSelectTag(
      [{ auto_keyword: false, idx: company_idx, name: company }].concat(
        applicantors.map((item) => item)
      )
    );
  }, [applicantors]);
  useEffect(() => {
    initData(responseData);
  }, [responseData]);

  const initData = (data) => {
    if (window.$Global.isEmptyObject(responseData)) return;
    if (data.members.length) {
      setInventors(data.members);
      setSelectingInventors(data.members);
    }

    for (let i = 0; i < data.open_list.length; i++) {
      InventionAPI.getInventionOpenFileList(
        invention_idx,
        data.open_list[i].idx
      ).then((res) => {
        data.open_list[i].fileList = res.data;
      });
    }
    setInventionName(data.title);
    setInventionNameEn(data.title_en);
    setApplicantors(data.joint_apply);
    setApplicantorsHistory(data.joint_apply);
    setInventionManageNumber(data.manage_no);
    setInventionPublish(data.open_list);
    setInventionPublishHistory(data.open_list);
    setInventionState(data.state);
    setFirstApplyPatent({
      idx: data.patent_idx,
      apply_number: data.apply_number,
    });
    setNation(data.nation);
    setType(data.type);
    setPaymentManageCompany({
      idx: data.payment_manage_company_idx,
      name: data.payment_manage_company,
    });
    setPatentManageCompany({
      idx: data.manage_company_idx,
      name: data.manage_company,
    });

    // ✅ 추가된 코드: inventionDetailsState 초기화
    setInventionDetailsState({
      gov_funding_program: data.gov_funding_program || "",
      gov_project_title: data.gov_project_title || "",
      gov_project_id: data.gov_project_id || "",
      gov_task_code: data.gov_task_code || "",
      gov_total_research_period: data.gov_total_research_period || "",
      gov_current_research_period: data.gov_current_research_period || "",
      gov_ministry: data.gov_ministry || "",
      gov_lead_institution: data.gov_lead_institution || "",
      gov_research_institution: data.gov_research_institution || "",
      private_funding_program: data.private_funding_program || "",
      private_project_title: data.private_project_title || "",
      private_project_id: data.private_project_id || "",
      private_total_research_period: data.private_total_research_period || "",
      private_current_research_period:
        data.private_current_research_period || "",
      private_contractor: data.private_contractor || "",
      private_research_institution: data.private_research_institution || "",
      invention_detail: data.invention_detail || "",
    });
  };

  const resetData = () => {
    setInventionName("");
    setInventors([{ idx: "", name: "", share: "100" }]);
    setApplicantors([]);
    setInventionPublish([]);
  };

  const getJointCompany = (keyword) => {
    if (keyword.length) {
      InventionAPI.getJointCompany(keyword).then((res) =>
        setAutoCompleteInventionPublish(res.data)
      );
    } else {
      setAutoCompleteInventionPublish([]);
    }
  };

  const drawInventionData = async () => {
    if (invention_idx === "new") return;

    try {
      const res = await InventionAPI.getInventionInfo(invention_idx);
      setResponseData(res.data);
      setHistoryData(_.cloneDeep(res.data));
    } catch (error) {
      console.error("발명 정보를 가져오는 중 오류 발생:", error);
    }
  };

  const saveToast = () => {
    if (inventionType) {
      window.$Global.saveToast();
    } else {
      window.$Global.registerToast();
    }
  };

  const onClickChangeInventionState = (state) => {
    setInventionState(state);
    setInventionStateMenu(false);
  };

  const onClickSave = async () => {
    if (!inventionName) {
      alert("발명의 명칭(국문)을 입력해주세요");
      return;
    }
    const filteredPublish = inventionPublish.filter(
      (item) => item.reason && item.reason.trim() !== ""
    );

    setInventionPublish(filteredPublish);
    // ✅ inventors 데이터를 변환하여 idx, name, name_en 적용 & share가 없으면 0으로 설정
    let formattedInventors;
    // 수정
    if (inventionType) {
      formattedInventors = inventors.map((item) => ({
        idx: item.inventor_idx || item.idx, // inventor_idx → idx 변경
        name: item.inventor_name, // inventor_name → name 변경
        name_en: item.inventor_name_en, // inventor_name_en → name_en 변경
        share: item.share || 0, // share 값이 없으면 0 설정
        sort: item.sort,
      }));
      let payload = {
        title: inventionName,
        title_en: inventionNameEn,
        state: inventionState,
        members: formattedInventors, // ✅ 변환된 inventors 데이터 사용
        joint_apply: applicantors,
        open_list: filteredPublish,
        nation: nation,
        type: type,
        manage_no: inventionManageNumber,
        patent_idx: firstApplyPatent ? firstApplyPatent.idx : "",
        payment_manage_company: paymentManageCompany.name || company,
        payment_manage_company_idx:
          paymentManageCompany.idx || applicantors.length === 0
            ? company_idx
            : "",
        manage_company: patentManageCompany.name || company,
        manage_company_idx:
          patentManageCompany.idx || applicantors.length === 0
            ? company_idx
            : "",
        ...inventionDetailsState,
      };

      await API.patch(`/manager/invention/${invention_idx}`, payload).then(
        () => {
          saveToast();
          setEditing(false);
        }
      );
      // 등록
    } else {
      formattedInventors = inventors.map((item) => ({
        ...item, // 기존 데이터 유지
        idx: item.inventor_idx, // inventor_idx → idx 변경
        name: item.inventor_name, // inventor_name → name 변경
        name_en: item.inventor_name_en, // inventor_name_en → name_en 변경
        share: item.share || 0, // share 값이 없으면 0 설정
      }));
      let formData = new FormData();
      formData.append("title", inventionName);
      formData.append("title_en", inventionNameEn);
      formData.append("state", inventionState);
      if (formattedInventors.filter((item) => item.idx).length) {
        formData.append("members", JSON.stringify(formattedInventors));
      }
      formData.append("joint_apply", JSON.stringify(applicantors));
      if (inventionPublish.filter((item) => item.reason).length) {
        formData.append("open_list", JSON.stringify(inventionPublish));
      }
      // formData.append("open_list", JSON.stringify(inventionPublish));
      formData.append("nation", nation);
      formData.append("type", type);
      formData.append("manage_no", inventionManageNumber);
      formData.append("created_date", convertTimestamp(new Date()));
      formData.append(
        "patent_idx",
        firstApplyPatent ? firstApplyPatent.idx : ""
      );
      formData.append(
        "payment_manage_company",
        paymentManageCompany.name || company
      );
      formData.append(
        "payment_manage_company_idx",
        paymentManageCompany.idx || applicantors.length === 0 ? company_idx : ""
      );
      formData.append("manage_company", patentManageCompany.name || company);
      formData.append(
        "manage_company_idx",
        patentManageCompany.idx || applicantors.length === 0 ? company_idx : ""
      );

      for (let i = 0; i < inventionUploadIFileViewList.length; i++) {
        formData.append("file", inventionUploadIFileViewList[i].file);
      }

      for (let i = 0; i < descriptionUploadIFileViewList.length; i++) {
        formData.append("description", descriptionUploadIFileViewList[i].file);
      }

      for (let i = 0; i < inventionPublish.length; i++) {
        for (let j = 0; j < inventionPublish[i].fileList.length; j++) {
          formData.append("open" + i, inventionPublish[i].fileList[j].file);
        }
      }

      for (const key in inventionDetailsState) {
        formData.append(key, inventionDetailsState[key]);
      }

      await API.post(`/manager/invention`, formData).then((res) => {
        alert("등록되었습니다");
        history.replace(`/invention/${res.data.invention_idx}`);
        setEditing(false);
      });
    }
  };

  const onClickCancel = () => {
    if (invention_idx === "new") {
      resetData();
    }

    setResponseData(historyData);
    setApplicantors(applicantorsHistory);
    setInventionPublish(inventionPublishHistory);
    setEditing(false);
    setInventionStateMenu(false);
  };

  const onClickInventionStateMenu = () => {
    setInventionStateMenu(!inventionStateMenu);
  };

  const onClickDownload = (idx) => {
    if (
      fileType.type === INVENTION_UPLOAD_FILE_VIEW_LIST ||
      fileType.type === DESCRIPTION_UPLOAD_FILE_VIEW_LIST
    ) {
      InventionAPI.downloadInventionFile(invention_idx, idx);
    } else {
      InventionAPI.downloadInventionOpenFile(
        invention_idx,
        fileType.open_idx,
        idx
      );
    }
  };

  const onClickInventionDescription = () => {
    InventionAPI.downloadInventionDescription(invention_idx);
  };

  const onClickDeleteFileInDownload = async (idx) => {
    if (
      fileType.type === INVENTION_UPLOAD_FILE_VIEW_LIST ||
      fileType.type === DESCRIPTION_UPLOAD_FILE_VIEW_LIST
    ) {
      await InventionAPI.deleteInventionFile(invention_idx, idx);
    } else {
      await InventionAPI.deleteInventionOpenFile(
        invention_idx,
        fileType.open_idx,
        idx
      );
    }
    alert("삭제되었습니다");
    drawInventionData();
    let find_index = fileViewList.findIndex((item) => item.idx === idx);
    fileViewList.splice(find_index, 1);
    setFileViewList(fileViewList.concat([]));
  };

  const onClickDeleteFileInUpload = (idx) => {
    let copyArr = uploadFileViewList.slice(0);
    copyArr.splice(idx, 1);
    setUploadFileViewList(copyArr);

    switch (fileType.type) {
      case INVENTION_UPLOAD_FILE_VIEW_LIST:
        setInventionUploadFileViewList(copyArr);
        break;
      case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
        setDescriptionInventionUploadFileViewList(copyArr);
        break;
      case RELATIVE_DATA:
        inventionPublish[fileType.idx].fileList = copyArr;
        setInventionPublish(inventionPublish);
        break;
    }
  };

  const onClickInsertInvention = () => {
    switch (fileType.type) {
      case INVENTION_UPLOAD_FILE_VIEW_LIST:
        setInventionUploadFileViewList(uploadFileViewList);
        break;
      case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
        setDescriptionInventionUploadFileViewList(uploadFileViewList);
        break;
      case RELATIVE_DATA:
        inventionPublish[fileType.idx].fileList = uploadFileViewList;
        setInventionPublish(inventionPublish);
        break;
    }
    setUpPopupShow(false);
  };

  const onClickUpdateInvention = async () => {
    let formData = new FormData();
    for (let i = 0; i < uploadFileViewList.length; i++) {
      formData.append("file", uploadFileViewList[i].file);
    }
    switch (fileType.type) {
      case INVENTION_UPLOAD_FILE_VIEW_LIST:
        formData.append("description", "0");
        await InventionAPI.uploadInventionFile(invention_idx, formData);
        break;
      case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
        formData.append("description", "1");
        await InventionAPI.uploadInventionFile(invention_idx, formData);
        break;
      case RELATIVE_DATA:
        await InventionAPI.uploadInventionOpenFile(
          invention_idx,
          fileType.open_idx,
          formData
        );
        break;
    }
    alert("업로드되었습니다");
    setUploadFileViewList([]);
    setUpPopupShow(false);
    drawInventionData();
  };

  const onClickCategoryAdd = (type) => {
    switch (type) {
      case INVENTOR:
        setInventors(
          inventors.concat({
            idx: "",
            name: "",
            name_en: "",
            birth_date: "",
            email: "",
            share: 0,
            address: "",
          })
        );
        break;
      case APPLICANTORS:
        setApplicantors(
          applicantors.concat([{ name: "", idx: "", auto_keyword: false }])
        );
        break;
      case INVENTIONPUBLISH:
        setInventionPublish(
          inventionPublish.concat({
            idx: "",
            open_at: 0,
            reason: "",
            reason_comment: "",
            created_at: new Date().getTime(),
            fileList: [],
          })
        );
        break;
    }
  };

  const onClickCategoryDelete = (type, idx) => {
    switch (type) {
      case INVENTOR:
        const updatedInventors = inventors
          .filter((_, index) => index !== idx) // ✅ 해당 idx 제외
          .map((inventor, index) => ({
            ...inventor,
            sort: index + 1, // ✅ index + 1 추가
          }));
        setInventors(updatedInventors);
        break;
      case APPLICANTORS:
        setApplicantors(applicantors.filter((value, index) => index !== idx));
        break;
      case INVENTIONPUBLISH:
        setInventionPublish(
          inventionPublish.filter((value, index) => index !== idx)
        );
        break;
    }
  };

  const onChangeCategoryUpdate = async (type, idx, key, value) => {
    switch (type) {
      case INVENTOR:
        inventors[idx][key] = value;
        setInventors(inventors.concat([]));
        break;
      case APPLICANTORS:
        if (key === "idx") {
          applicantors[idx].name = value.name;
          applicantors[idx].idx = value.name;
        } else {
          applicantors[idx][key] = value;
        }
        setApplicantors(applicantors.concat([]));
        if (timer) {
          clearTimeout(timer);
        }
        const newTimer = setTimeout(async () => {
          await getJointCompany(value);
        }, 250);
        setTimer(newTimer);
        break;
      case INVENTIONPUBLISH:
        inventionPublish[idx][key] = value;
        setInventionPublish(inventionPublish.concat([]));
        break;
    }
  };

  const onChangeType = (e) => {
    if (e.target.value === "1") {
      setPopupPatentSelectShow(true);
    }
    setType(e.target.value);
  };

  const onChangeInventorUpdate = (idx, item, value) => {
    const Idx = value.target.value;
    const matchedInventor = inventorSelect.find(
      (inventor) => inventor.idx === Idx
    );
    inventors[idx].idx = matchedInventor.idx;
    inventors[idx].name = matchedInventor.name;
    inventors[idx].name_en = matchedInventor.name_en;
    inventors[idx].birth_date = matchedInventor.birth_date;
    inventors[idx].email = matchedInventor.email;
    inventors[idx].inventor_addr = matchedInventor.inventor_addr;
    setInventors(inventors.concat([]));
  };

  const onClickUploadPopShow = (type, idx, open_idx) => {
    setFileType({ type: type, idx: idx, open_idx: open_idx });

    switch (type) {
      case INVENTION_UPLOAD_FILE_VIEW_LIST:
        setUploadFileViewList(inventionUploadIFileViewList);
        break;
      case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
        setUploadFileViewList(descriptionUploadIFileViewList);
        break;
      case RELATIVE_DATA:
        if (invention_idx === "new") {
          setUploadFileViewList(inventionPublish[idx].fileList);
        } else {
          setUploadFileViewList([]);
        }
        break;
      default:
        break;
    }
    setUpPopupShow(true);
    setDownPopupShow(false);
  };

  const onClickDownloadPopShow = (type, idx, open_idx) => {
    setFileType({ type: type, idx: idx, open_idx: open_idx });

    if (inventionType) {
      switch (type) {
        case INVENTION_UPLOAD_FILE_VIEW_LIST:
          InventionAPI.getInventionFileList(invention_idx, 0).then((res) =>
            setFileViewList(res.data)
          );
          break;
        case DESCRIPTION_UPLOAD_FILE_VIEW_LIST:
          InventionAPI.getInventionFileList(invention_idx, 1).then((res) =>
            setFileViewList(res.data)
          );
          break;
        case RELATIVE_DATA:
          InventionAPI.getInventionOpenFileList(invention_idx, open_idx).then(
            (res) => setFileViewList(res.data)
          );
          break;
        default:
          break;
      }
    }
    setUpPopupShow(false);
    setDownPopupShow(true);
  };

  const onClickPatentListAdd = async () => {
    if (!inventors.length) {
      alert("최소 한 명 이상의 발명자를 추가해야 합니다.");
      setEditing(true);
      return;
    }

    let payload = {};
    let res;

    if (type == 0) {
      payload = {
        invention_idx: invention_idx,
        nation: nation,
        type: type,
        patent_list: [],
        invention_name: inventionName,
        invention_name_en: inventionNameEn,
      };
      // 원출원
      res = await PatentAPI.register(payload);
    } else if (type == 1) {
      payload = {
        nation: nation,
        type: type,
      };
      // 우선권
      res = await PatentAPI.familyRegister(firstApplyPatent.idx, payload);
    }

    if (res?.data?.patent_idx) {
      setPatentIdx(res.data.patent_idx); // ✅ 받은 patent_idx 상태로 저장 (추가)
    }

    openRegModalHandler();
  };

  const autoKeywordShow = (idx, bool) => {
    applicantors.forEach((item) => (item.auto_keyword = false));
    applicantors[idx].auto_keyword = bool;
    setApplicantors(applicantors.concat([]));
  };

  const onClickFirstApplySuccess = (selected_item) => {
    setFirstApplyPatent(selected_item);
    setPopupPatentSelectShow(false);
  };

  // 목록으로 이동하는 핸들러 함수
  const handleBackToList = () => {
    history.push(`/invention/list`);
  };

  inventorTag = inventors.map((item, index) => {
    return (
      <div
        key={item.idx}
        className={`rows inventor-add ${editing ? "right" : ""}`}
      >
        <TitleAndDeleteRow
          title={INVENTOR}
          index={index}
          editing={editing}
          onDelete={onClickCategoryDelete}
        />
        <div className="inventor-row">
          <div className="inventor-section">
            <FieldRow
              defaultTitle={"이름"}
              fieldClass="fieldName"
              title={item.inventor_name}
            />
            <FieldRow
              defaultTitle={"영문명"}
              fieldClass="fieldName"
              title={item.inventor_name_en}
            />
            <FieldRow
              defaultTitle={"생년월일"}
              fieldClass="fieldBirtday"
              title={item.birth_date}
            />

            <ShareInput
              titleClass="share-input"
              defaultValue={item.share}
              content={`${item.share}%`}
              inputType="number"
              editing={editing}
              onChange={(e) =>
                onChangeCategoryUpdate(INVENTOR, index, "share", e.target.value)
              }
            />
          </div>
          <div className="inventor-section">
            <FieldRow
              defaultTitle={"이메일"}
              fieldClass="fieldEmail"
              title={item.email}
            />
            <FieldRow
              defaultTitle={"주소"}
              fieldClass="fieldAddress"
              title={item.inventor_addr}
            />
          </div>
        </div>
        {/* <DynamicSelectRow
          title={`${INVENTOR} ${index + 1}`}
          titleClass="between"
          content={item.name}
          defaultValue={item.idx}
          editing={editing}
          onClickCategoryDelete={() => onClickCategoryDelete(INVENTOR, index)}
          options={inventorSelect}
          onChange={(e) => onChangeInventorUpdate(index, e)}
        />
        <InputRow
          title="지분율"
          titleClass="between"
          content={`${item.share}%`}
          defaultValue={item.share}
          inputType="number"
          editing={editing}
          onChange={(e) =>
            onChangeCategoryUpdate(INVENTOR, index, "share", e.target.value)
          }
        /> */}
      </div>
    );
  });

  applicantorsTag = applicantors.map((item, index) => {
    return (
      <div
        key={item.idx}
        className={`rows ${editing && index !== 0 ? "center" : ""}`}
      >
        <div
          className="row between"
          style={editing && index === 0 ? { marginLeft: "15px" } : null}
        >
          <h2 className="title">
            {APPLICANTORS + " " + (index + 1)}
            {editing && (
              <button
                className="icon_badge_del"
                onClick={() => onClickCategoryDelete(APPLICANTORS, index)}
              />
            )}
          </h2>
          {editing ? (
            <div>
              <input
                type="text"
                value={item.name}
                onChange={(e) =>
                  onChangeCategoryUpdate(
                    APPLICANTORS,
                    index,
                    "name",
                    e.target.value
                  )
                }
                onFocus={() => autoKeywordShow(index, true)}
                onBlur={() => autoKeywordShow(index, false)}
              />
              {applicantors[index].auto_keyword ? (
                <ul>
                  {autoCompleteInventionPublish.map((item) => {
                    return (
                      <li
                        onMouseDown={() =>
                          onChangeCategoryUpdate(APPLICANTORS, index, "idx", {
                            idx: item.idx,
                            name: item.name,
                          })
                        }
                      >
                        <button>{item.name}</button>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          ) : (
            <p>{item.name}</p>
          )}
        </div>
      </div>
    );
  });

  inventionPublishTag = inventionPublish.map((item, index) => {
    return (
      <div
        className={`rows inventor-add ${editing ? "invention-publish" : ""}`}
      >
        <React.Fragment key={item.idx}>
          <TitleAndDeleteRow
            title={INVENTIONPUBLISH}
            index={index}
            editing={editing}
            onDelete={onClickCategoryDelete}
          />
          <div className="inventor-row">
            <div className="inventor-section">
              <ReasonAndInputRow
                selectClass="selectSearch"
                content={item}
                editing={editing}
                onChange={(e, type) =>
                  onChangeCategoryUpdate(
                    INVENTIONPUBLISH,
                    index,
                    type,
                    e.target.value
                  )
                }
                onClick1={() =>
                  invention_idx === "new" || item.idx
                    ? onClickDownloadPopShow(RELATIVE_DATA, index, item.idx)
                    : alert("저장 후 업로드 가능합니다")
                }
                onClick2={() =>
                  invention_idx === "new" || item.idx
                    ? onClickUploadPopShow(RELATIVE_DATA, index, item.idx)
                    : alert("저장 후 업로드 가능합니다")
                }
              />
            </div>
            <div className="inventor-section">
              <DatePickerCustom
                data={item.open_at ? new Date(item.open_at) : ""}
                name={`open_at_${index}`}
                onChangeDatePicker={(date) =>
                  onChangeCategoryUpdate(
                    INVENTIONPUBLISH,
                    index,
                    "open_at",
                    convertTimestamp(date)
                  )
                }
                minDate={new Date(1900, 0, 1)}
                placeholderTxt="날짜 선택"
                disabledd={!editing}
              />
            </div>
          </div>

          {/* <DynamicInputRow
        title="사유"
        titleClass="between"
        content={item.reason}
        editing={editing}
        onChange={(e) =>
          onChangeCategoryUpdate(
            INVENTIONPUBLISH,
            index,
            "reason",
            e.target.value
          )
        }
        onClickCategoryDelete={() =>
          onClickCategoryDelete(INVENTIONPUBLISH, index)
        }
      /> */}
          {/* <FileRow
        title="관련자료"
        titleClass="between"
        onClick1={() =>
          invention_idx === "new" || item.idx
            ? onClickDownloadPopShow(RELATIVE_DATA, index, item.idx)
            : alert("저장 후 업로드 가능합니다")
        }
        onClick2={() =>
          invention_idx === "new" || item.idx
            ? onClickUploadPopShow(RELATIVE_DATA, index, item.idx)
            : alert("저장 후 업로드 가능합니다")
        }
      /> */}
        </React.Fragment>
      </div>
    );
  });

  return (
    <div id="InventionDetail" className={`${isNew ? "isNew" : ""}`}>
      <div className="header">
        <div className="header_box">
          <h2 className="title">
            발명신고서 {invention_idx === "new" ? "등록" : "상세"}페이지
          </h2>
          <div className="info">
            <button
              className="state"
              onClick={editing ? onClickInventionStateMenu : undefined}
            >
              {window.$Global.convertInventionStateToStr(inventionState)}
              {inventionType && editing && <i className="icon_more" />}
            </button>
            {inventionType && inventionStateMenu && (
              <ul>
                <li>
                  <button onClick={() => onClickChangeInventionState(0)}>
                    발명신고서 접수
                  </button>
                  <button onClick={() => onClickChangeInventionState(1)}>
                    사건 등록
                  </button>
                  <button onClick={() => onClickChangeInventionState(2)}>
                    종료
                  </button>
                  <button onClick={() => onClickChangeInventionState(3)}>
                    폐기
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="btns">
          {invention_idx === "new" ? (
            <button className="btn_save" onClick={onClickSave}>
              등록
            </button>
          ) : editing ? (
            <>
              <button className="btn_save" onClick={onClickSave}>
                저장
              </button>
              <button className="btn_cancle" onClick={onClickCancel}>
                취소
              </button>
            </>
          ) : (
            <>
              <button className="btn_back" onClick={handleBackToList}>
                목록 보기
              </button>
              <button
                className="btn_download"
                onClick={onClickInventionDescription}
              >
                서식 다운로드
              </button>
              <button className="btn_edit" onClick={() => setEditing(true)}>
                수정
              </button>
            </>
          )}
          {!editing && company_idx && !isInventor && (
            <button className="btn_add" onClick={onClickPatentListAdd}>
              특허 관리 신건 접수
            </button>
          )}
        </div>
      </div>
      <div className="form">
        <div className="field">
          <div className="rows">
            <InputRow
              title="발명의 명칭 국문"
              titleClass="colspan2 between"
              content={inventionName}
              defaultValue={inventionName}
              inputClass="long"
              editing={editing}
              onChange={(e) => setInventionName(e.target.value)}
            />
            <FileRow
              title="발명 신고서 및 설명서"
              onClick1={() =>
                onClickDownloadPopShow(INVENTION_UPLOAD_FILE_VIEW_LIST)
              }
              onClick2={() =>
                onClickUploadPopShow(INVENTION_UPLOAD_FILE_VIEW_LIST)
              }
              haveFile={responseData.invention_file_cnt}
            />
          </div>
        </div>
        <div className="field">
          <div className="rows">
            <InputRow
              title="발명의 명칭 영문"
              titleClass="colspan2 between"
              content={inventionNameEn}
              defaultValue={inventionNameEn}
              inputClass="long"
              editing={editing}
              onChange={(e) => setInventionNameEn(e.target.value)}
            />
          </div>
          <div className="rows">
            <div className="row between">
              <h2 className="title">국가</h2>
              {editing ? (
                <select
                  onChange={(e) => setNation(e.target.value)}
                  defaultValue={nation || 0}
                >
                  {[...nationType.entries()]
                    .filter(([_, item]) => item) // 빈 값 제거
                    .map(
                      (
                        [originalIdx, item] // 원래 인덱스를 유지하면서 매핑
                      ) => (
                        <option key={originalIdx} value={originalIdx}>
                          {item}
                        </option>
                      )
                    )}
                </select>
              ) : (
                <p>{window.$Global.convertNationCodeToStr(+nation)}</p>
              )}
            </div>
            <div className="row between">
              <h2 className="title">유형</h2>
              {editing ? (
                <select value={type} onChange={onChangeType}>
                  <option value={0}>원출원</option>
                  <option value={1}>우선권 주장</option>
                </select>
              ) : (
                <p>
                  {type === 0 ? "원출원" : type === 1 ? "우선권 주장" : "N/A"}
                </p>
              )}
            </div>
            {type == "1" && (
              <div className="row between">
                <h2 className="title">선출원 출원번호</h2>
                <p>
                  {firstApplyPatent
                    ? firstApplyPatent.apply_number || "N/A"
                    : ""}
                </p>
              </div>
            )}
          </div>
          <div className="rows">
            <InputRow
              title="관리번호"
              titleClass="colspan5 between"
              content={inventionManageNumber}
              defaultValue={inventionManageNumber}
              inputClass="very-short"
              editing={editing}
              onChange={(e) => setInventionManageNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="field" style={{ marginTop: 60 }}>
          {/* 발명자 추가 섹션 */}
          <InventorAddSection
            editing={editing}
            inventorTag={inventorTag}
            openSearchModalHandler={openSearchModalHandler}
          />
        </div>

        <div
          className="field"
          style={{
            marginTop: inventors.length > 0 || !editing ? 60 : 150,
          }}
        >
          {(editing ||
            applicantorsTag.length !== 0 ||
            inventionPublishTag.length !== 0) && (
            <h2 style={{ fontSize: 22, marginBottom: 20 }}>부가입력</h2>
          )}
          <div className="rows" style={{ float: "left", overflow: "hidden" }}>
            {editing && (
              <div className="row between plus">
                <h2 className="title">공동출원인</h2>
                <button
                  className="icon_green_add"
                  onClick={() => onClickCategoryAdd(APPLICANTORS)}
                />
              </div>
            )}
          </div>
          {applicantorsTag}
        </div>
        {applicantorsTag.length !== 0 && (
          <div className="field">
            <div className="rows">
              <DynamicSelectRow
                title="연차료 관리 회사"
                titleClass="between annual"
                editing={editing}
                deleteBtnHide={true}
                defaultValue={paymentManageCompany.idx}
                content={paymentManageCompany.name}
                options={paymentManagerSelectTag}
                onChange={(e) =>
                  setPaymentManageCompany({
                    idx: e.target.value,
                    name: e.target.options[e.target.selectedIndex].text,
                  })
                }
              />
              {/* 특허 관리 회사 */}
              <DynamicSelectRow
                title="특허 관리 회사"
                titleClass="between"
                editing={editing}
                deleteBtnHide={true}
                defaultValue={patentManageCompany.idx}
                content={patentManageCompany.name}
                options={paymentManagerSelectTag}
                onChange={(e) =>
                  setPatentManageCompany({
                    idx: e.target.value,
                    name: e.target.options[e.target.selectedIndex].text,
                  })
                }
              />
            </div>
          </div>
        )}
        <div className="field" style={{ overflow: "hidden", clear: "both" }}>
          <div className="rows" style={{ float: "left", overflow: "hidden" }}>
            {editing && (
              <div className="row between plus">
                <h2 className="title">출원 전 발명 공개</h2>
                <button
                  className="icon_green_add"
                  onClick={() => onClickCategoryAdd(INVENTIONPUBLISH)}
                />
              </div>
            )}
          </div>

          {inventionPublishTag}
        </div>
        <div className="field" style={{ marginTop: 60, marginBottom: 60 }}>
          <button
            className="toggle-button"
            onClick={() => setToggle((prev) => !prev)}
          >
            발명 설명서 작성
            <i
              className={
                toggle ? "icon_more_arrow_up" : "icon_more_arrow_down_black"
              }
            />
          </button>
        </div>
        {toggle && (
          <div className="field" style={{ marginBottom: 60 }}>
            <div className="field-header">
              <h2>국가 연구 과제</h2>
              <a
                href="https://www.ntis.go.kr/ThMain.do"
                target="_blank"
                rel="noopener noreferrer"
                className="ntis-link"
              >
                NTIS 바로가기
              </a>
            </div>
            <div className="rows">
              <InputRow
                title="사업명"
                titleClass="colspan3 between blue-placeholder"
                content={inventionDetailsState.gov_funding_program}
                defaultValue={inventionDetailsState.gov_funding_program}
                inputClass="long"
                placeholder="관련 국가연구과제는 반드시 NTIS 사이트 검색 후 작성"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "gov_funding_program",
                    e.target.value
                  )
                }
              />
              <InputRow
                title="과제명"
                titleClass="colspan3 between"
                content={inventionDetailsState.gov_project_title}
                defaultValue={inventionDetailsState.gov_project_title}
                inputClass="long"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "gov_project_title",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="rows">
              <InputRow
                title="과제고유번호"
                titleClass="colspan3 between blue-placeholder"
                content={inventionDetailsState.gov_project_id}
                defaultValue={inventionDetailsState.gov_project_id}
                inputClass="long"
                placeholder="숫자 10 자리 (NTIS 고유국가과제 번호)"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange("gov_project_id", e.target.value)
                }
              />
              <InputRow
                title="기관세부과제번호"
                titleClass="colspan3 between"
                content={inventionDetailsState.gov_task_code}
                defaultValue={inventionDetailsState.gov_task_code}
                inputClass="long"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange("gov_task_code", e.target.value)
                }
              />
            </div>
            <div className="rows">
              <InputRow
                title="총 연구기간"
                titleClass="colspan4 between"
                content={inventionDetailsState.gov_total_research_period}
                defaultValue={inventionDetailsState.gov_total_research_period}
                inputClass="middle"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "gov_total_research_period",
                    e.target.value
                  )
                }
              />
              <InputRow
                title="당해 연구기간"
                titleClass="colspan4 between"
                content={inventionDetailsState.gov_current_research_period}
                defaultValue={inventionDetailsState.gov_current_research_period}
                inputClass="middle"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "gov_current_research_period",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="rows">
              <InputRow
                title="부처명"
                titleClass="colspan4 between"
                content={inventionDetailsState.gov_ministry}
                defaultValue={inventionDetailsState.gov_ministry}
                inputClass="middle"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange("gov_ministry", e.target.value)
                }
              />
              <InputRow
                title="주관기관"
                titleClass="colspan4 between"
                content={inventionDetailsState.gov_lead_institution}
                defaultValue={inventionDetailsState.gov_lead_institution}
                inputClass="middle"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "gov_lead_institution",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="rows">
              <InputRow
                title="과제수행기관"
                titleClass="colspan3 between"
                content={inventionDetailsState.gov_research_institution}
                defaultValue={inventionDetailsState.gov_research_institution}
                inputClass="long"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "gov_research_institution",
                    e.target.value
                  )
                }
              />
            </div>

            <div className="field-header">
              <h2>민간 용역 과제</h2>
            </div>
            <div className="rows">
              <InputRow
                title="사업명"
                titleClass="colspan3 between"
                content={inventionDetailsState.private_funding_program}
                defaultValue={inventionDetailsState.private_funding_program}
                inputClass="long"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "private_funding_program",
                    e.target.value
                  )
                }
              />
              <InputRow
                title="과제명"
                titleClass="colspan3 between"
                content={inventionDetailsState.private_project_title}
                defaultValue={inventionDetailsState.private_project_title}
                inputClass="long"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "private_project_title",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="rows">
              <InputRow
                title="과제번호"
                titleClass="colspan3 between"
                content={inventionDetailsState.private_project_id}
                defaultValue={inventionDetailsState.private_project_id}
                inputClass="long"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "private_project_id",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="rows">
              <InputRow
                title="총 연구기간"
                titleClass="colspan4 between"
                content={inventionDetailsState.private_total_research_period}
                defaultValue={
                  inventionDetailsState.private_total_research_period
                }
                inputClass="middle"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "private_total_research_period",
                    e.target.value
                  )
                }
              />
              <InputRow
                title="당해 연구기간"
                titleClass="colspan4 between"
                content={inventionDetailsState.private_current_research_period}
                defaultValue={
                  inventionDetailsState.private_current_research_period
                }
                inputClass="middle"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "private_current_research_period",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="rows">
              <InputRow
                title="과제수행기관"
                titleClass="colspan3 between"
                content={inventionDetailsState.private_research_institution}
                defaultValue={
                  inventionDetailsState.private_research_institution
                }
                inputClass="long"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "private_research_institution",
                    e.target.value
                  )
                }
              />
              <InputRow
                title="용역업체"
                titleClass="colspan3 between"
                content={inventionDetailsState.private_contractor}
                defaultValue={inventionDetailsState.private_contractor}
                inputClass="long"
                editing={editing}
                onChange={(e) =>
                  handleInventionDetailChange(
                    "private_contractor",
                    e.target.value
                  )
                }
              />
            </div>

            <div className="field-header">
              <h2>발명설명서</h2>
            </div>
            <textarea
              rows="15"
              cols="50"
              value={
                inventionDetailsState.invention_detail ||
                `[주요기술내용]
  1. 기술분야 및 배경기술
  2. 발명의 내용
    - 해결하고자 하는 과제(발명의 목적)
    - 효과
  3. 발명의 실시를 위한 구체적인 내용
  4. 특허권으로 보호 받고자 하는 부분
  5. 도면 및 도면의 설명(별지에 손으로 직접 그린 것도 가능)
    - 기타 미비한 자료는 추후 특허 사무소에서 추가 요청
  6. 저작권(소프트웨어 프로그램)의 경우 제출 서식 4-5 프로그램 등록 신청 명세서를 자세하게 작성할 것`
              }
              onChange={(e) =>
                handleInventionDetailChange("invention_detail", e.target.value)
              }
              disabled={!editing}
            />
          </div>
        )}
      </div>
      {/* 특허 관리 신건 접수 모달 */}
      <Modal
        ref={regModalRef}
        onClose={closeRegModalHandler}
        contentClassName="modal-patent-manage"
      >
        <p>특허 관리 신건이 생성되었습니다.</p>
        <div>
          <button
            onClick={() => {
              patentIdx
                ? history.replace(`/patent/detail/${patentIdx}`)
                : history.replace("/patent/list");
            }}
          >
            관리 페이지 이동
          </button>
          <button
            onClick={() => {
              history.replace("/invention/list");
            }}
          >
            확인
          </button>
        </div>
      </Modal>
      {/* 발명자 추가 모달 */}
      <InventorSearchModal
        ref={searchModalRef}
        onClose={closeSearchModalHandler}
        onSubmit={handleSubmit}
        selectingInventors={selectingInventors}
        onSelectInventor={handleSelectInventor}
        onRemoveInventor={handleRemoveInventor}
      />
      <DetailPopupDownload
        show={downPopupShow}
        setShow={setDownPopupShow}
        fileViewList={fileViewList}
        setFileList={setFileViewList}
        onClickDownload={onClickDownload}
        onClickDelete={onClickDeleteFileInDownload}
      />
      <DetailPopupUpload
        show={upPopupShow}
        setShow={setUpPopupShow}
        uploadFileViewList={uploadFileViewList}
        setFileList={setUploadFileViewList}
        onClickUpload={
          invention_idx === "new"
            ? onClickInsertInvention
            : onClickUpdateInvention
        }
        onClickDelete={onClickDeleteFileInUpload}
      />
      {popupPatentSelectShow && (
        <PopupPatentSelect
          onClickSuccess={onClickFirstApplySuccess}
          onClickClose={() => {
            if (type === "1" && firstApplyPatent === null) {
              setType(0);
            }
            setPopupPatentSelectShow(false);
          }}
        />
      )}
    </div>
  );
}

export default InventionDetail;
