import "./css/ContentLayout.scss";

const ContentLayout = ({
  title,
  btnContent = null,
  content,
  isTable,
  minH,
  borderBtm = true,
}) => {
  return (
    <div
      className={`ContentLayout ${borderBtm ? "" : "border_none"}`}
      style={minH ? { minHeight: minH } : { minHeight: "auto" }}
    >
      {title || btnContent ? (
        <div className="header" style={{ display: "flex", gap: "10px" }}>
          <strong className="layout_tit">{title}</strong>
          {btnContent && <div className="title_button">{btnContent}</div>}
        </div>
      ): null}
      <div className={`layount_content ${isTable ? "table_layout" : ""}`}>
        {content}
      </div>
    </div>
  );
};

export default ContentLayout;
