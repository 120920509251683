import React, { useEffect, useState } from "react";
import DatePickerCustom from "../../datepicker/DatePickerCustom";
import "../css/DocTradeSelectDate.scss";

function DocTradeSelectDate({ getList, onChangeDate, setCurPage }) {
  useEffect(() => {
    onClickSelect();
  }, []);

  useEffect(() => {
    if (startDate > endDate) {
      setStartDate(endDate);
    }
  }, [startDate, endDate]);

  const onChangeSelectMonth = (num) => {
    let params = {
      start_at: today.setMonth(today.getMonth() - num),
    };
    getList(params);
  };

  const onClickSelect = () => {
    setCurPage(1);
    onChangeDate(startDate, endDate);
    getList({
      page: 1,
      start_at: startDate,
      end_at: endDate,
    });
  };

  const convertStart = (date) => {
    let selectDate = new Date(date);
    return (
      selectDate.getFullYear() +
      "-" +
      (selectDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      selectDate.getDate().toString().padStart(2, "0") +
      " 00:00:00"
    );
  };

  const convertEnd = (date) => {
    let selectDate = new Date(date);
    return (
      selectDate.getFullYear() +
      "-" +
      (selectDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      selectDate.getDate().toString().padStart(2, "0") +
      " 23:59:59"
    );
  };

  const today = new Date();
  const [startDate, setStartDate] = useState(
    new Date(convertStart(today.getTime()))
  );
  const [endDate, setEndDate] = useState(new Date(convertEnd(today.getTime())));

  return (
    <div id="DocTradeSelectDate">
      <div className="content_table_top">
        <div className="content_table_top_title">
          <h3 className="content_table_top_text">조회기간</h3>
          <div className="content_table_top_radio">
            <input
              type="radio"
              name="inquiryPeriod"
              id="1month"
              className="hidden content_table_top_radio_input"
            />
            <label
              htmlFor="1month"
              className="content_table_top_radio_label"
              onClick={() => onChangeSelectMonth(1)}
            >
              1개월
            </label>
          </div>
          <div className="content_table_top_radio">
            <input
              type="radio"
              name="inquiryPeriod"
              id="3month"
              className="hidden content_table_top_radio_input"
            />
            <label
              htmlFor="3month"
              className="content_table_top_radio_label"
              onClick={() => onChangeSelectMonth(3)}
            >
              3개월
            </label>
          </div>
          <div className="content_table_top_radio">
            <input
              type="radio"
              name="inquiryPeriod"
              id="6month"
              className="hidden content_table_top_radio_input"
            />
            <label
              htmlFor="6month"
              className="content_table_top_radio_label"
              onClick={() => onChangeSelectMonth(6)}
            >
              6개월
            </label>
          </div>
        </div>
        <div className="content_table_top_date clearfix">
          <div className="content_table_top_date_start">
            <div className="content_table_top_date_ico">
              <DatePickerCustom
                data={startDate}
                onChangeDatePicker={(date) =>
                  setStartDate(new Date(convertStart(date)).getTime())
                }
                maxDate={endDate}
              />
            </div>
          </div>
          <span className="style">~</span>
          <div className="content_table_top_date_end">
            <div className="content_table_top_date_ico">
              <DatePickerCustom
                data={endDate}
                onChangeDatePicker={(date) =>
                  setEndDate(new Date(convertEnd(date)).getTime())
                }
                maxDate={today}
              />
            </div>
          </div>
          <button
            type="submit"
            className="content_table_top_date_lookup"
            onClick={onClickSelect}
          >
            조회하기
          </button>
        </div>
      </div>
    </div>
  );
}

export default DocTradeSelectDate;
