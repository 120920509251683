import React, { useEffect, useState } from "react";
import DBox from "../../dueDiligence/Dbox";
import ToggleSlide from "../../dueDiligence/ToggleSlide";
import ContentLayout from "../../dueDiligence/ContentLayout";
import SearchDemand from "./SearchDemand";
import transfer from "../../../API/transfer";
import MiniLoading from "../../loading/MiniLoading";

const DemandCompany = () => {
  const [patentData, setPatentData] = useState({});
  const [searchParams, setSearchParams] = useState({
    searchFlag: "", // 필터 조건
    searchKeyword: "",
    page: 1,
  });
  const [loadingStates, setLoadingStates] = useState({
    isLoadingPatentData: false,
  });
  const setIsLoading = (key, value) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fetchPatentData = async (params) => {
    setIsLoading("isLoadingPatentData", true);
    const data = await transfer.getTransferPatentList(params);
    setPatentData(data.data || []);
    setIsLoading("isLoadingPatentData", false);
  };
  useEffect(() => {
    fetchPatentData(searchParams);
  }, [searchParams]);
  return (
    <div className="info_slide">
      <DBox
        content={
          <ToggleSlide
            title={"수요기업찾기"}
            content={
              <>
                <ContentLayout
                  content={
                    loadingStates.isLoadingPatentData ? (
                      <div style={{height: "750px"}}>
                        <MiniLoading show={loadingStates.isLoadingPatentData}/>
                      </div>
                    ) : (
                      <SearchDemand
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        patentData={patentData}
                      />
                    )
                  }
                />
              </>
            }
          />
        }
      />
    </div>
  );
};

export default DemandCompany;
