import React, { useContext, useEffect, useState, useMemo } from "react";
import "./css/Home.scss";
import HomeAgent from "./HomeAgent";
import HomeInventor from "./HomeInventor";
import HomeCompany from "./HomeCompany";
import HomeTaskManage from "./HomeTaskManage";
import MemberContext from "../../store/Member";
import "chartjs-plugin-datalabels";
import StatisticAPI from "../../API/statistic/index";
import HomeInvestor from "./HomeInvestor";
import CommonAPI from "../../API/common";
import CommonContext from "../../store/Common";
import FindBizChange from "../common/FindBizChange";
import BannerOneClick from "../common/BannerOneClick";
import IntroFindBiz from "../intro/findbiz/IntroFindBiz";
import { useLocation } from "react-router-dom";
import RenewalDashBoard from "../intro/findbiz/RenewalDashBoard";

function Home({ history }) {
  const location = useLocation();
  const { isMobile } = useContext(CommonContext);
  const { auth, manage_company_idx } = useContext(MemberContext);
  const cur_year = new Date().getFullYear();
  const prev_four_year = cur_year - 4;

  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);
  const isInvestor = window.$Global.checkAuth("투자자", auth);
  const [statistic, setStatistic] = useState(() => ({
    annual_payment: Array(8).fill().map((_, i) => ({
      year: new Date().getFullYear() - 3 + i,
      cost: 0,
      td_cost: 0
    })),
    graph: {
      apply: Object.fromEntries(
        Array(8).fill().map((_, i) => [
          `${new Date().getFullYear() - 3 + i}`, 
          { cnt: 0 }
        ])
      ),
      register: Object.fromEntries(
        Array(8).fill().map((_, i) => [
          `${new Date().getFullYear() - 3 + i}`, 
          { cnt: 0 }
        ])
      )
    },
    patent_statistic: {
      all: 0,
      registered: 0,
      applied: 0,
      terminated: 0
    },
    trademark_statistic: {
      all: 0,
      registered: 0,
      applied: 0,
      terminated: 0
    },
    design_statistic: {
      all: 0,
      registered: 0,
      applied: 0,
      terminated: 0
    },
    cost_last_month: {
      patent_cost: 0,
      trademark_cost: 0,
      total_cost: 0,
      compare_cost: 0
    }
  }));

  const [patentData, setPatentData] = useState({});
  const [trademarkData, setTrademarkData] = useState({});
  const [designData, setDesignData] = useState({});
  const [annualFeeData, setAnnualFeeData] = useState({});
  const [patent_count_data, setPatentCountData] = useState({});
  const isIpnowType = window.$Global.isIpnow(location);
  const findbizType = !window.$Global.isIpnow(location);

  const [loadingStates, setLoadingStates] = useState({
    isLoadingPatent: false,
    isLoadingTrademark: false,
    isLoadingAnnualCount: false,
    isLoadingExpectationYearFee: false,
  });

  const safeStatistic = useMemo(() => {
    return {
      annual_payment: statistic?.annual_payment || [],
      graph: statistic?.graph || { apply: {}, register: {} },
      patent_statistic: statistic?.patent_statistic || { all: 0, registered: 0, applied: 0, terminated: 0 },
      trademark_statistic: statistic?.trademark_statistic || { all: 0, registered: 0, applied: 0, terminated: 0 },
      design_statistic: statistic?.design_statistic || { all: 0, registered: 0, applied: 0, terminated: 0 },
      cost_last_month: statistic?.cost_last_month || { patent_cost: 0, trademark_cost: 0, total_cost: 0, compare_cost: 0 }
    };
  }, [statistic]);

  useEffect(async () => {
    setLoadingStates({
      isLoadingPatent: true,
      isLoadingTrademark: true,
      isLoadingAnnualCount: true,
      isLoadingExpectationYearFee: true,
    });

    if (isCompany) {
      try {
        const optionData = await CommonAPI.getOption(0)
          .then((res) => res.data[0]?.option || {})
          .catch(error => {
            console.error('Common API error:', error);
            return {};
          });

        const payload = {
          ...optionData,
          start_year: cur_year - 4,
          end_year: cur_year + 3,
          joint: optionData.joint || 0,
          mem_cnt: optionData.mem_cnt || 0,
          business: optionData.business || 0,
          company_idx: optionData.company_idx || manage_company_idx,
          type: optionData.type || 'all'
        };

        StatisticAPI.getPatentMainStatistic(payload)
          .then(res => {
            setStatistic(prev => ({
              ...prev,
              patent_statistic: res.data?.patent_statistic || { registered: 0, applied: 0, terminated: 0, all: 0 }
            }));
          })
          .finally(() => {
            setLoadingStates(prev => ({ ...prev, isLoadingPatent: false }));
          });

        StatisticAPI.getTrademarkMainStatistic(payload)
          .then(res => {
            setStatistic(prev => ({
              ...prev,
              trademark_statistic: res.data?.trademark_statistic || { registered: 0, applied: 0, terminated: 0, all: 0 }
            }));
          })
          .finally(() => {
            setLoadingStates(prev => ({ ...prev, isLoadingTrademark: false }));
          });

        StatisticAPI.getAnnualPayMainStatistic(payload)
          .then(res => {
            setStatistic(prev => ({
              ...prev,
              annual_payment: res.data?.annual_payment || []
            }));
          })
          .finally(() => {
            setLoadingStates(prev => ({ ...prev, isLoadingAnnualCount: false }));
          });

        StatisticAPI.getDesignMainStatistic(payload)
          .then(res => {
            setStatistic(prev => ({
              ...prev,
              design_statistic: res.data?.design_statistic || { registered: 0, applied: 0, terminated: 0, all: 0 }
            }));
          });

        StatisticAPI.getGraphMainStatistic(payload)
          .then(res => {
            const graphData = res.data?.graph || { apply: {}, register: {} };
            const apply = graphData.apply || {};
            const register = graphData.register || {};
            
            const apply_arr = Object.keys(apply).map(key => ({
              year: key,
              cnt: apply[key]?.cnt || 0
            }));
            
            const register_arr = Object.keys(register).map(key => ({
              year: key,
              cnt: register[key]?.cnt || 0
            }));

            setStatistic(prev => ({
              ...prev,
              graph: graphData,
              annual_apply: apply_arr,
              annual_register: register_arr
            }));
          })
          .finally(() => {
            setLoadingStates(prev => ({ ...prev, isLoadingExpectationYearFee: false }));
          });

        StatisticAPI.getStatistic(payload)
          .then(res => {
            setStatistic(prev => ({
              ...prev,
              cost_last_month: res.data?.cost_last_month || {
                total_cost: 0,
                patent_cost: 0,
                trademark_cost: 0,
                compare_cost: 0
              }
            }));
          });

      } catch (error) {
        console.error('Failed to fetch statistics:', error);
        // 에러 처리...
      }
    } else if (isAgent) {
      setStatistic(prev => ({
        ...prev,
        patent_statistic: { registered: 0, applied: 0, terminated: 0, all: 0 },
        trademark_statistic: { registered: 0, applied: 0, terminated: 0, all: 0 },
        design_statistic: { registered: 0, applied: 0, terminated: 0, all: 0 }
      }));
    }
  }, []);

  
  useEffect(() => {
    if (window.$Global.isEmptyObject(statistic)) {
      return;
    }

    // 데이터 처리
    const patentChartData = getCommonData(statistic.patent_statistic);
    const trademarkChartData = getCommonData(statistic.trademark_statistic);
    const designChartData = getCommonData(statistic.design_statistic);

    // 상태 업데이트
    setPatentData(patentChartData);
    setTrademarkData(trademarkChartData);
    setDesignData(designChartData);

    if (isCompany) {
      let annual_fee_year_arr = window.$Global.calcYear(cur_year, 3);

      // 연차료 데이터 설정
      const annualPaymentData = Array.isArray(statistic.annual_payment) 
        ? statistic.annual_payment.slice(0, 4).map((item) => {
            return item.cost + (item.td_cost || 0);
          })
        : new Array(4).fill(0);

      setAnnualFeeData({
        labels: annual_fee_year_arr.convert_arr,
        datasets: [{
          data: annualPaymentData,
          backgroundColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 206, 86)",
            "rgb(75, 192, 192)",
          ],
          borderColor: [
            "rgb(255, 99, 132)",
            "rgb(54, 162, 235)",
            "rgb(255, 206, 86)",
            "rgb(75, 192, 192)",
          ],
          borderWidth: 1,
        }],
      });

      // 연도별 데이터 처리
      const annual_year_arr = window.$Global.calcYear(cur_year - 4, 4);
      const years = annual_year_arr.year_arr;
      const yearLabels = annual_year_arr.convert_arr;
      const defaultAnnualData = Array(5).fill({ cnt: 0 });
      
      // 출원/등록 데이터 처리
      const apply_arr = statistic.annual_apply || defaultAnnualData;
      const register_arr = statistic.annual_register || defaultAnnualData;

      // 특허 갯수 데이터 설정
      setPatentCountData({
        labels: yearLabels,
        datasets: [
          {
            label: "출원갯수",
            data: years.map(year => {
              const yearData = apply_arr.find(item => String(item.year) === String(year));
              return yearData ? yearData.cnt : 0;
            }),
            backgroundColor: "rgb(255, 172, 18)",
            borderColor: "rgba(255, 172, 18, 0.2)",
            borderWidth: 4,
            lineTension: 0,
            fill: false,
          },
          {
            label: "등록갯수",
            data: years.map(year => {
              const yearData = register_arr.find(item => String(item.year) === String(year));
              return yearData ? yearData.cnt : 0;
            }),
            backgroundColor: "rgb(0, 149, 174)",
            borderColor: "rgba(0, 149, 174, 0.2)",
            borderWidth: 4,
            lineTension: 0,
            fill: false,
          },
        ],
      });
    }
  }, [statistic, cur_year]);

  
  const getCommonData = (obj) => {
    // 중첩된 구조 처리
    const data = obj?.patent_statistic || obj?.trademark_statistic || obj?.design_statistic || obj;
    
    if (!data || typeof data !== 'object') {
        console.warn('Invalid data object provided to getCommonData:', data);
        return {
            labels: ["등록", "출원", "포기"],
            datasets: [{
                data: [0, 0, 0],
                backgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
                hoverBackgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
                borderWidth: 0,
            }]
        };
    }

    // 데이터 값 추출
    const registered = Number(data.registered || 0);
    const applied = Number(data.applied || 0);
    const terminated = Number(data.terminated || 0);

    return {
        labels: ["등록", "출원", "포기"],
        datasets: [{
            data: [registered, applied, terminated],
            backgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
            hoverBackgroundColor: ["#ffffff", "#ffd4a6", "#ffa94d"],
            borderWidth: 0,
        }]
    };
  };

  const patent_trademark_options = {
    legend: {
      display: false,
    },
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const annualFee_options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
        },
        formatter: function (value) {
          return window.$Global.commaify(value);
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
            max: window.$Global.calcGraphMaxNumber(
              statistic.annual_payment &&
                statistic.annual_payment.map((item) => item.cost + item.td_cost)
            ),
            callback: function (value) {
              return window.$Global.commaify(value);
            },
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          return window.$Global.commaify(tooltipItem.value) + "원";
        },
      },
    },
  };

  const patent_count_max =
    window.$Global.calcGraphMaxNumber(
      statistic.annual_apply &&
        statistic.annual_apply
          .map((item) => item.cnt)
          .concat(statistic.annual_register.map((item) => item.cnt))
    ) * 1.2;

  const patent_count_options = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      angleLines: {
        lineWidth: 0,
      },
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
            max: patent_count_max,
            stepSize: patent_count_max / 4,
            maxTicksLimit: 5,
          },
          gridLines: {
            display: true,
            borderDash: [3],
            color: "#ffffff",
            zeroLineColor: "#ffffff",
            drawBorder: true,
            drawOnChartArea: true,
            drawTicks: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
        },
      },
    },
  };

  const hasRegistered = (obj) => {

    return obj?.registered + obj?.applied + obj?.terminated > 0;
  };

  if (
    !window.$Global.checkAuth("투자자", auth) &&
    (window.$Global.isEmptyObject(statistic) ||
      window.$Global.isEmptyObject(patentData) ||
      window.$Global.isEmptyObject(trademarkData))
  ) {
    return null;
  }

  const findbizStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 1200,
    margin: "20px auto",
  };

  return (
    <>
      {/*{isIpnowType && (*/}
      {/*  <div className="pc">*/}
      {/*    <div className="section" style={findbizStyle}>*/}
      {/*      <BannerOneClick history={history} />*/}
      {/*      <FindBizChange history={history} />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
      <div id="Home" className={isCompany && findbizType ? "findbiz" : ""}>
        {isIpnowType ? (
          window.$Global.checkAuth("특허회사_관리자", auth) ? (
            <HomeCompany
              data={patentData}
              data2={trademarkData}
              data3={annualFeeData}
              data4={patent_count_data}
              designData={designData}
              options={patent_trademark_options}
              options2={annualFee_options}
              options3={patent_count_options}
              patent_all_cnt={statistic.patent_statistic?.all}
              trademark_all_cnt={statistic.trademark_statistic?.all}
              design_all_cnt={statistic.design_statistic?.all}
              isEmptyPatentData={!hasRegistered(statistic.patent_statistic)}
              isEmptyTrademarkData={
                !hasRegistered(statistic.trademark_statistic)
              }
              isEmptyDesignData={!hasRegistered(statistic.design_statistic)}
              history={history}
              statistic={statistic}
              loadingStates={loadingStates}
            />
          ) : window.$Global.checkAuth("특허회사_발명자만", auth) ? (
            <HomeInventor
              data={patentData}
              data2={trademarkData}
              designData={designData}
              options={patent_trademark_options}
              patent_all_cnt={statistic.patent_statistic?.all}
              trademark_all_cnt={statistic.trademark_statistic?.all}
              design_all_cnt={statistic.design_statistic?.all}
              isEmptyPatentData={!hasRegistered(statistic.patent_statistic)}
              isEmptyTrademarkData={
                !hasRegistered(statistic.trademark_statistic)
              }
              isEmptyDesignData={!hasRegistered(statistic.design_statistic)}
            />
          ) : isAgent ? (
            <HomeAgent
              data={patentData}
              data2={trademarkData}
              designData={designData}
              options={patent_trademark_options}
              patent_all_cnt={statistic.patent_statistic?.all}
              trademark_all_cnt={statistic.trademark_statistic.all}
              design_all_cnt={statistic.design_statistic?.all}
              isEmptyPatentData={!hasRegistered(statistic.patent_statistic)}
              isEmptyTrademarkData={
                !hasRegistered(statistic.trademark_statistic)
              }
              isEmptyDesignData={!hasRegistered(statistic.design_statistic)}
            />
          ) : (
            isInvestor && <HomeInvestor history={history} />
          )
        ) : isMobile ? (
          <IntroFindBiz />
        ) : (
          <HomeTaskManage />
        )}
      </div>
    </>
  );
}

export default Home;
