import React from "react";
import UserAuthRoute from "./common/UserAuthRoute";
import MobileUserManage from "../components/manage/mobile/MobileUserManage";
import MyPrice from "../components/manage/MyPrice";
import MyProduct from "../components/manage/MyProduct";
import CompanyCode from "../components/manage/CompanyCode";
import ManageApproval from "../components/manage/ManageApproval";
import MySelectboxDB from "../components/manage/MySelectboxDB";
import ManageMyPoint from "../components/manage/ManageMyPoint";
import UserManage from "../components/manage/UserManage";
import CustomSwitch from "./common/CustomSwitch";
import CacheRoute from "react-router-cache-route";
import ManageTradeMark from "../components/manage/ManageTradeMark";
import InventorMember from "../components/manage/InventorMember";
import ManageCompanyInfo from "../components/manage/ManageCompanyInfo";

function ManageRoute({ match }) {
  return (
    <CustomSwitch>
      <UserAuthRoute
        exact
        path={`${match.path}/user`}
        requireAuth={true}
        component={UserManage}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/mobile`}
        requireAuth={true}
        component={MobileUserManage}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/product`}
        requireAuth={true}
        component={MyProduct}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/code`}
        requireAuth={true}
        component={CompanyCode}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/approval`}
        requireAuth={true}
        component={ManageApproval}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/selectboxDB`}
        requireAuth={true}
        component={MySelectboxDB}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/inventor`}
        requireAuth={true}
        component={InventorMember}
      />
      <UserAuthRoute
        exact
        path={`${match.path}/companyInfo`}
        requireAuth={true}
        component={ManageCompanyInfo}
      />
      <CacheRoute
        exact
        path={`${match.path}/price`}
        render={(props) => window.$Global.renderFunc(props, MyPrice)}
        when="always"
      />
      <CacheRoute
        exact
        path={`${match.path}/point`}
        render={(props) => window.$Global.renderFunc(props, ManageMyPoint)}
        when="always"
      />
      <UserAuthRoute
        exact
        path={`${match.path}/trademark`}
        requireAuth={true}
        component={ManageTradeMark}
      />
    </CustomSwitch>
  );
}

export default ManageRoute;
