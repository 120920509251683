import Pagination from "../../common/Pagination";
import React, { useEffect, useState } from "react";
import AdminAPI from "../../../API/admin";

const AdminGiveUpInventorList = ({ history, location }) => {
  const { apply_number, dummy_patent_idx } = location.state;
  const [curPage, setCurPage] = useState(1);
  const [last, setLast] = useState(1);
  const [inventorList, setInventorList] = useState([]);

  useEffect(() => {
    getInventorList();
  }, []);

  const getInventorList = () => {
    let params = {
      page: curPage,
      count: 10,
      dummy_patent_idx: dummy_patent_idx,
    };
    AdminAPI.getPatentInventorList(params).then((res) => {
      setLast(res.data.last);
      res.data.items.map((item) => {
        item.isEdit = false;
      });
      setInventorList(res.data.items);
    });
  };

  const updateInventorInfo = (item) => {
    AdminAPI.updateInventorInfo(item.idx, item).then((res) => {
      getInventorList();
    });
  };

  const handleChange = (e, item, type) => {
    item[type] = e.target.value;
    setInventorList([...inventorList]);
  };

  return (
    <div id="InventorList">
      <div className="header">
        <h2>발명자 리스트 확인하기</h2>
        <button onClick={() => history.goBack()}>목록으로 돌아가기</button>
      </div>
      <div className="inventor_text">"{apply_number}"</div>
      <div className="inventor_list">
        <table>
          <colgroup>
            <col width="50px" />
            <col width="80px" />
            <col width="200px" />
            <col width="250px" />
            <col width="500px" />
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>이름</th>
              <th>전화번호</th>
              <th>이메일</th>
              <th>주소</th>
              <th>수정</th>
            </tr>
          </thead>
          <tbody>
            {inventorList?.map((item, index) => {
              return (
                <tr>
                  <td>{(curPage - 1) * 10 + (index + 1)}</td>
                  <td>
                    {item.isEdit ? (
                      <input
                        type="text"
                        value={item.inventor_name}
                        onChange={(e) => {
                          handleChange(e, item, "inventor_name");
                        }}
                      />
                    ) : (
                      item.inventor_name
                    )}
                  </td>
                  <td>
                    {item.isEdit ? (
                      <input
                        type="text"
                        value={item.user_tel_no}
                        onChange={(e) => {
                          handleChange(e, item, "user_tel_no");
                        }}
                      />
                    ) : (
                      item.user_tel_no
                    )}
                  </td>
                  <td>
                    {item.isEdit ? (
                      <input
                        type="text"
                        value={item.user_email}
                        onChange={(e) => {
                          handleChange(e, item, "user_email");
                        }}
                      />
                    ) : (
                      item.user_email
                    )}
                  </td>
                  <td>
                    {item.isEdit ? (
                      <input
                        type="text"
                        value={item.user_addr}
                        onChange={(e) => {
                          handleChange(e, item, "user_addr");
                        }}
                      />
                    ) : (
                      item.user_addr
                    )}
                  </td>
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {item.isEdit ? (
                      <>
                        <button
                          className="btn_save"
                          onClick={() => updateInventorInfo(item)}
                        >
                          저장
                        </button>
                        <button
                          className="btn_cancel"
                          onClick={() => {
                            getInventorList();
                          }}
                        >
                          취소
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn_edit"
                        onClick={() => {
                          item.isEdit = !item.isEdit;
                          setInventorList([...inventorList]);
                        }}
                      >
                        수정
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
    </div>
  );
};

export default AdminGiveUpInventorList;
