export const colors = [
  "#4E7CE9",
  "#A18CF7",
  "#33CBD0",
  "#FF9675",
  "#F2677C",
  "#FFD200",
  "#95B0F2",
  "#DCE5FB",
  "#D0C5FB",
  "#FEC1C1",
  "#FFB84F",
  "#B8CBF6",
];

export const techEval = {
  data: {
    labels: ["2020", "2021", "2022", "2023", "2024"],
    datasets: [
      {
        label: "AAA",
        data: [5, 6, 7, 6, 5],
        backgroundColor: colors[0],
      },
      {
        label: "AA",
        data: [10, 9, 8, 9, 10],
        backgroundColor: colors[1],
      },
      {
        label: "A",
        data: [15, 14, 13, 14, 15],
        backgroundColor: colors[2],
      },
      {
        label: "BBB",
        data: [20, 19, 18, 19, 20],
        backgroundColor: colors[3],
      },
      {
        label: "BB",
        data: [25, 24, 23, 24, 25],
        backgroundColor: colors[4],
      },
      {
        label: "B",
        data: [30, 29, 28, 29, 30],
        backgroundColor: colors[5],
      },
      {
        label: "CCC",
        data: [5, 6, 7, 6, 5],
        backgroundColor: colors[6],
      },
      {
        label: "CC",
        data: [3, 4, 5, 4, 3],
        backgroundColor: colors[7],
      },
      {
        label: "C",
        data: [1, 2, 3, 2, 1],
        backgroundColor: colors[8],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
          beginAtZero: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            callback: function (value) {
              return window.$Global.commaify(value) + "건";
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        pointStyle: "rectRounded",
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const datasetLabel = dataset.label || "";
          const value = dataset.data[tooltipItem.index];
          return `${datasetLabel}: ${window.$Global.commaify(value)}건`;
        },
      },
    },
  },
};

export const techTransfer = {
  data: {
    labels: ["2020", "2021", "2022", "2023", "2024"],
    datasets: [
      {
        type: "bar",
        label: "건수",
        data: [10, 20, 30, 40, 50], // Example data, adjust accordingly
        backgroundColor: colors[0],
        yAxisID: "y-axis-1",
      },
      {
        type: "line",
        label: "누적 건수",
        data: [10, 100, 60, 100, 150], // Example data, adjust accordingly
        borderColor: colors[11],
        backgroundColor: colors[11],
        fill: false,
        yAxisID: "y-axis-1",
        tension: 0,
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-1",
          type: "linear",
          position: "left",
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return `${window.$Global.commaify(value)}건`;
            },
          },
        },
      ],
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
        filter: function (legendItem, chartData) {
          const dataset = chartData.datasets[legendItem.datasetIndex];
          if (dataset.type === "line") {
            legendItem.fillStyle = dataset.borderColor;
            legendItem.strokeStyle = dataset.borderColor;
          }
          return true;
        },
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var datasetLabel =
            data.datasets[tooltipItem.datasetIndex].label || "";
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var formattedValue =
            parseInt(value) >= 1000
              ? window.$Global.commaify(value) + "건"
              : value + "건";
          return datasetLabel + ": " + formattedValue;
        },
      },
    },
  },
};

export const univHeaderData = [
  {
    value: "No",
    row: 40,
  },
  {
    value: "대학교",
    row: 40,
  },
  {
    value: "기술이전 건수",
    row: 40,
  },
  {
    value: "주요 발명자",
    row: 40,
  },
  {
    value: "평균 등급",
    row: 40,
  },
  {
    value: "주요 IPC 분야",
    row: 40,
  },
  {
    value: "키워드",
    row: 40,
  },
];

export const chartOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false, // 범례 표시
    position: "bottom",
    labels: {
      usePointStyle: true,
      pointStyle: "rectRounded",
    },
  },
  plugins: {
    datalabels: {
      color: "#fff", // 데이터 레이블 색상
      anchor: "end", // 레이블 위치
      align: "start", // 레이블 정렬
      font: {
        size: 14, // 폰트 크기
      },
      formatter: (value) => {
        return value > 0 ? `${value}` : ""; // 값이 0보다 클 경우만 표시
      },
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          font: {
            size: 14, // Y축 글씨 크기
          },
        },
      },
    ],
  },
};
