import React from "react";
import "./css/ResearchIFrame.scss";
const ResearchIFrame = ({ item, setShowPopup }) => {
  const {
    apply_number,
    document_number,
    nation_string,
    companyName,
    activeTab,
    nation,
  } = item;

  return (
    <div className="modal">
      <iframe
        src={`https://dev.biznavi.co.kr/researchTech/ipnow/patent/${
          nation_string === "KR"
            ? apply_number.replace(/-/g, "")
            : item.document_number
        }/detail?nation=${
          nation == 3 ? "EP" : nation_string
        }&companyName=${companyName}&tab=${activeTab}`}
        width="100%"
        height="100%"
      />
      <button onClick={() => setShowPopup(false)}>
        <i className="icon_exit" />
      </button>
    </div>
  );
};

export default ResearchIFrame;
