import React, { useCallback, useEffect, useState } from "react";
import ContentLayout from "../../dueDiligence/ContentLayout";
import PatentAPI from "../../../API/patent";
import { CURRENT_YEAR } from "../../dueDiligence/constants/years_config";
import Pagination from "../../common/Pagination";
import DetailData from "./DetailData";
import "../css/CandidatePop.scss";

const CompanyPopup = ({ yearData, header, closePopup, selectedData }) => {
  const [companyStatusYear, setCompanyStatusYear] = useState({
    year: yearData.year,
    isAccumulated: yearData.isAccumulated,
  });
  const widths = [1, 3, 1, 2, 2, 2, 3];
  const [rowData, setRowData] = useState({});
  const [page, setPage] = useState(1);
  const [companyData, setCompanyData] = useState({});
  const getTransferCompany = useCallback(async () => {
    const { year, isAccumulated } = companyStatusYear;
    const res = await PatentAPI.getTransferCompany({
      apply_at: year,
      count: 10,
      page: page,
      company_code: selectedData[2],
      ...(isAccumulated && { sub_apply_at: CURRENT_YEAR }),
    });
    return res.data || [];
  }, [companyStatusYear, page]);
  useEffect(() => {
    const getRowData = async () => {
      const data = await getTransferCompany();
      setRowData(data);
    };
    getRowData();
  }, [page]);

  const formatValue = (value, i, arrLength) => {
    if (value === 0) return ""; // value가 0이면 빈 문자열
    else if (i === 3) return `${value}건`; // i === 3이면 'n건' 형식
    else if (i === arrLength - 1 && typeof value === "string" && value !== "") {
      return value
        .split(",")
        .map((word) => `#${word.trim()}`)
        .join(" "); // 마지막 값이면 해시태그 변환
    }

    return window.$Global.commaify(value);
  };
  return (
    <div
      onClick={() => {
        closePopup();
      }}
      id="CandidatePop"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="patent_content"
      >
        <ContentLayout
          title={`${selectedData[1]} 기술이전 현황`}
          btnContent={yearData.isAccumulated ? "누적" : yearData.year}
          content={
            <>
              <div className="categoryTableWrapper">
                <div className="category">
                  <div style={{ width: "1340px" }} className="InfoTable">
                    <table className="researcher-table">
                      <colgroup>
                        {header?.map((element, idx) => {
                          return <col width={`${widths[idx]}px`} />;
                        })}
                      </colgroup>
                      <thead>
                        <tr>
                          {header?.map((element) => {
                            return <th className="grid-header">{element}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="no-hover">
                          {Object.values(selectedData).map((value, i, arr) => {
                            if (i === 2) return null; // i === 2는 건너뜀

                            return (
                              <td key={i} className="grid-cell">
                                {formatValue(value, i, arr.length)}
                              </td>
                            );
                          })}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="category"
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      width: "440px",
                      maxWidth: "440px",
                      position: "relative",
                    }}
                    className="InfoTable"
                  >
                    <strong style={{ position: "absolute", top: "-30px" }}>
                      기업별 기술이전 현황
                    </strong>
                    <table className="researcher-table">
                      <thead>
                        <tr>
                          <th className="grid-header" style={{ width: 1 }}>
                            Rank
                          </th>
                          <th className="grid-header" style={{ width: 2 }}>
                            기업명(양수인)
                          </th>
                          <th className="grid-header" style={{ width: 1 }}>
                            건수
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {rowData?.data?.length > 0 ? (
                          rowData?.data?.map((row, index) => {
                            return (
                              <tr
                                key={index}
                                onClick={() => {
                                  setCompanyData(row);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <td className="grid-cell">
                                  {index + 1 + 10 * (page - 1)}
                                </td>
                                <td className="grid-cell">{row.transferee}</td>
                                <td className="grid-cell">{`${row.cnt}건`}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={3}>데이터가 없습니다.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {/* 데이터가 존재할 경우에만 Pagination 노출 */}
                    <Pagination
                      curPage={page}
                      lastNum={Math.ceil(rowData.total_count / 10)}
                      disableMoveToTop={true}
                      onClick={setPage}
                    />
                  </div>
                  {Object.keys(companyData).length > 0 && (
                    <DetailData
                      companyStatusYear={companyStatusYear}
                      companyData={companyData}
                    />
                  )}
                </div>
              </div>
            </>
          }
        />

        <button
          style={{
            position: "absolute",
            right: "20px",
            top: "20px",
            border: "transparent",
          }}
          onClick={() => {
            closePopup();
          }}
        >
          <i className="icon_exit_gray"></i>
        </button>
      </div>
    </div>
  );
};

export default CompanyPopup;
